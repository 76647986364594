import React, { useState } from "react";
import { Col, Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Avatar, Spin } from "antd";
import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-alice-carousel/lib/alice-carousel.css";

import { cardData } from "./data";
import { useHistory } from "react-router-dom";
import Arrow from "../../../images/arrowIcon.png"
const CardOne = ({e, key}) => {
  const history = useHistory();
  const [isEnable, setIsEnable] = useState(false);
  const advisorCategoryPage = (e) => {
    history.push(e?.url);
  };
  const enableArrow = () => {
    setIsEnable(true);
  };
  const disableArrow = () => {
    setIsEnable(false);
  };
  
  return (
    <>

                  <Card
                    className={`categ ${isEnable === true && 'border-categ'} `}
                    key={key}
                    onClick={(e1) => advisorCategoryPage(e)}
                    onMouseEnter={() => enableArrow()}
                    onMouseLeave={() => disableArrow()}
                  >
                    <Card.Body>
                      <div className="d-flex flex-column">
                      <div className={`d-flex ${isEnable === true && 'justify-content-between'} `}>
                          <Avatar shape="square" size={60} src={e.img} />
                        {isEnable === true && <div> <img src={Arrow} alt="arrowIcon" /></div>}
                          </div>
                        <p className="font-cards color-global pt-3 bold-txt black">
                          {e.title}
                        </p>
                        <p className="card-txt font-card2 color-global my-2 ">
                          {e.description}
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                
    </>
  );
};

export default CardOne;
