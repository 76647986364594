import axios from "axios";
import { setDeletePaymentProfiles, setGetPaymentProfiles, setInAppProductListing, setUpdatePaymentProfiles } from "../redux/actions";
import { BASE_URL } from "../config/constants";
import { message, Modal } from "antd";

///////////////////// CREDIT CARD /////////////////
// START  -- v21_1
export const addPaymentProfile = async (obj,setBtnLoading, success) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/API/v21_1/Authorize_Net_API/addPaymentProfile`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("MustttttObj", obj);
    console.log("res: ", res);
    if (res?.data?.result === '1') {
      setBtnLoading(false)
      success();
    }
    if (res?.data?.result === '0') {
      setBtnLoading(false)
      message.error(res?.data?.message);
    }
  } catch (err) {
    // setBtnLoading(false);
    console.log("err", err);
    // const error = err?.messages?.message?.map((e, i)=>{
    //  return e.text
    // })
    // console.log("error", error);
    // message.error(error[0]);
  }
};
export const getPaymentProfiles = async (dispatch) => {
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {} ;
  try {
    const obj = {
      auth_key: 'NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj',
      client_id: userInfo?.id,
    }
    const res = await axios.post(
      `${BASE_URL}/API/v21_1/Authorize_Net_API/getPaymentProfiles`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("res: ", res);
    if (res?.data?.result === '1') {
      console.log("get profs. details", res.data.message);
      dispatch(setGetPaymentProfiles(res.data.message))
    }
    //   if(res?.data?.result === 0){
    //      dispatch(setInAppProductListing(res.data.message))
    //   }
  } catch (err) {
    console.log("err", err);
  }
};
export const chargeCustomer = async (obj,setChargeLoadBtn, callPaymentConfirmModal) => {
  try {
console.log("chargCust", obj);
    const res = await axios.post(
      `${BASE_URL}/API/v21_1/Authorize_Net_API/chargeCustomer`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("res: ", res);
    if (res?.data?.result === '1') {
      console.log("get profs. details", res.data.message);
      callPaymentConfirmModal();
    }
    setChargeLoadBtn(false)
    //   if(res?.data?.result === 0){
    //      dispatch(setInAppProductListing(res.data.message))
    //   }
  } catch (err) {
    console.log("err", err);
  }
};
export const updatePaymentProfile = async (customerPaymentProfileId, success, dispatch) => {
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {} ;
  try {
    const obj = {
      auth_key: 'NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj',
      client_id: userInfo?.id,
      payment_profile_id: customerPaymentProfileId,
    }
    const res = await axios.post(
      `${BASE_URL}/API/v21_1/Authorize_Net_API/updateDefaultPaymentProfile`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("res: ", res);
    if (res?.data?.result === '1') {
      console.log("get profs. details", res.data.message);
      // updatePaymentProfiles(customerPaymentProfileId);
      // dispatch(setUpdatePaymentProfiles(customerPaymentProfileId))
      success();
    }
    //   if(res?.data?.result === 0){
    //      dispatch(setInAppProductListing(res.data.message))
    //   }
  } catch (err) {
    console.log("err", err);
  }
};
export const deletePaymentProfile = async (customerPaymentProfileId, paymentProfiles, deleteSuccess, dispatch) => {
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {} ;
  const token = localStorage.getItem('token');
  try {
    const obj = {
      auth_key: 'NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj',
      client_id: userInfo?.id,
      payment_profile_id: customerPaymentProfileId,
    }
    const res = await axios.post(
      `${BASE_URL}/API/v21_1/Authorize_Net_API/deletePaymentProfile`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          // "Authorization" : `Bearer ${token}`
        },
      }
    );
    console.log("res: ", res);
    if (res?.data?.result === '1') {
      console.log("get profs. details", res.data.message);
     const checking = paymentProfiles?.filter(e=> e.customerPaymentProfileId !== customerPaymentProfileId)
      console.log("checking", checking);
      dispatch(setDeletePaymentProfiles(checking))
     deleteSuccess();
    }
    //   if(res?.data?.result === 0){
    //      
    //   }
  } catch (err) {
    console.log("err", err);
  }
};
// END
// -------------------------------------------

/////////////// PAYPAL /////////////////

// START  -- v21_1

export const getPaypalClientToken = async (dispatch, setIsSpin) => {
  const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {} ;
  try {
    const obj = {
      auth_key: 'NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj',
      client_id: userInfo?.id,
      device_type: 'web',
      tarot_id: "d8f6548b1a5530d2",
    }
    const res = await axios.post(
      `${BASE_URL}/API/v21_1/Braintree_API/getClientToken`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("RES_Braintree_API", res);
    if (res?.data?.result === '1') {
      console.log("tokenn", res.data.access_token);
      localStorage.setItem("paypalToken", res.data.access_token);
      setIsSpin(false);
      // dispatch(setGetPaymentProfiles(res.data.message))
    }
    // else{
    //   message.error("Something went wrong. Try agin later.")
    // }
      // if(res?.data?.result === 0){
      //    dispatch(setInAppProductListing(res.data.message))
      // }
  } catch (err) {
    console.log("err", err);
  }
};
export const createPaypalTransaction = async (obj, callPaymentConfirmModal, setIsSpin) => {
  // const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {} ;
  try {
    // const obj = {
    //   auth_key: 'NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj',
    //   payment_method_nonce: payload?.nonce,
    //   amount: '',
    //   description: '',
    //   purchase_item_id: '',
    //   client_id: userInfo?.id,
    //   extended: '',
    //   device_type: 'web',
    // }
    console.log("objpaypal", obj);
    const res = await axios.post(
      `${BASE_URL}/API/v21_1/Braintree_API/createPaypalTransaction`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("RES_Paypalcheckout_API", res);
    if (res?.data?.result === '1') {
      // console.log("tokenn", res.data.access_token);
      // message.success(res.data.message);
      localStorage.removeItem("paypalToken");
      callPaymentConfirmModal(res.data.message);
      // dispatch(setGetPaymentProfiles(res.data.message))
    }
    if (res?.data?.result === '0') {
      message.error(res.data.message);
      setIsSpin(false);
      // dispatch(setGetPaymentProfiles(res.data.message))
    }
  } catch (err) {
    console.log("err", err);
  }
};



