import React, { memo, useEffect, useState } from "react";
import { Divider, Skeleton, Tabs } from "antd";
import { Row, Col } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import Nodata from "../../../images/nodata.svg"
import AdvisorsReviewList from "./advisorReviewsList";
import OverView from "./OverView";
import { useDispatch, useSelector } from "react-redux";
import { getReviewsForAdvisor } from "../../../api/review";

import "./index.css";

const Index = ({ advisorDetails, reviewsList }) => {
  const [isReviews, setIsReviews] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [isloading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  console.log("reviews", reviewsList);
  useEffect(() => {
    setIsReviews(reviewsList);
  }, [reviewsList]);
  const fetchMoreReviews =() =>{
    setIsLoading(true);
    setPageNo(prev=> prev + 1); 
  }
  console.log("pageNo", pageNo);
  useEffect(() => {
    if(pageNo !== 0){
      getReviewsForAdvisor(advisorDetails?.id, pageNo, setIsLoading, dispatch);
    } 
  }, [pageNo]);
  const newReviews = useSelector(x => x.advisorReviews);
  console.log('newReviews', newReviews);
  useEffect(()=>{
    setIsLoading(false);
    setIsReviews(prev => { return [...prev, newReviews]});
  }, [newReviews])
  console.log("isReviews", isReviews);
  return (
    <>
      <Row className="main_review_section bg-light my-4" id="reviews">
        <Col lg={12} className="main_review_section_heading mb-4">
          <h6>All Reviews</h6>
        </Col>
        <Col lg={12} className="main_review_section_analysis">
          <OverView
            ratings={advisorDetails?.ratings}
            reviewsCount={reviewsList?.length}
          />
        </Col>
        <Col lg={12}>
          <Divider />
        </Col>
        <Col lg={12}>
          {isReviews?.length !== 0 ? (
            <>
              {isReviews?.flat(1)?.map((e1, i) => {
                return (
                  <>
                    <AdvisorsReviewList e1={e1} key={i} advisorDetails={advisorDetails} />
                  </>
                );
              })}
             {isloading === false ? <div className="seeMoreReviews" onClick={() => fetchMoreReviews()}>
                <p>See More </p>
                <div>
                  <BsArrowRight style={{ color: "#3A86F4" }} />
                </div>
              </div>: <Skeleton active />}
            </>
          ) : (
            <div className="main-nodata">
              <div className="nodata-container">
              <img src={Nodata} alt="nodata" />
            </div>
            <h3>Nothing to show...</h3>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default memo(Index);
