import React, { memo, useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import "./index.css";
import { getInAppProducts } from "../../../api/credits";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

const DropdownChat = ({
  setPurchaseID,
  setSelectedItem,
  selectedItem,
  setIsModalOpen,
  setIsVisible,
  isVisible,
  hidee,
  setHidee,
  proff,
}) => {
  const dispatch = useDispatch();
  const [appProd, setAppProd] = useState([]);
  const [isSpin, setIsSpin] = useState(null);
  const [items, setItems] = useState([]);
  // const [isVisible, setIsVisible] = useState(false);
  const [defaultItem, setDefaultItem] = useState(null);
 const [minList, setMinList] = useState([3,5,10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]);
 const [creditsList, setCreditsList] = useState([]);
  // const [selectedItem, setSelectedItem] = useState(null)
  // const items = [
  //   {
  //     label: "1st menu item",
  //     key: "0",
  //   },
  //   {
  //     label: "2nd menu item",
  //     key: "1",
  //   },
  //   {
  //     type: "divider",
  //   },
  //   {
  //     label: "3rd menu item",
  //     key: "3",
  //   },
  // ];
  useEffect(() => {
    getInAppProducts(setIsSpin, dispatch);
  }, []);

  useEffect(()=>{
    const arr = minList?.map((e)=>{
      let priceSet = +(proff?.live_rate) * e
      return {label : e + " mins", price: priceSet, key: e};
    });
    console.log("testArr", arr);
    setCreditsList(arr);
  }, [proff, minList])

  useEffect(() => {
    if (localStorage.getItem("dropdown-credits")) {
      const localItem = JSON.parse(localStorage.getItem("dropdown-credits"));
      setSelectedItem({
        label: localItem?.label,
        price: localItem?.price,
        key: localItem?.key,
      });
    } else {
      if(creditsList?.length !== 0){
        setSelectedItem({
          label: creditsList[0]?.label,
          price: creditsList[0]?.price,
          key: creditsList[0]?.key,
        });
      }
    }
  }, [creditsList]);
  // const inappProducts = useSelector((state) => state.inAppProductList);

  // useEffect(() => {
  //   if (inappProducts !== null && inappProducts !== undefined) {
  //     console.log("appProd", appProd["Live Credit"]);
  //     setAppProd(inappProducts["Live Credit"]);
  //   }
  // }, [inappProducts]);

  // console.log("appProd", appProd);
  // useEffect(() => {
  //   const newCredits = appProd?.map((e) => {
  //     return {
  //       label: e?.credits?.concat(" Credits"),
  //       key: e?.id,
  //       productid: e?.product_id,
  //       price: e?.price,
  //     };
  //   });
  //   setItems(newCredits);
  // }, [appProd]);

  // const selectcredits = ({ label }) => {
  //   console.log("label", label);
  // };
  // const handleChange = (value) => {
  //   console.log(`selected ${value}`);
  // };
  const showVisibleDiv = () => {
    setIsVisible(!isVisible);
  };
  console.log("items", items);

  const selectCredits = (item) => {
    localStorage.setItem("dropdown-credits", JSON.stringify({ label: item?.label, price: item?.price, key: item?.key }));
    setSelectedItem({ label: item?.label, price: item?.price, key: item?.key });
    setIsVisible(false);
    setPurchaseID(item?.key);
    setIsModalOpen(false);
  };
  console.log("selectedItem", selectedItem)
  // useEffect(()=>{
  //   if(hidee === true && isVisible === true){
  //     setIsVisible(false);
  //   }
  // }, [hidee])
  // console.log("hideee", hidee, isVisible);
  return (
    <>
      <div className="main_drop_container">
        <div
          className="head_drop_container credits_drop_down"
          onClick={() => showVisibleDiv()}
        >
          <span className="part2_para">
            {selectedItem === null
              ? "Choose your credits"
              : selectedItem?.label}
          </span>
          <div className="d-flex align-items-center part2_para">
            {selectedItem === null ? "$0.00" : `$${selectedItem?.price + '.00'}`} &nbsp;
            <RightOutlined />
          </div>
        </div>
        {isVisible === true && (
          <div className="body_drop_container">
            {creditsList?.map((item) => {
              return (
                <p onClick={() => selectCredits(item)}>
                  <span>{item?.label}</span>
                  <span>$ {item?.price + '.00'}</span>
                </p>
              );
            })}
          </div>
        )} 
      </div>

      {/* <Dropdown
        menu={{
          items,
          selectcredits,
        }}
        className="drop_width"
        trigger={["click"]}
        dropdownRender={(menu)=>{

        }}

        
      >
        <a onClick={(e) =>  e.preventDefault()} className="txt_line">
          <Space className="credits_drop_down">
            20 Live Credits
            <div className="d-flex align-items-center">
              $20.00 &nbsp;
              <RightOutlined />
            </div>
          </Space>
        </a>
      </Dropdown>
      <Select
        className="drop_width"
    defaultValue="lucy"
    style={{
      width: 200,
    }}
    onChange={handleChange}
    options={items.map((item) => ({
      label: item?.label.concat(' ').concat(item?.price),
      value: item?.price,
    }))}
  /> */}
    </>
  );
};

export default DropdownChat;
