import React, { memo, useEffect, useState } from "react";
import { Alert, Avatar, Button, DatePicker, Form, Input, Select } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { updateUserInfo } from "../../../api/client";
import { useDispatch } from "react-redux";
import Woman from "../../../images/woman.svg";
import "./index.css";

const { Option } = Select;

const Index = ({ setUserPopDetails, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const [isDisable, setIsDisable] = useState(true)
  const onFinish = async (values) => {
    console.log("Success:", values);
    updateUserInfo(values, dispatch);
    console.log("date", moment(values.date).format("YYYY-MM-DD"));
    setUserPopDetails(values);
    setIsModalOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const userInformation = localStorage.getItem("emailForSignIn") || "";
    console.log("userInformation", userInformation);
  });
  const checkDob = (date, dateString) => {
    setIsDisable(false);
    console.log("Value", date, dateString);

    const date1 = new Date(dateString);
    const date2 = new Date();
    let yearsDiff = date2.getFullYear() - date1.getFullYear();
    console.log("yearsDiff", yearsDiff);
    // console.log(diffTime + " milliseconds");
    // console.log(diffDays + " days");
    if (yearsDiff >= 18) {
      setIsDisable(true);
    
    }else{
      setIsDisable(false);
    }
  };
  console.log("---------------------CHK----------------------");
  return (
    <>
      <Container>
        <Row className="d-flex justify-content-start align-items-center">
          <Col lg={12} className="d-flex flex-column justify-content-center align-items-center">
         <div className="d-flex justify-content-center align-items-center">  
          <Form
              name="basic"
              // labelCol={{
              //   span: 8,
              // }}
              wrapperCol={{
                span: 24,
              }}
              style={{width: '350px', height: '450px'}}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="d-flex flex-column gap-3 justify-content-center"
            >
             <div style={{width: '346px'}} className="d-flex flex-column gap-3">
              <Avatar src={Woman} />
              <p className="intro_txt">Introduce Yourself</p>
              <p className="intro_des">How would you like your advisor to address you?</p>
            </div>
              <Form.Item
                name="Username"
                // label="User Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your user name!",
                  },
                  {
                    message: "Minimum (3)",
                    min: 3,
                  },
                ]}
              >
                <Input className="user_input_name" placeholder="User name"  />
              </Form.Item>
              <Form.Item
                name="date"
                // label="Date of Birth"
                rules={[
                  {
                    required: true,
                    message: "Please input your date of birth!",
                  },
                ]}
                validateStatus={isDisable === false ? "error" : ''}
                help={isDisable === false ? "User should be atleast 18 years" : ''}
              >
                <DatePicker className="user_input_name" onChange={checkDob} placeholder="Date Of Birth" />
              </Form.Item>
              {/* <Form.Item
                name="gender"
                // label="Gender"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Select
                  placeholder="Select a option and change input text above"
                  // onChange={this.onGenderChange}
                  allowClear
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Form.Item> */}
 <p className="disclamer">{'*'} Please enter your date of birth so we can provide you with the most accurate reading.</p>
             <div className="d-flex gap-2">
             <Button className="cancel_btn">
                  Cancel
                </Button>
             <Form.Item
                // wrapperCol={{
                //   offset: 12,
                //   span: 10,
                // }}
              >
                <Button className="connect_btn" htmlType="submit">
                  Connect
                </Button>
              </Form.Item>
         
             </div>
             
             </Form>
            </div>  
           
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(Index);
