import React, { memo, useEffect, useState } from "react";
import { Alert, Button, Form, Input, message, Spin } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import auth from "../../../config/firebase";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { useHistory } from "react-router-dom";
import { usePubNub } from "pubnub-react";
import { finalLogin, registerUser } from "../../../api/auth";
import { LoadingOutlined } from "@ant-design/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";
import "./index.css";

const Index = ({ setNormalMode, setEmailMode }) => {
  const returnTo = '/';
  const pubnub = usePubNub();
  const history = useHistory();
  const [userEmail, setUserEmail] = useState("");
  const [channels, setChannels] = useState();
  const [showpopup, setShowPopup] = useState(false);
  const [showPopupRedirect, setShowPopupRedirect] = useState(false);
  const [showBtn, setShowBtn] = useState(true);
  const [showResendBtn, setShowResendBtn] = useState(true);
  const [modal2Open, setModal2Open] = useState(false)
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 70,
      }}
      spin
    />
  );

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      setShowPopupRedirect(true);
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
        window.localStorage.setItem("emailForSignIn", email);
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          console.log("res", result);
          window.localStorage.removeItem("emailForSignIn");
          // localStorage.setItem("token", result?.user?.accessToken);
          window.localStorage.setItem("userInfo", JSON.stringify(result?.user));
          if (result?.user?.emailVerified === true) {
            finalLogin(
              result?.user,
              setShowPopupRedirect,
              setModal2Open,
              returnTo,
              history
            );
            // history.push("/advisors");
            // message.success("Login successfully!");
          } else {
            registerUser(
              result?.user,
              setShowPopupRedirect,
              setModal2Open,
              returnTo,
              history
            );
            // message.warning("Unable to login. Please create your account first!")
          }
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  });
  const onFinish = async (values) => {
    console.log("Success:", values.email);
    setShowBtn(false);
    if (showBtn === false) {
      setShowResendBtn(false);
    }
    setUserEmail(values.email);
    var emaill = values.email;
    const actionCodeSettings = {
      url: "https://psychictxt--staging-iw55bmye.web.app/email",
      // url: "https://app.psychictxt.com/email",
      handleCodeInApp: true,
    };
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
        window.localStorage.setItem("emailForSignIn", email);
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          console.log("res final email after redirect", result);
          window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    } else {
      sendSignInLinkToEmail(auth, emaill, actionCodeSettings)
        .then((result) => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          console.log("result of send link to email", result);
          console.log("working");
          // message.success("Link is sent to your email")
          setShowPopup(true);
          window.localStorage.setItem("emailForSignIn", values.email);
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("error", error);
          // ...
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const leaveApplication = () => {
    pubnub.removeListener(listnerAdd);
    pubnub.unsubscribeAll();
  };
  const listnerAdd = {
    status: (statusEvent) => {
      var subscribedChannels = statusEvent.subscribedChannels;
      console.log("subscribedChannels", subscribedChannels);
      if (statusEvent.category === "PNConnectedCategory") {
        console.log("Connected");
      }
    },
    message: (event) => {
      const message = event.message;
      console.log("message", message);
    },
  };
  useEffect(() => {
    const userInformation = localStorage.getItem("emailForSignIn") || "";
    console.log("userInformation", userInformation);
    setChannels("Channel_".concat(userEmail));
    if (userInformation !== null && userInformation !== "") {
      setChannels("Channel_".concat(userInformation));
    }
  });

  useEffect(() => {
    console.log("channelss", channels, listnerAdd);
    pubnub.addListener(listnerAdd);
    if (channels) {
      pubnub.subscribe({
        channels: [channels, "channel_application"],
        withPresence: true,
      });
      pubnub
        .publish({
          channel: channels,
          message: "Hello World!",
        })
        .then((result) => {
          console.log("result", result);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
    return leaveApplication;
  }, [pubnub, channels, userEmail]);

  return (
    <>
      <Container>
        {showpopup === true && (
          <Alert
            // style={{ width: "00px" }}
            className="test"
            message="An email has been sent to your address, please login using the link provided in your email."
            type="info"
          />
        )}
        {showPopupRedirect === true && (
          <Row className="d-flex flex-column justify-content-center align-items-center gap-2 vh-100">
            <Spin indicator={antIcon} />
            <h6 className="text-center">Please wait...</h6>
          </Row>
        )}

        {showPopupRedirect === false && (
          <Row className="d-flex flex-column justify-content-center align-items-start h-100">
            <Form
              className="d-flex flex-column formm"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <p className="my-3 title_phone">Please enter your email</p>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  style={{
                    width: "300px",
                  }}
                  className="phone"
                  placeholder="Write email here"
                />
              </Form.Item>
              {showBtn === true ? (
                <Form.Item
                >
                  <Button type="primary" htmlType="submit" className="pointing">
                    Submit
                  </Button>
                </Form.Item>
              ) : (
                <>
                  {" "}
                  {showResendBtn === true && (
                    <Form.Item
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="pointing"
                      >
                        Resend email
                      </Button>
                    </Form.Item>
                  )}
                </>
              )}
              <IoReturnUpBackOutline
              style={{ fontSize: "30px", cursor: "pointer" }}
              color="#3a86f4"
              onClick={() => {
                setNormalMode(true);
                setEmailMode(false);
              }}
            />
            </Form>
          </Row>
        )}
      </Container>
    </>
  );
};

export default memo(Index);
