import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Avatar, Button, Divider, List, Radio } from "antd";
import "./index.css";
import parse from "html-react-parser";
import { BiPurchaseTag } from "react-icons/bi";

const rex =
  /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu;

const ListTopUp = ({ item, i, onChange }) => {
 
  return (
    <>
      {item && item?.credits && (
        <>
          <Col key={item?.id} lg={12} className="sub-list-container" id={item?.id}>
            <Row className={item?.checked === false ? "list" : "listChecked"}>
              <Col
                lg={8}
                className="d-flex justify-content-start gap-2 align-items-center"
              >
                {/* <Radio.Group onChange={onChange} value={checked}> */}
                {console.log("chechHere", item?.checked)}
               <Radio
                  onChange={(e)=> onChange(item)}
                  // value={item?.checked === false ? false : true }
                  checked={item?.checked}
                  // defa
                  id={item?.id}
                  name={item?.id}
                ></Radio>
                {/* </Radio.Group> */}
                {item.icon ? (
               <div style={{width: '40px'}}>  <Avatar src={item.icon} size={35} /></div> 
                ) : (
                  <div style={{width: '40px'}}>   <BiPurchaseTag style={{ fontSize: "35px" }} color="#1890FF" /></div>
                )}
                <div>
                  <p className={item?.checked === false ? "title" : "titleChecked"}>
                    {item.credits}{" "}
                    <span
                      className={
                        item?.checked === false ? "description" : "descriptionChecked"
                      }
                    >
                      credits
                    </span>
                    {
                      item?.discount !== "0" && (
                        <>
                          {" + " + item.discount}{" "}
                          <span
                            className={
                              item?.checked === false
                                ? "description"
                                : "descriptionChecked"
                            }
                          >
                            bonus
                          </span>
                        </>
                      )
                      // "  +  " + item.discount + " bonus"
                    }
                  </p>
                  <span
                    className={
                      item?.checked === false ? " description" : "descriptionChecked"
                    }
                  >
                    {parse(item.description)}
                  </span>
                </div>
              </Col>
              <Col lg={4} className="d-flex justify-content-end">
                <div className="d-flex flex-column align-items-start">
                  <p
                    className={item?.checked === false ? "buy_txt" : "buy_txtChecked"}
                  >
                    Buy Now at
                  </p>
                  <p className={item?.checked === false ? "price" : "priceChecked"}>
                    $ {item?.price}
                  </p>
                </div>
                {/* onClick={(e) => linktoPaymentpage(item.id)} */}
              </Col>
            </Row>
          </Col>
          {/* </Row> */}
          {/* <Divider /> */}
        </>
      )}
    </>
  );
};

export default memo(ListTopUp);
