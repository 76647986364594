import React, { memo, useEffect } from "react";
import { Alert, Avatar, Button, Select } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import Runner from "../../../../images/runner.svg";
import "./index.css";
// import moment from "moment";
// import { updateUserInfo } from "../../../api/client";
// import { useDispatch } from "react-redux";

// const { Option } = Select;

const Index = ({ setOrderType, setIsModalOpen2, img,infoo,dur,typ,repTime, sendMessage }) => {
  console.log("repTime", repTime);
  // const dispatch = useDispatch();
  const onFinish = (e) => {
    console.log("name", e);
    setOrderType(e)
    sendMessage(img, typ, dur, null, e, repTime);
    setIsModalOpen2(false);
  };

  // const onFinishFailed = (errorInfo) => {
  //   console.log("Failed:", errorInfo);
  // };

  // useEffect(() => {
  //   const userInformation = localStorage.getItem("emailForSignIn") || "";
  //   console.log("userInformation", userInformation);
  // });

  return (
    <>
      <Container>
        <Row className="d-flex justify-content-center align-items-center">
          <Col
            lg={10}
            className="d-flex flex-column justify-content-center align-items-center gap-3"
            style={{height: '400px'}}
          >
            {/* <Alert
              message="Upgrade to a FAAST reading to get a response within 60 mins."
              description="FAASt reading costs 75 more credits than standard txt reading.s"
              type="info"
              showIcon
            /> */}
            {/* <br /> */}
            <Avatar src={Runner} size={48} shape="square" />
            <p className="upgrade_fast_text">Upgrade to a FAAST Reading to get a response within 60 Mins.</p>
           <p className="txt_explain">FAAST Reading costs 75 More Credits than Standard Txt Reading</p>
            <Button className="btn_fast" htmlType="submit" onClick={e=> onFinish("fast")}>
              Yes! Get Me A FAAST Reading
            </Button>
            <Button className="noUpgrade" htmlType="submit" onClick={e=> onFinish("text")}>
              No Upgrade
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(Index);
