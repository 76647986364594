import { Input, Radio, Spin, Switch } from "antd";
import React, { useState, useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import { AiFillDelete, AiTwotoneAudio } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import "./index.css";
import Attachment from "./attachment";
import Emoji from "./emoji";
import VoiceRecorder from "./voicerecorder";
import { resolvePlugin } from "@babel/core/lib/config/files";
import { FiSmile } from "react-icons/fi";
import { RiAttachment2 } from "react-icons/ri";

const MessageBox = ({
  sendMessage,
  setMsg,
  clearInput,
  setClearInput,
  setShowResult,
  showResult,
  setSelectedFile,
  selectedFile,
  color,
  setColor,
  setReplyTime,
  setShowAttach,
  showAttach,
  setShowAudio,
  showAudio,
  showInput,
  setShowInput,
  isMsgSpin,
  messageInput,
  setMessage,
  setShowEmojiTrue,
  openEmojiBox,
  advisorsID,
  advisorDetails,
  advisorOnlineStatus
}) => {
  console.log("advisorDetails--", advisorOnlineStatus,  advisorDetails);
  // const [message, setMessage] = useState({ text: "" });
  const [time, setTime] = useState(0);
  const [res, setRes] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [read, setRead] = useState(null);
  const submitMsg = async () => {
    //   if(read !== null){
    //   let  fileee = await fetch(read?.selectedFile?.file).then(r => r.blob()).then(blobFile => new File([blobFile], 'image', { type: blobFile.type }));
    //   sendMessage(
    //     selectedFile.file !== null ? fileee : "",
    //     selectedFile.type,
    //     time
    //   );
    // }else{
    sendMessage(
      selectedFile.file !== null ? selectedFile.file : "",
      selectedFile.type,
      time
    );
    // }
  };
  console.log("selectedFile---------------", selectedFile);
  const inputMessage = (e) => {
    const { value } = e?.target;
    setMessage((prev) => {
      return { ...prev, text: value };
    });
    setMsg((prev) => {
      return { ...prev, text: value };
    });
    setShowAttach(false);
    setShowAudio(false);
  };
  console.log("MSGGGGGGGG", messageInput);
  const addEmoji = (e) => {
    let emoji = e.emoji;
    setMessage((prev) => {
      console.log("prev", prev);
      return {
        // ...prev,
        // text: e.emoji,
        text: prev.text + emoji,
      };
    });
    setMsg((prev) => {
      console.log("prev", prev);
      return { text: prev.text + emoji };
    });
  };
  console.log("text message", messageInput);

  // useEffect(() => {
  //   if (localStorage.getItem("reading") && messageInput?.text === "") {
  //     setSelectedFile({
  //       file: null,
  //       type: "",
  //     });
  //   }
  // }, [messageInput]);

  const delteVoice = () => {
    setShowResult(false);
    setRes("");
    setShowInput(true);
    setShowAttach(true);
    setShowAudio(true);
  };
  console.log("res", res);
  console.log("time", time);
  const handleClick = (e) => {
    console.log("reply", e.target.value);
    setReplyTime(e.target.value);
  };

  return (
    <>
      {
        <>
          {isModalOpen === true && (
            <div className="p-3" style={{ visibility: "hidden" }}>
              <Attachment
                setSelectedFile={setSelectedFile}
                color={color}
                setColor={setColor}
                showAttach={showAttach}
                setMsg={setMsg}
                setMessage={setMessage}
                message={messageInput}
                submitMsg={submitMsg}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                setClearInput={setClearInput}
                clearInput={clearInput}
              />
            </div>
          )}
          {isModalOpen === false && (
            <div
              className={`${
                advisorDetails?.status === "1"
                  ? ""
                  : "layout-overlay"
              } message-boxss`}
            >
              <div className="options">
                <h6>Reply to my question with: </h6>
                <Radio.Group
                  size="small"
                  buttonStyle="solid"
                  variant="danger"
                  onChange={handleClick}
                  defaultValue="text"
                  style={{
                    color: "pink",
                  }}
                  disabled={
                    advisorDetails?.status === "1"
                      ? false
                      : true
                  }
                >
                  <Radio.Button value="text">Text</Radio.Button>
                  <Radio.Button value="audio">Voice</Radio.Button>
                </Radio.Group>
              </div>

              {/* {isModalOpen === false &&  */}
              <div className="checkk d-flex justify-content-start align-items-center py-2">
                {isModalOpen === false && (
                  <div className="input-inbox">
                    {showResult === true ? (
                      <>
                        {" "}
                        {res !== "" ? (
                          <div className="d-flex align-items-center gap-3">
                            <audio controls id="audio">
                              <source src={res} id="src" />
                            </audio>
                            <div>
                              {" "}
                              <AiFillDelete
                                onClick={delteVoice}
                                style={{ fontSize: "25px", color: `red` }}
                              />
                            </div>
                            {time}
                          </div>
                        ) : (
                          <p>Loading...</p>
                        )}
                      </>
                    ) : (
                      <>
                        {" "}
                        {showInput === true && (
                          <Input
                            placeholder={
                              advisorDetails?.status === "1"
                                ? "Write here"
                                : "Your chat has been disabled because advisor is not online."
                            }
                            value={messageInput.text}
                            onChange={(e) => {
                              if(advisorDetails?.status === "1" ){
                                inputMessage(e);
                              }
                            }}
                            className="input-chat"
                            allowClear={true}
                            // disabled={
                            //   advisorDetails?.status === "1" ||
                            //   advisorDetails?.live_status === "1"
                            //     ? false
                            //     : true
                            // }
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
                <div className="btnn">
                  <div>
                    {advisorDetails?.status === "1" ? (
                      <Attachment
                        setSelectedFile={setSelectedFile}
                        color={color}
                        setColor={setColor}
                        showAttach={showAttach}
                        setMsg={setMsg}
                        setMessage={setMessage}
                        message={messageInput}
                        submitMsg={submitMsg}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        setClearInput={setClearInput}
                        clearInput={clearInput}
                      />
                    ) : (
                      <RiAttachment2
                      className="attach-disable"
                        style={{ fontSize: "25px"}}
                      />
                    )}
                  </div>
                  {isModalOpen === false && (
                    <div>
                      {/* {showAudio === true &&   */}
                      {advisorDetails?.status === "1" ? (
                        <VoiceRecorder
                          setShowResult={setShowResult}
                          setRes={setRes}
                          setSelectedFile={setSelectedFile}
                          setTime={setTime}
                          time={time}
                          setShowInput={setShowInput}
                          setShowAttach={setShowAttach}
                          setShowAudio={setShowAudio}
                          showAudio={showAudio}
                        />
                      ) : (
                        <AiTwotoneAudio
                          style={{ fontSize: "25px", color: "#e6e6e6" }}
                        />
                      )}
                    </div>
                  )}
                  {isModalOpen === false && showInput === true && (
                    <div classname="icons">
                      <FiSmile
                        className={`${
                          advisorDetails?.status === "1"
                            ? "pointing smilecolors"
                            : "smilecolorsdis"
                        }`}
                        style={{ fontSize: "25px" }}
                        onClick={() => {
                          if (
                            advisorDetails?.status === "1" 
                          ) {
                            openEmojiBox();
                          }
                        }}
                      />
                    </div>
                  )}
                  {isModalOpen === false && (
                    <div>
                      {isMsgSpin === true ? (
                        <Spin />
                      ) : (
                        <Button
                          disabled={
                            advisorDetails?.status === "1" 
                              ? false
                              : true
                          }
                          className="msg_box_btn"
                          onClick={(e) => submitMsg()}
                        >
                          Send
                        </Button>
                      )}
                    </div>
                  )}
                </div>{" "}
              </div>
            </div>
          )}
        </>
      }
    </>
  );
};

export default MessageBox;
