import React, { memo, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserDetails } from "../../api/client";
import { getUpdateChatRoom } from "../../api/chat";
import LiveChat from "../../components/LiveChat";
import { Spin } from "antd";
import { getAdvisorFullProfile } from "../../api/advisor";
import { usePubNub } from "pubnub-react";
import { setMessages } from "../../redux/actions";

const Index = () => {
  let { id } = useParams();
  console.log("advisor", id);
  const [advisor, setAdProfile] = useState(null);
  const [advisorTyping, setAdvisorTyping] = useState(null);
  const [continueChat, setContinueChat] = useState(null);
  const dispatch = useDispatch();
  const [advisorName, setAdvisorName] = useState("");
  const [advisorOnlineStatus, setAdvisorOnlineStatus] = useState("");
  const [advisorLink, setAdvisorLink] = useState(null);
  const [advisorID, setAdvisorID] = useState();
  const [advisorRefreshList, setAdvisorRefreshList] = useState();
// test
  useEffect(() => {
    const checkID = id.split('-', 2)[1];
    if(checkID === 'continue'){
      setContinueChat('continue')
    }
    console.log('checkID', checkID);
  }, [id]);
// test

  useEffect(() => {
    getAdvisorFullProfile(id.split('-', 2)[1] === undefined ? id : id.split('-', 2)[0], dispatch);
  }, [id, dispatch]);

  const advisorProfile = useSelector((state) => state.advisorProfile);
  console.log("advisorProfile", advisorProfile);

  useEffect(() => {
    setAdProfile(advisorProfile);
  }, [advisorProfile]);

  //
  const [chatArr, setChatArr] = useState([]);
  const [userProfilee, setUserProfilee] = useState(null);
  const [channels, setChannels] = useState("");
  const pubnub = usePubNub();
  const handleMessage = (event) => {
    const message = event.message;

    console.log("MSG---", message);
    if (message.auth_key !== "") {
      setChatArr((prev) => [
        ...prev,
        {
          msg_text: message.msg_text ? message.msg_text : "",
          msg_date: message.msg_date ? message.msg_date : new Date(),
          sender_type: message.sender_type ? message.sender_type : "advisor",
          video_url: message.video_url ? message.video_url : "",
          message_type: message.message_type ? message.message_type : "",
        },
      ]);
    }
    if (message.message_type === "update_credits") {
      setUserProfilee((prev) => {
        return {
          ...prev,
          free_credit: message?.payload?.free_credit,
          paid_credit: message?.payload?.paid_credit,
        };
      });
    }
    if (message?.message_type) {
      dispatch(setMessages(message));
    }
    // updateUserCredits(message, dispatch);
  };
  console.log("chatArr", chatArr);
  const leaveApplication = () => {
    pubnub.removeListener(listnerAdd);
    pubnub.unsubscribeAll();
  };
  const listnerAdd = {
    status: (statusEvent) => {
      var subscribedChannels = statusEvent.subscribedChannels;
      console.log("subscribedChannels", subscribedChannels);
      if (statusEvent.category === "PNConnectedCategory") {
        console.log("Connected !!!");
      }

    },
    message: (event) => {
      const message = event.message;
      console.log("MSGS", message);
      if (message.auth_key !== "") {
        setChatArr((prev) => [
          ...prev,
          {
            msg_text: message.msg_text ? message.msg_text : "",
            msg_date: message.msg_date ? message.msg_date : new Date(),
            sender_type: message.sender_type ? message.sender_type : "advisor",
            video_url: message.video_url ? message.video_url : "",
            message_type: message.message_type ? message.message_type : "",
          },
        ]);
      }
      if (message.message_type === "update_credits") {
        setUserProfilee((prev) => {
          return {
            ...prev,
            free_credit: message?.payload?.free_credit,
            paid_credit: message?.payload?.paid_credit,
          };
        });
      }
      if (message?.message_type) {
        dispatch(setMessages(message));
      }

    },
    presence: (event) => {
     
      console.log("action", event.action);
     
      var state = event.state;
    
      console.log("state", event.state);
      setAdvisorTyping(state);
     
    },
  };
  useEffect(() => {
    const obj = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : {};
  
    const sessionID = localStorage.getItem("livechatsessionID");
    setChannels("Channel_User_".concat(obj?.id));
    pubnub.addListener(listnerAdd);
    if (channels) {
      pubnub.subscribe({
        channels: [
          channels,
          "channel_application",
          "live_session_".concat(sessionID),
        ],
        withPresence: true,
      });
    }

    return leaveApplication;
  }, [pubnub, channels, listnerAdd, leaveApplication]);
  //
  const sendPubnubMsg = (obj) => {
    console.log("Publishing");
    pubnub.publish({
      channel: ["Channel_Advisor_".concat(advisor?.id)],
      message: obj,
    });
  };
  const typingFun = () => {
    const sessionID = localStorage.getItem("livechatsessionID");
    pubnub.setState(
      {
        state: { type: "user", isTyping: true },
        channels: ["live_session_".concat(sessionID)],
      },
      function (status, response) {
        if (status.error) {
          console.log(status);
        } else {
          console.log(response);
          console.log("Typing.......");
        }
      }
    );
  };
  const endTypingFun = () => {
    const sessionID = localStorage.getItem("livechatsessionID");
    pubnub.setState(
      {
        state: { type: "user", isTyping: false },
        channels: ["live_session_".concat(sessionID)],
      },
      function (status, response) {
        if (status.error) {
          console.log(status);
        } else {
          console.log(response);
          console.log("client Typing end.......");
        }
      }
    );
  };
  const advisorPubnubList = useSelector((x)=> x.refreshList);
  console.log("advisorPubnubList", advisorPubnubList);

  useEffect(()=>{
    if (Object.keys(advisorPubnubList).length !== 0) {
      var newObj = Object.values(advisorPubnubList?.advisor)[0];
      console.log("new", newObj);
      setAdvisorRefreshList(newObj);
      
    }
  }, [advisorPubnubList])

  useEffect(()=>{
    setAdvisorOnlineStatus(advisorRefreshList?.live_status)
  }, [advisorRefreshList])
console.log("advisorRefreshList", advisorRefreshList);

  return (
    <>
      {id && <LiveChat
        advisor={advisor}
        sendPubnubMsg={sendPubnubMsg}
        typingFun={typingFun}
        endTypingFun={endTypingFun}
        advisorTyping={advisorTyping}
        continueChat={continueChat}
        setAdvisorName={setAdvisorName}
        setAdvisorOnlineStatus={setAdvisorOnlineStatus}
        setAdvisorLink={setAdvisorLink}
        setAdvisorID={setAdvisorID}
        advisorOnlineStatus={advisorOnlineStatus}
        advisorLink={advisorLink}
        advisorRefreshList={advisorRefreshList}
        id={id}
      />}
      {/* {sessionDetails === null ? 
     <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
        <Spin/> 
        <h6>We are trying to connect to your psychic. Please Wait.</h6>
        </Container>
     : <LiveChat />} */}
    </>
  );
};

export default memo(Index);
