import axios from "axios";
import {
  setInAppProductListing,
  setOneWebProductListing,
} from "../redux/actions";
import { BASE_URL, VERSION } from "../config/constants";

// Simple general credits

export const getInAppProducts = async (setIsSpin, dispatch) => {
  const user = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : {};
  const token = localStorage.getItem("token");
  const obj = {
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    client_id: user?.id,
    device_type: "web",
    lang: "en",
  };
  try {
    const res = await axios.post(
      `${BASE_URL}/API/v19/Iap_Page_API/getWebAppProducts`,
      obj,
      {
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          // 'withCredentials': true,
        },
      }
    );
    console.log("APPRES: ", res);
    if (res?.data?.result === 1) {
      console.log("app details", res.data.message);
      dispatch(setInAppProductListing(res.data.message));
    }
    setIsSpin(false);
    //   if(res?.data?.result === 0){
    //      dispatch(setInAppProductListing(res.data.message))
    //   }
  } catch (err) {
    console.log("err", err);
  }
};

export const getOneWebProduct = async (id, dispatch) => {
  const token = localStorage.getItem("token");
  const obj = {
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    item_id: id,
    device_type: "android",
    lang: "en",
  };
  try {
    const res = await axios.post(
      `${BASE_URL}/API/v19/Iap_Page_API/getWebProduct`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          // 'Authorization': `Bearer ${token}`,
          // 'withCredentials': true,
        },
      }
    );
    console.log("APPRES: ", res);
    if (res?.data?.result === 1) {
      console.log("app details", res.data.message);
      dispatch(setOneWebProductListing(res.data.message));
    }
    //   if(res?.data?.result === 0){
    //      dispatch(setInAppProductListing(res.data.message))
    //   }
  } catch (err) {
    console.log("err", err);
  }
};

// Extended credits

export const getExtendedCredits = async (id, dispatch) => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : {};
  const obj = {
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    user_id: userInfo?.id,
    client_id: userInfo?.id,
    device_type: "web",
    user_type: "client",
    chat_rate: 2.0,
  };
  try {
    const res = await axios.post(
      `${BASE_URL}/API/v19/Extended_Discount_API/getExtendedCredit`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          // 'Authorization': `Bearer ${token}`,
          // 'withCredentials': true,
        },
      }
    );
    console.log("APPRES: ", res);
    if (res?.data?.result === 1) {
      console.log("app details", res.data.message);
      // dispatch(setOneWebProductListing(res.data.message));
    }
    //   if(res?.data?.result === 0){
    //      dispatch(setInAppProductListing(res.data.message))
    //   }
  } catch (err) {
    console.log("err", err);
  }
};
