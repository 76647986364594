import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Form, Input, InputNumber, message, Spin } from "antd";
import auth from "../../../config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import clevertap from "clevertap-web-sdk";
import { finalLogin, onSigninUser } from "../../../api/auth";
import { LoadingOutlined } from "@ant-design/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";
import "./index.css";

const Index = ({ setNormalMode, setPhoneMode, setModal2Open ,returnTo}) => {
  const [opt, setOtp] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const countRef = useRef(null);
  const [timer, setTimer] = useState(60);
  const [timerVisible, setTimerVisible] = useState(false);
  const [showPopupRedirect, setShowPopupRedirect] = useState(false);
  const [val, setVal] = useState(null);
  const [enableResend, showEnableResend] = useState(false);

  const handleStartTimer = () => {
    setTimerVisible(true);
    countRef.current = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
  };

  const handleResetTimer = () => {
    clearInterval(countRef.current);
    setTimer(0);
  };

  // if(timer <= 0){
  //   handleResetTimer();
  // };
  console.log("timerr", timer);
  // if(timer <= 1){
  //   handleResetTimer();
  // }
  useEffect(() => {
    if (timer <= 0) {
      handleResetTimer();
      showEnableResend(true);
    }
  }, [timer]);

  const onFinish = async (values) => {
    console.log("Success:", values);
    setVal(values);
    const test = values.phonenumber.indexOf("+");
    console.log("+", test);
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("res", response);
        },
      },
      auth
    );

    let appVerifier = window.recaptchaVerifier;
    if (test !== -1) {
      signInWithPhoneNumber(auth, values.phonenumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setIsVisible(true);
          message.success("OTP sent!");
          handleStartTimer();
        })
        .catch(function (error) {
          console.log(error);
          message.error("Oops! Try again.");
          // Error; SMS not sent
          // ...
        });
    } else {
      message.error("Enter number. E.g: +92 xxx xxxxxxx.");
    }
  };
  const resendOPT = (values) => {
    console.log("valll:", values);
    clearInterval(countRef.current);
    setTimer(60);
    // setVal(values);
    //  const test = values.phonenumber.indexOf("+");
    //  console.log("+", test);
    window.recaptchaVerifier = new RecaptchaVerifier(
      "resend",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("res", response);
        },
      },
      auth
    );

    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, values.phonenumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setIsVisible(true);
        message.success("OTP sent!");
        handleStartTimer();
      })
      .catch(function (error) {
        console.log(error);
        message.error("Oops! Try again.");
        // Error; SMS not sent
        // ...
      });
  };
  const verifyOTP = (e) => {
    setShowPopupRedirect(true);
    handleResetTimer();
    setTimerVisible(false);
    // console.log("e", e);
    let otpval = e.OTP;
    setOtp(otpval);
    // if(otpval === 6){
    console.log(otpval);
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otpval)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log("user", user);
        onSigninUser(user, setShowPopupRedirect, setModal2Open, returnTo,  history);
        // ...
      })
      .catch((error) => {
        console.log(error);
        // User couldn't sign in (bad verification code?)
        // ...
      });
    // }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleEvent = () => {
    clevertap.recordEvent("login", {
      Date: new Date(),
    });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 70,
      }}
      spin
    />
  );
  return (
    <>
      <Container>
        {showPopupRedirect === true && (
          <Row className="d-flex flex-column justify-content-center align-items-center gap-2 h-100">
            <Spin indicator={antIcon} />
            <h6 className="text-center">Please wait...</h6>
          </Row>
        )}
        {showPopupRedirect === false && (
          <div className="maincontainer">
            {/* <Col
              lg={6}
              className="d-flex flex-column justify-content-center align-items-around h-100 w-100"
            > */}
              {isVisible === false && (
                <Form
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="d-flex flex-column formm"
                >
                  <p className="my-3 title_phone">
                    Please enter your phone number
                  </p>
                  <Form.Item
                    name="phonenumber"
                    // className="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                    ]}
                  >
                    <Input
                      className="phone"
                      style={{
                        width: "300px",
                      }}
                      placeholder="E.g +92 xxx xxxxxxx"
                    />
                  </Form.Item>
                  <div id="recaptcha-container" />
                  <Form.Item
                    // wrapperCol={{
                    //   offset: 20,
                    //   span: 4,
                    // }}
                    style={{ alignItems: "center !important" }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      id="sign-in-button"
                      size="medium"
                      className="px-3"
                    >
                      Send
                    </Button>
                  </Form.Item>
                </Form>
              )}
             {isVisible === false && <IoReturnUpBackOutline
                style={{ fontSize: "30px", cursor: "pointer" }}
                color="#3a86f4"
                onClick={() => {
                  setNormalMode(true);
                  setPhoneMode(false);
                }}
              />}

              {isVisible === true && (
                <Form
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  className="d-flex flex-column formm"
                  onFinish={verifyOTP}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <p className="my-3 title_phone">
                    Please enter 6 digits OTP 
                  </p>
                  <Form.Item
                    name="OTP"
                    // label="OTP"
                    rules={[
                      {
                        required: true,
                        message: "Please enter 6 digit number!",
                      },
                      {
                        min: 6,
                      },
                    ]}
                  >
                    <Input
                      id="otpinput"
                      value={opt}
                      className=" phone"
                      placeholder="Enter 6 digits OTP"
                      style={{width: '300px'}}
                    />
                  </Form.Item>
                  <div id="recaptcha-container" />
                  <Form.Item
                    // wrapperCol={{
                    //   offset: 14,
                    //   span: 10,
                    // }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={handleEvent}
                    >
                      Submit
                    </Button>
                    {timerVisible === true && (
                      <h6
                        style={{ fontSize: "0.8rem" }}
                        className=" my-3"
                      >
                        {" "}
                        {timer} seconds left
                      </h6>
                    )}
                    {timerVisible === true && (
                      <>
                        {enableResend === true && (
                          <h6
                            style={{ fontSize: "0.8rem" }}
                            className="text-center pointing"
                            id="resend"
                            onClick={() => resendOPT(val)}
                          >
                            {" "}
                            Resend OTP
                          </h6>
                        )}
                      </>
                    )}
                  </Form.Item>
                  <p className="muted_label">Please do not close this window.</p>
                </Form>
              )}
            {/* </Col> */}
          </div>
        )}
      </Container>
    </>
  );
};

export default memo(Index);
