import React, { memo, useEffect, useState } from "react";
import { Alert, Button, DatePicker, Form, Input, Select } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { updateUserInfo } from "../../../api/client";
import { useDispatch } from "react-redux";
import SigninModal from "../../../modals/signinModal";
import { useHistory, useParams } from "react-router-dom";
import { ImFilePicture } from "react-icons/im";
import Secure from "../../../images/secure.svg";
import Safe from "../../../images/safe.svg";
import Service from "../../../images/service.svg";
import "./index.css";

const { Option } = Select;

const Part3 = ({ setUserPopDetails, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const [isDisable, setIsDisable] = useState(true);
  const [modal2Open, setModal2Open] = useState(false);
  const [isTitle, setIsTitle] = useState(null);
  const history = useHistory();
  const { id } = useParams();
  const [img, setImg] = useState(null);
  const [displayImg, setDisplayImg] = useState(null);
  var returnTo = `/reading/${id}`;

  const onFinish = async (values) => {
    console.log("Success:", values);
    let msgRead =
      "SITUATION: " + values?.situation + " " + "QUESTION: " + values?.question;
    if (img !== null) {
      const obj = {
        ...values,
        advisorID: id,
        selectedFile: img,
        reading: msgRead,
      };
      localStorage.setItem("reading", JSON.stringify(obj));
      history.push("/signup");
      // setIsTitle("Create an account!");
      // setModal2Open(true);
    } else {
      const obj = { ...values, advisorID: id, reading: msgRead };
      localStorage.setItem("reading", JSON.stringify(obj));
      history.push("/signup");
      // setIsTitle("Create an account!");
      // setModal2Open(true);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const userInformation = localStorage.getItem("emailForSignIn") || "";
    console.log("userInformation", userInformation);
  });
  const checkDob = (date, dateString) => {
    setIsDisable(false);
    console.log("Value", date, dateString);

    const date1 = new Date(dateString);
    const date2 = new Date();
    let yearsDiff = date2.getFullYear() - date1.getFullYear();
    console.log("yearsDiff", yearsDiff);
    if (yearsDiff >= 18) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  };
  const handler = async (e) => {
    console.log("fileee", e);
    var filename = e?.target?.files[0];
    const { name } = e?.target?.files[0];
    console.log("filename", name?.split(".")[0]);
    setDisplayImg(e?.target?.files[0]);

    setImg({
      file: URL.createObjectURL(e?.target?.files[0]),
      type: "photo",
      fileName: name?.split(".")[0],
    });
  };
  useEffect(() => {
    console.log("ch", img?.file);
  }, []);
  console.log("img", img);
  console.log("displayImg", displayImg);
  return (
    <>
      <div className="part3_main_container px-4">
        <ul className="mb-0">
          <li className="whyPsychic">Why Psychic Txt is right for you?</li>
        </ul>
        <Row className="part3_section1">
          <Col lg={4} className="d-flex align-items-center gap-2">
            <img src={Secure} style={{ width: "42px", height: "42px" }} />
            <p className="sec_saf_ser secure">Secure Payments</p>
          </Col>
          <Col lg={8} className="d-flex align-items-center">
            <ul className="listing_txt">
              <li>Financial Information Completely Protected</li>
              <li>Secured Transaction Process</li>
              <li>{"Save Time & Receive Credits Instantly"}</li>
              <li>Trusted by Millions Psychic Txt Users</li>
            </ul>
          </Col>
        </Row>

        <Row className="part3_section2">
          <Col lg={4} className="d-flex align-items-center gap-2">
            <img src={Safe} style={{ width: "42px", height: "42px" }} />
            <p className="sec_saf_ser safe">{"Safe, private & Trusted"}</p>
          </Col>
          <Col lg={8} className="d-flex align-items-center">
            <ul className="listing_txt">
              <li>{"Reading Details 100% Anonymous & Confidential"}</li>
              <li>
                {"Detailed Personal Information Hidden from  Advisors & Public"}
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="part3_section3">
          <Col lg={4} className="d-flex align-items-center gap-2">
            <img src={Service} style={{ width: "42px", height: "42px" }} />
            <p className="sec_saf_ser service">
              Service Satisfaction Guaranteed
            </p>
          </Col>
          <Col lg={8} className="d-flex align-items-center">
            <ul className="listing_txt">
              <li>{'Reading Details 100% Anonymous & Confidential'}</li>
              <li>{'Detailed Personal Information Hidden from Advisors & Public'}</li>
            </ul>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Part3;
