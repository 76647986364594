import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Avatar, Button, Divider, List, Radio } from "antd";
import "./index.css";
import ListTopUp from "./list";
import parse from "html-react-parser";
import { BiPurchaseTag } from "react-icons/bi";
import Arrow from "../../images/arrow_btn.svg";
import PurchaseModal from "../../modals/purchaseModal";

const rex =
  /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu;

const Index = ({ data, setPurchaseID, purchaseID }) => {
  const { id } = useParams();
  console.log("textid", id);
  const history = useHistory();
  console.log("Children", data);
  const [checked, setChecked] = useState(false);
  const [price, setPrice] = useState(null);
  const [idd, setId] = useState(null);
  const [newData, setNewData] = useState([]);
  var returnTo = window?.location?.href;
  console.log("returnTo", window?.location?.href);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancels = () => {
    setIsModalOpen(false);
  }
  const linktoPaymentpage = (ids) => {
    console.log("ids", ids);
    if (id === "Live_Credit") {
      history.push(`/billing/${ids.concat("-").concat("extended")}`);
    } else if (returnTo?.includes('/reading')) {
      setIsModalOpen(true);
      setPurchaseID(`/billing/${ids.concat("-").concat("normal")}`);
    } else if (returnTo?.includes('/livereading')) {
      setIsModalOpen(true);
      setPurchaseID(`/billing/${ids.concat("-").concat("normal")}`);
    } else {
      history.push(`/billing/${ids.concat("-").concat("normal")}`);
    }
    // if (id === "purchase") {
    //   history.push(`/billing/${ids.concat("-").concat("normal")}`);
    // }
  };
  useEffect(() => {
    let arrData = data;
    setNewData(
      arrData?.map((e, i) => {
        return { ...e, checked: false };
      })
    );
  }, [data]);

  console.log("newData", newData);
  const onChange = (item) => {
    console.log("item", item);
    let arrData = newData;
    let chk = arrData?.map((e) => {
      if (e.id === item.id) {
        console.log("match", e);
        return { ...e, checked: true };
      }
      return { ...e, checked: false };
    });
    console.log("chk", chk);
    setNewData(prev => chk);
    setId(item?.id);
    setPrice(item?.price);
  };

  return (
    <>
   {purchaseID !== null && <PurchaseModal isModalOpen={isModalOpen} handleCancel={handleCancels} purchaseID={purchaseID} setIsModalOpen={setIsModalOpen} />}
      <Container fluid className={checked === false ? "main" : "mainChecked"}>
        <Row className="main-list-container">
          {newData.length !== 0 &&
            newData?.map((item, i) => {
              return (
                <ListTopUp
                  item={item}
                  i={i}
                  onChange={onChange}
                  // setParentChecked={setChecked}
                  // setId={setId}
                  // setPrice={setPrice}
                />
              );
            })}
        </Row>
        {price !== null && idd !== null && (
          <Row className="d-flex justify-content-end mt-2 mb-3">
            <div
              className="button_topup"
              onClick={() => linktoPaymentpage(idd)}
            >
              <div>
                <p className="button_text_1">Buy Text Credit At</p>
                <p className="price_button mt-2">{price !== null && price}</p>
              </div>
              <img src={Arrow} />
            </div>
          </Row>
        )}
      </Container>
    </>
  );
};

export default memo(Index);
