import React, { useEffect, useState } from "react";
import { usePubNub } from "pubnub-react";
import { updateUserCredits } from "./api/index";
import { useDispatch } from "react-redux";

const ChannelSubscribeCheck = ({children}) => {
    const pubnub = usePubNub();
    const dispatch = useDispatch();
    const [channels, setChannels] = useState("");
    const leaveApplication = () => {
        pubnub.removeListener(listnerAdd);
        pubnub.unsubscribeAll();
      };
      const listnerAdd = {
        status: (statusEvent) => {
          var subscribedChannels = statusEvent.subscribedChannels;
          console.log("subscribedChannels", subscribedChannels);
          if (statusEvent.category === "PNConnectedCategory") {
            console.log("Connected");
          }
        },
        message: (event) => {
          const message = event.message;
          console.log("message", message);
          // if(message?.message_type === 'update_credits'){
            updateUserCredits(message, dispatch);
          // }
        },
      };
    useEffect(() => {
        const userInformation = localStorage.getItem("userInfo") || {};
        const obj = JSON.parse(userInformation);
        setChannels("Channel_User_".concat(obj?.id));
        pubnub.addListener(listnerAdd);
        if (channels) {
          pubnub.subscribe({
            channels: [channels, "channel_application"],
            withPresence: true,
          });
          pubnub.publish({
            channel: [channels, "channel_application"],
            message: "Hello World!",
          });
        }
        return leaveApplication;
      }, [pubnub, channels, leaveApplication, listnerAdd]);
  return (
    <>
    {children}
    </>
  );
};

export default ChannelSubscribeCheck;
