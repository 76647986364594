import React, { memo } from "react";
import { Button, Divider, Form, Input, message } from "antd";
import {  useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import "./index.css";

const Index = () => {
  const history = useHistory();
  const onFinish = async (values) => {
    console.log("Success:", values);
    try{
      const res = axios.post("/");

    }catch(err){
      console.log("err", err)
    }

  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Container>
        <Row className="d-flex justify-content-center align-items-center gap-3 pt-5 vh-100">
          <Col
            lg={6}
            className="d-flex flex-column justify-content-around p-5 h-75"
            style={{ border: "2px solid gray" }}
          >
            <h2 className="text-center">Register</h2>
            <Divider />
            <Form
                name="basic"
                // labelCol={{
                //   span: 8,
                // }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <p>Phone Number</p>
                <Form.Item
                  name="phonenumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                  style={{
                    width: "400px",
                  }}
                  />
                </Form.Item>
                <div id="recaptcha-container" />
                <Form.Item
                  wrapperCol={{
                    offset: 10,
                    span: 14,
                  }}
                >
                  <Button type="primary" htmlType="submit" id="sign-in-button">
                    Send
                  </Button>
                </Form.Item>
              </Form>
            </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(Index);
