import axios from "axios";
import { setMessages, setUserInfo, setUserProfileInfo } from "../redux/actions";
import {BASE_URL, VERSION} from "../config/constants"
import { message } from "antd";
import moment from "moment";

export const updateUserCredits = async (credits, dispatch) => {
    console.log("credits", credits)
    // const userInformation = localStorage.getItem("userInfo") || {};
    // const user = JSON.parse(userInformation);
    // const obj = {
    //   auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    //   id: user?.id,
    //   free_credit: credits?.payload?.free_credit,
    //   paid_credit: credits?.payload?.paid_credit,
    //   paid_seconds: credits?.payload?.paid_seconds,
    //   has_made_inapp_purchase: 1,
    // };
    // console.log("obj", obj)
    console.log("credits", credits);
    dispatch(setMessages(credits));
    // try {
    //   const res = await axios.post(
    //     "https://staging.psychictxt.net/API/v19/Client_API/updateCredits",
    //     obj,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   );
    //   console.log("RES CREDITS: ", res);
    //   if(res.data.result === 1){
    //     dispatch(setMessages(credits));
    //     // message.success("Check your credits are updated!")
    //   }

    // } catch (err) {
    //   console.log("err", err);
    // }
  };

export const getClientInfo = async (dispatch) => {
    const userInformation = localStorage.getItem("userInfo") || {};
    const user = JSON.parse(userInformation);
    const obj = {
      auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
      id: user?.id,
      app_version: '3.7.4',
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/API/${VERSION}/Client_API/getClientInfo`,
        obj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("RES INFO: ", res);
      if(res?.data?.result === 1){
        dispatch(setUserInfo(res.data))
      }

    } catch (err) {
      console.log("err", err);
    }
  };

  export const updateUserInfo = async (userInfo,  dispatch) => {
    const userInformation = localStorage.getItem("userInfo") || {};
    const user = JSON.parse(userInformation);
    const obj = {
      auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
      id: user?.id,
      username: userInfo?.username,
      app_version: '3.7.4',
      email: user?.email,
      dob: moment(userInfo?.date).format("YYYY-MM-DD"),
      is_updated: 1,
      gender: userInfo?.gender,
      password: '0Niy97D3r1Zydm',
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/API/${VERSION}/Client_API/updateClient`,
        obj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("RES INFO: ", res);
      if(res?.data?.result === 1){
        // message.success("Profile updated!")
        // getUserDetails(dispatch);
        // setIsTitle("Login to your account");
        // setModal2Open(true);
      }

    } catch (err) {
      console.log("err", err);
    }
  };

  export const getUserDetails = async (dispatch) => {
    // const userInformation = localStorage.getItem("userInfo") || '{}';
    const user = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : {}
    // const user = JSON.parse(userInformation);
    // const user = JSON.parse(userInformation);
    const token = localStorage.getItem('token')
    const obj = {
      auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
      client_id: user?.id,
      user_type: 'client',
      app_version: '3.7.4',
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/API/${VERSION}/Client_API/getClientFromId`,
        obj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // "Authorization" : `Bearer ${token}`
          },
        }
      );
      // console.log("RES INFO: ", res);
      if(res?.data?.status === 1){
        console.log("prof", res.data.user)
         dispatch(setUserProfileInfo(res.data.user[0]))
      }

    } catch (err) {
      console.log("err", err);
    }
  };
