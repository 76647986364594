import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { notification } from "antd";
const red = createSlice({
  name: "sendSignal",
  initialState: {
    advisorListing: [], // home page listing
    messages: {}, // pubnub messages
    userInfo: {}, // client information
    reviewMetadata: [], // for getting options for client review
    chatContent: [], //get chat content client side
    profile: null, //user profile information
    advisorProfile: {}, //advisor full profile details
    chatMsg: {}, // advisor pubnub chat msgs incoming
    liveChatRoomDetails: null, // livechat room session details
    inAppProductList: null, // in app product details to purchase
    paymentProfiles: [], // payment credit card listing (for purchase products)
    oneProductList: [], // 1 product details
    creditsMsg: {}, // for credits update msg for home page nav bar
    refreshList: {}, // pubnub msg dispatch for refresh list
    advisorReviews: [], //advisor profile reviews
  },
  reducers: {
    setAdvisorsListing: (state, action) => {
      console.log("action", action.payload);
      return { ...state, advisorListing: action.payload };
    },
    // setMessaging: (state, action) => {
    //   console.log("action", action.payload)
    //   if(action.payload.free_credit !== '0' && action.payload.free_credit !== 0){
    //     const args = {
    //       message: 'Credits!',
    //       description:
    //         `You have ${action.payload.free_credit} free credits`,
    //       duration: 15,
    //     };
    //     notification.open(args);
    //   }
    //   if(action.payload.free_seconds !== '0' && action.payload.free_seconds !== 0){
    //     const args = {
    //       message: 'Credits!',
    //       description:
    //         `You have ${action.payload.free_seconds} free seconds`,
    //       duration: 15,
    //     };
    //     notification.open(args);
    //   }
    //   if(action.payload.paid_credit !== '0' && action.payload.paid_credit !== 0){
    //     const args = {
    //       message: 'Credits!',
    //       description:
    //         `You have ${action.payload.paid_credit} paid credits`,
    //       duration: 15,
    //     };
    //     notification.open(args);
    //   }
    //   if(action.payload.paid_seconds !== '0' && action.payload.paid_seconds !== 0){
    //     const args = {
    //       message: 'Credits!',
    //       description:
    //         `You have ${action.payload.paid_seconds} paid seconds`,
    //       duration: 15,
    //     };
    //     notification.open(args);
    //   }
    //   return { ...state, messages: action.payload };
    // },
    setClientInfo: (state, action) => {
      return { ...state, userInfo: action.payload };
    },
    setReviewMetaData: (state, action) => {
      return { ...state, reviewMetadata: action.payload };
    },
    setClientChat: (state, action) => {
      return { ...state, chatContent: action.payload };
    },
    setUserProfile: (state, action) => {
      return { ...state, profile: action.payload };
    },
    setAdvisorProfileDetails: (state, action) => {
      return { ...state, advisorProfile: action.payload };
    },
    setChatMsg: (state, action) => {
      return { ...state, chatMsg: action.payload };
    },
    refreshListingAdvisor: (state, action) => {
      return { ...state, refreshList: action.payload };
    },
    setUpdateCreditsMsg: (state, action) => {
      return { ...state, creditsMsg: action.payload };
    },
    setCreateChatRoom: (state, action) => {
      return { ...state, liveChatRoomDetails: action.payload };
    },
    setInAppProductList: (state, action) => {
      return { ...state, inAppProductList: action.payload };
    },
    getPaymentProfiles: (state, action) => {
      return { ...state, paymentProfiles: action.payload };
      // arr: state.arr.concat(action.newItem)
    },
    updatePaymentProfiles: (state, action) => {
      console.log("state", state);
      console.log("action", action);
      // const updated = state.filter((e) => e.customerPaymentProfileId === action.payload);
          // console.log("updated", updated);

      return { ...state, paymentProfiles: action.payload };
      // arr: state.arr.concat(action.newItem)
    },
    deletePaymentProfiles: (state, action) => {
      return { ...state, paymentProfiles: action.payload };
      // arr: state.arr.concat(action.newItem)
    },
    setOneWebProductList: (state, action) => {
      return { ...state, oneProductList: action.payload };
    },
    setReviewsForAdvisor: (state, action) => {
      return { ...state, advisorReviews: action.payload };
    },
  },
});

const { actions, reducer } = red;
export const {
  setAdvisorsListing,
  setMessaging,
  setClientInfo,
  setReviewMetaData,
  setClientChat,
  setUserProfile,
  setAdvisorProfileDetails,
  setChatMsg,
  setCreateChatRoom,
  setInAppProductList,
  getPaymentProfiles,
  setOneWebProductList,
  updatePaymentProfiles,
  deletePaymentProfiles,
  setUpdateCreditsMsg,
  refreshListingAdvisor,
  setReviewsForAdvisor,
} = actions;

export default reducer;
