import React, { memo, useEffect, useState } from "react";
import { Button, Divider, message } from "antd";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";
import { NavBar } from "../../components";
import BlackTagLine from "../../components/LandingPage/BlackTagLine";
import Footer from "../../components/Footer";
import ContactImg from "../../images/contact-img.png"
import ContactForm from "./contactForm";
const Index = () => {



  return (
    <>
    <NavBar />
    <div className="line-draw"></div>
      <Container>
        <Row className="d-flex justify-content-center align-items-center py-4">
      <Col lg={6}>
        <ContactForm />
      </Col>
      <Col lg={6} className="contact_Imgg">
        <div className="image-container-contact mt-5">
        <img src={ContactImg} />
        </div>
     
      </Col>
        </Row>
      </Container>
      <BlackTagLine />
      <Footer />
    </>
  );
};

export default memo(Index);
