import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { message, Spin } from "antd";
import axios from "axios";
import { Container } from "react-bootstrap";

export const Spinner = () => <Spin />;

const LoginCheck = ({ children }) => {
  const [isSpinner, setIsSpinner] = useState(true);
  const history = useHistory();
  useEffect(() => {
    verifyClient();
  }, []);
  
  const verifyClient = async () => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(user);
   
      const obj = {
        user_type: "client",
        user_id: userInfo?.id,
        jwt: token,
      };
      console.log("obj", obj);
      try {
        const res = await axios.post(
          "https://staging.psychictxt.net/API/v22/Client_API/verifyJWT",
          obj,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              // "Authorization" : `Bearer ${token}`
            },
          }
        );
        console.log("res--", res);
        if(res?.data?.isVerify !== true){
          history.push("/");
          message.error("please login first");
        }
        setIsSpinner(false);
      } catch (err) {
        console.log("err", err);
      }
  };
  return (
    <>
      {isSpinner === true ? (
        <Container className="vh-100 d-flex justify-content-center align-items-center">
          <Spin />
        </Container>
      ) : (
        children
      )}
    </>
  );
};

export default LoginCheck;
