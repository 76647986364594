import axios from "axios";
import React, { memo, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Parser from "html-react-parser";

import "./index.css";
import Banner from "../../../images/profile_background.png";
import PlayButton from "../../../images/playbutton.png";
import { Avatar, Modal, Tag } from "antd";
import { TbBellRinging } from "react-icons/tb";
import { AiFillHeart } from "react-icons/ai";
import VideoModal from "../../../modals/videoModal";

const Index = ({ advisorDetails }) => {
  const [showModal, setShowModal] = useState(false);
  const handleCancel = () => {
    setShowModal(false);
  };
  console.log("advisorDetails", advisorDetails);
  return (
    <>
      <Row>
        <Col lg={6} className="section_1_profile_icons">
          <Avatar
            src={advisorDetails?.s3_display_picture}
            size={70}
            className="section_1_profile_icons_1"
          />
          <div
            onClick={() => setShowModal(true)}
            // onMouseLeave={() => setShowModal(false)}
          >
            <Avatar
              src={PlayButton}
              size={24}
              className="section_1_profile_icons_2 pointing"
            />
          </div>
        </Col>
        <Col lg={6} className="pt-3">
          {advisorDetails?.rush_status === "1" && (
            <div>
              <Tag color="#00A542" className="tags-txt">
                1 Hr
              </Tag>
            </div>
          )}
          {advisorDetails?.is_new_advisor === "1" && (
            <div>
              <Tag color="#F29C38" className="tags-txt">
                New
              </Tag>
            </div>
          )}
          {advisorDetails?.is_spanish === "1" && (
            <div>
              <Tag color="#3A86F4" className="tags-txt">
                Espanol
              </Tag>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="section_1_profile_name">
          <p>{advisorDetails?.first_name}</p>
          <div className="bell_bg shadow-sm"><TbBellRinging color="#595F62" /></div>
          <div className="heart_bg"> <AiFillHeart color="#FFFFFF" /></div>
        </Col>
        <Col lg={12}>
          <p className="des_profile">
            {(advisorDetails?.short_description)?.replace(/<[^>]+>/g, '')}
          </p>
        </Col>
      </Row>
      <VideoModal showModal={showModal} handleCancel={handleCancel} advisorDetails={advisorDetails}/>
    </>
  );
};

export default memo(Index);
