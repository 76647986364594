import React, { memo, useEffect, useState } from "react";
import { updateUserCredits } from "../../api";
import { Banner, MeetOurAdvisors } from "../../components";
import { usePubNub } from "pubnub-react";  
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../api/client";
import TrendingAdvisors from "../../components/LandingPage/TrendingAdvisors";
import RecommendedForYou from "../../components/LandingPage/RecommendedForYou";
import ExploreAdvisorByCategories from "../../components/LandingPage/ExploreAdvisorByCategories";
import BlackTagLine from "../../components/LandingPage/BlackTagLine";
import Footer from "../../components/Footer";
import { useHistory } from "react-router-dom";
const Index = () => {
  const pubnub = usePubNub();
  const dispatch = useDispatch();
  const history = useHistory();
  const [navInfo, setNavInfo] = useState(null);
  const [isUser, setIsUser] = useState(null);
  const [channels, setChannels] = useState("");
  console.log("TESTTT", '/billing/1-normal'?.split('/')[2]?.split('-')[0])
  useEffect(() => {
    getUserDetails(dispatch);
  }, [dispatch]);

  // useEffect(()=>{
  //   setMessages()
  // }, [])
  const profile = useSelector((state) => state.profile);
  console.log("profile", profile);
  useEffect(() => {
    localStorage.removeItem("dropdown-credits")
    setIsUser(profile);
  }, [profile]);

  const listnerAdd = {
    status: (statusEvent) => {
      var subscribedChannels = statusEvent.subscribedChannels;
      console.log("subscribedChannels", subscribedChannels);
      if (statusEvent.category === "PNConnectedCategory") {
        console.log("Connected nav");
      }
    },
    message: (event) => {
      const message = event.message;
      console.log("main_msgs", message);
        updateUserCredits(message, dispatch);
    },
  };
  useEffect(()=>{
    const idd = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : '';
    setChannels("Channel_User_".concat(idd !== undefined ? idd?.id : isUser?.id));
  }, [isUser]);
  
  const leaveApplication = () => {
    pubnub.removeListener(listnerAdd);
    pubnub.unsubscribeAll();
  };
  useEffect(() => {
    pubnub.addListener(listnerAdd);
    if (channels) {
      console.log("channels", channels);
      pubnub.subscribe({
        channels: [channels, "channel_application"],
        withPresence: true,
      });
    }
    return leaveApplication;
  }, []);
  // pubnub, channels

  const goTolistingpage = () =>{
    localStorage.removeItem('search');
    history.push('/alladvisors')
  }
 
  return (
    <div className="bg-light d-flex flex-column gap-4">

    <Banner setNavInfo={setNavInfo} navInfo={navInfo}/>
    <TrendingAdvisors setNavInfo={setNavInfo} goTolistingpage={goTolistingpage}/>
    <RecommendedForYou setNavInfo={setNavInfo} goTolistingpage={goTolistingpage}/>
    <ExploreAdvisorByCategories goTolistingpage={goTolistingpage}/>
    <BlackTagLine />
    <Footer />
  
    </div>
  );
};

export default memo(Index);
