import React, { memo, useEffect, useState } from "react";
import { Alert, Button, DatePicker, Form, Input, Select } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { updateUserInfo } from "../../../api/client";
import { useDispatch } from "react-redux";
import SigninModal from "../../../modals/signinModal";
import { useHistory, useParams } from "react-router-dom";
import { ImFilePicture } from "react-icons/im";
import "./index.css";
import Part1 from "./part1";
import Part2 from "./part2";
import Part3 from "./part3";

const { Option } = Select;

const Index = ({ id }) => {

  return (

      <Row>
        <Col lg={12} className="main_part_container">
          <Part1 />
          <Part2 id={id} />
          <Part3 />
        </Col>
      </Row>
  
  );
};

export default memo(Index);
