import axios from "axios";
import React, { memo, useEffect } from "react";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Popconfirm,
  Rate,
  Spin,
  Tag,
} from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { usePubNub } from "pubnub-react";
import image from "../../images/images.jpeg";
import "./index.css";
import { setListingAdvisors, setMessages } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { RiInboxArchiveFill } from "react-icons/ri";
import clevertap from "clevertap-web-sdk";
import { pushEvent } from "../../utils";
import { updateUserCredits } from "../../api/client";
import { BsFillChatDotsFill } from "react-icons/bs";
// import { updateUserCredits } from "../../api";
const { Meta } = Card;

const Index = () => {
  const pubnub = usePubNub();
  const [channels, setChannels] = useState("");
  const [listing, setListing] = useState([]);
  const [newList, setNewList] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [open, setOpen] = useState(false);
  const history = useHistory();
  console.log("channel", channels);
  const dispatch = useDispatch();
  
  useEffect(() => {
    getListing();
    getUserInfo();
  }, []);
  // const updateUserCredits = async (credits) => {
  //   console.log("credits", credits)
  //   const userInformation = localStorage.getItem("userInfo") || {};
  //   const user = JSON.parse(userInformation);
  //   const obj = {
  //     auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
  //     id: user?.id,
  //     free_credit: credits.payload.free_credit,
  //     paid_credit: credits.payload.paid_credit,
  //     paid_seconds: credits.payload.paid_seconds,
  //     has_made_inapp_purchase: 1,
  //   };
  //   console.log("obj", obj)
  //   try {
  //     const res = await axios.post(
  //       "https://staging.psychictxt.net/API/v19/Client_API/updateCredits",
  //       obj,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     console.log("RES CREDITS: ", res);
  //     if(res.data.result === 1){
  //       dispatch(setMessages(credits));
  //       // message.success("Check your credits are updated!")
  //     }

  //   } catch (err) {
  //     console.log("err", err);
  //   }
  // };
  useEffect(() => {
    if (newList !== null) {
      const arr = listing?.map((e) => {
        if (e.id === newList.id) {
          return newList;
        }
        return e;
      });
      filterListing(arr);
      // setListing((old) => [...old, newList]);
    }
  }, [newList]);

  const leaveApplication = () => {
    pubnub.removeListener(listnerAdd);
    pubnub.unsubscribeAll();
  };
  const listnerAdd = {
    status: (statusEvent) => {
      var subscribedChannels = statusEvent.subscribedChannels;
      console.log("subscribedChannels", subscribedChannels);
      if (statusEvent.category === "PNConnectedCategory") {
        console.log("Connected");
      }
    },
    message: (event) => {
      const message = event.message;
      console.log("message", message);
      // dispatch(setMessages(message));
      updateUserCredits(message, dispatch)
      var newObj = Object.values(message?.advisor)[0];
      console.log("new", newObj);
      setNewList(newObj);
     
      // setListing(old => [...old, newObj])
      // console.log("newList-----", listing)
    },
  };
  useEffect(() => {
    const userInformation = localStorage.getItem("userInfo") || {};
    const obj = JSON.parse(userInformation);
    setChannels("Channel_User_".concat(obj?.id));
    // pubnub.addListener(createSignalListener(store.dispatch));
    pubnub.addListener(listnerAdd);
    if (channels) {
      pubnub.subscribe({
        channels: [channels, "channel_application"],
        withPresence: true,
      });
      pubnub.publish({
        channel: [channels, "channel_application"],
        message: "Hello World!",
      });
    }
    return leaveApplication;
  }, [pubnub, channels]);

  const getListing = async () => {
    const obj = { auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj" };
    const res = await axios.post(
      "https://staging.psychictxt.net/API/v19/Advisor_API/getAdvisorWithoutReviews",
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("res", res);
    dispatch(setListingAdvisors(Object.values(res.data.message)));
    filterListing(Object.values(res.data.message));
  };

  const filterListing = (res) => {
    var array = res.filter((e) => {
      return e.status === "1" && e.live_status === "1";
    });
    var array2 = res.filter((e) => {
      return e.live_status === "1" && e.status !== "1";
    });
    var array3 = res.filter((e) => {
      return e.status === "1" && e.live_status !== "1";
    });
    var array4 = res.filter((e) => {
      return e.status === "0" && e.live_status === "0";
    });
    setListing(array.concat(array2).concat(array3).concat(array4));
  };

  const getUserInfo = () => {
    const userInformation = localStorage.getItem("userInfo") || {};
    console.log("userInformation----", JSON.parse(userInformation));
    const obj = JSON.parse(userInformation);
    console.log("objj- user", obj)
    setUserInfo({ ...obj });
  };
  const handleAdvisor = (e) => {
    console.log("received value", e.target.id);
    const id = e.target.id;
    history.push(`/advisors/${id}`);
  };
  const handleEventPushClick = () => {
    pushEvent("Inbox Opened", {
      "User name": userInfo?.username,
      "User ID": userInfo?.id,
      Date: new Date(),
    });
  };
  const showPopconfirm = () => {
    setOpen(true);
  };
  const logoutSession = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    history.push("/");
    console.log("logout");
  };
  return (
    <>
      <Container>
        <Row className="d-flex justify-content-end">
          <Popconfirm
            title="Do you want to logout?"
            okText="Logout"
            cancelText="Cancel"
            open={open}
            onConfirm={logoutSession}
          >
            <Button onClick={showPopconfirm}>Logout</Button>
          </Popconfirm>
        </Row>
        <Row
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100px" }}
        >
          <Col lg={4} md={4} className="d-flex justify-content-center ">
            <Avatar size={64} src={image} />
          </Col>
          {userInfo && (
            <Col lg={5} md={5}>
              <Row className="d-flex justify-content-start gap-3">
                <p className="w-25 mb-0">ID:</p>
                <p className="mb-0 text-muted">
                  {userInfo.id ? userInfo.id : "-"}
                </p>
              </Row>
              <Row className="d-flex justify-content-start gap-3">
                <p className="w-25 mb-0">User Name:</p>
                <p className="mb-0 text-primary">
                  {" "}
                  {userInfo.username ? userInfo?.username : "-"}
                </p>
              </Row>
              <Row className="d-flex justify-content-start gap-3">
                <p className="w-25 mb-0">Email: </p>
                <p className="mb-0 text-primary">
                  {" "}
                  {userInfo.email ? userInfo.email : "-"}
                </p>
              </Row>
            </Col>
          )}
          <Col lg={2}>
            <Row>
              <a href="/inbox" onClick={handleEventPushClick}>
                <BsFillChatDotsFill color="pink" style={{fontSize: '35px'}}/>
              </a>
            </Row>
          </Col>
        </Row>
        <Divider />
        <h5 className="text-center p-3">
          <u>Advisors Listing</u>{" "}
        </h5>

        {listing.length !== 0 ? (
          <Row className="d-flex gap-4 justify-content-center">
            {listing?.map((e, i) => {
              return (
                <>
                  <Col
                    lg={3}
                    md={6}
                    key={i}
                    className="d-flex justify-content-center"
                  >
                    <Card
                      hoverable
                      style={{
                        width: 240,
                        height: 450,
                        marginBottom: "10px",
                        position: "relative",
                      }}
                      cover={
                        <img
                          alt="example"
                          src={e.s3_profile_picture}
                          style={{ overflow: "hidden", height: 240 }}
                          id={e.id}
                          onClick={(val) => handleAdvisor(val)}
                        />
                      }
                    >
                      <div className="online_status">
                        {e.live_status === "1" && (
                          <Tag color="green">Online</Tag>
                        )}
                        {e.live_status === "2" && <Tag color="red">Busy</Tag>}
                      </div>
                      <div className="call_rate">
                        {e.live_status === "1" ? (
                          <Tag color="blue">{e.call_rate} Cr/Min</Tag>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="featured">
                        {e.is_featured === "1" ? (
                          <Tag color="red">FEATURED</Tag>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* call_rate */}
                      <Meta
                        title={e.username}
                        description={e?.short_description
                          ?.replace("<p>", "")
                          .replace("&nbsp;", "")
                          .replace("</p>", "")}
                        style={{ height: 60, overflow: "hidden" }}
                      />
                      <Rate value={Math.round(e.ratings)} /> &nbsp;{" "}
                      {Math.round(e.ratings)}
                      <Row>
                        <Col lg={6}>
                          <p className="mb-0">{e.total_orders}</p>{" "}
                          <p>Readings</p>
                        </Col>
                        <Col lg={6}>
                          <p className="mb-0">{e.year_joined}</p>{" "}
                          <p>Year Joined</p>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-around">
                        {e.live_status === "0" ? (
                          <Button
                            type="primary"
                            size="small"
                            // disabled={e.live_status === "0" ? true : false}
                            disabled={true}
                            style={{ width: "80px" }}
                          >
                            Live Chat
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            size="small"
                            // disabled={e.live_status === "0" ? true : false}
                            style={{ width: "80px" }}
                          >
                            Live Chat
                          </Button>
                        )}
                        <Button
                          type="danger"
                          size="small"
                          style={{ width: "80px" }}
                          disabled={e.status === "0" ? true : false}
                        >
                          Text
                        </Button>
                      </Row>
                    </Card>
                  </Col>
                </>
              );
            })}
          </Row>
        ) : (
          <Row className="d-flex justify-content-center align-items-center">
            <Spin style={{ width: "100px", height: "100px" }} />
          </Row>
        )}
      </Container>
    </>
  );
};

export default memo(Index);
