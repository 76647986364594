import { Col } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserDetails } from "../../api/client";
import { getOneWebProduct } from "../../api/credits";
import { PaymentCartDetails, UserCardDetails } from "../../components/Payment";
import { usePubNub } from "pubnub-react";

const Index = ({id, setIsModalOpen}) => {

  console.log("CHECKIDDD---", id);
  const dispatch = useDispatch();
  const [oneProdValue, setOneProdValue] = useState({});
  const [isUser, setIsUser] = useState(null);
  const [prodID, setProdID] = useState(undefined);
  const pubnub = usePubNub();
  const [channels, setChannels] = useState("");
  useEffect(() => {
    if (id) {
      const realID= id?.split('-', 1)[0]?.split('/')[2];
      // .split('-', 1)[0]?.split('/')[2];
      console.log("hhh",realID)
      getOneWebProduct(realID, dispatch);
    }
  }, []);

  useEffect(() => {
    console.log("ppp",id)
    setProdID(id?.split('/')[2]?.split('-')[0]) // ?.split('/')[2]?.split('-')[0]
    // setIsModalOpen // test here
  }, [id]);
  const oneProductList = useSelector((x) => x.oneProductList);
  console.log("oneProductList", oneProductList[0]);
  useEffect(() => {
    setOneProdValue(oneProductList[0]);
  }, [oneProductList]);

  console.log("oneProdValue", oneProdValue);

  useEffect(() => {
    getUserDetails(dispatch);
  }, []);

  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    setIsUser(profile);
  }, [profile]);
  console.log("PROFCHK", isUser);

  return (
    <>
  
           {prodID && <UserCardDetails oneProdValue={oneProdValue} prodID={prodID} isUser={isUser} id={id} setIsModalOpen={setIsModalOpen} />}

    </>
  );
};

export default memo(Index);
