import {
  Avatar,
  Button,
  Divider,
  Modal,
  notification,
  Rate,
  Skeleton,
  Spin,
  Tag,
} from "antd";
import axios from "axios";
import Emoji from "./emoji";
import React, { memo, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AiFillVideoCamera, AiTwotoneSafetyCertificate } from "react-icons/ai";
import { BiDotsVerticalRounded, BiPhoneCall } from "react-icons/bi";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import "./index.css";
import MessageBox from "./messagebox";
import ScrollableFeed from "react-scrollable-feed";
import { usePubNub } from "pubnub-react";
import clevertap from "clevertap-web-sdk";
import S3FileUpload from "react-s3";
import { updateUserCredits } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { getClientInfo, getUserDetails } from "../../../api/client";
import ReviewModal from "../../../modals/reviewModal";
import UserDetailForm from "../../Reading/userDetailForm";
import InfoDetailForm from "../../Chat/chatpopup/InfoDetailForm";
import MessageDetailForm from "../../Chat/chatpopup/messageDetailForm";
import { getAdvisorFullProfile } from "../../../api/advisor";
import { SpaceContext } from "antd/lib/space";
import { useHistory } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import InboxHead from "./inboxHead";
import InboxChatContent from "./inboxChatContent";
import LoadMore from "./loadMore";
// import { getChatContent } from "../../../api/chat";

const oneDay = 24 * 60 * 60 * 1000;
const config = {
  bucketName: "mytarotadvisorvideos",
  region: "us-west-2",
  accessKeyId: "AKIAU7M7MBKJ2DEJLFP4",
  secretAccessKey: "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",
};

const Index = ({
  advisorID,
  pageNum,
  advisorName,
  advisorOnlineStatus,
  setChatArr,
  chatArr,
  advisorLink,
  advisorsID,
  setAdvisorLink,
  setAdvisorName,
  setAdvisorOnlineStatus,
  advisorRefreshList,
  setHasData,
  hasData,
  setPage,
  page,
}) => {
  console.log(
    Math.round(
      Math.abs((new Date() - new Date("2022-10-20 06:27:02 +0000")) / oneDay)
    )
  );
  console.log("advisorsID", advisorsID);
  const pubnub = usePubNub();
  const history = useHistory();
  const userInform = useSelector((state) => state.userInfo);
  console.log("----userInform----", userInform);
  const dispatch = useDispatch();
  const chatContainer = React.createRef();
  const [counts, setCounts] = useState(1);
  console.log("id-------", advisorID);
  console.log("pageNum-------", pageNum);
  const [imgSet, setImgSet] = useState("");
  const [chatBody, setChatBody] = useState([]);
  const [user, setUser] = useState();
  // const [hasData, setHasData] = useState(true);
  const [pageNo, setPageNo] = useState(pageNum);
  // const [message, setMessage] = useState("");
  const [channels, setChannels] = useState("");
  const [clearInput, setClearInput] = useState(false);
  const [msg, setMsg] = useState({ text: "" });
  const [showResult, setShowResult] = useState(false);
  const [color, setColor] = useState("lightgray");
  const [msgId, setMsgId] = useState(null);
  const [userObj, setUserObj] = useState(null);
  const [replyTime, setReplyTime] = useState("");
  const [userProfilee, setUserProfilee] = useState(null);
  const [userPopDetails, setUserPopDetails] = useState(null);
  const [orderType, setOrderType] = useState("");
  const [allow, setAllow] = useState(false);
  const [rateVal, setRateVal] = useState(null);
  const [rateVal2, setRateVal2] = useState(null);
  console.log("msg", msg);
  const [idd, setmsgID] = useState(null);
  const [enableInfoMsg, setEnableInfoMsg] = useState(true);
  const [enableRate, setEnableRate] = useState(true);
  const [messageInput, setMessage] = useState({ text: "" });
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    type: "",
  });
  const [isModalOpen0, setIsModalOpen0] = useState(false); // false
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [showAttach, setShowAttach] = useState(true);
  const [showAudio, setShowAudio] = useState(true);
  const [showInput, setShowInput] = useState(true);
  const [isMsgSpin, setIsMsgSpin] = useState(false);
  const [showEmojiTrue, setShowEmojiTrue] = useState(false);
  // Test
  const [img, setImg] = useState("");
  const [infoo, setInfooMsg] = useState("");
  const [dur, setDur] = useState("");
  const [typ, setTyp] = useState("");

  //  Test end
  const [showEmojis, setShowEmojis] = useState(false);
  const [read, setRead] = useState(null);
  const [readAdvisorName, setReadAdvisorName] = useState(null);
  const [onlineStatusInbox, setOnlineStatusInbox] = useState('');
  const [advisorDetails, setAdvisorDetails] = useState(null);
  const [loaderProcess, setLoaderProcess] = useState(false);
  const openEmojiBox = () => {
    setShowEmojis(true);
  };
  useEffect(() => {
    setRateVal2(rateVal);
  }, [rateVal]);
  const showModal = (id) => {
    setMsgId(id);
    setIsModalOpen0(true);
  };
  console.log("msg id", msgId);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel0 = () => {
    setIsModalOpen0(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };
  console.log("chk--", userObj);
  useEffect(() => {
    getAdvisorFullProfile(advisorsID, dispatch);
    // getClientInfo(dispatch);
    getUserDetails(dispatch);
  }, [userPopDetails]);
  const profile = useSelector((state) => state.profile);
  console.log("profile", profile);

  useEffect(() => {
    console.log("Hit");
    setUserProfilee(profile);
    setUserObj(profile);
  }, [profile, userPopDetails]);

  const advisorProfile = useSelector((x) => x.advisorProfile);
  console.log("advisorProfile", advisorProfile);

  useEffect(() => {
    setAdvisorLink(advisorProfile?.s3_display_picture);
    // setAdvisorName(advisorProfile?.first_name);
    setReadAdvisorName(advisorProfile?.username);
    setAdvisorOnlineStatus(advisorProfile?.live_status);
    setAdvisorDetails(advisorProfile);
  }, [advisorProfile]);

  const handleMessage = (event) => {
    const message = event.message;
    console.log("message", message);
    if (message.auth_key !== "") {
      setChatArr((prev) => [
        ...prev,
        {
          // client_deleted: chatMsgs?.client_deleted,
          client_dob: message.client_dob ? message.client_dob : "",
          client_gender: message.client_gender ? message.client_gender : "",
          // closing_reason: message?.closing_reason,
          id: message?.message_id,
          // is_closed: message?.is_closed,
          is_rateable: message?.is_rateable,
          message_review_id: message?.message_review_id,
          message_type: message?.message_type,
          msg_date: message?.msg_date,
          msg_text: message?.msg_text,
          receiver_displayname: message?.receiver_displayname,
          receiver_id: message?.receiver_id,
          receiver_type: message?.receiver_type,
          response_time: message?.response_time,
          review_status: message?.review_status,
          sender_displayname: message?.sender_displayname,
          sender_id: message?.sender_id,
          sender_type: message.sender_type,
          status: message.status,
          video_url: message.video_url,
        },
      ]);
    }
    // updateUserCredits(message, dispatch);
  };
  const leaveApplication = () => {
    pubnub.removeListener(listnerAdd);
    pubnub.unsubscribeAll();
  };
  const listnerAdd = {
    status: (statusEvent) => {
      var subscribedChannels = statusEvent.subscribedChannels;
      console.log("subscribedChannels", subscribedChannels);
      if (statusEvent.category === "PNConnectedCategory") {
        console.log("Connected");
      }
    },
    message: handleMessage,
  };

  useEffect(() => {
    getClientInfo(dispatch);
    const userInformation = localStorage.getItem("userInfo");
    const obj = JSON.parse(userInformation);
    // if (obj && obj !== null && obj !== undefined) {
    //   setUserObj(obj);
    // }
    setChannels("Channel_User_".concat(obj?.id));
    pubnub.addListener(listnerAdd);
    if (channels) {
      pubnub.subscribe({
        channels: [channels, "channel_application"],
        withPresence: true,
      });
    }
    return leaveApplication;
  }, [pubnub, channels]);
  console.log("chatBody--", chatBody);
  console.log("chatArr", chatArr);

  const addEmoji = (e) => {
    let emoji = e.emoji;
    setMessage((prev) => {
      console.log("prev", prev);
      return {
        // ...prev,
        // text: e.emoji,
        text: prev.text + emoji,
      };
    });
    setMsg((prev) => {
      console.log("prev", prev);
      return { text: prev.text + emoji };
    });
  };
  useEffect(() => {
    const userInformation = localStorage.getItem("userInfo") || {};
    const objectuser = JSON.parse(userInformation);
    setUser(objectuser);
    if (advisorsID) {
      getChatContent();
    }
  }, [advisorsID]);

  const scrollToMyRef = () => {
    const scroll =
      this.chatContainer.current.scrollHeight -
      this.chatContainer.current.clientHeight;
    this.chatContainer.current.scrollTo(0, scroll);
  };
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const handleEventPushClick = () => {
    const userInformation = localStorage.getItem("userInfo") || {};
    const objectuser = JSON.parse(userInformation);
    clevertap.event.push("Chat Started", {
      "User name": objectuser?.username,
      "User ID": objectuser?.id,
      "advisor ID": advisorsID,
      Date: new Date(),
    });
  };

  const sendMessage = async (image, type, dura, infomsg, orderTyp, repTime) => {
    if (orderType !== "") {
      setIsMsgSpin(true);
    }
    setImg(image);
    setTyp(type);
    setDur(dura);
    setInfooMsg(infomsg);
    console.log("repTime", repTime, replyTime);
    console.log("git img and type", image, type);
    if (type === "") {
      type = "text";
    }
    if (infomsg) {
      type = "info";
    }
    console.log("type", type);
    console.log("dura", dura);
    var imgObj = "";
    var secondObject = image;
    const userInformation = localStorage.getItem("userInfo") || {};
    const objectuser = JSON.parse(userInformation);
    console.log("userProfilee", userProfilee);
    if (
      // (
      userProfilee &&
      userProfilee?.username === ""
      // ||
      // userProfilee?.dob === "" || userProfilee?.gender === '')
    ) {
      console.log("--CHKKKK---", userProfilee);
      setIsModalOpen(true);
    } else {
      setIsMsgSpin(true);
      // return;
      var ids = objectuser?.id
        .concat("-to-")
        .concat(advisorsID)
        .concat("/")
        .concat(moment(new Date()).format("YYYY-MM-DD"))
        .concat("-")
        .concat(moment(new Date()).format("HH-MM-SS"));
      var name = "audio"
        .concat("/")
        .concat(ids)
        .concat("~")
        .concat(dura?.split(":0", 3)[2])
        .concat(".mp3");
      var file = new File([image?.audioData], name, { type: image.type });
      console.log("last", file);
      console.log("userInform", userInform);
      const credits = +userProfilee?.free_credit + +userProfilee?.paid_credit;
      const seconds = +userProfilee?.free_seconds + +userProfilee?.paid_seconds;
      if (credits >= 150) {
        console.log("msg chk---", msg.text);
        if ((msg.text !== null && msg.text !== "") || image !== "" || infomsg) {
          //test
          if (
            advisorProfile &&
            advisorProfile?.rush_status === "1" &&
            orderType === "" &&
            !infomsg
          ) {
            //advisor?.rush_status === '1' &&
            setOrderType("text");
            setIsModalOpen2(true);
          } else {
            console.log("OrderType: -> ", orderType);
            if (type === "audio") {
              secondObject = file;
              console.log("secondObject", secondObject);
            }
            if (type === "audio" || type === "photo") {
              await S3FileUpload.uploadFile(secondObject, config)
                .then((data) => {
                  console.log("-------UPLOADED------");
                  console.log("location", data.location);
                  imgObj = data.location;
                })
                .catch((err) => console.error(err));
            }
            const obj = {
              auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
              // message_type: "text",
              msg_date: "2022-10-07 11:12:27 +0500",
              msg_text: infomsg ? infomsg : msg.text ? msg.text : "",
              receiver_displayname: advisorName,
              receiver_id: advisorsID,
              receiver_type: "advisor",
              sender_displayname: userProfilee?.username
                ? userProfilee.username
                : userPopDetails?.username,
              sender_id: userProfilee?.id,
              sender_type: "client",
              App_Version: "7.2",
              Device_Type: "web",
              client_dob: userPopDetails?.date
                ? moment(userPopDetails.date).format("YYYY-MM-DD")
                : userProfilee?.dob,
              client_gender:
                userProfilee?.gender !== ""
                  ? userProfilee.gender
                  : userPopDetails?.gender,
              client_message_review_id: "",
              email_notification_enabled: 1,
              message_review_id: "w_".concat(makeid(21)),
              // response_time: infomsg
              //   ? "0"
              //   : replyTime !== "" || (repTime !== "" && repTime !== undefined && repTime !== null)
              //   ? (orderType === "fast" || orderTyp === 'fast') ? "rush_".concat(replyTime) : replyTime
              //   : "text",
              response_time: infomsg
                ? "0"
                : orderType === "fast" || orderTyp === "fast"
                ? "rush_".concat(
                    replyTime !== "" ||
                      (repTime !== "" &&
                        repTime !== undefined &&
                        repTime !== null)
                      ? replyTime
                      : "text"
                  )
                : replyTime !== "" ||
                  (repTime !== "" && repTime !== undefined && repTime !== null)
                ? replyTime
                : "text",
              review_status: 0,
              status: type === "info" ? 1 : 0,
              video_url: imgObj !== "" ? imgObj : "",
              message_type:
                orderType === "fast" || orderTyp === "fast"
                  ? "rush_".concat(type)
                  : type,
              order_type:
                orderType === "fast" || orderTyp === "fast"
                  ? "rush_".concat(type)
                  : type,
              // re_engagement: 1,
              // live_session_id: 1,
            };
            console.log("new api obj", obj);
            //test
            try {
              const res = await axios.post(
                "https://staging.psychictxt.net/API/v19/Pubnub/saveChatPayload",
                obj,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              console.log("FINAL_RES", res);
              setIsMsgSpin(false);
              if (res?.data?.result === -1) {
                notification.open({
                  message: "Update!",
                  description: `${res.data.message}`,
                  duration: 3,
                });
              } else {
                setChatArr((prev) => [
                  ...prev,
                  {
                    msg_text: infomsg ? infomsg : msg.text ? msg.text : "",
                    msg_date: new Date(),
                    sender_type: "client",
                    video_url: imgObj ? imgObj : "",
                    message_type: type ? type : "text",
                    status: 0,
                    review_status: "0",
                  },
                ]);
                setMsg({ text: "" });
                setClearInput(true);
                setShowResult(false);
                setSelectedFile({
                  file: null,
                  type: "",
                });
                setColor("lightgray");
                // handleEventPushClick();
                setMessage({ text: "" });
                setShowInput(true);
                setShowAttach(true);
                setShowAudio(true);
                // if (localStorage.getItem("reading")) {
                  localStorage.removeItem("reading");
                // }
              }
            } catch (err) {
              console.log("err", err);
            }
          }
        } else {
          notification.open({
            message: "Notification!",
            description: "Please enter a message first.",
            duration: 3,
          });
          setIsMsgSpin(false);
        }
        //end
      } else {
        // notification.open({
        //   message: "Credits!",
        //   description: "Please update credits.",
        //   duration: 3,
        // });
        history.push("/credits/text");
        setIsMsgSpin(false);
      }
    }
  };
  useEffect(() => {
    if (advisorRefreshList !== undefined) {
      // var list_neww = chatArr;
      const filtering = chatArr?.filter((x) => {
        if (
          x?.sender_id === advisorRefreshList?.id ||
          x?.receiver_id === advisorRefreshList?.id
        ) {
          return (x.txt_status = advisorRefreshList.status);
          // return advisorRefreshList;

        }
        return x;
      });
      console.log("filtering", filtering);
      setChatArr(filtering);
      // setAdvisorLink(advisorRefreshList?.live_status);
    }
  }, [advisorRefreshList]);
  const getChatContent = async () => {
    const userInformation = localStorage.getItem("userInfo") || {};
    const objectuser = JSON.parse(userInformation);
    const obj = {
      auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
      user_a: objectuser.id, //141360, //
      user_b: advisorsID,
      page: page !== undefined && page !== 0 && page !== null ? page : 0,
      type: "",
    };
    console.log("obj--", obj);

    try {
      const res = await axios.post(
        "https://staging.psychictxt.net/API/v19/Message_API/getChatBody_Web",
        obj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("res ----", res?.data?.message);
      // // setChatBody(res?.data?.message);
      // setChatBody(chatBody.concat(res?.data?.message).reverse());
      // setChatArr(chatArr.concat(res?.data?.message).reverse());
      // // , () => scrollToMyRef()
      // if (res.data.message.length === 0) {
      //   setHasData(false);
      // }
      if (page !== undefined && page !== 0 && page !== null) {
        if (res.data.message.length !== 0) {
          // setChatArr(prev => prev.concat(res?.data?.message));
          setChatArr((prev) => prev.concat(res?.data?.message));
          setCount((prev) => prev + 10);
          setIsSpin(false);
          setLoading(false);
        } else {
          console.log("page, chkkk");
          setHasData(false);
          setCount((prev) => prev - 10);
          setIsSpin(false);
        }
      } else {
        if (res?.data?.message?.length === 0) {
          setHasData(false);
          setIsSpin(false);
        } else {
          // setChatArr([].concat(res?.data?.message));
          setChatArr([].concat(res?.data?.message));
          // arr = res?.data?.message.sort(function (a, b) {
          //   var dateA = new Date(a.date_prop).getTime();
          //   var dateB = new Date(b.date_prop).getTime();
          //   return dateA < dateB ? 1 : -1; // ? -1 : 1 for ascending/increasing order
          // });
          setIsSpin(false);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    console.log("hi-");
    // if (chatBody?.length === 20) {
    //   setHasData(false);
    //   return;
    // }
    setPageNo((prev) => prev + 1);
    var count = pageNo + 1;
    setTimeout(() => {
      getChatContent();
      // setChatBody(chatBody.concat(Array.from({ length: 5 })) );
    }, 1500);
  };
  useEffect(() => {
    if (
      msg.text === "" &&
      showAttach === false &&
      showAudio === false &&
      showInput === true
    ) {
      setShowAttach(true);
      setShowAudio(true);
    }
  });
  console.log("page no", pageNo);
  const [loading, setLoading] = useState(false);
  // const [hasData, setHasData] = useState(true);
  // const [page, setPage] = useState(0);
  const [count, setCount] = useState(10);
  const [isspin, setIsSpin] = useState();
  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setPage((prev) => prev + 1);
    setLoading(true);
    //   getChatContent(advisor, setChatArr, dispatch, setIsSpin);
    // fetch('https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo')
    //   .then((res) => res.json())
    //   .then((body) => {
    //     setChatArr([...chatArr, ...body.results]);
    //     setLoading(false);
    //   })
    // setLoading(false);
    // .catch(() => {
    //   setLoading(false);
    // });
  };
  console.log("hasData", hasData);
  // useEffect(() => {
  //   loadMoreData();
  // }, []);
  useEffect(() => {
    if (page !== 0) {
      getChatContent(
        advisorsID,
        setChatArr,
        dispatch,
        setLoading,
        page,
        setCount,
        setHasData
      );
    }
  }, [page]);

  function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/png'});
}
  const sendReadingMessage = async () => {
    console.log("read_funnnn");
    let newImgObj = "";
    if (read?.selectedFile) {
      let { fileName, file } = read?.selectedFile;
      console.log("TESTFile", file);
      // let fileee = await fetch(file)
      //   .then((r) => r.blob())
      //   .then(
      //     (blobFile) => new File([blobFile], fileName, { type: blobFile.type })
      //   );

        // const base64Data = new Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        // const type = fileName.split(';')[0].split('/')[1];
        // const fileee = {
        //   Body: base64Data,
        //   ACL: 'public-read',
        //   ContentEncoding: 'base64', // required
        //   ContentType: `image/${type}` // required. Notice the back ticks
        // }


      const fileBlob = dataURItoBlob(file);
      var fileee = new File([fileBlob], fileName, { type: "image/png"});
      console.log("TESTFile---", fileee);
      await S3FileUpload.uploadFile(fileee, config)
        .then((data) => {
          console.log("-------UPLOADED------");
          console.log("location", data.location);
          newImgObj = data.location;
        })
        .catch((err) => console.error(err));
    }
    const obj = {
      auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
      // message_type: "text",
      msg_date: new Date(),
      msg_text: read?.reading,
      receiver_displayname:
        readAdvisorName !== null ? readAdvisorName : advisorName,
      receiver_id: advisorsID,
      receiver_type: "advisor",
      sender_displayname: userProfilee?.username,
      sender_id: userProfilee?.id,
      sender_type: "client",
      App_Version: "7.2",
      Device_Type: "web",
      client_dob: userProfilee?.dob,
      client_gender: userProfilee?.gender,
      client_message_review_id: "",
      email_notification_enabled: 1,
      message_review_id: "w_".concat(makeid(21)),
      response_time: "text",
      review_status: 0,
      status: 0,
      video_url: newImgObj,
      message_type: read?.selectedFile ? "photo" : "text",
      order_type: read?.selectedFile ? "photo" : "text",
    };
    console.log("new api obj", obj);
    //test
    try {
      const res = await axios.post(
        "https://staging.psychictxt.net/API/v19/Pubnub/saveChatPayload",
        obj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("FINAL_RES", res);
      if (res?.data?.result === -1) {
        notification.open({
          message: "Update!",
          description: `${res.data.message}`,
          duration: 3,
        });
       
      } else {
        console.log("newImgObj", newImgObj);
        setLoaderProcess(false);
        setChatArr((prev) => [
          ...prev,
          {
            msg_text: read?.reading,
            msg_date: new Date(),
            sender_type: "client",
            video_url: newImgObj ? newImgObj : "",
            message_type: read?.selectedFile ? "photo" : "text",
            status: 0,
            review_status: "0",
          },
        ]);
        // localStorage.removeItem("reading");
        // setRead(null);
        if(localStorage.getItem('reading')){

          localStorage.removeItem('reading');
        }
       
        // setCounts(counts => counts + 1);
        notification.open({
          message: "Success!",
          description: `Your order has been placed. You may view your orders in the inbox.`,
          duration: 3,
        });
      }
    } catch (err) {
      console.log("err", err);
      // notification.open({
      //   message: "Error!",
      //   description: `${err}`,
      //   duration: 3,
      // });
    }
  };
  // useEffect(() => {
  //   if (localStorage.getItem("reading")) {
  //     sendReadingMessage();
  //   }
  // }, []);

  useEffect(() => {
    // if(localStorage.getItem("reading")){
    //   console.log("read_calling");
      const reading = localStorage.getItem("reading")
      ? JSON.parse(localStorage.getItem("reading"))
      : null;
    if (reading) {
      setRead(reading);
    }
    // }
 
  }, []);

  useEffect(() => {
    if (read && readAdvisorName) {
      setLoaderProcess(true);
      sendReadingMessage();
    }
  }, [read, readAdvisorName]);

  console.log("read_test", read);
  console.log("main_advisorDetails", advisorRefreshList);
  return (
    <>
      <InboxHead
        advisorName={advisorName}
        advisorOnlineStatus={advisorOnlineStatus}
        advisorLink={advisorLink}
        advisorsID={advisorsID}
        advisorDetails={advisorDetails}
        advisorRefreshList={advisorRefreshList}
        setOnlineStatusInbox={setOnlineStatusInbox}
      />
      {isModalOpen0 === true && msgId !== null &&  ( 
        <ReviewModal
          advisorID={advisorsID}
          advisorName={advisorName}
          isModalOpen={isModalOpen0}
          advisorLink={advisorLink}
          handleOk={handleOk}
          handleCancel={handleCancel0}
          msgId={msgId}
          setIsModalOpen={setIsModalOpen0}
          setRateVal={setRateVal}
          setmsgID={setmsgID}
          setEnableRate={setEnableRate}
        />
      )}
      <Row id="scroller" className="read-box p-3">
        {/* {chatArr.length !== 0 ? ( */}
         {loaderProcess === false ? <>
            {hasData === true ? (
              <div className="main-container-msg">
                {loading === false ? (
                  <LoadMore loadMoreData={loadMoreData} />
                ) : (
                  <div className="d-flex justify-content-center">
                    <Skeleton active />
                  </div>
                )}
              </div>
            ) : (
              <Divider plain>no more messages 🤐</Divider>
            )}
            <ScrollableFeed>
              {chatArr
                ?.sort(function (a, b) {
                  var dateA = new Date(a.msg_date).getTime();
                  var dateB = new Date(b.msg_date).getTime();
                  return dateA > dateB ? 1 : -1; // ? -1 : 1 for ascending/increasing order "<"
                })
                ?.map((e) => {
                  return (
                    <InboxChatContent
                      e={e}
                      enableInfoMsg={enableInfoMsg}
                      setIsModalOpen3={setIsModalOpen3}
                      rateVal2={rateVal2}
                      idd={idd}
                      advisorLink={advisorLink}
                      advisorOnlineStatus={advisorOnlineStatus}
                      enableRate={enableRate}
                      userObj={userObj}
                      showModal={showModal}
                      advisorDetails={advisorDetails}
                      advisorRefreshList={advisorRefreshList}
                      onlineStatusInbox={onlineStatusInbox}
                    />
                  );
                })}
            </ScrollableFeed>
          </> : <div className="w-100 h-100 d-flex align-items-center justify-content-center gap-3">
          <Spin />
          <h6 className="mb-0">Please wait until your order is placed.</h6>
            </div>}
        {/* ) : (
          ""
          // <div>
          //   <p>Start your first chat!</p>
          // </div>
        )} */}
        {showEmojis === true && (
          <div className="iconsx">
            <Emoji
              addEmoji={addEmoji}
              setShowEmojis={setShowEmojis}
              showEmojis={showEmojis}
            />
          </div>
        )}
        {/* </InfiniteScroll> */}
      </Row>
      <MessageBox
        sendMessage={sendMessage}
        setMsg={setMsg}
        clearInput={clearInput}
        setClearInput={setClearInput}
        setShowResult={setShowResult}
        showResult={showResult}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        setColor={setColor}
        color={color}
        setReplyTime={setReplyTime}
        setShowAttach={setShowAttach}
        showAttach={showAttach}
        setShowAudio={setShowAudio}
        showAudio={showAudio}
        showInput={showInput}
        setShowInput={setShowInput}
        isMsgSpin={isMsgSpin}
        messageInput={messageInput}
        setMessage={setMessage}
        setShowEmojiTrue={setShowEmojiTrue}
        openEmojiBox={openEmojiBox}
        advisorsID={advisorsID}
        advisorDetails={advisorDetails}
        advisorOnlineStatus={advisorOnlineStatus}
      />
      <Modal
        title="User Details"
        open={isModalOpen} 
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="userDetail-modal"
      >
        <UserDetailForm
          setUserPopDetails={setUserPopDetails}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
      <Modal
        title="Message Details"
        open={isModalOpen2}
        // onOk={handleOk}
        onCancel={handleCancel2}
        footer={null}
      >
        <MessageDetailForm
          setOrderType={setOrderType}
          setIsModalOpen2={setIsModalOpen2}
          img={img}
          infoo={infoo}
          dur={dur}
          typ={typ}
          replyTime={replyTime}
          sendMessage={sendMessage}
        />
      </Modal>
      <Modal
        title="Info Message"
        open={isModalOpen3} 
        // onOk={handleOk}
        onCancel={handleCancel3}
        footer={null}
        className="userDetail-modal"
      >
        <InfoDetailForm
          sendMessage={sendMessage}
          setIsModalOpen3={setIsModalOpen3}
          setEnableInfoMsg={setEnableInfoMsg}
        />
      </Modal>
    </>
  );
};

export default memo(Index);
