import React from "react";
// import { Row, Col } from "react-bootstrap";
import { Progress, Rate } from "antd";
import { LikeOutlined } from "@ant-design/icons";
import { Col, Row, Statistic } from "antd";
import "./index.css";
const RateCountProgressBar = () => {
  return (
    <>
      <div className="rateCountProgressBar">
        <Rate count={1} 
        value={5} 
        style={{ color: "#F7B614", fontSize: '15px' }}
         disabled />
        <span>5</span>
        <Progress percent={30} size="small"/>
      </div>
    </>
  );
};

export default RateCountProgressBar;
