import React, { useState } from "react";
import { Col, Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Avatar, Spin } from "antd";
import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-alice-carousel/lib/alice-carousel.css";

import { cardData } from "./data";
import { useHistory } from "react-router-dom";
import Arrow from "../../../images/arrowIcon.png"
import CardOne from "./cardOne";
const Cards = () => {
  const history = useHistory();
  const [isEnable, setIsEnable] = useState(false);
  const advisorCategoryPage = (e) => {
    history.push(e?.url);
  };
  const enableArrow = () => {
    setIsEnable(true);
  };
  const disableArrow = () => {
    setIsEnable(false);
  };
  
  return (
    <>
      {cardData?.length !== 0 ? (
        cardData?.map((e, i) => {
          return (
            <>
              {" "}
              {i < 8 && (
                <Col
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  className="card-container mb-3"
                  key={i}
                >
                  <CardOne e={e} key={i} />
                </Col>
              )}
            </>
          );
        })
      ) : (
        <Container className="d-flex justify-content-center">
          <Spin />
        </Container>
      )}
    </>
  );
};

export default Cards;
