import React, { memo, useEffect } from "react";
import { Avatar, Button, Form, Input, Select } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import InfiChat from "../../../../images/info_chat.svg";
import "./index.css";
const { TextArea } = Input;

const Index = ({
  setInfoMsg,
  sendMessage,
  setIsModalOpen3,
  setEnableInfoMsg,
}) => {
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    console.log("Success:", values);
    sendMessage("", "", "", values.infomsg);
    // setInfoMsg(values?.infomsg)
    setEnableInfoMsg(false);
    setIsModalOpen3(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const userInformation = localStorage.getItem("emailForSignIn") || "";
    console.log("userInformation", userInformation);
  });

  return (
    <>
      <Container>
        <Row className="d-flex justify-content-center align-items-center">
          <Col lg={12} className="d-flex justify-content-center align-items-center">
            <Form
              name="basic"
              // labelCol={{
              //   span: 8,
              // }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="d-flex flex-column gap-3 form-height justify-content-center"
            >
              <div style={{ width: "346px" }} className="d-flex flex-column gap-3">
                <Avatar src={InfiChat} size={60} shape="square" />
                <p className="inform-msg">Information Message</p>
                <p className="inform-desc">
                  Write here your details information so you can get accurate
                  answers
                </p>
              </div>
              <Form.Item
                name="infomsg"
                rules={[
                  {
                    required: true,
                    message: "Please enter your info message!",
                  },
                ]}
              >
                <TextArea
                  rows={6}
                  className="inform-area"
                  placeholder="Write here..."
                />
              </Form.Item>
              <div className="d-flex gap-3">
                <Button className="inform-cancel-btn">Cancel</Button>
                <Form.Item>
                  <Button className="inform-send-btn" htmlType="submit">
                    Send
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(Index);
