import React, { memo, useEffect, useState } from "react";
import { Alert, Button, DatePicker, Form, Input, Select } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import dayjs from 'dayjs';
import { updateUserInfo } from "../../../api/client";
import { useDispatch } from "react-redux";
import SigninModal from "../../../modals/signinModal";
import { useHistory, useParams } from "react-router-dom";
import { ImFilePicture } from "react-icons/im";
import "./index.css";
import { FaUpload } from "react-icons/fa";

const { Option } = Select;

const Index = ({ setIsModalOpen }) => {
  const form = Form.useForm();
  const dispatch = useDispatch();
  const [isDisable, setIsDisable] = useState(true);
  const [modal2Open, setModal2Open] = useState(false);
  const [isTitle, setIsTitle] = useState(null);
  const history = useHistory();
  const { id } = useParams();
  const [img, setImg] = useState(null);
  const [displayImg, setDisplayImg] = useState(null);
  const [formDetails, setFormDetails] = useState();
  const [showTopMyBalance, setShowTopMyBalance ] = useState(false);
  var returnTo = `/reading/${id}`;

  const onFinish = async (values) => {
   if(localStorage.getItem('reading')){
    if(localStorage.getItem('userInfo') !== undefined && localStorage.getItem('userInfo') !== null){
      // user is logged in
      const user = JSON.parse(localStorage.getItem('userInfo'));
      const reads = JSON.parse(localStorage.getItem('reading'));
      console.log("user--", user);
      if(+user.free_credit + +user?.paid_credit >=150){
          history.push(`/chat/${reads?.advisorID}`)
        }else{
          setIsModalOpen(true);
        }
     
    }else{
      // history.push('/signup');
      setIsTitle('Create an account!')
      setModal2Open(true);
    }
   }else{ console.log("Success:", values);
    let msgRead =
      "SITUATION: " + values?.situation + " " + "QUESTION: " + values?.question;
    if (img !== null) {
      const obj = {
        ...values,
        advisorID: id,
        selectedFile: img,
        reading: msgRead,
      };
      localStorage.setItem("reading", JSON.stringify(obj));
      // history.push("/signup");
      setIsTitle('Create an account!')
      setModal2Open(true);
    } else {
      const obj = { ...values, advisorID: id, reading: msgRead };
      localStorage.setItem("reading", JSON.stringify(obj));
      // history.push("/signup");
      setIsTitle('Create an account!')
      setModal2Open(true);

    }}
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const userInformation = localStorage.getItem("emailForSignIn") || "";
    console.log("userInformation", userInformation);
  });
  const checkDob = (date, dateString) => {
    setIsDisable(false);
    console.log("Value", date, dateString);

    const date1 = new Date(dateString);
    const date2 = new Date();
    let yearsDiff = date2.getFullYear() - date1.getFullYear();
    console.log("yearsDiff", yearsDiff);
    if (yearsDiff >= 18) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  };
  const handler = async (e) => {
    console.log("fileee", e);
    var filename = e?.target?.files[0];
    const { name } = e?.target?.files[0];
    console.log("filename", name?.split(".")[0]);
    setDisplayImg(e?.target?.files[0]);
    const reader = new FileReader();
    reader.addEventListener("load", ()=>{
      // localStorage.setItem()
      setImg({ file: reader.result, type: "photo" , fileName: name?.split('.')[0]});
    })
    reader.readAsDataURL(e?.target?.files[0]);
    //  setImg({ file: URL.createObjectURL(e?.target?.files[0]), type: "photo" , fileName: name?.split('.')[0]});
  };
  useEffect(() => {
    console.log("ch", img?.file);
  }, []);
  console.log("img", img);
  console.log("displayImg", displayImg);
  useEffect(()=>{
    if(localStorage.getItem('reading')){
      setFormDetails(JSON.parse(localStorage.getItem('reading')))
    }
  }, [])
console.log("formDetails", formDetails);
// useEffect(() => {
//   form.setFieldsValue(formDetails);
// }, [form, formDetails]);
  return (
    <>
      <SigninModal
        returnTo={returnTo}
        isTitle={isTitle}
        setModal2Open={setModal2Open}
        modal2Open={modal2Open}
        setIsModalOpen={setIsModalOpen}
      />
      <p className="main_head_reading ">Order Request </p>
      <p className="para_reading my-3">
        Please fill out the below form and we will get back to you as soon as
        possible.
      </p>
      <Row className="d-flex justify-content-center align-items-center">
        <Col lg={12}>
          <Form
            name="basic"
            // labelCol={{
            //   span: 8,
            // }}
            wrapperCol={{
              span: 22,
            }}
            // initialValues={formDetails}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="d-flex flex-column gap-3 w-100"
            initialValues={{
                  remember: true,
                  username: localStorage.getItem('reading') ? JSON.parse(localStorage.getItem('reading'))?.username : '',
                  date: localStorage.getItem('reading') ? dayjs(JSON.parse(localStorage.getItem('reading'))?.date, "YYYY MM DD") : '',
                  gender: localStorage.getItem('reading') ? JSON.parse(localStorage.getItem('reading'))?.gender : '',
                 situation: localStorage.getItem('reading') ? JSON.parse(localStorage.getItem('reading'))?.situation : '',
                 question: localStorage.getItem('reading') ? JSON.parse(localStorage.getItem('reading'))?.question : '',
            }}
          >
            <Form.Item
              name="username"
              // label="User Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your user name!",
                },
                {
                  message: "Minimum (3)",
                  min: 3,
                },
              ]}
            >
              <Input className="w-100 input_reading" placeholder="Username" />
            </Form.Item>
            <div className=" d-flex gap-1" style={{ width: "96%" }}>
              <Form.Item
                name="date"
                // label="Date of Birth"
                rules={[
                  {
                    required: true,
                    message: "Please input your date of birth!",
                  },
                ]}
                validateStatus={isDisable === false ? "error" : ""}
                help={
                  isDisable === false ? "User should be atleast 18 years" : ""
                }
                className="w-50"
              >
                <DatePicker
                  className="w-100 input_reading"
                  onChange={checkDob}
                  placeholder='Date of Birth'
                />
              </Form.Item>
              <Form.Item
                name="gender"
                className="w-50"
                //  placeholder="Gender"
              >
                <Select
                  allowClear
                  className="input_reading "
                  placeholder="Gender"
                  // style={{color: 'red'}}
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Form.Item>
            </div>
            <Form.Item
              name="situation"
              // label="Explain your situation"
              rules={[
                {
                  required: true,
                  message: "Please enter your situation description!",
                },
              ]}
            >
              <Input.TextArea
                className="w-100 input_reading"
                placeholder="Explain your situation"
                rows={4}
              />
            </Form.Item>
            <Form.Item
              name="question"
              // label="Ask Question"
              rules={[
                {
                  required: true,
                  message: "Please ask your question!",
                },
              ]}
            >
              <Input.TextArea
                className="w-100 input_reading"
                placeholder="Ask Question"
                rows={5}
              />
            </Form.Item>
            <div className="pointing">
              <label
                className={`pointing ${
                  displayImg === null ? "darkGrayColor" : "pinkColor"
                }`}
              >
                <div
                  className="input_reading upload_file"
                  style={{ width: "500px", padding: "10px" }}
                >
                  <p> Upload image</p> <FaUpload color="#BFBFBF" />
                </div>
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handler}
                />
              </label>
            </div>
            {displayImg !== null ? (
              <p className="imageName">{displayImg?.name}</p>
            ): localStorage.getItem('reading') && JSON.parse(localStorage.getItem('reading'))?.selectedFile?.fileName && (
              <p className="imageName">{JSON.parse(localStorage.getItem('reading'))?.selectedFile?.fileName}</p>
            )}
            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 10,
              }}
            >
              <Button type="primary" htmlType="submit" className="submit_btnn">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default memo(Index);
