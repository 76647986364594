import axios from "axios";
import React, { memo, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { useParams } from "react-router-dom";

import "./index.css";
import Banner from "../../../images/profile_background.png";
import { Avatar, Divider, Rate, Tag } from "antd";
import Section1 from "./Section1";
import { TbCertificate } from "react-icons/tb";
import { AiOutlineStar } from "react-icons/ai";
import CardHead from "../../../images/cardheader.png";
const Index = ({ advisorDetails }) => {
  return (
    <>
      
      <div className="section_2_container_tag d-flex align-items-center">
            <img src={CardHead} alt="tag" />
            <Rate
              value={parseInt(advisorDetails?.ratings).toFixed(1)}
              className="rating_section"
              color="#F29C38"
              disabled
            />
            <p className="review_sec ">
              {parseInt(advisorDetails?.ratings).toFixed(1)} Reviews
            </p>
          </div>
        <div className="section_2_container">
         
          <div className="tag_readings">
            <Tag color="#DFF1E7" className="read_txt py-1">
              <p >
                {advisorDetails?.total_orders} &nbsp;
              </p>
            <span> readings since {advisorDetails?.year_joined}</span> 
            </Tag>
          </div>
          <div className="section_2_part3_a">
            <TbCertificate style={{ fontSize: "20px" }} />{" "}
            <span>
              <p>
                {new Date().getFullYear() - advisorDetails?.year_joined} Years
              </p>
              <h6>Total experiance</h6>
            </span>
          </div>
          <div className="section_2_part3_c"> <Divider type='vertical' /></div>
          <div className="section_2_part3_b">
            <AiOutlineStar style={{ fontSize: "20px" }} />
            <span>
              <p>
                {/* {parseInt(e.ratings).toFixed(1)} */}
                {advisorDetails?.review_count}
              </p>
              <h6>Total Reviews</h6>
            </span>
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default memo(Index);
