import { Avatar } from "antd";
import React, { memo, useEffect } from "react";
import "./index.css";
import { GoPrimitiveDot } from "react-icons/go";
import { useHistory } from "react-router-dom";

const InboxHead = ({
  advisorName,
  advisorOnlineStatus,
  advisorLink,
  advisorRefreshList,
  advisorsID,
  advisorDetails,
  setOnlineStatusInbox
}) => {
  const history = useHistory();
  console.log("advisorName", advisorName);
  useEffect(()=>{
   
    if(advisorRefreshList
      ? advisorRefreshList?.status === "1"
      : advisorOnlineStatus === "1" ||
        advisorDetails?.status === "1"){
          setOnlineStatusInbox('1')
        }else{
          setOnlineStatusInbox('0')
        }
  }, [advisorRefreshList, advisorOnlineStatus, advisorDetails])
  return (
    <>
      <div className="head-box shadow-sm">
        <div className="first w-100">
          <div className="avatar advisor_profile_dot">
            <Avatar
              size={38}
              className="pointing"
              src={advisorLink}
              onClick={() => history.push(`/advisors/${advisorsID}`)}
            />
            {/* advisorOnlineStatus === "1" || */}
            {(advisorRefreshList
              ? advisorRefreshList?.status === "1"
              : advisorOnlineStatus === "1" ||
                advisorDetails?.status === "1") && (
              <GoPrimitiveDot
                className="advisor_profile_dot_header"
                color="#12B76A"
              />
            )}
          </div>
          <div>
            <p className="mb-0 pb-0">{advisorName}</p>
            {advisorOnlineStatus === "1" ||
              advisorDetails?.status === "1" ||
              (advisorDetails?.live_status === "1" && <span>Online</span>)}
          </div>
        </div>
      </div>
    </>
  );
};

export default InboxHead;
