

export const cardData = [
    {
        id: 1,
        img: '/love.png',
        title: 'Love Readings',
        url: 'category/lovereadings',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
        id: 2,
        img: '/psychic.png',
        title: 'Psychic Readings',
        url: 'category/psychicreadings',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
        id: 3,
        img: '/tarot.png',
        title: 'Tarot Readings',
        url: 'category/tarotreadings',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
        id: 4,
        img: '/palm.png',
        title: 'Palm Readings',
        url: 'category/palmreadings',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
        id: 5,
        img: '/angel.png',
        title: 'Angle insights',
        url: 'category/angleinsights',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
        id: 6,
        img: '/horo.png',
        title: 'Astrology & Horoscopes',
        url: 'category/astrologyandhoroscopes',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
        id: 7,
        img: '/dream.png',
        title: 'Dream Analysis',
        url: 'category/dreamanalysis',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
        id: 8,
        img: '/oracle.png',
        title: 'Oracle guidance',
        url: 'category/oracleguidance',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
]