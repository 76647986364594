import { Input, Radio, Spin, Switch } from "antd";
import React, { useState } from "react";
import { AiFillDelete, AiTwotoneAudio } from "react-icons/ai";
import { BsImage } from "react-icons/bs";
import { FiSend, FiSmile } from "react-icons/fi";
import TxtInput from "./FooterContent";
import EmojiPicker from "emoji-picker-react";
import Attachment from "./FooterContent/attachment";
import VoiceRecorder from "../../Inbox/ChatContent/voicerecorder";
import Emoji from "./emoji";
import "./index.css";

function Footer({
  msg,
  setMsg,
  sendMessage,
  setSelectedFile,
  selectedFile,
  setColor,
  color,
  setShowResult,
  showResult,
  clearInput,
  setClearInput,
  setReplyTime,
  showInput,
  setShowInput,
  setShowAttach,
  showAttach,
  setShowAudio,
  showAudio,
  isMsgSpin,
  messageInput,
  setMessage
}) {
  const [time, setTime] = useState("0");
  const [res, setRes] = useState("");
  // const [message, setMessage] = useState({ text: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [showInput, setShowInput] = useState(true);
  const delteVoice = () => {
    setShowResult(false);
    setRes("");
    setShowInput(true);
    setShowAttach(true);
    setShowAudio(true);
  };
  const handleClick = (e) => {
    console.log("reply", e.target.value);
    setReplyTime(e.target.value);
    // if (checked === true) {
    //   setReplyTime("text");
    // }else{
    //   setReplyTime("audio");
    // }
  };
  const submitMsg = () => {
    sendMessage(
      selectedFile.file !== null ? selectedFile.file : "",
      selectedFile.type,
      time
    );
  };

  return (
    <>
      <div className="options">
        {isMsgSpin === true && (
          <span style={{ fontSize: "0.5rem" }}>Sending...</span>
        )}
        <h6>Reply to my question with: </h6>
        {/* <Switch
          checkedChildren="Text"
          unCheckedChildren="Voice"
          defaultChecked
          onChange={handleClick}
        /> */}
        <Radio.Group
          size="small"
          buttonStyle="solid"
          variant="danger"
          onChange={handleClick}
          defaultValue="text"
          style={{
            color: "pink",
          }}
        >
          <Radio.Button value="text">Text</Radio.Button>
          <Radio.Button value="audio">Voice</Radio.Button>
        </Radio.Group>
      </div>
      <div className="footer">
        <Attachment
          setSelectedFile={setSelectedFile}
          color={color}
          setColor={setColor}
          showAttach={showAttach}
          setMsg={setMsg}
          setMessage={setMessage}
          message={messageInput}
          submitMsg={submitMsg}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setClearInput={setClearInput}
          clearInput={clearInput}
        />
        {isModalOpen === false && (
          <>
            <div className="txt">
              {showResult === true ? (
                <>
                  {" "}
                  {res !== "" ? (
                    <div className="d-flex gap-3 align-items-center">
                      <audio controls id="audio">
                        <source src={res} id="src" />
                      </audio>
                      <div>
                        <AiFillDelete
                          onClick={delteVoice}
                          style={{ fontSize: "20px", color: `red` }}
                        />
                      </div>
                      {time}
                    </div>
                  ) : (
                    <p>Loading...</p>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  {showInput === true && (
                    <TxtInput
                      setMsg={setMsg}
                      setMessage={setMessage}
                      message={messageInput}
                      clearInput={clearInput}
                      setShowAttach={setShowAttach}
                      setShowAudio={setShowAudio}
                    />
                  )}{" "}
                </>
              )}
            </div>
            {/* <FiSmile color="#F7B614" /> */}
            {/* <Emoji setMsg={setMsg} setMessage={setMessage} /> */}

            {/* <AiTwotoneAudio color="green" /> */}
            {/* {showAudio === true && <> */}
            {/* {showAudio === true && <>  */}
            <VoiceRecorder
              setShowResult={setShowResult}
              setRes={setRes}
              setSelectedFile={setSelectedFile}
              setTime={setTime}
              time={time}
              setShowInput={setShowInput}
              setShowAttach={setShowAttach}
              setShowAudio={setShowAudio}
              showAudio={showAudio}
            />
            {isMsgSpin === true ? (
              <Spin style={{ fontSize: "22px", color: "#3A86F4" }} />
            ) : (
              <FiSend
                className="pointing"
                style={{ fontSize: "22px", color: "#3A86F4" }}
                onClick={
                  (e) => submitMsg()
                  // sendMessage(
                  //   selectedFile.file !== null ? selectedFile.file : "",
                  //   selectedFile.type,
                  //   time
                  // )
                }
                // <span style={{fontSize: '0.5rem'}}>Sending...</span>
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Footer;
