import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { message, Skeleton, Spin } from "antd";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import "./index.css";

export const Spinner = () => <Spin />;

const LoginCheckInbox = ({ children }) => {
  const [isSpinner, setIsSpinner] = useState(true);
  const history = useHistory();
  useEffect(() => {
    verifyClient();
  }, []);

  const verifyClient = async () => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("userInfo");
    const userInfo = JSON.parse(user);

    const obj = {
      user_type: "client",
      user_id: userInfo?.id,
      jwt: token,
    };
    console.log("obj", obj);
    try {
      const res = await axios.post(
        "https://staging.psychictxt.net/API/v22/Client_API/verifyJWT",
        obj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            // "Authorization" : `Bearer ${token}`
          },
        }
      );
      console.log("res--", res);
      if (res?.data?.isVerify !== true) {
        history.push("/");
        message.error("please login first");
      }
      setIsSpinner(false);
    } catch (err) {
      console.log("err", err);
    }
  };
  return (
    <>
      {isSpinner === true ? (
        <Container className="vh-100 d-flex justify-content-center align-items-center main_divs">
          <Row className="main_row">
            <Col lg={3} className="siders shadow-sm">
              <Skeleton active />
            </Col>
            <Col lg={8} className="contains shadow-sm">
              <Skeleton active />
            </Col>
          </Row>
          {/* <Skeleton active /> */}
        </Container>
      ) : (
        children
      )}
    </>
  );
};

export default LoginCheckInbox;
