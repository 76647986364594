import { Modal } from "antd";
import React from "react";
import PurchaseCredits from "../views/PurchaseCredits";
import "./index.css";

const TopMyBalanceModal = ({isModalOpen, handleCancel}) => {
  return (
    <>
     <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        width='500px'
        style={{ borderRadius: '16px'}}
      >
      <PurchaseCredits />
      </Modal>
    </>
  );
};
export default TopMyBalanceModal;
