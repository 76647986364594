import React, { memo, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Rate,
  Select,
  Tag,
} from "antd";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { updateUserInfo } from "../../../api/client";
import { useDispatch, useSelector } from "react-redux";
import SigninModal from "../../../modals/signinModal";
import { useHistory, useParams } from "react-router-dom";
import { ImFilePicture } from "react-icons/im";
import Live from "../../../images/live.svg";
import Text from "../../../images/txt.svg";
import Arrow from "../../../images/arrowReading.svg";
import "./index.css";
import { getAdvisorFullProfile } from "../../../api/advisor";

const { Option } = Select;

const Part2 = ({ id }) => {
  const dispatch = useDispatch();
  const [advisorDetails, setAdvisorDetails] = useState(null);
  useEffect(() => {
    getAdvisorFullProfile(id, dispatch);
  }, [id]);

  const advisorProfile = useSelector((x) => x.advisorProfile);
  console.log("advisorProfile", advisorProfile);

  useEffect(() => {
    setAdvisorDetails(advisorProfile);
  }, [advisorProfile]);

  console.log("advisorDetails", advisorDetails);

  return (
    <div>
      <div className="part2_main_container">
        <Row className="d-flex justify-content-between w-100">
          <Col lg={8} className="d-flex gap-2">
            <Avatar src={advisorDetails?.s3_display_picture} size={64} />
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex gap-1">
                <Tag color="#00A542" className="tag_name">
                  Online
                </Tag>
                <span className="part2_name">{advisorDetails?.username}</span>
              </div>
              <p className="part2_para">{advisorDetails?.short_description}</p>
            </div>
          </Col>
          <Col lg={4} className="setup d-flex flex-column align-items-start">
            <Rate
              // value={parseInt(e.ratings).toFixed(1)}
              color="#F29C38"
              disabled={true}
              value={parseInt(advisorDetails?.ratings)?.toFixed(1)}
              style={{ fontSize: "16px" }}
            />
            <p className="rating_txt">{parseInt(advisorDetails?.ratings)?.toFixed(1)} Rating</p>
          </Col>
        </Row>
        <Divider className="divider_part2" />
        <Row className=" w-100">
          <Col lg={3} sm={5} xs={5} className="d-flex gap-1 align-items-center">
            <img src={Live} style={{ height: "20px", width: "20px" }} />
            <div className="part2_liveChat">
              <span>Live Chat</span>
              <p>{advisorDetails?.live_rate} Cr/Min</p>
            </div>
          </Col>
          <Col lg={1} sm={1} xs={1}>
            <Divider className="part2_vertical_divider mt-2" type="vertical" />
          </Col>
          <Col className="d-flex gap-1 align-items-center">
            <img src={Text} style={{ height: "20px", width: "20px" }} />
            <div className="part2_liveChat">
              <span>Get a Reading Now</span>
              <p>150 Txt Credits</p>
            </div>
          </Col>
        </Row>
        <Row className="part2_last">
          <p>Please provide your name and the Name of anyone in question</p>
        </Row>
      </div>
      <div className="part2_main_container2">
        <img src={Arrow} style={{ height: "53px" }} />
        <div className="part2_container_green">
          <div className="part2_green">
            <span>Advisor Replay</span>
            <p>
              I really loved this reading. A lot of insights were brought
              through and it was extremely helpful. The past energies that were
              picked up were accurate which gives me confidence about the ones
              coming in. Thank you!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Part2;
