import React, { memo } from 'react';
import { NavBar } from '../../components';
import Footer from '../../components/Footer';
import {Layout } from 'antd';
const {Content } = Layout;


const Index = ({children}) => (
  <>
    <Layout>
    <Layout style={{backgroundColor: "#FFFFFF"}} id="navBarLayout">  
    <NavBar />
    </Layout>
      <Content>{children}</Content>
      <Footer />
    </Layout>
</>
)

export default memo(Index);