
import React from "react";
import Reviews from "../Reviews";
import Speciality from "../Specialities";
import About from "./about";

import "./index.css";

const AllThree = ({advisorDetails, reviewsList}) => {
  return (
    <>
    <About advisor={advisorDetails}/>
    <Speciality />
    <Reviews advisorDetails={advisorDetails} reviewsList={reviewsList}/>
    </>
  );
};

export default AllThree;
