
import React, { memo } from "react";
import "./index.css";

const LoadMore = ({ loadMoreData }) => {
  return (
    <>
                    <p className="load-more" onClick={(e) => loadMoreData()}>
              load more
            </p>
    </>
  );
};

export default LoadMore;
