import React, { memo, useEffect, useState } from "react";
import { Button, Divider, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Email, Phone } from "../../components";
import {
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import auth from "../../config/firebase";
import axios from "axios";
import FacebookLogin from "react-facebook-login";
import "./index.css";
import { FaFacebook } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { getClientFromAccountID, onSigninUser } from "../../api/auth";

const Index = () => {
  const history = useHistory();
  const [status, setStatus] = useState(false);

  // Gmail pop up
  const SigninWithGmail = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((res) => {
        console.log("RES GMAIL FIREBASE: ", res);
        onSigninUser(res?.user, history);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }; 

  // facebook popup
  const responseFacebook = (response) => {
    console.log("fb res", response);
    getClientFromAccountID(response, history)

  };

  useEffect(()=>{
    const token = localStorage.getItem("token") || null;
    if(token !== null && token !== undefined){
      history.push("/");
      message.success("Already logged in!");
    }
  }, [])

  return (
    <>
      <Container>
        <Row className="d-flex justify-content-center align-items-center vh-100">
          <Col
            lg={6}
            className="d-flex flex-column  justify-content-around p-5 h-75"
            style={{ border: "2px solid gray" }}
          >
            <h2 className="text-center">Login / Sign up</h2>
            <Divider />
            <Button type="warning" className="btnn-login">
              <Link to="/phone" element={<Phone />} className="text">
                Login with Phone number
              </Link>
            </Button>
            <Button type="danger" className="btnn-login">
              <Link to="/email" element={<Email />} className="text">
                Login with Email
              </Link>
            </Button>
            <FacebookLogin
              appId="1498729823712651"
              autoLoad={false}
              fields="name,email,picture"
              onClick={(e) => setStatus(true)}
              callback={responseFacebook}
              cssClass="my-facebook-button-class"
              icon={<FaFacebook color="white" style={{marginRight: '5px'}}/>}
            />
            <Button onClick={SigninWithGmail}><FcGoogle  /> &nbsp; Login with Gmail</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(Index);
