const BASE_URL = 'https://staging.psychictxt.net'
const BASE_URL_2 = 'https://staging.psychic-harmony.net'
const VERSION = 'v19'

const COMMON_API_PARAMS = {
    device_type: '',
    app_version: '',
    auth_key: '',
}

export {BASE_URL, VERSION, COMMON_API_PARAMS, BASE_URL_2};