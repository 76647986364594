import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFeed from "react-scrollable-feed";
import { getChatContent } from "../../../api/chat";
import moment from "moment";
import ReviewModal from "../../../modals/reviewModal";
import { Button, Divider, Rate, Skeleton, Spin } from "antd";
import { usePubNub } from "pubnub-react";
import InfiniteScroll from "react-infinite-scroll-component";

const Content = ({
  advisor,
  setChatArr,
  chatArr,
  isSpin,
  setIsModalOpen3,
  userProfilee,
  enableInfoMsg,
  enableRate,
  setEnableRate,
}) => {
  const oneDay = 24 * 60 * 60 * 1000;
  console.log("chatarr--", chatArr);
  const pubnub = usePubNub();
  const dispatch = useDispatch();
  const [userObj, setUserObj] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [msgId, setMsgId] = useState(null);
  const [channels, setChannels] = useState("");
  const [rateVal, setRateVal] = useState(null);
  const [idd, setmsgID] = useState(null);
  useEffect(() => {
    // const userInformation = localStorage.getItem("userInfo") || {};
    // const obj = JSON.parse(userInformation);
    // if (obj && obj !== null && obj !== undefined) {
    if (userProfilee) {
      setUserObj(userProfilee);
    }
  }, [userProfilee]);
  const showModal = (id) => {
    setMsgId(id);
    setIsModalOpen(true);
  };
  console.log("msg id", msgId);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // const handleMessage = (event) => {
  //   const message = event.message;
  //   console.log("messagetest---", message);
  //   if (message.auth_key !== "") {
  //     setChatArr((prev) => [
  //       ...prev,
  //       {
  //         msg_text: message.msg_text ? message.msg_text : "",
  //         msg_date: message.msg_date ? message.msg_date : new Date(),
  //         sender_type: message.sender_type ? message.sender_type : "advisor",
  //         video_url: message.video_url ? message.video_url : "",
  //         message_type: message.message_type ? message.message_type : "",
  //       },
  //     ]);
  //   }
  //   // setUserProfilee(prev =>{
  //   //   return{ ...prev,
  //   //    free_credit: message.payload.free_credit,
  //   //    paid_credit: message.payload.paid_credit,
  //   //  }
  //   //  })
  //   // updateUserCredits(message, dispatch);
  // };
  // const leaveApplication = () => {
  //   pubnub.removeListener(listnerAdd);
  //   pubnub.unsubscribeAll();
  // };
  // const listnerAdd = {
  //   status: (statusEvent) => {
  //     var subscribedChannels = statusEvent.subscribedChannels;
  //     console.log("subscribedChannels", subscribedChannels);
  //     if (statusEvent.category === "PNConnectedCategory") {
  //       console.log("Connected !!!");
  //     }
  //   },
  //   message: handleMessage,
  // };
  // console.log("chhhhh", channels);
  // useEffect(() => {
  //   const userInformation = localStorage.getItem("userInfo") || {};
  //   const obj = JSON.parse(userInformation);
  //   setChannels("Channel_User_".concat(obj?.id));
  //   pubnub.addListener(listnerAdd);
  //   if (channels) {
  //     pubnub.subscribe({
  //       channels: [channels, "channel_application"],
  //       withPresence: true,
  //     });
  //   }
  //   return leaveApplication;
  // }, [pubnub, channels]);
  const [loading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(10);
  const [isspin, setIsSpin] = useState();
  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setPage((prev) => prev + 1);
    setLoading(true);
    //   getChatContent(advisor, setChatArr, dispatch, setIsSpin);
    // fetch('https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo')
    //   .then((res) => res.json())
    //   .then((body) => {
    //     setChatArr([...chatArr, ...body.results]);
    //     setLoading(false);
    //   })
    // setLoading(false);
    // .catch(() => {
    //   setLoading(false);
    // });
  };
  // useEffect(() => {
  //   loadMoreData();
  // }, []);
  useEffect(() => {
    if (page !== 0) {
      getChatContent(
        advisor,
        setChatArr,
        dispatch,
        setLoading,
        page,
        setCount,
        setHasData
      );
    }
  }, [page]);
  console.log("page, page", page);
  console.log("page, count", count);
  console.log("page, hasData", hasData);
  return (
    <>
      {/* <div id="scrollableDiv"></div> */}
      <div className="chat-content shadow " id="scrollableDiv">
        {hasData === true ? (
          <>
            {loading === false ? (
              <p className="load-more" onClick={e=> loadMoreData()}>
                load more
              </p>
            ) : (
              <div className="d-flex justify-content-center">
                {" "}
                <Spin />
              </div>
            )}
          </>
        ) : (
          <Divider plain>no more messages 🤐</Divider>
        )}
        {isSpin === true ? (
          <div className="spinner">
            <Spin />
          </div>
        ) : (
          <>
            {/* <InfiniteScroll
                  dataLength={chatArr.length}
                  next={loadMoreData}
                  hasMore={false} // chatArr.length === count
                  // pageStart={0}
                  loader={<Spin />}
                  // threshold={100}
                  endMessage={
                    <Divider plain>no more messages 🤐</Divider>
                  }
                  style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                  // inverse={true} //
                  isReverse={true}
                  scrollableTarget="scrollableDiv"
                  position="top"
                > */}
            <ScrollableFeed>
              {chatArr.length !== 0 ? (
                <>
                  {chatArr
                    ?.sort(function (a, b) {
                      var dateA = new Date(a.msg_date).getTime();
                      var dateB = new Date(b.msg_date).getTime();
                      return dateA > dateB ? 1 : -1; // ? -1 : 1 for ascending/increasing order
                    })
                    ?.map((e) => {
                      // console.log("testt", e.video_url.split('~')[0]);
                      return (
                        <>
                          {e?.msg_text !== "" &&
                          (e?.message_type === "text" ||
                            e?.message_type === "rush_text") ? (
                            <div id="anchor" className="chatbody">
                              <div
                                className={
                                  e?.sender_type === "client"
                                    ? "chat-client"
                                    : "chat"
                                  // e?.closing_reason !== null ?
                                }
                                // ref={chatContainer}
                              >
                                <p
                                  id={e?.id}
                                  className={`mb-0 shadow  ${
                                    e?.message_type === "notice"
                                      ? "notification-txt"
                                      : ""
                                  }`}
                                >
                                  {e?.msg_text} &nbsp;
                                  <span
                                    className={
                                      e?.sender_type === "client"
                                        ? "time-client"
                                        : "time2"
                                    }
                                  >
                                    {moment(e?.msg_date).format("HH:MM")}
                                  </span>
                                </p>
                              </div>
                              <hr />
                            </div>
                          ) : e?.message_type === "live_chat_start" ? (
                            <>
                              <div className="d-flex justify-content-center">
                                <span className="info">Live Chat Started</span>
                              </div>
                            </>
                          ) : e?.message_type === "live_chat_pause" ||
                            e?.message_type === "livechat_pause" ? (
                            <>
                              <div className="d-flex justify-content-center">
                                <span className="info">
                                  {e?.msg_text
                                    ? e.msg_text
                                    : e?.display_message === ""
                                    ? "chat paused by client"
                                    : ""}
                                </span>
                              </div>
                            </>
                          ) : e?.message_type === "live_chat_resume" ||
                            e?.message_type === "livechat_resume" ? (
                            <>
                              <div className="d-flex justify-content-center">
                                <span className="info">
                                  {e?.msg_text
                                    ? e.msg_text
                                    : e?.display_message === ""
                                    ? "chat resumed by client"
                                    : ""}
                                </span>
                              </div>
                            </>
                          ) : e?.message_type === "livechat_continue" ||
                            e?.message_type === "live_chat_continue" ? (
                            <div className="d-flex justify-content-center">
                              <span className="info">
                                {e?.msg_text
                                  ? e.msg_text
                                  : "Live chat continue"}
                              </span>
                            </div>
                          ) : e?.msg_text !== "" &&
                            (e?.message_type === "text" ||
                              e?.message_type === "rush_text" ||
                              e?.message_type === "live_text") ? (
                            <div id="anchor" className="chatbody">
                              <div
                                className={
                                  e?.sender_type === "client"
                                    ? "chat-client"
                                    : "chat"
                                  // e?.closing_reason !== null ?
                                }
                                // ref={chatContainer}
                              >
                                <p
                                  id={e?.id}
                                  className={`mb-0 shadow  ${
                                    e?.message_type === "notice"
                                      ? "notification-txt"
                                      : ""
                                  }`}
                                >
                                  {e?.msg_text} &nbsp;
                                  <span
                                    className={
                                      e?.sender_type === "client"
                                        ? "time-client"
                                        : "time2"
                                    }
                                  >
                                    {moment(e?.msg_date).format("HH:MM")}
                                  </span>
                                </p>
                              </div>
                              <hr />
                            </div>
                          ) : e?.msg_text !== "" &&
                            e?.message_type === "info" ? (
                            <>
                              <div className="d-flex justify-content-center">
                                <span className="info">Info Message</span>
                              </div>
                              <div id="anchor" className="chatbody">
                                <div
                                  className={
                                    e?.sender_type === "client"
                                      ? "chat-client info-msg"
                                      : "chat info-msg"
                                    // e?.closing_reason !== null ?
                                  }
                                  // ref={chatContainer}
                                >
                                  {/* <div className="d-flex justify-content-center">
                                <span className="info">Info Message</span>
                              </div> */}
                                  <p
                                    id={e?.id}
                                    className={`mb-0 shadow  ${
                                      e?.message_type === "notice"
                                        ? "notification-txt"
                                        : ""
                                    }`}
                                  >
                                    {e?.msg_text} &nbsp;
                                    <span
                                      className={
                                        e?.sender_type === "client"
                                          ? "time-client"
                                          : "time2"
                                      }
                                    >
                                      {moment(e?.msg_date).format("HH:MM")}
                                    </span>
                                  </p>
                                  {e.status === "0" &&
                                    enableInfoMsg === true && (
                                      <span
                                        className="info text-center mb-2"
                                        onClick={(e1) => setIsModalOpen3(true)}
                                      >
                                        Tap to provide Info
                                      </span>
                                    )}
                                </div>
                                <hr />
                              </div>
                            </>
                          ) : e?.video_url !== "" &&
                            (e?.message_type === "photo" ||
                              e?.message_type === "rush_photo" ||
                              e?.message_type === "live_photo") ? (
                            <div id="anchor" className="chatbody">
                              <div
                                className={
                                  e?.sender_type === "client"
                                    ? "chat-client"
                                    : "chat"
                                  // e?.closing_reason !== null ?
                                }
                              >
                                <div className="video-container shadow">
                                  <img
                                    src={e?.video_url?.split("~")[0]}
                                    alt="image"
                                  />
                                  <h6> {e?.msg_text}</h6>
                                  <span className="time-client">
                                    {moment(e?.msg_date).format("HH:MM")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : e?.video_url !== "" &&
                            (e?.message_type === "audio" ||
                              e?.message_type === "rush_audio") ? (
                            <>
                              <div
                                className={
                                  e?.sender_type === "client"
                                    ? "audio-container"
                                    : "audio-container-advisor"
                                }
                              >
                                <audio controls preload="auto">
                                  <source src={e?.video_url} id="src" />
                                </audio>

                                <span className="time-client pe-2">
                                  <span className="duration">
                                    {e.video_url?.split("~")[1].split(".")[0]}{" "}
                                    sec
                                  </span>
                                  <span>
                                    {" "}
                                    {moment(e?.msg_date).format("HH:MM")}
                                  </span>
                                </span>
                              </div>
                            </>
                          ) : e?.message_type === "livechat_ended" ||
                            e?.message_type === "live_chat_ended" ? (
                            <div className="d-flex justify-content-center">
                              <span className="info">
                                {e?.msg_text ? e.msg_text : "Live chat ended"}
                              </span>
                            </div>
                          ) : e?.msg_text !== "" &&
                            e?.message_type === "info" ? (
                            <>
                              <div className="d-flex justify-content-center">
                                <span className="info">Info Message</span>
                              </div>
                              <div id="anchor" className="chatbody">
                                <div
                                  className={
                                    e?.sender_type === "client"
                                      ? "chat-client info-msg"
                                      : "chat info-msg"
                                    // e?.closing_reason !== null ?
                                  }
                                  // ref={chatContainer}
                                >
                                  {/* <div className="d-flex justify-content-center">
                              <span className="info">Info Message</span>
                            </div> */}
                                  <p
                                    id={e?.id}
                                    className={`mb-0 shadow  ${
                                      e?.message_type === "notice"
                                        ? "notification-txt"
                                        : ""
                                    }`}
                                  >
                                    {e?.msg_text} &nbsp;
                                    <span
                                      className={
                                        e?.sender_type === "client"
                                          ? "time-client"
                                          : "time2"
                                      }
                                    >
                                      {moment(e?.msg_date).format("HH:MM")}
                                    </span>
                                  </p>
                                  {e?.status === "0" &&
                                    enableInfoMsg === true && (
                                      <span
                                        className="info text-center mb-2"
                                        onClick={(e1) => setIsModalOpen3(true)}
                                      >
                                        Tap to provide Info
                                      </span>
                                    )}
                                </div>
                                <hr />
                              </div>
                            </>
                          ) : e?.video_url !== "" &&
                            (e?.message_type === "photo" ||
                              e?.message_type === "rush_photo") ? (
                            <div id="anchor" className="chatbody">
                              <div
                                className={
                                  e?.sender_type === "client"
                                    ? "chat-client"
                                    : "chat"
                                  // e?.closing_reason !== null ?
                                }
                              >
                                <div className="video-container shadow">
                                  <img
                                    src={e?.video_url?.split("~")[0]}
                                    alt="image"
                                  />
                                  <h6> {e?.msg_text}</h6>
                                  <span className="time-client">
                                    {moment(e?.msg_date).format("HH:MM")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : e?.video_url !== "" &&
                            (e?.message_type === "audio" ||
                              e?.message_type === "rush_audio") ? (
                            <>
                              <div
                                className={
                                  e?.sender_type === "client"
                                    ? "audio-container"
                                    : "audio-container-advisor"
                                }
                              >
                                <audio controls preload="auto">
                                  <source src={e?.video_url} id="src" />
                                </audio>

                                <span className="time-client pe-2">
                                  <span className="duration">
                                    {e.video_url?.split("~")[1].split(".")[0]}{" "}
                                    sec
                                  </span>
                                  <span>
                                    {" "}
                                    {moment(e?.msg_date).format("HH:MM")}
                                  </span>
                                </span>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {(e?.is_rateable === "1" || e?.is_rateable === 1) &&
                            Math.round(
                              Math.abs(
                                (new Date() - new Date(e?.msg_date)) / oneDay
                              )
                            ) < 15 &&
                            userObj?.has_made_inapp_purchase === "1" &&
                            e?.sender_type === "advisor" &&
                            e?.review_status === "0" &&
                            enableRate === true &&
                            e?.message_type !== "info" &&
                            e?.message_type.includes("live_") === false && (
                              <div onClick={(e1) => showModal(e?.id)}>
                                <p className="rate-txt">Rate Now</p>
                              </div>
                            )}
                          {e?.review_status !== "0" && (
                            <Rate
                              value={+e?.review_status}
                              style={{ fontSize: "1rem" }}
                              disabled
                            />
                          )}
                          {rateVal &&
                            idd &&
                            idd === e.id &&
                            enableRate === false && (
                              <Rate
                                id={e.id}
                                value={rateVal}
                                style={{ fontSize: "1rem" }}
                                disabled
                              />
                            )}
                        </>
                      );
                    })}

                  {isModalOpen === true && msgId !== null && (
                    <ReviewModal
                      advisorID={advisor?.id}
                      isModalOpen={isModalOpen}
                      handleOk={handleOk}
                      handleCancel={handleCancel}
                      msgId={msgId}
                      setIsModalOpen={setIsModalOpen}
                      setRateVal={setRateVal}
                      setmsgID={setmsgID}
                      setEnableRate={setEnableRate}
                    />
                  )}
                </>
              ) : (
                // <Spin />
                <div>
                  <h6 className="text-center">Start your first chat!</h6>
                </div>
              )}
            </ScrollableFeed>
            {/* </InfiniteScroll> */}
          </>
        )}
      </div>
    </>
  );
};

export default Content;
