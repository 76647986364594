import React from "react";
// import { Row, Col } from "react-bootstrap";
import { Progress, Rate } from "antd";
import { LikeOutlined } from "@ant-design/icons";
import { Col, Row, Statistic } from "antd";
import "./index.css";
import RateCountProgressBar from "./RateCountProgressBar";
const OverView = ({ratings, reviewsCount}) => {
  console.log("ratings", ratings);
  return (
    <>
      <Row>
        <Col span={8}>
          <Statistic
            title="Total Reviews"
            value={reviewsCount}
       
          />
        </Col>
        <Col span={8}>
          {/* <Statistic title="Average Rating" value={"4.0"} /> */}
          <p className="ant-statistic-title">Average Rating</p>
          <div className="overview_section_2">
            <p className="ant-statistic-content-value">{reviewsCount === 0 ? 0.0 : parseInt(ratings).toFixed(1)}</p>{" "}
            <Rate defaultValue={parseInt(ratings).toFixed(1)} disabled style={{ color: "#F7b614", fontSize: '15px' }} />
    
          </div>
        </Col>
        <Col span={8}>
          {[1, 2, 3, 4, 5].map((e, i) => {
            return <RateCountProgressBar />;
          })}
        </Col>
      </Row>
    </>
  );
};

export default OverView;
