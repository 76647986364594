import { Button, Form, Modal, Rate, Input, Tag, Divider, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createReview, getReviewMetadata } from "../api/review";

const { CheckableTag } = Tag;
const layout = {
  // labelCol: {
  //   span: 4,
  // },
  wrapperCol: {
    span: 24,
  },
};
const validateMessages = {
  required: "${label} is required!",
};

const ReviewModal = ({
  advisorID,
  isModalOpen,
  handleOk,
  handleCancel,
  msgId,
  setIsModalOpen,
  setRateVal,
  setmsgID,
  setEnableRate,
  advisorName,
  advisorLink
}) => {
  const [value, setValue] = useState(0);
  const [clickables, setClickables] = useState([]);
  const [questions, setQuestions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getReviewMetadata(dispatch);
  }, []);
  const reviewMetaData = useSelector((state) => state.reviewMetadata);

  useEffect(() => {
    setClickables(reviewMetaData?.[5]?.clickables);
    setQuestions(reviewMetaData?.[5]?.questions);
  }, [reviewMetaData]);
  const [selectedTags, setSelectedTags] = useState([
    { clickable_id: null, title: null },
  ]);
  const [selectedQs, setSelectedQs] = useState([
    { slug: null, question_option_id: null },
  ]);
  const [finalQs, setFinalQs] = useState([
    { question_id: null, question_option_id: null },
  ]);

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    setSelectedTags(nextSelectedTags);
  };
  const handleChangeQ = (qoptionId, qId, checked) => {
    console.log("-Q-", qoptionId, qId, checked);
    const nextSelectedTags = checked
      ? [...selectedQs, qoptionId]
      : selectedQs.filter((t) => t !== qoptionId);
    console.log("You are interested in QQ: ", nextSelectedTags);
    setSelectedQs(nextSelectedTags);
    const finalSelectedTags = checked
      ? [
          ...finalQs,
          {
            question_option_id: qoptionId.question_option_id,
            question_id: qId,
          },
        ]
      : finalQs.filter(
          (t) => t.question_option_id !== qoptionId.question_option_id
        );
    setFinalQs(finalSelectedTags);
  };
  const onFinish = (values) => {
    console.log("val", values);
    if (value !== 0) {
      const obj = reviewMetaData?.[value - 1];
      console.log("obj", obj);
      const C1 = obj?.clickables;
      const Q1 = obj?.questions;
      const replace_c = selectedTags
        ?.filter((e1) => e1?.clickable_id !== null)
        ?.map((e) => {
          const finded = C1?.find((e1) => e1?.title === e?.title);
          if (finded) {
            return { ...e, clickable_id: finded?.clickable_id };
          }
          return e;
        });
      const sort_q = finalQs
        ?.filter((e1) => e1.question_id !== null)
        ?.sort(function (a, b) {
          return +a.question_id - +b.question_id;
        });
      console.log("sort_q", sort_q);
      const replace_q = sort_q?.map((e, i) => {
        return { ...e, question_id: Q1[i].question_id };
      });
      console.log("replaceq", replace_q);
      values = { ...values, clickables: replace_c, questions: replace_q };
    }
    console.log(values);
    const finded = reviewMetaData?.filter(
      (e) => +e.option_id === values.rating
    );
    createReview(
      values,
      advisorID,
      finded?.[0],
      msgId,
      setIsModalOpen,
      setRateVal,
      setmsgID,
      setEnableRate
    );
  };

  return (
    <>
      <Modal
        // title="Post a review"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        style={{ width: "580px" }}
      >
        <div className="head_rate_modal">
          <p className="heading_Rate_modal">Rating Advisor</p>
          <p className="para_rate_modal">How Would You Rate Our Advisor?</p>
        </div>
        <Divider className="head_divider" />
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          validateMessages={validateMessages}
          className="form_modal w-100"
        >
          <div className="body_rate_modal">
            <Avatar src={advisorLink} size={48} />
            <div>
              <p className="body_rate_modal_name mb-0">{advisorName}</p>
              <Form.Item
                name="rating"
                // label="Rating"
                style={{ width: "100%" }}
                rules={[
                  {
                    type: "number",
                    required: true,
                  },
                ]}
              >
                <Rate onChange={setValue} value={value} />
              </Form.Item>
            </div>
          </div>
          <Divider className="head_divider2" />
          <p className="option_select mt-2">Select Options</p>
          <Form.Item
            // name="clickables"
            // label="Clickables"
            rules={[
              {
                type: "array",
                // required: true,
              },
            ]}
            className="tags_holder mb-2"
          >
            {clickables?.map((tag) => (
              <CheckableTag
                className="tags_select_custom"
                key={tag.clickable_id}
                checked={selectedTags.indexOf(tag) > -1}
                onChange={(checked) => handleChange(tag, checked)}
                value={selectedTags}
              >
                {tag.title}
              </CheckableTag>
            ))}
          </Form.Item>
          <Divider className="head_divider" />
          <p className="give_answers my-2">Give us your answers</p>
          <Form.Item
          className="w-100"
            // name="questions"
            // label="questions"
          >
            {questions?.map((e, i) => (
              <div className="d-flex gap-2 mb-2">
              <p className="questions" style={{width: '80%'}}>{i +1}. {e.question_text}</p>
                {/* <Tag style={{ width: "100%" }}>{e.question_text}</Tag> */}
                {e?.question_options?.map((tag) => (
                  <>
                    <CheckableTag
                      key={tag.question_option_id}
                      checked={selectedQs.indexOf(tag) > -1}
                      onChange={(checked) =>
                        handleChangeQ(tag, e.question_id, checked)
                      }
                      className="yes_no_tags"
                    >
                      {tag.slug}
                    </CheckableTag>
                  </>
                ))}
                {/* <br />{" "} */}
              </div>
            ))}
          </Form.Item>
          <Divider className="head_divider" />
<p  className="give_answers my-2" >Care To Share More About It</p>
          <Form.Item
            name="review"
            // label="Review"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea className="text_area" placeholder="Write your comments here" rows={5}/>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              ...layout.wrapperCol,
              // offset: 10,
            }}
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
         <div className="d-flex gap-2">  <Button onClick={()=> handleCancel()} className="btn_cancell">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" className="btn_submitt">
              Send
            </Button>
            </div> 
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ReviewModal;
