import React, { memo, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Alert, Button, Card, Divider, Modal } from "antd";
// import React, { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import ImageIcon from "../../images/img_lady.png";
import "../../modals/index.css";
import { useHistory } from "react-router-dom";
import FacebookLogin from "react-facebook-login";
import {
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import { getClientFromAccountID, getClientFromAppleID, onSigninUser } from "../../api/auth";
import auth from "../../config/firebase";
import { Email, Phone } from "../../components";
import { IoCloseSharp } from "react-icons/io5";
import EPBoth from "../../components/Login/EPBoth";
import { Col, Row } from "react-bootstrap";
import { BsApple } from "react-icons/bs";
import { BiPencil } from "react-icons/bi";
import { TbRulerOff } from "react-icons/tb";
import AppleLogin from "react-apple-login";
import axios from "axios";

const Index = ({ setIsModalLiveOpen }) => {
  let returnVar = document?.URL?.split("/")[3];
  const returnTo = "/";
  const history = useHistory();
  const [status, setStatus] = useState(false);
  const [normalMode, setNormalMode] = useState(true);
  const [phoneMode, setphoneMode] = useState(false);
  const [emailMode, setEmailMode] = useState(false);
  const [showPopupRedirect, setShowPopupRedirect] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isEmailSendVisible, setIsEmailSendVisible] = useState(false);
  const [phone, setPhone] = useState("");
  const [reset, setReset] = useState(false);
  const [title, setTitle] = useState("");
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [isTitle, setIsTitle] = useState(
    returnVar === "signup" ? "Create an account!" : "Welcome Back! 👋"
  );
  const [modal2Open, setModal2Open] = useState(false);
  console.log("title", isTitle);
  //  Phone Email Login
  const PhoneLogin = () => {
    // history.push("/phone");
    setNormalMode(false);
    setphoneMode(true);
  };

  useEffect(() => {
    setTitle(isTitle);
  }, [isTitle]);

  const EmailLogin = () => {
    // history.push("/email");
    setNormalMode(false);
    setphoneMode(false);
    setEmailMode(true);
  };
  // Gmail Login
  const SigninWithGmail = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((res) => {
        console.log("RES GMAIL FIREBASE: ", res);
        setModal2Open(false);
        onSigninUser(
          res?.user,
          setShowPopupRedirect,
          setModal2Open,
          returnTo,
          history,
          setIsModalLiveOpen
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  //   Facebook login
  const responseFacebook = (response) => {
    console.log("fb res", response);
    setModal2Open(false);
    getClientFromAccountID(
      response,
      setShowPopupRedirect,
      setModal2Open,
      returnTo,
      history,
      setIsModalLiveOpen
    );
  };

  const onClose = () => {
    setModal2Open(false);
    setNormalMode(true);
    setphoneMode(false);
    setEmailMode(false);
    setIsVisible(false);
    if (localStorage.getItem("reading")) {
      localStorage.removeItem("reading");
    }
    if (localStorage.getItem("livereading")) {
      localStorage.removeItem("livereading");
    }
  };
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const shiftLoginStatus = (input) => {
    console.log("input", input);
    if (title === "Create an account!") {
      setTitle("Welcome Back! 👋");
    } else {
      setTitle("Create an account!");
    }
  };
  const [appleRes, setAppleRes] = useState(null);

  const appleResponse = response => {
    if (!response.error) {
      console.log("responseApp", response);
      const {authorization} = response;
      getClientFromAppleID(authorization,
        setShowPopupRedirect,
        setModal2Open,
        returnTo,
        history,
        setIsModalLiveOpen)
      // axios
      //   .post("/auth", response)
      //   .then(res => {
      //     setAppleRes(res.data );
      //      console.log("appleRes", res);
      //     })
      //   .catch(err => console.log("appleError", err));
    }
  };
  return (
    <>
      {/* {showOTPPopup === true && (
        <div className="d-flex justify-content-center">
          <Alert
            message={`"OTP sent on" ${"+"
              .concat(phone?.phone?.slice(0, 1))
              .concat("**** ***")
              .concat(phone?.phone?.slice(-3))}`}
            type="success"
            showIcon
            closable
          />
        </div>
      )} */}
      {/* {showEmailPopup === true && (
        <div className="d-flex justify-content-center">
          <Alert
            message="An email has been sent to your address, please login using the link provided in your email."
            type="success"
            showIcon
            closable
          />
        </div>
      )} */}


      <div className="main-signin-modal bg-white">
        <div className="side-content-signinmodal">
          <img src={ImageIcon} alt="loginImage" className="image-Side" />
        </div>
        {normalMode === true && (
          <>
            <div
              className={`${
                isVisible === false
                  ? "main-content-signinmodal"
                  : "main-content-signinmodal main-content-signinmodal-otp"
              } py-5 px-5`}
            >
              <div
                className={
                  isVisible === false && showEmailPopup === false
                    ? "sub-sub-content"
                    : showEmailPopup === true
                    ? "sub-sub-content-email"
                    : "sub-sub-sub-cot"
                }
              >
                {
                  isVisible === false && showEmailPopup === false && (
                    <div className="closable-header">
                      <p>
                        {title === "Create an account!"
                          ? "Already have an account?"
                          : "Don’t have an account?"}
                      </p>
                      <Button
                        size="medium"
                        className="me-2 button-login-style"
                        onClick={() => shiftLoginStatus(title)}
                      >
                        {title === "Create an account!" ? "Login" : "Signup"}
                      </Button>
                      {/* <IoCloseSharp
                      onClick={onClose}
                      style={{ fontSize: "20px", cursor: "pointer" }}
                    /> */}
                    </div>
                  )
                  // : (
                  //   <div className="closable-header-otp">
                  //     <BsArrowLeft
                  //       onClick={() => setIsVisible(false)}
                  //       style={{ fontSize: "20px", cursor: "pointer" }}
                  //     />
                  //     <IoCloseSharp
                  //       onClick={onClose}
                  //       style={{ fontSize: "20px", cursor: "pointer" }}
                  //     />
                  //   </div>
                  // )
                }
                <div className="sub-content-signinmodal">
                  {
                    isVisible === false && showEmailPopup === false && (
                      <p className="text1">
                        {title === "Create an account!" ? "SIGN UP" : "LOGIN"}
                      </p>
                    )
                    // ) : (
                    //   <p className="text1">OTP</p>
                    // )
                  }
                  {isVisible === false ? (
                    showEmailPopup === false ? (
                      <h4>{title}</h4>
                    ) : (
                      <h4>
                        An email has been sent to your address, please login
                        using the link provided in your email.
                      </h4>
                    )
                  ) : (
                    <h4>Please check your phone</h4>
                  )}

                  {isVisible === false ? (
                    showEmailPopup === false && (
                      <span className="text3">
                        Please enter your account details
                      </span>
                    )
                  ) : (
                    <span className="text3">
                      Please enter the valid one time passcode that we have sent
                      on phone number{" "}
                      {"+"
                        .concat(phone?.slice(0, 1))
                        .concat("**** ***")
                        .concat(phone?.slice(-3))}
                    </span>
                  )}
                  {isVisible === true && (
                    <div
                      className="changeNo mt-1"
                      onClick={() => {
                        setIsVisible(false);
                        setReset(true);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <BiPencil />
                      <p>Change Number</p>
                    </div>
                  )}
                  {showEmailPopup === false && (
                    <EPBoth
                      setNormalMode={setNormalMode}
                      setPhoneMode={setphoneMode}
                      setModal2Open={setModal2Open}
                      returnTo={returnTo}
                      setShowOTPPopup={setShowOTPPopup}
                      setIsVisible={setIsVisible}
                      isVisible={isVisible}
                      reset={reset}
                      setPhone={setPhone}
                      phone={phone}
                      setShowEmailPopup={setShowEmailPopup}
                      setIsModalLiveOpen={setIsModalLiveOpen}
                      setIsEmailSendVisible={setIsEmailSendVisible}
                      isEmailSendVisible={isEmailSendVisible}
                    />
                  )}
                  {isVisible === false && showEmailPopup === false && (
                    <div className="d-flex justify-content-center align-items-center gap-2 my-sm-2">
                      <div
                        style={{
                          height: "1px",
                          width: "100px",
                          backgroundColor: "#595F62",
                          opacity: "0.5",
                        }}
                      >
                        {" "}
                      </div>
                      <p className="divider-txt">Or continue with</p>
                      <div
                        style={{
                          height: "1px",
                          width: "100px",
                          backgroundColor: "#595F62",
                          opacity: "0.5",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                  )}
                  {isVisible === false && showEmailPopup === false && (
                    <Row>
                      <Col lg={6}>
                        <Card
                          className="cards-signinmodal"
                          onClick={() => SigninWithGmail()}
                        >
                          <div>
                            <FcGoogle style={{ fontSize: "20px" }} />
                          </div>
                          <p>Gmail</p>
                        </Card>
                      </Col>
                      <Col lg={6}>
                        <AppleLogin
                          clientId="com.activepackets.talkpok.auth"
                          redirectURI="https://psychictxt.web.app/login"
                          usePopup={true}
                          callback={appleResponse} // Catch the response
                          scope="name"
                          // responseMode="query"
                          render={(
                            renderProps //Custom Apple Sign in Button
                          ) => (
                            <Card
                              className="cards-signinmodalApple"
                              onClick={renderProps.onClick}
                            >
                              <div>
                                <BsApple
                                  style={{ fontSize: "20px", color: "#FFFFFF" }}
                                />
                              </div>
                              <p>Apple</p>
                            </Card>
                          )}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
                {isVisible === false && (
                  <p className="lastpart my-3">
                    By signing you agree with the{" "}
                    <a href="#">terms of service</a> and{" "}
                    <a href="#">privacy policy</a>.
                  </p>
                )}
              </div>
            </div>
          </>
        )}
        {phoneMode === true && (
          <div className="main-content-signinmodal">
            <Phone
              setNormalMode={setNormalMode}
              setPhoneMode={setphoneMode}
              setModal2Open={setModal2Open}
              returnTo={returnTo}
            />
          </div>
        )}
        {emailMode === true && (
          <div className="main-content-signinmodal">
            <Email
              setNormalMode={setNormalMode}
              setEmailMode={setEmailMode}
              setModal2Open={setModal2Open}
              returnTo={returnTo}
            />
          </div>
        )}
      </div>

  
    </>
  );
};

export default memo(Index);
