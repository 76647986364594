import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";
import { MdOutlineMarkChatUnread } from "react-icons/md";
import { BsChatSquareDots } from "react-icons/bs";
import SigninModal from "../../../modals/signinModal";
import LiveChatModal from "../../../modals/liveChatModal";
import Chatpopup from "../../Chat/chatpopup";
import { useHistory } from "react-router-dom";

const Index = ({advisorDetails}) => {
  const history = useHistory();
  const [modal2Open, setModal2Open] = useState(false);
  const [isTitle, setIsTitle] = useState(null);
  const [show, setShow] = useState(false);
  const [navInfo, setNavInfo] = useState(null);
  const [advisor, setAdvisor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [livechatAdvisorInfo, setLivechatAdvisorInfo] = useState(null);
  const [liveChatSessionMsg, setLiveChatSessionMsg] = useState(null);
  var returnTo = '/' + document?.URL?.split('/')[3] + '/' + document?.URL?.split('/')[4];
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const checkUser = () => {
    // console.log("e", e);
    const obj = JSON.parse(localStorage.getItem("userInfo"));
    console.log("obj", obj);
    if (obj === null) {
      // history.push("/login");
      // history.push('/signup')
      history.push(`/reading/${advisorDetails?.id}`);
      // setIsTitle("Login to your account");
      // setModal2Open(true);
    } else {
      setAdvisor(advisorDetails);
      // setShow(true);
      history.push(`/chat/${advisorDetails?.id}`);
    }
  };
  const startLiveChat = () => {
    const obj = JSON.parse(localStorage.getItem("userInfo"));
    if (obj === null) {
      // history.push("/login");
      // setIsTitle("Login to your account");
      // setModal2Open(true);
      history.push(`/livereading/${advisorDetails?.id}`)
    } else {
      setIsModalOpen(true);
      setLivechatAdvisorInfo(advisorDetails);
    }
  };
  return (
    <>
      {isTitle !== null && (
        <SigninModal
        returnTo={returnTo}
          isTitle={isTitle}
          setModal2Open={setModal2Open}
          modal2Open={modal2Open}
        />
      )}
            {show === true && advisor !== null && (
        <Chatpopup
          setShow={setShow}
          advisor={advisorDetails}
          setNavInfo={setNavInfo}
        />
      )}
      <>
        {isModalOpen === true && (
          <LiveChatModal
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            livechatAdvisorInfo={livechatAdvisorInfo}
            liveChatSessionMsg={liveChatSessionMsg}
          />
        )}
      </>
      <Row className="d-flex justify-content-center gap-3 px-2">
        <Col
          lg={10}
          style={{
            cursor: "pointer",
          }}
          onClick={() => 
            {
              if(advisorDetails?.live_status === '1'){
                startLiveChat();
              }
              }}
          className={`${advisorDetails?.live_status === '1' ? 'chat-section3 pointing' : 'chat-section-disable-3'} shadow-sm`}
        >
          <div className="cover">
            <MdOutlineMarkChatUnread
              color="#FFFFFF"
              style={{
                fontSize: "20px",
              }}
            />
          </div>
          <div className="w-75">
            <span
              className=" d-flex flex-column gap-1"
            >
              <span className="chat-txt">Chat</span>
              <span>
                <b className="chat-txt_2">2.00 Cr/Min</b>
              </span>
            </span>
          </div>
        </Col>
        <Col
          lg={10}
          className={`${advisorDetails?.status === '1' ? 'chat-section3_b pointing' : 'chat-section3-disable_b'} shadow-sm pointing`}
          style={{
            borderRadius: "10px",
            height:'67px'
          }}
          onClick={() => 
            {
              if(advisorDetails?.status === '1'){
                checkUser();
              }
            }
            }
        >
        <div className="cover"> <BsChatSquareDots
            color={advisorDetails?.status === '1' ? "#3A86F4" : '#FFFFFF'}
            style={{
              fontSize: "20px",
              paddingTop: "2px",
              cursor: "pointer",
            }}
          /></div> 
        <div className="d-flex flex-column align-items-start"> 
        <p className={`${advisorDetails?.status === '1' ? "text-txt" : 'text-txt text-disable'}`}>Reading</p>
          <b className={`${advisorDetails?.status === '1' ? "text-txt-2" : 'text-txt-2 text-disable'}`}>150 Credits</b>
          </div> 
        </Col>
      </Row>
    </>
  );
};

export default memo(Index);
