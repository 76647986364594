import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrAttachment } from "react-icons/gr";
import S3FileUpload from "react-s3";
import { getUpdateChatRoom } from "../../../api/chat";
import AttachmentModal from "../../../modals/attachmentModal";
import "../index.css";
// Buffer.from("anything", "base64");
// window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: "mytarotadvisorvideos",
  region: "us-west-2",
  accessKeyId: "AKIAU7M7MBKJ2DEJLFP4",
  secretAccessKey: "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",
};

const Attachment = ({
  setSelectedFile,
  color,
  setColor,
  showAttach,
  setMsg,
  setMessage,
  message,
  submitMsg,
  isModalOpen,
  setIsModalOpen,
  setClearInput,
  clearInput,
  handlePauseTimer,
  handleResumeTimer,
  setPause,
  setResume,
  setClientResume,
  setAdvisorResume,
  advisorResume, img,
  setImg,
  msg
}) => {
  console.log("MESSSXX", message);
  const [fileName, setFileName] = useState();
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [img, setImg] = useState(null);
  const [info, setShowInfo] = useState(true);
  const handler = (e) => {
    console.log("fileee", e);
    var filename = e?.target?.files[0] || e;
    console.log("filename", filename);
    // setImg(filename);
    setSelectedFile({ file: filename, type: "photo" });
    setColor("pink");
    // console.log("hhh",URL.createObjectURL(e?.target?.files[0]));
    if (e?.target?.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
    }
  };
  const delteImg = () => {
    setImg(null);
    setSelectedFile({ file: "", type: "" });
    setColor("lightgray");
  };
  const onFinish = async () => {
    handleResumeTimer();
    submitMsg();
    setIsModalOpen(false);

  };
  const handleOpen = () => {
    setIsModalOpen(true);
    handlePauseTimer();
    // setPause(true)
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    // handleResumeTimer();
    // setClientResume(false);
    const sessionID = localStorage.getItem("livechatsessionID");
    const ID = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};
    getUpdateChatRoom(sessionID, ID, "live_chat_resume", setShowInfo);
    if(advisorResume === true){
      setAdvisorResume(true)
    }
    // setMsg({text: ''});
    // setSelectedFile({ file: null, type: "" });
    // setMessage({text: ''})
    setClearInput(true);
    setImg(null);
    // setMsg(prev => {
    //   return {...prev, text: ''}
    // });
      setSelectedFile({ file: null, type: "" });
      // setMessage(prev => {
      //   return {...prev, text: ''}
      // });
      // setResume(true);
  };
  console.log("ATTACHMENTMSGXX", msg);
  return (
    <>
      <AttachmentModal
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handler={handler}
        delteImg={delteImg}
        img={img}
        setImg={setImg}
        setMsg={setMsg}
        setMessage={setMessage}
        message={message}
        submitMsg={submitMsg}
        clearInput={clearInput}
        onFinish={onFinish}
        msg={msg}
      />
      {showAttach === true && (
        <div className="d-flex align-items-baseline gap-2">
          <label>
            <GrAttachment
              style={{ fontSize: "20px", color: `${color}` }}
              className="pointing"
              onClick={handleOpen}
            />
            {/* <input
            type="file"
            // name="myfile"
            style={{ display: "none" }}
            onChange={handler}
          /> */}
          </label>
          {/* {color === 'pink' && <AiFillDelete onClick={delteImg} style={{ fontSize: "20px", color: `red` }}/>} */}
        </div>
      )}
    </>
  );
};

export default Attachment;
