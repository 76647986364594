import React, { memo, useEffect, useRef, useState } from "react";
import { Divider, Form, Input, InputNumber, message, Spin } from "antd";
import auth from "../../../config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { Col, Container, Row, Button } from "react-bootstrap";
import axios from "axios";
import clevertap from "clevertap-web-sdk";
import { finalLogin, onSigninUser } from "../../../api/auth";
import { LoadingOutlined } from "@ant-design/icons";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { IoReturnUpBackOutline } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import "./index.css";
import { AiOutlineReload } from "react-icons/ai";
// const NumberFormat = require('react-number-format');

const Index = ({
  setNormalMode,
  setPhoneMode,
  setModal2Open,
  returnTo,
  setShowOTPPopup,
  setIsVisible,
  isVisible,
  reset,
  phone,
  setPhone,
  setShowEmailPopup,
  setIsModalLiveOpen,
  setIsEmailSendVisible,
  isEmailSendVisible,
  setIsModalOpen,
  proff
}) => {
  const [opt, setOtp] = useState("");
  const textInput = useRef(null);
  const [form] = Form.useForm();
  // phone
  const history = useHistory();
  const countRef = useRef(null);
  const [timer, setTimer] = useState(60);
  const [timerVisible, setTimerVisible] = useState(false); //false
  const [showPopupRedirect, setShowPopupRedirect] = useState(false);
  const [val, setVal] = useState(null);
  const [enableResend, showEnableResend] = useState(false); //false
  const [isPhone, setIsPhone] = useState(false);
  const [email, setEmail] = useState("");
  const [input, setInput] = useState("");

  // email
  const [showBtn, setShowBtn] = useState(true);
  const [showResendBtn, setShowResendBtn] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [showpopup, setShowPopup] = useState(false);

  const handleStartTimer = () => {
    setTimerVisible(true);
    countRef.current = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
  };

  const handleResetTimer = () => {
    clearInterval(countRef.current);
    setTimer(0);
  };

  // if(timer <= 0){
  //   handleResetTimer();
  // };
  console.log("timerr", timer);
  // if(timer <= 1){
  //   handleResetTimer();
  // }

  useEffect(() => {
    if (timer <= 0) {
      handleResetTimer();
      showEnableResend(true);
    }
  }, [timer]);

  useEffect(() => {
    if (reset === true) {
      clearInterval(countRef.current);
      setTimer(60);
    }
  }, [reset]);
  const onFinish = async (values) => {
    console.log("Success:", values);
    var phoneNum = values.phonenumber;
    console.log("phoneNum", phoneNum);
    setPhone(phoneNum);
    if (values?.phonenumber?.includes("+") === false) {
      phoneNum = "+".concat(values.phonenumber);
    }
    console.log("phoneNum", phoneNum);
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("res", response);
        },
      },
      auth
    );

    let appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNum, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setIsVisible(true);
        setShowOTPPopup(true);
        handleStartTimer();
      })
      .catch(function (error) {
        console.log(error);
        message.error("Oops! Try again.");
        form.resetFields();
        setVal(null);
        setPhone("");
      });
  };
  const resendOPT = (values) => {
    console.log("valll:", values);
    clearInterval(countRef.current);
    setTimer(60);
    var phoneNum = values.phonenumber;
    console.log("phoneNum", phoneNum);
    if (values?.phonenumber?.includes("+") === false) {
      phoneNum = "+".concat(values.phonenumber);
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("res", response);
        },
      },
      auth
    );

    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNum, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setIsVisible(true);
        message.success("OTP sent!");
        handleStartTimer();
      })
      .catch(function (error) {
        console.log(error);
        message.error("Oops! Try again.");
        setInput("");
        // form.resetFields();
        // Error; SMS not sent
        // ...
      });
  };
  const verifyOTP = (e) => {
    setShowPopupRedirect(true);
    handleResetTimer();
    setTimerVisible(false);
    let otpval = e;
    setOtp(otpval);
    console.log(otpval);
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otpval)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log("user", user);
        onSigninUser(
          user,
          setShowPopupRedirect,
          setModal2Open,
          returnTo,
          history,
          setIsModalLiveOpen,
          setIsModalOpen,
          proff
        );
        // ...
      })
      .catch((error) => {
        console.log(error);
        // User couldn't sign in (bad verification code?)
        // ...
      });
    // }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleEvent = () => {
    clevertap.recordEvent("login", {
      Date: new Date(),
    });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 70,
      }}
      spin
    />
  );

  useEffect(() => {
    if (phone === "") {
      setIsPhone(false);
    }
  }, [phone]);
  console.log("phone", phone);
  const handleChange = (otp) => setOtp(otp);
  return (
    <>
      <Container className="p-0">
        {showPopupRedirect === true && (
          <Row className="d-flex flex-column justify-content-center align-items-center gap-2 h-100">
            <Spin indicator={antIcon} />
            <h6 className="text-center">Please wait...</h6>
          </Row>
        )}
        {showPopupRedirect === false && (
          <div className="maincontainer">
            {isVisible === false && (
              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className="d-flex flex-column formm"
                form={form}
              >
                <Form.Item
                  name="phonenumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number!",
                    },
                  ]}
                >
                  <PhoneInput
                    country={"us"}
                    value={phone}
                    onChange={(phone) => {
                      setPhone({ phone });
                      if (phone === "") {
                        setIsPhone(false);
                      }
                    }}
                    disableInitialCountryGuess={true}
                    prefix="+"
                    masks={"...-....-..."}
                    defaultMask="...-....-..."
                    alwaysDefaultMask={true}
                    placeholder="Enter your phone number"
                  />
                </Form.Item>

                <Form.Item
                  style={{ alignItems: "start !important", width: "336px" }}
                >
                  <Button
                    className="w-100 button-style"
                    type="primary"
                    htmlType="submit"
                    id="sign-in-button"
                  >
                    <p className="signup-button">CONTINUE</p>
                  </Button>
                </Form.Item>
              </Form>
            )}
            <div id="recaptcha-container"></div>

            {isVisible === true && (
              <div className="otp_main">
                {" "}
                <OtpInput
                  value={opt}
                  onChange={handleChange}
                  numInputs={6}
                  separator={<span className="seperator"></span>}
                />
                <Button
                  className=" button-style mt-3"
                  style={{ width: "350px" }}
                  type="primary"
                  htmlType="submit"
                  id="sign-in-button"
                  onClick={() => verifyOTP(opt)}
                  disabled={timer === 0 ? true : false}
                >
                  <p className="signup-button">SUBMIT</p>
                </Button>
                <div className="timer-otp my-3">
                  {timerVisible === true && (
                    <>
                      {enableResend === true && (
                        <h6
                          style={{ fontSize: "0.8rem" }}
                          className="text-center pointing"
                          id="recaptcha-container"
                          onClick={() => resendOPT(val)}
                        >
                          <AiOutlineReload /> &nbsp; Resend Code
                        </h6>
                      )}
                    </>
                  )}
                  {timerVisible === true && (
                    <h6 style={{ fontSize: "0.8rem" }}>(00:{timer} Sec)</h6>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Container>
    </>
  );
};

export default memo(Index);
