

export const advisorCategory = [
    // {
    //     id: 'alladvisors',
    //     title: 'All Advisors',
    //     bg_url: "../../../images/allAdvisors.png",
    // },
    {
        id: 'lovereadings',
        title: 'Love Readings',
        bg_url: "banner-container-love",
        img: "/love.png"
    },
    {
        id: 'tarotreadings',
        title: 'Tarot Readings',
        bg_url: "banner-container-tarot",
        img: "/tarot.png"
    },
    {
        id: 'palmreadings',
        title: 'Palm Readings',
        bg_url: "banner-container-palm",
        img: "/palm.png"
    },
    {
        id: 'psychicreadings',
        title: 'Psychic Readings',
        bg_url: "banner-container-psychic",
        img: "/psychic.png"
    },
    {
        id: 'angleinsights',
        title: 'Angle Insights',
        bg_url: "banner-container-angle",
        img: "/angel.png"
    },
    {
        id: 'astrologyandhoroscopes',
        title: 'Astrology & Horoscopes',
        bg_url: "banner-container-astrohoro",
        img: "/horo.png"
    },
    {
        id: 'dreamanalysis',
        title: 'Dream Analysis',
        bg_url: "banner-container-dream",
        img: "/dream.png"
    },
    {
        id: 'oracleguidance',
        title: 'Oracle Guidance',
        bg_url: "banner-container-oracle",
        img: "/oracle.png"
    },

]