import { Modal } from "antd";
import React from "react";
import Payment from "../views/Payment";
import "./index.css";

const PurchaseModal = ({isModalOpen, handleCancel, purchaseID, setIsModalOpen}) => {
  return (
    <>
     <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        width='500px'
        className="px-3"
        style={{ borderRadius: '16px'}}
      > 
     {purchaseID !== null && <Payment id={purchaseID} setIsModalOpen={setIsModalOpen}/>}
      </Modal>
    </>
  );
};
export default PurchaseModal;
