import { Avatar, Divider, Drawer, message, Popover, Space } from "antd";
import React, { memo, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../../images/logo.png";
import { DownOutlined } from "@ant-design/icons";
import "./index.css";
import { useHistory } from "react-router-dom";
import { getUserDetails } from "../../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { usePubNub } from "pubnub-react";
import { Button, NavDropdown } from "react-bootstrap";
import SigninModal from "../../../modals/signinModal";
import { HiMenuAlt1 } from "react-icons/hi";
import DrawerIcon from "./drawerIcon";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsPlus } from "react-icons/bs";

var returnTo = "/";
// if(returnTo === '/undefined'){
//   console.log("returnTo", document?.URL?.split('/')[3])
// }
console.log("returnTo", returnTo);

const Index = ({ setNavInfo, navInfo }) => {
  const [isUser, setIsUser] = useState(null);
  const pubnub = usePubNub();
  const [channels, setChannels] = useState("");
  const [liveCredits, setLiveCredits] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal2Open, setModal2Open] = useState(false);
  const [isTitle, setIsTitle] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getUserDetails(dispatch);
  }, [dispatch]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const profile = useSelector((state) => state.profile);
  console.log("profile", profile);
  useEffect(() => {
    setIsUser(profile);
  }, [profile]);

  console.log("IS", isUser);

  const creditsMsg = useSelector((x) => x.creditsMsg);
  console.log("creditsMsg", creditsMsg);
  useEffect(() => {
    // if (creditsMsg?.message_type === "update_credits") {
    if (Object.keys(creditsMsg).length !== 0) {
      setIsUser((prev) => {
        return {
          ...prev,
          free_credit: creditsMsg?.free_credit,
          paid_credit: creditsMsg?.paid_credit,
          free_seconds: creditsMsg?.free_seconds,
          paid_seconds: creditsMsg?.paid_seconds,
        };
      });
    }

    // }
  }, [creditsMsg]);

  const logoutSession = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("dropdown-credits");
    history.push("/");
    setIsUser(null);
    message.success("You are logged out successfully!");
  };
  const redirectToPurchaseCredits = () => {
    history.push("/purchase-credits");
  };

  const content = (
    <>
      <div>
        <a href="/#" className="logout">
          Profile
        </a>
      </div>
      <hr />
      <div>
        {/* <p>Txt credits: </p> */}
        {isUser !== null && (
          <Button
            className="buy-btn btn btn-danger"
            // type="danger"
            // size="small"
            onClick={redirectToPurchaseCredits}
          >
            Buy now
          </Button>
        )}
        {isUser !== null && isUser?.free_credit && isUser?.paid_credit && (
          <p className="text_info" style={{ fontSize: "0.8rem" }}>
            Txt credits:{" "}
            {(typeof isUser?.free_credit === "string" &&
              typeof isUser?.paid_credit) === "string"
              ? +isUser?.free_credit + +isUser?.paid_credit
              : typeof isUser?.free_credit === "string" &&
                typeof isUser?.paid_credit === "number"
              ? +isUser?.free_credit + isUser?.paid_credit
              : typeof isUser?.free_credit === "number" &&
                typeof isUser?.paid_credit === "string"
              ? isUser?.free_credit + +isUser?.paid_credit
              : isUser?.free_credit + isUser?.paid_credit}{" "}
          </p>
        )}
        {isUser?.free_seconds && isUser?.paid_seconds && (
          <p className="text_info" style={{ fontSize: "0.8rem" }}>
            Live credits: {liveCredits !== null && liveCredits}
          </p>
        )}
      </div>
      <Space />
      <div>
        <a href="/inbox" className="logout">
          Inbox
        </a>
        <p className="logout" onClick={logoutSession}>
          Logout
        </p>
      </div>
    </>
  );

  // Pubnub
  const leaveApplication = () => {
    pubnub.removeListener(listnerAdd);
    pubnub.unsubscribeAll();
  };
  const listnerAdd = {
    status: (statusEvent) => {
      var subscribedChannels = statusEvent.subscribedChannels;
      console.log("subscribedChannels", subscribedChannels);
      if (statusEvent.category === "PNConnectedCategory") {
        console.log("Connected nav");
      }
    },
    message: (event) => {
      const message = event.message;
      console.log("NAV msgs", message);
      // if(message.type === 'update_credits'){

      // }
      if (message?.message_type === "update_credits") {
        setIsUser((prev) => {
          return {
            ...prev,
            free_credit: message?.payload?.free_credit.toString(),
            paid_credit: message?.payload?.paid_credit.toString(),
            free_seconds: message?.payload?.free_seconds,
            paid_seconds: message?.payload?.paid_seconds,
          };
        });
      }

  
    },
  };
  useEffect(() => {
    setChannels("Channel_User_".concat(isUser?.id));
    pubnub.addListener(listnerAdd);
    if (channels) {
      console.log("channels", channels);
      pubnub.subscribe({
        channels: [channels, "channel_application"],
        withPresence: true,
      });
    }
    return leaveApplication();
  }, [pubnub, channels]);
  // End
  useEffect(() => {
    // setIsUser(profile);
    if (isUser?.free_seconds && isUser?.paid_seconds) {
      const credit_val = +isUser?.free_seconds + +isUser?.paid_seconds;
      const calcul = ((credit_val * 2) / 60).toFixed(2);
      setLiveCredits(calcul);
    }
  }, [isUser]);

  return (
    <>
      <DrawerIcon
        open={open}
        onClose={onClose}
        isUser={isUser}
        liveCredits={liveCredits}
        logoutSession={logoutSession}
      />
      <Container fluid className="main_overflow shadow-sm p-0 m-0">
        <Container>
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className="md-d-none"
          >
            <Container fluid className="main-contain">
              {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
              {/* <Navbar.Collapse id="responsive-navbar-nav" className="md-d-none"> */}
              <div className="contain w-100">
                <div className="contain-sub">
                  {" "}
                  <Nav className="first-nav-main">
                    <div className="menu_icon_drawer" onClick={showDrawer}>
                      <HiMenuAlt1 style={{ fontSize: "20px" }} />
                    </div>
                  </Nav>
                  <div className="pointing contain-sub" onClick={()=> history.push('/')}>
                  <Nav>
                    <img className="image shadow-sm" src={Logo} />
                  </Nav>
                  <Nav>
                    <h4 className="brand">Psychic Txt</h4>
                  </Nav>
                  </div>
                </div>
                <Nav className="last-nav-main">
                  {isUser === null && (
                    <>
                      {" "}
                      {/* <Nav.Link> */}
                      <Button
                        // primary
                        className=" btn-loginn me-2"
                        // btn-loginn btn-light border-primary
                        onClick={() => {
                          setIsTitle("Welcome Back! 👋");
                          setModal2Open(true);
                        }}
                      >
                        <p className="login-button">Log in</p>
                      </Button>
                      <Button
                        className=" button-style"
                        onClick={() => {
                          setIsTitle("Create an account!");
                          setModal2Open(true);
                        }}
                      >
                        <p className="signup-button">Sign up</p>
                      </Button>
                    </>
                  )}
                  {isUser !== null && (
                    <>
                      <Button className="profile">
                        <span
                          className="user_Profile_pic"
                          onClick={() => history.push("/")}
                          // src="#"
                          // size={40}
                        >
                          <span>{isUser?.username?.charAt(0)}</span>
                        </span>
                        <div className="user_content_navbar">
                          <h3>{isUser?.username}</h3>
                          <p>
                            User Id: <span>{isUser?.id}</span>
                          </p>
                        </div>
                        {/* <DownOutlined /> */}
                      </Button>
                      {/* </Popover> */}
                    </>
                  )}
                </Nav>
              </div>
              {/* </Navbar.Collapse> */}
            </Container>
          </Navbar>
        </Container>
        {isTitle !== null && (
          <SigninModal
            isTitle={isTitle}
            setModal2Open={setModal2Open}
            modal2Open={modal2Open}
            returnTo={returnTo}
          />
        )}
      </Container>
    </>
  );
};

export default memo(Index);
