import { Card, Divider } from "antd";
import React from "react";
import { Row, Col } from "react-bootstrap";
import Cards from "../../LandingPage/ExploreAdvisorByCategories/cards";
import Categories from "../categories";

import "./index.css";

const Speciality = () => {
  return (
    <>
      <Row className="main_speciality_section mt-4 bg-light" id="special">
        <Col lg={12} className="main_speciality_section_heading ">
          <h6>Specialities</h6>
        </Col>
        <Col className="main_speciality_section_content w-100">
          <Categories />
        </Col>
      </Row>
    </>
  );
};

export default Speciality;
