import React, { memo,useState, useEffect } from "react";
import { Avatar, Card, Comment, Divider, Modal, Rate, Tag, Tooltip } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import PlayBtn from "../../images/playbutton.png";
import Parser from "html-react-parser";
import { TbBellRinging } from "react-icons/tb";
import { FaRegHeart } from "react-icons/fa";
import "./index.css";
import ChatIcons from "./ChatIcons";
import VideoModal from "../../modals/videoModal";

const Index = ({ advisorDetails, setShow }) => {
  const [showModal, setShowModal] = useState(false);
  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <>
      <Row className="fixed-main-header shadow-sm">
        {/* <Col lg={1}></Col> */}
        <Col lg={4} className="section_1_fixed_profile_icons">
          <Avatar
            src={advisorDetails?.s3_display_picture}
            size={64}
            className="section_1_fixed_profile_icons_1"
          />
        <div onClick={() => setShowModal(true)}><Avatar
            src={PlayBtn}
            size={18}
            className="section_1_fixed_profile_icons_2 pointing"
          /></div>
          <div className="section_1_fixed_profile">
            <p>{advisorDetails?.first_name}</p>
            <div className="des_fix_profile">
              {(advisorDetails?.short_description)?.replace(/<[^>]+>/g, '')}
            </div>
          </div>
        </Col>
        <Col lg={6} className=" d-flex align-items-center justify-content-center">
          <ChatIcons advisorDetails={advisorDetails} setShow={setShow}/>
        </Col>
        <Col lg={2} className="icon_fixed_header">
          <div className="circle_icon">
            <TbBellRinging color="#595F62" style={{ fontSize: "30px" }} />
          </div>
          <div className="circle_icon">
            <FaRegHeart color="#F04931" style={{ fontSize: "30px" }} />
          </div>
        </Col>
        {/* <Col lg={1}></Col> */}
      </Row>
      <VideoModal showModal={showModal} handleCancel={handleCancel} advisorDetails={advisorDetails}/>
    </>
  );
};

export default memo(Index);
