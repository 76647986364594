import { Input } from "antd";
import React, { memo, useState } from "react";
import { Col, Row, Container, Form, Button } from "react-bootstrap";
import NavBar from "../NavBar";
import "./index.css";
import { FiSearch } from "react-icons/fi";
import { useHistory } from "react-router-dom";

const { Search } = Input;

const Index = ({ setNavInfo, navInfo }) => {
  const [searchInput, setSearchInput] = useState(null);
  const history = useHistory();
  const onSearch = (e) => {
    e.persist()
    console.log('value', e.target.value);
    setSearchInput(e.target.value)
  }
  console.log("searchInput", searchInput);
  const FetechAdvisor = () =>{
    if(searchInput !== null){
      localStorage.setItem("search", searchInput)
      history.push(`/search`);
    }
  }
  return (
    <>
      <div className="banner-container bg-light">
        <Container fluid style={{backgroundColor: 'white'}} className="shadow-sm fixed-navbar">
        <NavBar setNavInfo={setNavInfo} navInfo={navInfo} />
        </Container>
        <Container>
          <Row className="getStarted">
            <Col lg={7} md={12} className="banner-section-1">
              {/* className="banner-section-1" */}
              <div className="contents">
                <p className="headings-1">
                  The Clarity You Need <br /> For A Fulfilling Life
                </p>
                <p className="descriptionp">
                  — We take pride in offering you the best advisors anywhere in
                  the world. Psychics, Tarot, Astrology and more. Your journey
                  to a brighter future starts with Psychic TXT.
                </p>
                <div className="search-box shadow-sm">
                  <Form className="d-flex">
                    <div style={{ width: "10%" }} className="icon">
                      <FiSearch style={{ fontSize: "20px" }} />
                    </div>
                    <Form.Control
                      type="input"
                      placeholder="Search by speciality or by reading type"
                      className="me-2 search-inputt"
                      aria-label="Search"
                      style={{ width: "70%", fontFamily: 'Rubik' }}
                      onChange={onSearch}
                    />
                   
                    <Button
                    className="shadow-sm button-style findAdvisorBtn"
                    onClick={()=> FetechAdvisor()}
                    >Find Advisor</Button>
                  </Form>
                </div>
               
              </div>
            
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default memo(Index);
