import { Divider, Input, Select, Space, Switch, Tag, Spin } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { MdOutlineMarkChatUnread } from "react-icons/md";
import { BsChatSquareDots } from "react-icons/bs";
import "./index.css";
import MultiRangeSlider from "./multirangeslider";
import { getFilterAdvisors } from "../../../api/advisor";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: 'white'
    }}
    spin
  />
);

const Index = ({setLoading}) => {
  const dispatch = useDispatch();
  const [minValue, set_minValue] = useState(25);
  const [maxValue, set_maxValue] = useState(75);
  const [icon, setIcon] = useState("text");
  const [tagList, setTagList] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [pricelist, setPricelist] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [filterObj, setFilterObj] = useState({
    live_chat: 0,
    text_chat: 0,
    is_online: 0,
    // reviews: [],
    // price_range: [],
  });
  console.log("filterObj", filterObj);
  console.log("reviewList", reviewList);
  const [removedTag, setRemovedTag] = useState(null);
  const [enableList, setEnableList] = useState(false);
  const handleShowTypeChange = (value) => {
    const val = value.value;
    const label = value.label
    console.log("value", val, label); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
    setIcon(val);
    // const find = tagList?.find((e)=> e.name === value.label);
      if(filterObj.text_status === undefined && filterObj.live_status === undefined){
        setFilterObj(prev => {
           return {...prev, [value.value]: 1};
          })
      }
      if(filterObj.live_status === undefined && filterObj.text_status !== undefined){
        delete filterObj.text_status;
        setFilterObj(prev => {
           return {...prev, [value.value]: 1};
          })
      }
      if(filterObj.live_status !== undefined && filterObj.text_status === undefined){
        delete filterObj.live_status;
        setFilterObj(prev => {
           return {...prev, [value.value]: 1};
          })
      }
    // setEnableList(true);
  };
  const handleReviewsChange = (value) => {
    const val = value.value;
    // const label = value.label
    // console.log("value", value.value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
    // const find = tagList?.find((e)=> e.name === value.label);
    // console.log("duplicateTest", find);
    // if(find === undefined){
    //   // setTagList((prev) => [...prev, {name: label, value: val}]);
      setReviewList(prev => [val])
    // }
    // setEnableList(true);
  };
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
    if (checked === true) {
      const find = tagList?.find((e)=> e.name === "Online Advisors");
      if(find === undefined){
        // setTagList((prev) => [...prev, "Online Advisors"]);
        setTagList(tagList?.filter((tag) => tag.name !== "Offline Advisors"))
        setTagList((prev) => [...prev, {name: "Online Advisors", value: "Online Advisors"}]);
      }
      setFilterObj(prev => {
        return {...prev, is_online: 1};
       })
      setEnableList(true);
    }
    if (checked === false) {
      const find = tagList?.find((e)=> e.name === "Offline Advisors");
      if(find === undefined){
        // setTagList((prev) => [...prev, "Offline Advisors"]);
        setTagList(tagList?.filter((tag) => tag.name !== "Online Advisors"))
        setTagList((prev) => [...prev, {name: "Offline Advisors", value: "Online Advisors"}]);
      }
      setFilterObj(prev => {
        return {...prev, is_online: 0};
       })
      setEnableList(true);
    }
  };
  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };
  const preventDefault = (e) => {
    console.log("e", e);
    const newTags = tagList?.filter((tag) => tag.name !== e.name);
    const revList = reviewList?.filter((e1) => e1 !== e.value);
    setReviewList(revList);
    const priList = pricelist?.filter((e1) => e1 !== e.value);
    setPricelist(priList)
    if (newTags?.length === 0) {
      setEnableList(false);
    }
     if(e?.value === 'Online Advisors'){
      setFilterObj(prev => {
        return {...prev, is_online: 0};
       })
     }
     if(e?.value === 'text_status' || e?.value === 'live_status'){
      setFilterObj(prev => {
        return {...prev, [e.value]: 0};
       })
     }
    setTagList(newTags);
  };

  console.log("setTaglist", tagList);
  useEffect(() => {
    if (removedTag !== null && removedTag !== `2 cr/min - 6 cr/min`) {
      // const find = tagList?.find((e)=> e.name === removedTag);
      // if(find === undefined){
      //   const finded = tagList?.filter((e)=> e.value !== 'price');
      //   setTagList((prev) => [ ...finded,  {name: removedTag, value: 'price'}]);
        setPricelist(prev => [removedTag])
      // }
      // setEnableList(true);
    }
  }, [removedTag]);
console.log("pricelist", pricelist);
  const submitFilter = () => {
    setIsLoading(true)
    getFilterAdvisors(filterObj, reviewList, pricelist,setIsLoading, dispatch);
  };
  return (
    <>
      <Row className="px-3 main_filter_row">
        <Col lg={2} md={2} sm={2} xs={6} className="filters-alignment">
          <Row>
            <Col lg={12}>
              <p className="filter-txt1">Show only</p>
            </Col>

            <Row>
              <Col lg={3} xs={3} className="pe-0">
                {icon === "live" && (
                  <MdOutlineMarkChatUnread
                    color="#3A86F4"
                    style={{
                      fontSize: "15px",
                      cursor: "pointer",
                      marginTop: "9px",
                    }}
                  />
                )}
                {icon === "text" && (
                  <BsChatSquareDots
                    color="#3A86F4"
                    style={{
                      fontSize: "15px",
                      cursor: "pointer",
                      marginTop: "9px",
                    }}
                  />
                )}
              </Col>
              <Col lg={9} xs={9} className="p-0">
                <Select
                  labelInValue
                  // defaultValue={{
                  //   value: "text_status",
                  //   label: "Text Chat",
                  // }}
                  placeholder="Text Chat"
                  // style={{
                  //   width: 120,
                  // }}
                  className="filter-txt-select"
                  bordered={false}
                  onChange={handleShowTypeChange}
                  options={[
                    {
                      value: "text_status",
                      label: "Text Chat",
                    },
                    {
                      value: "live_status",
                      label: "Live Chat",
                    },
                  ]}
                />
              </Col>
            </Row>
          </Row>
        </Col>
        <Col lg={3} md={3} sm={3} xs={6} className="filters-alignment">
          <Divider type="vertical" style={{ height: "50px", margin: '0 23px' }} />
          <Row>
            <Col lg={12}>
              <p className="filter-txt1">Number of reviews</p>
            </Col>

            <Col lg={12} className="p-0">
              <Select
                labelInValue
                // defaultValue={{
                //   value: "0-50",
                //   label: "0-50 Reviews",
                // }}
                placeholder="0-50 Reviews"
                // style={{
                //   width: 180,
                // }}
                className="filter-txt-select filter-txt-select-new"
                bordered={false}
                onChange={handleReviewsChange}
                options={[
                  {
                    value: "0-50",
                    label: "0-50 Reviews",
                  },
                  {
                    value: "50-100",
                    label: "50-100 Reviews",
                  },
                ]}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={3} md={4} sm={4} xs={6} className="filters-alignment">
          <Divider type="vertical" style={{ height: "50px", margin: '0 23px 0 0' }}  className="divid_verti"/>
          <Row>
            <Col lg={12} className="p-0 me-2">
              <p className="filter-txt1">Price Range</p>
            </Col>
            <Col lg={12}>
              <MultiRangeSlider
                min={2}
                max={6}
                setTagList={setTagList}
                onChange={({ min, max }) => {
                  console.log(`min = ${min}, max = ${max}`);
                  setRemovedTag(`${min} cr/min - ${max} cr/min`);
                  //   setTagList((prev) => [
                  //     ...prev,
                  //     `${min} cr/min - ${max} cr/min`,
                  //   ]);
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col lg={4} md={3} sm={3} xs={6} className="filters-alignment">
          <Divider type="vertical" style={{ height: "50px" }} />
          <Row className="online_filterss">
            <Col lg={2} className="p-0">
              <Switch
                defaultChecked={false}
                className="switchh"
                onChange={onChange}
              />
            </Col>
            <Col className="p-0">
              <p className="filter-txt-online">Online advisors</p>
            </Col>
            <Col lg={6} className="p-0">
             {isLoading === false ? <Button
                className="shadow-sm button-style btn-search py-2"
                onClick={() => submitFilter()}
              >
                <span>
                  <BiSearch style={{ marginTop: "4px" }} />
                </span>
                <span className="search-advisor">Search Advisor</span>
              </Button> : <Button
                className="shadow-sm button-style btn-search py-2"
              >
                <span>
                <Spin indicator={antIcon} />
                </span>
                <span className="search-advisor">Searching...</span>
              </Button>}
            </Col>
          </Row>
        </Col>
      </Row>
      {/* {enableList === true && (
        <>
          {" "}
          <hr />
          <Row className="px-md-4 tags-list">
            <Space size={[0, 10]} wrap>
              {tagList?.map((val, i) => {
                return (
                  <Tag
                    closable
                    onClose={(e) => {
                      e.preventDefault();
                      preventDefault(val);
                    }}
                    key={i}
                    className="selected_tags"
                  >
                    {val?.name}
                  </Tag>
                );
              })}
            </Space>
          </Row>
        </>
      )} */}
    </>
  );
};

export default memo(Index);
