import { Button, Modal, Input, Divider } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { ImFilePicture } from "react-icons/im";
import { TbCapture } from "react-icons/tb";
import Webcam from "react-webcam";
import CaptureImg from "../images/capture-img.svg";
import DropImg from "../images/drop-img.svg";
import "./index.css";

const AttachmentModal = ({
  isModalOpen,
  setIsModalOpen,
  handleCancel,
  handler,
  delteImg,
  img,
  setImg,
  setMsg,
  setMessage,
  message,
  submitMsg,
  clearInput,
  onFinish,
  msg,
}) => {
  const [cam, showCam] = useState(false);
  const webcamRef = useRef(null);
  // const [img, setImg] = useState(null);
  const showCamera = () => {
    showCam(true);
  };
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log("imageSrc", imageSrc);
    if (imageSrc) {
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "image.png", { type: "image/png" });
          console.log("file", file);
          handler(file);
        });

      showCam(false);
      setImg(imageSrc);
    }

    // handler(imageSrc);
  }, [webcamRef]);
  const inputMessage = (e) => {
    // e.persist();
    const value = e.target.value;
    console.log("messageCHKKK---", value);
    setMessage((prev) => {
      return { ...prev, text: e?.target?.value };
    });
    setMsg((prev) => {
      return { ...prev, text: e?.target?.value };
    });
    console.log("messageXX", message);
    // setShowAttach(false);
    // setShowAudio(false);
  };
  // const onFinish = async () => {
  //   submitMsg();
  //   setIsModalOpen(false);

  // };
  console.log("isModalOpen, MODAL__", isModalOpen);
  console.log("ATTACHMENTMODALMSGXX", msg);
  return (
    <>
      <Modal
        // title="Upload"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        // style={{ width: "850px" }}
        className="modal-pop-upload py-3"
      >
         {cam === false &&  <div className="upload-text-container px-3 pb-2 mt-2">
                <span>Upload Attachment</span>
                <p>Would you like to...</p>
              </div>}
        {cam === false && (
          <>
            {" "}
            <div className="attach-modal">
            
              <div className="pointing">
                <label className="pointing">
                  {/* <ImFilePicture
                    style={{ fontSize: "25px", color: "darkgray" }}
                  /> */}
                  <img src={DropImg} />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handler}
                  />
                  {/* &nbsp; Choose a file */}
                </label>
                {/* &nbsp; Choose a file */}
              </div>
              <div className="OR">Or</div>
              <div onClick={showCamera} className="pointing">
                <label>
                  <img src={CaptureImg} className="pointing" />
                  {/* <TbCapture style={{ fontSize: "25px", color: "darkgray" }} className="pointing"/> */}
                </label>
                {/* &nbsp; Capture your photo */}
              </div>
              {img && (
                <>
                  {" "}
                  <img
                    src={img}
                    width={"100px"}
                    height={"100px"}
                    alt="screenshot"
                    id="filee"
                  />
                  <div className="d-flex justify-content-center mt-3">
                    {/* <Button onClick={() => setImg(null)}>Reset</Button> */}
                    <AiFillDelete style={{ color: "red" }} onClick={delteImg} />
                  </div>
                </>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <Input
                placeholder="Write caption here..."
                onChange={(e) => inputMessage(e)}
                value={message.text}
                allowClear={true}
                className="input-holderr"
              />
            </div>{" "}
            <div className="d-flex justify-content-end mt-2 gap-3 mb-2" style={{width: '500px'}}>
            <Button className="upload-cancel-btn">
                Cancel
              </Button>
              <Button onClick={onFinish} className="upload-upload-btn">
                Upload
              </Button>
            </div>
          </>
        )}
        {cam === true && (
          <>
            {img === null && (
              <>
                <Webcam
                  imageSmoothing={true}
                  width={480}
                  height={480}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                />
                <div className="d-flex justify-content-center gap-3">
                <Button onClick={(e) => showCam(false)} size="small" className="upload-cancel-btn">
                    Cancel{" "}
                  </Button>
                  <Button onClick={capture} size="small" className="upload-upload-btn">
                    Capture photo
                  </Button>
                
                </div>
              </>
              // ) : (
              //   <>
              //     <img src={img} width={100} height={100} alt="screenshot" />
              //     <div className="d-flex justify-content-center mt-3">
              //       <Button onClick={() => setImg(null)}>Retake</Button>
              //     </div>
              //   </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};
export default AttachmentModal;
