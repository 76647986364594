import React, { memo, useEffect, useState } from "react";
import { Button, Divider, Form, Input, message } from "antd";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";
import { Email } from "../../components";
import Zendesk from "react-zendesk";
import { ZendeskAPI } from "react-zendesk";
const ZENDESK_KEY = process.env.ZEN_KEY;
console.log("ZENDESK_KEY", ZENDESK_KEY);
ZendeskAPI("webWidget", "setLocale", "en");

const ContactForm = () => {
  window.zESettings = {
    webWidget: {
      contactForm: {
        
      }
    }
  };
  const setting = {
    color: {
      theme: "red"
    },
    launcher: {
      chatLabel: {
        "en-US": "Need Help"
      }
    },
    contactForm: {
      fields: [
        { id: "description", prefill: { "*": "My pre-filled description" } },
        { name: "name", prefill: { "*": "My pre-filled description" } },
        { email: "email", prefill: { "*": "My pre-filled description" } }
      ]
    }
  };
    const onFinish = async (values) => {
        console.log("Success:", values);

      };
    
      const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
      };

  return (
    <>
    <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} onLoaded={() => console.log('is loaded')} />
        <p className="main_head_reading ">Contact Us </p>
      <p className="para_reading my-3">
      Please fill out the below form and we will get back to you as soon as possible.
      </p>
   <Form
            name="basic"
            // labelCol={{
            //   span: 8,
            // }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="d-flex flex-column gap-3 w-100"
          >
            <div className="d-flex gap-2">
       <div className="w-50">
        <Form.Item
              name="firstName"
              // label="User Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name!",
                },
                {
                  message: "Minimum (3)",
                  min: 3,
                },
              ]}
            >
              <Input className="w-100 input_reading" placeholder="First Name" />
            </Form.Item>
        </div>  
        <div className="w-50">   
            <Form.Item
              name="lastName"
              // label="User Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your last name!",
                },
                {
                  message: "Minimum (3)",
                  min: 3,
                },
              ]}
            >
              <Input className="w-100 input_reading" placeholder="Last Name" />
            </Form.Item>
            </div>
            </div>
                   <Form.Item
              name="email"
              // label="User Name"
              rules={[
                {
                    // type: email,
                  required: true,
                  message: "Please enter your email!",
                },
              
              ]}
            >
              <Input className="w-100 input_reading" placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="username"
              // label="User Name"
              rules={[
                // {
                //   required: true,
                //   message: "Please enter your user name!",
                // },
                // {
                //   message: "Minimum (3)",
                //   min: 3,
                // },
              ]}
            >
              <Input className="w-100 input_reading" placeholder="Username (Optional)" />
            </Form.Item>
            <Form.Item
              name="message"
              // label="User Name"
            //   rules={[
            //     // {
            //     //   required: true,
            //     //   message: "Please enter your message!",
            //     // },
            //     {
            //       message: "Minimum (3)",
            //       min: 3,
            //     },
            //   ]}
            >
              <Input.TextArea className="w-100 input_reading" placeholder="Message" rows={5} />
            </Form.Item>
            <Form.Item
            //   wrapperCol={{
            //     offset: 0,
            //     span: 10,
            //   }}
            >
              <Button type="primary" htmlType="submit" className="submit_btnn w-100">
                Send
              </Button>
            </Form.Item>
          </Form>
    </>
  );
};

export default ContactForm;
