import { Input } from "antd";
import React, { useEffect, useState } from "react";
import "../index.css";

const TxtInput = ({ setMsg, setMessage, message, clearInput, setClearInput, setShowAttach, setShowAudio}) => {
  // const [message, setMessage] = useState({ text: "" });
  const inputMessage = (e) => {
    // setClearInput(false)
    e.persist();
    const {value} = e.target;
    console.log("message---", value);
    setMessage((prev) => {
      return { ...prev, text: value };
    });
    setMsg((prev) => {
      return { ...prev, text: value };
    })
    console.log("message", message);
    setShowAttach(false);
    setShowAudio(false);
  };
  return (
    <Input
      // onClick={e=> setClearInput(false)}
      className="txt-input"
      placeholder="Type here..."
      // value={clearInput === false ? message.text : ""}
      value={message.text}
      onChange={(e) => inputMessage(e)}
      allowClear={true}
    />
  );
};

export default TxtInput;
