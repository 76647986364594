import axios from "axios";
import React, { memo, useEffect } from "react";
import { Breadcrumb, Card } from "antd";
import { Container } from "react-bootstrap";
import { useState } from "react";
import { useParams } from "react-router-dom";

import "./index.css";
import GeneralLayout from "../../layout/GeneralLayout";
import ProfileHeader from "../../components/AdvisorProfile/ProfileHeader";
import AboutAdvisor from "../../components/AdvisorProfile/AboutAdvisor";
import BlackTagLine from "../../components/LandingPage/BlackTagLine";
import FixedHeaderProfile from "./FixedHeaderProfile";
import Chatpopup from "../../components/Chat/chatpopup";

const Index = () => {
  let { id } = useParams();
  console.log("id", id);
  const [listing, setListing] = useState(null);
  const [limit, setLimit] = useState(10);
  const [max, setMax] = useState();
  const [reviewsList, setReviewsList] = useState([]);
  useEffect(() => {
    localStorage.removeItem('search');
    getListing();
  }, []);

  const getListing = async () => {
    const obj = { auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj", id: id };
    const res = await axios.post(
      "https://staging.psychictxt.net/API/v19/Advisor_API/getAdvisorFullProfile",
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("res", Object.values(res.data.message));
    setListing(Object.values(res.data.message)?.[0]);
    const reviews_list = Object.values(res.data.message)[0]?.reviews?.length;
    setReviewsList(Object.values(res.data.message)[0]?.reviews);
    setMax(reviews_list);
    console.log("reviews_list", reviews_list);
  };
  console.log("listing", listing);

  const onLoadMore = () => {
    setLimit(limit + 10);
  };

  useEffect(() => {
    window.onscroll = () => {
      console.log("hello");
      window.addEventListener("scroll", isSticky);
      return () => {
        window.removeEventListener("scroll", isSticky);
      };
    };
  }, []);
  const isSticky = () => {
    const scrollTop = window.scrollY;
    console.log("scrollTop", scrollTop);
    if (scrollTop >= 1) {
      document.getElementById("navBarLayout").classList.add("fixedNav");
    } else {
      document.getElementById("navBarLayout").classList.remove("fixedNav");
    }
    if (scrollTop >= 188) {
      document
        .getElementById("main_profile_banner_content")
        .classList.add("invisible");
      document.getElementById("fixed").classList.remove("invisible");
      document.getElementById("fixed").classList.add("fixed");
    } else {
      document
        .getElementById("main_profile_banner_content")
        .classList.remove("invisible");
      document.getElementById("fixed").classList.add("invisible");
      document.getElementById("fixed").classList.remove("fixed");
    }
  };
  const [show, setShow] = useState(false);
  const [navInfo, setNavInfo] = useState(null);
  return (
    <>
      <GeneralLayout>
        <Container fluid style={{backgroundColor: '#FFFFFF'}}>
          <div className="line-draw"></div>
        <Container> 
          <Breadcrumb separator=">" className="breadcrumb-containerr py-2">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="/alladvisors">All Advisors</Breadcrumb.Item>
                  <Breadcrumb.Item>{listing?.username}</Breadcrumb.Item>
            </Breadcrumb>
            </Container> 
        </Container>
        <Container fluid className="p-0 m-0">
          <Container fluid className="invisible" id="fixed">
            <FixedHeaderProfile advisorDetails={listing} setShow={setShow}/>
          </Container>
          {listing !== null && <ProfileHeader advisorDetails={listing} />}
          <Container>
          {show === true && listing && (
        <Chatpopup
          setShow={setShow}
          advisor={listing}
          setNavInfo={setNavInfo}
        />
      )}
            {listing !== null && (
              <AboutAdvisor
                advisorDetails={listing}
                reviewsList={reviewsList}
              />
            )}
            <BlackTagLine />
          </Container>
        </Container>
      </GeneralLayout>
    </>
  );
};

export default memo(Index);
