import { Modal } from "antd";
import React from "react";
import LivePayment from "../views/LivePayment";
import Payment from "../views/Payment";
import "./index.css";

const PurchaseModalLive = ({isModalOpen, handleCancel, purchaseID, setIsModalOpen, selectedItem}) => {
  return (
    <>
     <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        width='500px'
        className="px-3"
        style={{ borderRadius: '16px'}}
      > 
     {purchaseID !== null && <LivePayment id={purchaseID} setIsModalOpen={setIsModalOpen} selectedItem={selectedItem} />}
      </Modal>
    </>
  );
};
export default PurchaseModalLive;
