import { Avatar, Input } from "antd";
import React, { memo } from "react";
import { Col, Row, Container, Form, Button } from "react-bootstrap";
import NavBar from "../NavBar";
import "./index.css";
import { FiSearch } from "react-icons/fi";
import Crystal  from "../../../images/crystal_ball.png";
import { useHistory } from "react-router-dom";

const { Search } = Input;

const Index = ({ setNavInfo, navInfo }) => {
  const onSearch = (value) => console.log(value);
  const history = useHistory();
  return (
    <>
      <Container className="d-flex justify-content-center align-items-center">
        <Row className="card-banner d-flex justify-content-center">
          <Col lg={2} md={2} sm={12} xs={12} className="starting">
            <div className="line-img-cont">
              <img src={Crystal} alt="image" />
            </div>
          </Col>
          <Col lg={7} md={7} sm={11} xs={11}>
            <p className="head-line">
              Didn’t find what you were looking for? See all of our finest
              advisors.
            </p>
            <p className="para-line">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque maximus volutpat lorem sit amet scelerisque. Cras
              faucibus aliquet pulvinar.
            </p>
          </Col>
          <Col lg={3} md={3} sm={12} xs={12} className="ending">
          <Button className="shadow-sm button-style btnn-b" onClick={()=>{
            localStorage.removeItem('search');
            history.push('/alladvisors');
          }}>View All Advisors</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default memo(Index);
