import { Button, Radio, Spin } from "antd";
import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FiSend, FiSmile } from "react-icons/fi";
import { getUpdateChatRoom } from "../../../api/chat";
import TxtInput from "./input";
import Attachment from "./attachment";
import "./index.css";

function Footer({
  msg,
  setMsg,
  sendMessage,
  setSelectedFile,
  selectedFile,
  setColor,
  color,
  setShowResult,
  showResult,
  clearInput,
  setClearInput,
  setReplyTime,
  showInput,
  setShowInput,
  setShowAttach,
  showAttach,
  setShowAudio,
  showAudio,
  isMsgSpin,
  setStopTimer,
  userProfilee,
  handlePauseTimer,
  handleResumeTimer,
  setPause,
  setResume,
  messageInput,
  setMessage,
  typingFun,
  advisorTyping,
  setClientResume,
  setAdvisorResume,
  advisorResume,
  openEmojiBox
}) {
  const [time, setTime] = useState("0");
  const [img, setImg] = useState(null);
  const [res, setRes] = useState("");
  // const [message, setMessage] = useState({ text: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [session, setSession] = useState("live");
  const [info, setShowInfo] = useState(false);
  // const [showInput, setShowInput] = useState(true);
  const delteVoice = () => {
    setShowResult(false);
    setRes("");
    setShowInput(true);
    setShowAttach(true);
    setShowAudio(true);
  };
  const handleClick = (e) => {
    console.log("reply", e.target.value);
    setReplyTime(e.target.value);
  };
  console.log("FOOTERMSGXX", msg);
  const submitMsg = () => {
    setStopTimer(true);
    // const sessionID = localStorage.getItem("livechatsessionID");
    // getUpdateChatRoom(sessionID, userProfilee?.id, "live_chat_start", setShowInfo);
    sendMessage(
      selectedFile.file !== null ? selectedFile.file : "",
      selectedFile.type,
      time,
      "",
      "",
      "",
      session
    );
    setImg(null);
    // setMessage({ text: "" })
  };
  console.log("advisorTyping", advisorTyping);
  console.log("MESSXXXXXYY", messageInput);
  return (
    <>
      {/* <div className="options"> */}
      {(advisorTyping !== null && advisorTyping?.isTyping === "1") ||
        (advisorTyping?.isTyping === true &&
          advisorTyping?.type === "advisor" && (
            <h6 style={{ fontSize: "0.5rem", backgroundColor: "transparent" }}>
              Advisor is typing...
            </h6>
          ))}
      {/* </div> */}
      {/* <div className="options">
        {isMsgSpin === true && (
          <span style={{ fontSize: "0.5rem" }}>Sending...</span>
        )}
        <h6>Reply to my question with: </h6>
        <Radio.Group
          size="small"
          buttonStyle="solid"
          variant="danger"
          onChange={handleClick}
          defaultValue="text"
          style={{
            color: "pink",
          }}
        >
          <Radio.Button value="text">Text</Radio.Button>
          <Radio.Button value="audio">Voice</Radio.Button>
        </Radio.Group>
      </div> */}
           {isModalOpen === true && (
        <div className="p-3" style={{ visibility: "hidden" }}>
          <Attachment
            setSelectedFile={setSelectedFile}
            color={color}
            setColor={setColor}
            showAttach={showAttach}
            setMsg={setMsg}
            setMessage={setMessage}
            message={messageInput}
            submitMsg={submitMsg}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setClearInput={setClearInput}
            clearInput={clearInput}
          />
        </div>
      )}
      <div className="live-chat-footer" >
        {isModalOpen === false && (
          <div className="live-chat-footer-div px-2" style={{ border: "1px solid #EAECF0" }}>
            <div className="live-chat-input">
              {showInput === true && (
                <TxtInput
                  setMsg={setMsg}
                  setMessage={setMessage}
                  message={messageInput}
                  clearInput={clearInput}
                  setClearInput={setClearInput}
                  setShowAttach={setShowAttach}
                  setShowAudio={setShowAudio}
                  typingFun={typingFun}
                />
              )}
            </div>
            <Attachment
              setSelectedFile={setSelectedFile}
              color={color}
              setColor={setColor}
              showAttach={showAttach}
              setMsg={setMsg}
              setMessage={setMessage}
              message={messageInput}
              submitMsg={submitMsg}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              setClearInput={setClearInput}
              clearInput={clearInput}
              handlePauseTimer={handlePauseTimer}
              handleResumeTimer={handleResumeTimer}
              setPause={setPause}
              setResume={setResume}
              setClientResume={setClientResume}
              setAdvisorResume={setAdvisorResume}
              advisorResume={advisorResume}
              img={img}
              setImg={setImg}
              msg={msg}
            />
                {isModalOpen === false && showInput === true && <div classname="icons">
              <FiSmile className="pointing" style={{fontSize: "25px", color: '#667085'}} onClick={()=> openEmojiBox()} />
              </div>}
            {isMsgSpin === true ? (
              <Spin style={{ fontSize: "22px", color: "#3A86F4" }} />
            ) : (
              // <FiSend
              //   className="pointing"
              //   style={{ fontSize: "22px", color: "#3A86F4" }}
              //   onClick={(e) => submitMsg()}
              // />
              <Button type="primary" onClick={(e) => submitMsg()}>
                Send
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Footer;
