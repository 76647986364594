import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { Provider } from "react-redux";
import myStore from "./config/store";

const pubnub = new PubNub({
  publishKey: "pub-c-3bedba69-11a8-4c67-b225-dcb3f3ddadab",
  subscribeKey: "sub-c-78762070-e783-11e6-8919-0619f8945a4f",
  chatChannel: "chat",
  applicationChannel: "channel_application",
  uuid: "MyUserID",
  restore: true,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={myStore}>
        <PubNubProvider client={pubnub}>
          <App />
        </PubNubProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
