import { message } from "antd";
import axios from "axios";
import { setAdvisorReviews, setMetaDataOfReview, setUserInfo } from "../redux/actions";
import {BASE_URL, VERSION} from "../config/constants"

export const getReviewMetadata = async (dispatch) =>{
    const obj = {
      auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/API/${VERSION}/Review_API/getReviewMetadata`,
        obj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("RES REVIEW METADATA: ", res);
      if(res.data.result === 1){
        dispatch(setMetaDataOfReview(res.data.message));
      }

    } catch (err) {
      console.log("err", err);
    }
}

export const createReview = async (values, advisorID, finded, msgId, setIsModalOpen, setRateVal, setmsgID, setEnableRate) => {
    const userInformation = localStorage.getItem("userInfo") || {};
    const user = JSON.parse(userInformation);
    const obj = {
      auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
      client_id: user?.id,
      advisor_id: advisorID,
      option_id: finded?.option_id,
      option_value: finded?.option_value,
      comment: values?.review,
      device_type: 'web',
      app_version: '7.3.4',
      clickable: values.clickables,
      question: values.questions,
      message_id: msgId,
    };
    console.log("obj", obj)
    try {
      const res = await axios.post(
        `${BASE_URL}/API/${VERSION}/Review_API/createReview`,
        obj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("RES Post review: ", res);
      if(res.data.result === 1){
        setRateVal(values?.rating)
        setmsgID(msgId)
        // dispatch(setMessages(credits));
        setEnableRate(false)
        setIsModalOpen(false)
        message.success("Reviewed posted successfully!")
      }else{
        message.error("Something went wrong. Try again later!")
      }

    } catch (err) {
      console.log("err", err);
    }
  };

  export const getReviewsForAdvisor = async (id, pageNo, setIsLoading, dispatch) =>{
    const obj = {
      auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
      advisor_id: id,
      page: pageNo,
    };
    try {
      const res = await axios.post(
        `${BASE_URL}/API/${VERSION}/Advisor_API/getReviews`,
        obj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("RES_REVIEWS: ", res);
      if(res.data.result === 1){
        // setIsLoading(false)
        dispatch(setAdvisorReviews(res.data.message));
      }

    } catch (err) {
      console.log("err", err);
    }
}
