import { Alert, Button, Card, Divider, Modal } from "antd";
import React, { memo, useEffect, useState } from "react";
import { DatePicker, Form, Input, Select } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { updateUserInfo } from "../api/client";
import { useDispatch } from "react-redux";
import SigninModal from "./signinModal";
import { useHistory, useParams } from "react-router-dom";
import { ImFilePicture } from "react-icons/im";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import "./index.css";
const { Option } = Select;
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';
const ReadingModal = ({ setModalReadOpen, modalReadOpen, setIsTitle, setModal2Open, setReturnToPoint, advisorId }) => {
  // const history = useHistory();
  const [isDisable, setIsDisable] = useState(true);
  // const [modal2Open, setModal2Open] = useState(false);
  // const [isTitle, setIsTitle] = useState(null);
  const history = useHistory();
  const {id} = useParams()
  const [img, setImg] = useState(null);
  var returnTo = '/'
  const onFinish = async (values) => {
    console.log("Success:", values);
    let msgRead = 'SITUATION: ' + values?.situation + ' ' + 'QUESTION: ' + values?.question;
    if(img !== null){
      const obj = {...values, advisorID: id, selectedFile: img, reading: msgRead}
      localStorage.setItem("reading", JSON.stringify(obj));
      setReturnToPoint(`/reading/${advisorId}`)
      setModal2Open(true);
      setIsTitle("Create an account!");
      setModal2Open(true);
      setModalReadOpen(false);
    }else{
      const obj = {...values, advisorID: id, reading: msgRead}
      localStorage.setItem("reading", JSON.stringify(obj));
      setReturnToPoint(`/reading/${advisorId}`)
      // setModal2Open(true);
      setIsTitle("Create an account!");
      setModal2Open(true);
      setModalReadOpen(false);
    }
    // localStorage.setItem("question", values?.question);
    // updateUserInfo(values, dispatch);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const userInformation = localStorage.getItem("emailForSignIn") || "";
    console.log("userInformation", userInformation);
  });
  const checkDob = (date, dateString) => {
    setIsDisable(false);
    console.log("Value", date, dateString);

    const date1 = new Date(dateString);
    const date2 = new Date();
    let yearsDiff = date2.getFullYear() - date1.getFullYear();
    console.log("yearsDiff", yearsDiff);
    if (yearsDiff >= 18) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  };
  const handler = async (e) => {
    console.log("fileee", e);
    var filename = e?.target?.files[0];
    const {name} = e?.target?.files[0];
    console.log("filename", name?.split('.')[0]);
    
   setImg({ file: URL.createObjectURL(e?.target?.files[0]), type: "photo" , fileName: name?.split('.')[0]});
  };
  useEffect(()=>{
    console.log('ch', img?.file);
  }, [])

  // console.log("type", '+'.concat((phone?.phone)?.slice(0,1))).concat('**** ***').concat((phone?.phone)?.slice(-3));
  return (
    <>
      <Modal
        // title="Sign up/Signin"
        // className={`"shadow main_modal_view"`}
        centered
        open={modalReadOpen}
        onCancel={()=> setModalReadOpen(false)}
        onOk={() => setModalReadOpen(false)}
        // closable={false}
        width={700}
        footer={null}
      >
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h5>Introduce yourself to your psychic. </h5>
            <Form
              name="basic"
              // labelCol={{
              //   span: 8,
              // }}
              // wrapperCol={{
              //   span: 16,
              // }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="d-flex flex-column align-items-center justify-content-around gap-3 py-5"
            >
              <Form.Item
                name="username"
                // label="User Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your user name!",
                  },
                  {
                    message: "Minimum (3)",
                    min: 3,
                  },
                ]}
              >
                <Input style={{width: '300px'}} placeholder="username" />
              </Form.Item>
              <Form.Item
                name="date"
                // label="Date of Birth"
                rules={[
                  {
                    required: true,
                    message: "Please input your date of birth!",
                  },
                ]}
                validateStatus={isDisable === false ? "error" : ""}
                help={
                  isDisable === false ? "User should be atleast 18 years" : ""
                }
                // style={{zIndex: '999999999999'}}
              >
                 <DatePicker format={dateFormat} onChange={checkDob}
                  placeholder={moment(new Date()).format("YYYY-MM-DD")} style={{width: '300px'}} />
                {/* <DatePicker
                  className="w-100"
                  onChange={checkDob}
                  placeholder={moment(new Date()).format("YYYY-MM-DD")}
                /> */}
              </Form.Item>
              <Form.Item
                name="gender"
                // label="Gender"
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Select
                  placeholder="Select gender"
                  // onChange={this.onGenderChange}
                  allowClear
                  style={{width: '300px'}}
                >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="situation"
                // label="Explain your situation"
                rules={[
                  {
                    required: true,
                    message: "Please enter your situation description!",
                  },
                ]}
              >
                <Input style={{width: '300px'}} placeholder="Explain your situation" />
              </Form.Item>
              <Form.Item
                name="question"
                // label="Ask Question"
                rules={[
                  {
                    required: true,
                    message: "Please ask your question!",
                  },
                ]}
              >
                <Input style={{width: '300px'}} placeholder="Ask Question" />
              </Form.Item>
              <div className="pointing">
                <label className="pointing">
                  <ImFilePicture
                    style={{ fontSize: "40px", color: "darkgray" }}
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handler}
                  />  &nbsp;<span>Choose a file</span> 
                </label>
                {/* &nbsp; Choose a file */}
              </div>
              {img !== null && img?.name}
              <Form.Item
                // wrapperCol={{
                //   offset: 6,
                //   span: 10,
                // }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
</div>
      </Modal>
    </>
  );
};
export default ReadingModal;
