import {
    Avatar,
    Button,
    Divider,
    Modal,
    notification,
    Rate,
    Spin,
    Tag,
  } from "antd";
  import axios from "axios";
  import Emoji from "./emoji";
  import React, { memo, useEffect, useState } from "react";
  import { Col, Row } from "react-bootstrap";
  import { AiFillVideoCamera, AiTwotoneSafetyCertificate } from "react-icons/ai";
  import { BiDotsVerticalRounded, BiPhoneCall } from "react-icons/bi";
  import moment from "moment";
  import InfiniteScroll from "react-infinite-scroll-component";
  import "./index.css";
  import MessageBox from "./messagebox";
  import ScrollableFeed from "react-scrollable-feed";
  import { usePubNub } from "pubnub-react";
  import clevertap from "clevertap-web-sdk";
  import S3FileUpload from "react-s3";
  import { updateUserCredits } from "../../../api";
  import { useDispatch, useSelector } from "react-redux";
  import { getClientInfo, getUserDetails } from "../../../api/client";
  import ReviewModal from "../../../modals/reviewModal";
  import UserDetailForm from "../../Reading/userDetailForm";
  import InfoDetailForm from "../../Chat/chatpopup/InfoDetailForm";
  import MessageDetailForm from "../../Chat/chatpopup/messageDetailForm";
  import { getAdvisorFullProfile } from "../../../api/advisor";
  import { SpaceContext } from "antd/lib/space";
  import { useHistory } from "react-router-dom";
  import { GoPrimitiveDot } from "react-icons/go";
  // import { getChatContent } from "../../../api/chat";
  
  const oneDay = 24 * 60 * 60 * 1000;
  const config = {
    bucketName: "mytarotadvisorvideos",
    region: "us-west-2",
    accessKeyId: "AKIAU7M7MBKJ2DEJLFP4",
    secretAccessKey: "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",
  };
  
  const Index = ({
    e,
    enableInfoMsg,
    setIsModalOpen3,
    rateVal2,
    idd,
    advisorLink,
    advisorOnlineStatus,
    enableRate,
    userObj,
    showModal,
    advisorDetails,
    advisorRefreshList,
    onlineStatusInbox
  }) => {
   
    return (
        <div key={e?.id}>
          {e?.msg_text !== "" &&
          (e.message_type === "text" || e.message_type === "rush_text" ||  e?.message_type === "live_text") ? (
            <div id="anchor" className="chatbody my-3">
              {e?.sender_type === "advisor" && (
                <div className="advisor_profile_dot">
                  <Avatar size={30} src={advisorLink ? advisorLink : ""} />
                  {onlineStatusInbox === '1' && (
                    // || advisorOnlineStatus === "1" 
                    <GoPrimitiveDot
                      className="advisor_profile_dot_content"
                      color="#12B76A"
                    />
                  )}
                </div>
              )}
              <div
                className={
                  e?.sender_type === "client" ? "chat-client" : "chatAd mt-1"
                }
              >
                {e?.sender_type === "client" ? (
                  <span className="time-client">
                    <span>You</span>
                    <span>{moment(e?.msg_date).format("dddd HH:MM A")}</span>
                  </span>
                ) : (
                  <span className="time2">
                    {moment(e?.msg_date).format("dddd HH:MM A")}
                  </span>
                )}
  
                <p
                  id={e?.id}
                  className={`mb-0  ${
                    e?.message_type === "notice" ? "notification-txt" : ""
                  }`}
                >
                  {e?.msg_text} &nbsp;
                </p>
              </div>
              <hr />
            </div>
          ) : e?.msg_text !== "" && e.message_type === "info" ? (
            <>
              {" "}
              <div className="d-flex justify-content-center">
                <span className="infoMsg w-100 text-center">Info Message</span>
              </div>{" "}
              <div id="anchor" className="chatbody my-3">
                {e?.sender_type === "advisor" && (
                  <div className="advisor_profile_dot">
                    <Avatar size={30} src={advisorLink ? advisorLink : ""} />
                    {onlineStatusInbox === '1' && (
                      <GoPrimitiveDot
                        className="advisor_profile_dot_content"
                        color="#12B76A"
                      />
                    )}
                  </div>
                )}
                <div
                  className={
                    e?.sender_type === "client"
                      ? "chat-client info-msg"
                      : "chatAd info-msg mt-1"
                    // e?.closing_reason !== null ?
                  }
                  // ref={chatContainer}
                >
                  {e?.sender_type === "client" ? (
                    <span className="time-client">
                      <span>You</span>
                      <span>{moment(e?.msg_date).format("dddd HH:MM A")}</span>
                    </span>
                  ) : (
                    <span className="time2">
                      {moment(e?.msg_date).format("dddd HH:MM A")}
                    </span>
                  )}
                  <p
                    id={e?.id}
                    className={`mb-0 ${
                      e?.message_type === "notice" ? "notification-txt" : ""
                    }`}
                  >
                    {e?.msg_text} &nbsp;
                  </p>
                  {e.status === "0" && enableInfoMsg === true && (
                    <span
                      className="infoMsg text-center w-100"
                      onClick={(e1) => setIsModalOpen3(true)}
                    >
                      Tap to provide Info
                    </span>
                  )}
                </div>
                <hr />
              </div>
            </>
          ) : e?.video_url !== "" &&
            (e?.message_type === "photo" || e?.message_type === "rush_photo" ||  e?.message_type === "live_photo") ? (
            <div id="anchor" className="chatbody my-3">
              {e?.sender_type === "advisor" && (
                <div className="advisor_profile_dot">
                  <Avatar size={30} src={advisorLink ? advisorLink : ""} />
                  {onlineStatusInbox === '1' && (
                    <GoPrimitiveDot
                      className="advisor_profile_dot_content"
                      color="#12B76A"
                    />
                  )}
                </div>
              )}
              <div
                className={
                  e?.sender_type === "client" ? "chat-client" : "chatAd"
                  // e?.closing_reason !== null ?
                }
              >
                {e?.sender_type === "client" ? (
                  <span className="time-client photo_width">
                    <span>You</span>
                    <span>{moment(e?.msg_date).format("dddd HH:MM A")}</span>
                  </span>
                ) : (
                  <span className="time2">
                    {moment(e?.msg_date).format("dddd HH:MM A")}
                  </span>
                )}
                <div
                  className={
                    e?.sender_type === "client"
                      ? "video-container"
                      : "video-container-ad"
                    // e?.closing_reason !== null ?
                  }
                >
                  {/* {console.log("TestImG", e?.id, e?.video_url)} */}
                  <img src={e?.video_url} alt="image" />
                  {/* <span style={{color: 'black'}}>{e?.msg_text}</span> */}
                </div>
                {e?.msg_text !== "" && (
                  <div id="anchor" className="chatbody my-2">
                    <div
                      className={
                        e?.sender_type === "client"
                          ? "chat-client"
                          : "chatAd mt-1"
                        // e?.closing_reason !== null ?
                      }
                      // ref={chatContainer}
                    >
                      <p
                        id={e?.id}
                        className={`mb-0  ${
                          e?.message_type === "text" ||
                          e?.message_type === "rush_text"
                            ? "notification-txt"
                            : ""
                        }`}
                      >
                        {e?.msg_text} &nbsp;
                      </p>
                    </div>
                    <hr />
                  </div>
                )}
              </div>
            </div>
          ) : e?.video_url !== "" &&
            (e?.message_type === "audio" || e?.message_type === "rush_audio") ? (
            <div
              className={`${e?.sender_type === "advisor" ? "d-flex gap-2" : ""}`}
            >
              {e?.sender_type === "advisor" && (
                <div className="advisor_profile_dot">
                  <Avatar size={30} src={advisorLink ? advisorLink : ""} />
                  {onlineStatusInbox === '1' && (
                    <GoPrimitiveDot
                      className="advisor_profile_dot_content"
                      color="#12B76A"
                    />
                  )}
                </div>
              )}
              <div
                className={
                  e?.sender_type === "client"
                    ? "audio-container"
                    : "audio-container-advisor"
                }
              >
                {e?.sender_type === "client" ? (
                  <span className="time-client audio_width">
                    <span>You</span>
                    <span>{moment(e?.msg_date).format("dddd HH:MM A")}</span>
                  </span>
                ) : (
                  <span className="time2">
                    {moment(e?.msg_date).format("dddd HH:MM A")}
                  </span>
                )}
                <audio controls preload="auto">
                  <source src={e?.video_url} id="src" type="audio/mpeg" />
                </audio>
              </div>
            </div>
          ) : e?.message_type === "live_chat_start" ? (
            <>
              <div className="d-flex justify-content-center my-2">
                <span className="start_session">
                  Live Chat Started
                </span>
              </div>
            </>
          ) : e?.message_type === "live_chat_pause" ||
            e?.message_type === "livechat_pause" ? (
            <>
              <div className="d-flex justify-content-center my-2">
                <span className="start_session">
                  {e?.msg_text
                    ? e.msg_text
                    : e?.display_message === ""
                    ? "chat paused by client"
                    : ""}
                </span>
              </div>
            </>
          ) : e?.message_type === "live_chat_resume" ||
            e?.message_type === "livechat_resume" ? (
            <>
              <div className="d-flex justify-content-center my-2">
                <span className="start_session">
                  {e?.msg_text
                    ? e.msg_text
                    : e?.display_message === ""
                    ? "chat resumed by client"
                    : ""}
                </span>
              </div>
            </>
          ) : e?.message_type === "livechat_continue" ||
            e?.message_type === "live_chat_continue" ? (
            <div className="d-flex justify-content-center my-2">
              <span className="start_session">
                {e?.msg_text ? e.msg_text : "Live chat continue"}
              </span>
            </div>
          ) : e?.message_type === "live_chat_summary" ? (
            <div id="anchor" className="chatbody my-2">
              <div
                className={
                  e?.sender_type === "client"
                    ? "chat-client"
                    : "chatAdd"
                  // e?.closing_reason !== null ?
                }
                // ref={chatContainer}
              >
                                             <p>
                                              <span>{
                    e?.msg_text
                      ?.split("Credits Used:")[0]
                      ?.split("Chat Duration:")[0]
                  }</span>
                                                <span
                  id={e?.id}
                  className={`mb-0 summary`}
                > 
                <Divider className="divide_layer"/>
                  <span className="d-flex align-items-center gap-3">
                  <div className="chat_duration">
                  <h4> Chat Duration:</h4> 
                    <h5 className="durationn">
                      {
                        e?.msg_text
                          ?.split("Credits Used:")[0]
                          ?.split("Chat Duration:")[1]
                      }
                    </h5>
                  </div>
                  <Divider type="vertical" className="vertical_divid" />
                  <div className="chat_duration">
                  <h4>  Credits Used:</h4>
                    <h5 className="creditss">
                      {e?.msg_text?.split("Credits Used:")[1]}
                    </h5>
                  </div>
                </span> 
                </span>
                  </p> 
              </div>
              <hr />
            </div>
          ) : e?.message_type === "livechat_ended" ||
          e?.message_type === "live_chat_ended" ? (
          <div className="d-flex justify-content-center">
            <span className="end_session">
              {e?.msg_text ? e.msg_text : "Live chat ended"}
            </span>
          </div>
        ) : (
            ""
          )}
          {rateVal2 !== null &&
          idd &&
          idd === e.id &&
          e?.sender_type !== "client" &&
          enableRate === false ? (
            <Rate value={rateVal2} style={{ fontSize: "1rem", marginLeft: '58px' }} disabled />
          ) : (
            <>
              {" "}
              {(e?.is_rateable === "1" || e?.is_rateable === 1) &&
                Math.round(
                  Math.abs((new Date() - new Date(e?.msg_date)) / oneDay)
                ) < 15 &&
                userObj?.has_made_inapp_purchase === "1" &&
                e?.sender_type === "advisor" &&
                e?.review_status === "0" &&
                e?.message_type !== "info" && (
                  <div className="rate_box mb-4">
                    <Rate count={1} color="#FEC33F" value={5} disabled />
                    <div className="rate_txt">
                      <span>Rate The Advisor</span>
                      <p>Hope you’d like the service and satisfaction answer.</p>
                    </div>
                    <Button
                      onClick={(e1) => showModal(e?.id)}
                      className="rate-txt"
                    >
                      Rate Now
                    </Button>
                  </div>
                )}
              {e?.review_status !== "0" && e?.review_status !== undefined && (
                <Rate
                  value={+e?.review_status}
                  style={{ fontSize: "1rem", marginLeft: '58px' }}
                  disabled
                />
              )}
            </>
          )}
        </div>
   
    );
  };
  
  export default memo(Index);
  