import { Avatar, Button, message, Modal, notification, Spin } from "antd";
import axios from "axios";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getChatContent,
  getUpdateChatRoom,
  liveChatLoopPoll,
} from "../../api/chat";
import { getClientInfo, getUserDetails } from "../../api/client";
import Content from "./Container/Content"; //
import Footer from "./Container/Footer";
// import Header from "./Header";
import S3FileUpload from "react-s3";
import { usePubNub } from "pubnub-react";
import "./index.css";
import moment from "moment";
import ChannelSubscribeCheck from "../../channelsubscribecheck";
import UserDetailForm from "../Reading/userDetailForm";
import MessageDetailForm from "../Chat/chatpopup/messageDetailForm";
import InfoDetailForm from "../Chat/chatpopup/InfoDetailForm";
import { useHistory } from "react-router-dom";
import PurchasecreditsModal from "../../modals/purchaseCreditsmodal";
import { GoPrimitiveDot } from "react-icons/go";
const config = {
  bucketName: "mytarotadvisorvideos",
  region: "us-west-2",
  accessKeyId: "AKIAU7M7MBKJ2DEJLFP4",
  secretAccessKey: "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",
};

const ChatContainer = ({
  advisor,
  setStopTimer,
  sendPubnubMsg,
  typingFun,
  endTypingFun,
  advisorTyping,
  clientResume,
  setClientResume,
  advisorResume,
  setAdvisorResume,
  setShowInformationMsg,
  continueChat,
  stopTimer,
  setTotalCredits,
  advisorOnlineStatus,
  advisorLink,
}) => {
  const pubnub = usePubNub();
  const [channels, setChannels] = useState("");
  const history = useHistory();
  const [chatArr, setChatArr] = useState([]);
  const [msg, setMsg] = useState({ text: "" });
  const [color, setColor] = useState("lightgray");
  const [showResult, setShowResult] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const [isSpin, setIsSpin] = useState(true);
  const [replyTime, setReplyTime] = useState("");
  const [orderType, setOrderType] = useState("");
  const [infoMsg, setInfoMsg] = useState("");
  const [userPopDetails, setUserPopDetails] = useState(null);
  const [userProfilee, setUserProfilee] = useState(null);
  const [showInput, setShowInput] = useState(true);
  const [showAttach, setShowAttach] = useState(true);
  const [showAudio, setShowAudio] = useState(true);
  const [enableInfoMsg, setEnableInfoMsg] = useState(true);
  const [enableRate, setEnableRate] = useState(true);
  const [info, setShowInfo] = useState(true);
  const [pause, setPause] = useState(false);
  const [resume, setResume] = useState(false);
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    type: "",
  });
  const [messageInput, setMessage] = useState({ text: "" });
  const [isMsgSpin, setIsMsgSpin] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  // test
  const [img, setImg] = useState("");
  const [infoo, setInfooMsg] = useState("");
  const [dur, setDur] = useState("");
  const [typ, setTyp] = useState("");
  // test
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [liveCredits, setLiveCredits] = useState(0);
  const [continueCredits, setContinueCredits] = useState(0);
  const [startContinueTimer, setStartContinueTimer] = useState(false);
  console.log("continueCredits", continueCredits);
  const [llc, setLCC] = useState(false);
  const [lc_c, setLC_C] = useState(false);
  const [startChat, setStartChat] = useState(false);
  const dispatch = useDispatch();
  const [continueTimer, setContinueTimer] = useState(40);
  const countpoolRef = useRef(null);
  // const [pushArray, setPushArray] = useState([])
  var pushArray = [];
  const countRef = useRef(null);
  const [timer, setTimer] = useState(0);
  const [showEmojis, setShowEmojis] = useState(false);

  const openEmojiBox = () => {
    setShowEmojis(true);
  };
  console.log("continueChat___Chkkkk, Container", continueChat);
  useEffect(() => {
    getUserDetails(dispatch);
  }, [userPopDetails]);

  const userInform = useSelector((state) => state.userInfo);
  const profile = useSelector((state) => state.profile);
  console.log("profile", profile);
  console.log("advisor----", advisor);
  // Test
  useEffect(() => {
    if (continueChat === "continue") {
      console.log("came from continue");
      const sessionID = localStorage.getItem("livechatsessionID");
      getUpdateChatRoom(
        sessionID,
        profile,
        "live_chat_continue",
        setShowInfo,
        history,
        dispatch
      );
    }
    if (continueChat === null && stopTimer === true && lc_c === false) {
      setLCC(true);
      countpoolRef.current = setInterval(() => {
        console.log("Calling_pool_loop!");
        const sessionID = localStorage.getItem("livechatsessionID");
        liveChatLoopPoll(
          sessionID,
          profile?.id,
          "live_chat_start",
          history,
          handleResetContinueTimer,
          advisor?.id,
          setStartContinueTimer
          // setStartContinueTimer,
        );
      }, 4000);
    }
  }, [continueChat]);

  useEffect(() => {
    if (lc_c === false && continueChat === "continue") {
      setLCC(true);
      setTimer(+localStorage.getItem("timerVal"));
      handleStartContinueTimer();
    }
  }, [lc_c]);

  useEffect(() => {
    if (startContinueTimer === true && lc_c === false) {
      // setLCC(true);
      handleStartTimer();
    }
  }, [startContinueTimer, lc_c]);
  // startContinueTimer
  // const contTimer = localStorage.getItem("purchasedLiveCredits");
  // test
  useEffect(() => {
    getChatContent(advisor, setChatArr, dispatch, setIsSpin);
  }, [advisor]);

  useEffect(() => {
    setUserProfilee(profile);
  }, [profile, userPopDetails]);

  useEffect(() => {
    // setIsUser(profile);
    if (profile?.free_seconds && profile?.paid_seconds) {
      const credit_val = +profile?.free_seconds + +profile?.paid_seconds;
      setLiveCredits(credit_val);
    } else {
      setLiveCredits(0);
    }
  }, [profile]);

  console.log("credit_val", liveCredits);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel4 = () => {
    setIsModalOpen4(false);
  };
  const chatMsgs = useSelector((x) => x.chatMsg);
  console.log("chatMsgs", chatMsgs);
  
  const handleStartContinueTimer = () => {
    localStorage.setItem("liveChatAdvisorID", advisor?.id);
    setShowInformationMsg(false);
    // setTimerVisible(true);
    // countpoolRef.current = setInterval(() => {
    //   setContinueTimer((continueTimer) => continueTimer - 1);
    // }, 1000);
    countpoolRef.current = setInterval(() => {
      console.log("Calling_pool_loop!");
      const sessionID = localStorage.getItem("livechatsessionID");
      liveChatLoopPoll(
        sessionID,
        profile?.id,
        "live_chat_continue",
        history,
        handleResetContinueTimer,
        advisor?.id,
        setContinueCredits
        // setStartContinueTimer,
      );
    }, 4000);
  };
  console.log("countpoolRef", countpoolRef);
  console.log("timer, --continueTimer--", continueTimer);
  const handleResetContinueTimer = () => {
    // clearInterval(countRef.current);
    console.log("Calling_pool_loop stop!");
    clearInterval(countpoolRef.current);
    // setContinueTimer(0);
  };
  useEffect(() => {
    if (
      msg.text === "" &&
      showAttach === false &&
      showAudio === false &&
      showInput === true
    ) {
      setShowAttach(true);
      setShowAudio(true);
    }
  });
  // console.log("MSGXXX", msg);

  useEffect(() => {
    if (chatMsgs?.auth_key !== "") {
      setChatArr((prev) => [
        ...prev,
        // chatMsgs
        {
          // client_deleted: chatMsgs?.client_deleted,
          client_dob: chatMsgs.client_dob ? chatMsgs.client_dob : "",
          client_gender: chatMsgs.client_gender ? chatMsgs.client_gender : "",
          // closing_reason: chatMsgs?.closing_reason,
          id: chatMsgs?.message_id,
          // is_closed: chatMsgs?.is_closed,
          is_rateable: chatMsgs?.is_rateable,
          message_review_id: chatMsgs?.message_review_id,
          message_type: chatMsgs?.message_type,
          msg_date: chatMsgs?.msg_date,
          msg_text: chatMsgs?.msg_text,
          receiver_displayname: chatMsgs?.receiver_displayname,
          receiver_id: chatMsgs?.receiver_id,
          receiver_type: chatMsgs?.receiver_type,
          response_time: chatMsgs?.response_time,
          review_status: chatMsgs?.review_status,
          sender_displayname: chatMsgs?.sender_displayname,
          sender_id: chatMsgs?.sender_id,
          sender_type: chatMsgs.sender_type,
          status: chatMsgs.status,
          video_url: chatMsgs.video_url,
        },
      ]);
    }
    // if (
    //   chatMsgs?.message_type === "livechat_missed" ||
    //   chatMsgs?.message_type === "live_chat_missed"
    // ) {
    //   history.push("/");
    // }
    if (
      chatMsgs?.message_type === "livechat_start" ||
      (chatMsgs?.message_type === "live_chat_start" &&
        timer === 0 &&
        continueChat === null &&
        llc === false)
    ) {
      // localStorage.setItem("liveChatAdvisorID", advisor?.id);
      // setShowInformationMsg(false);
      setLC_C(true);
      console.log("timer, check--start", continueCredits);
      handleStartTimer();
    }
    if (
      chatMsgs?.message_type === "livechat_ended" ||
      chatMsgs?.message_type === "live_chat_ended"
    ) {
      handleResetTimer();
      message.info("Live chat ended");
      history.push("/");
      localStorage.removeItem("livechatsessionID");
      localStorage.removeItem("purchasedLiveCredits");
      localStorage.removeItem("discountLiveCredits");
      localStorage.removeItem("continueCredits");
      localStorage.removeItem("timerVal");
    }
    if (
      chatMsgs?.message_type === "live_chat_pause" ||
      chatMsgs?.message_type === "livechat_pause"
    ) {
     
      clearInterval(countRef.current);
      if (
        chatMsgs?.sender_type === "advisor" ||
        chatMsgs?.msg_text === "Chat paused by advisor"
      ) {
        setAdvisorResume(true);
        setStartChat(true);
      }
      if (
        chatMsgs?.sender_type === "client" ||
        chatMsgs?.msg_text === "chat paused by client"
      ) {
        setClientResume(true);
        setStartChat(true);
      }
    }
    if (
      chatMsgs?.message_type === "live_chat_resume" ||
      chatMsgs?.message_type === "livechat_resume"
    ) {
      if (
        chatMsgs?.sender_type === "advisor" ||
        chatMsgs?.msg_text === "Chat resumed by advisor"
      ) {
        setAdvisorResume(false);
      }
      if (
        chatMsgs?.sender_type === "client" ||
        chatMsgs?.msg_text === "chat resumed by client"
      ) {
        setClientResume(false);
      }
      // countRef.current = setInterval(() => {
      //   setTimer((timer) => timer + 1);
      // }, 1000);
    }
    if (chatMsgs?.message_type === "livechat_continue" && llc === false) {
      setLC_C(true);
      localStorage.setItem("liveChatAdvisorID", advisor?.id);
      setShowInformationMsg(false);
      setTimer(+localStorage.getItem("timerVal"));
      localStorage.setItem("continueCredits", chatMsgs.paid_seconds);
      setContinueCredits((prev) => prev + chatMsgs.paid_seconds);
      // setStartContinueTimer(true)
      // handleStartTimer();
    }
  
  }, [chatMsgs]);
  console.log("lcc_lc_c", llc, lc_c);
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const addEmoji = (e) => {
    let emoji = e.emoji;
    setMessage((prev) => {
      console.log("prev", prev);
      return {
        // ...prev,
        // text: e.emoji,
        text: prev.text + emoji,
      };
    });
    setMsg((prev) => {
      console.log("prev", prev);
      return { text: prev.text + emoji };
    });
  };

  const sendMessage = async (
    image,
    type,
    dura,
    infomsg,
    orderTyp,
    repTime,
    session
  ) => {
    // pubnub.publish({
    //   channel: ['Channel_Advisor_'.concat(advisor?.id)],
    //   message: "Hello World!",
    // });
    // if (orderType !== "") {
    endTypingFun();
    setIsMsgSpin(true);
    // }
    console.log("git img and type", image, type);
    console.log("repTime", repTime, replyTime);
    setImg(image);
    setTyp(type);
    setDur(dura);
    setInfooMsg(infomsg);
    if (type === "") {
      type = "text";
    }
    if (infomsg) {
      type = "info";
    }
    console.log("type", type);
    console.log("dura", dura);
    var imgObj = "";
    var secondObject = image;
    const userInformation = localStorage.getItem("userInfo") || {};
    const objectuser = JSON.parse(userInformation);
    console.log("userProfilee", userProfilee);
    if (
      // (
      userProfilee &&
      userProfilee?.username === ""
      // ||
      // userProfilee?.dob === "" || userProfilee?.gender === '')
    ) {
      console.log("--CHKKKK---", userProfilee);
      setIsModalOpen(true);
    } else {
      setIsMsgSpin(true);
      // return;
      var ids = objectuser?.id
        .concat("-to-")
        .concat(advisor.id)
        .concat("/")
        .concat(moment(new Date()).format("YYYY-MM-DD"))
        .concat("-")
        .concat(moment(new Date()).format("HH-MM-SS"));
      var name = "audio"
        .concat("/")
        .concat(ids)
        .concat("~")
        .concat(dura?.split(":0", 3)[2])
        .concat(".mp3");
      var file = new File([image?.audioData], name, { type: image.type });
      console.log("last", file);
      console.log("userInform", userInform);
      const credits = +userProfilee?.free_credit + +userProfilee?.paid_credit;
      const seconds = +userProfilee?.free_seconds + +userProfilee?.paid_seconds;
      if (credits !== 0 || seconds !== 0) {
        console.log("msg chk---", msg.text);

        if ((msg.text !== null && msg.text !== "") || image !== "" || infomsg) {
          //test
          if (orderType === "") {
            setOrderType("text");
          }
          // setIsModalOpen2(true);
          console.log("OrderType: -> ", orderType);
          if (type === "audio") {
            secondObject = file;
            console.log("secondObject", secondObject);
          }
          if (type === "audio" || type === "photo") {
            await S3FileUpload.uploadFile(secondObject, config)
              .then((data) => {
                console.log("-------UPLOADED------");
                console.log("location", data.location);
                imgObj = data.location;
              })
              .catch((err) => console.error(err));
          }
          console.log("replyTime", replyTime);
          const obj = {
            auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
            msg_date: new Date(),
            msg_text:
              messageInput.text !== ""
                ? messageInput.text
                : msg.text
                ? msg.text
                : "",
            receiver_displayname: advisor?.username,
            receiver_id: advisor?.id,
            receiver_type: "advisor",
            sender_displayname: userProfilee?.username
              ? userProfilee.username
              : userPopDetails?.username,
            sender_id: userProfilee?.id,
            sender_type: "client",
            App_Version: "7.2",
            Device_Type: "web",
            client_dob: userPopDetails?.date
              ? moment(userPopDetails.date).format("YYYY-MM-DD")
              : userProfilee?.dob,
            client_gender:
              userProfilee?.gender !== ""
                ? userProfilee.gender
                : userPopDetails?.gender,
            // client_message_review_id: "",
            email_notification_enabled: 1,
            message_review_id: "w_".concat(makeid(21)),
            response_time: session === "live" ? "live" : "",
            review_status: 0,
            status: session === "live" ? 1 : 0,
            video_url: imgObj !== "" ? imgObj : "",
            message_type: session === "live" ? "live_".concat(type) : type,
            order_type: session === "live" ? "live_".concat(type) : type,
          };
          console.log("new api obj", obj);
          sendPubnubMsg(obj);
          try {
            const res = await axios.post(
              "https://staging.psychictxt.net/API/v19/Pubnub/saveChatPayload",
              obj,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            console.log("response Send msg ----", res);
            setIsMsgSpin(false);
            if (res?.data?.result === -1) {
              notification.open({
                message: "Update!",
                description: `${res.data.message}`,
                duration: 3,
              });
            } else {
              setChatArr((prev) => [
                ...prev,
                {
                  msg_text:
                    messageInput.text !== ""
                      ? messageInput.text
                      : msg.text
                      ? msg.text
                      : "",
                  msg_date: new Date(),
                  sender_type: "client",
                  video_url: imgObj ? imgObj : "",
                  message_type:
                    session === "live" ? "live_".concat(type) : type,
                  status: 0,
                },
              ]);
              setMsg({ text: "" });
              setClearInput(true);
              setShowResult(false);
              setShowInput(true);
              setSelectedFile({
                file: null,
                type: "",
              });
              setColor("lightgray");
              // handleEventPushClick();
              setMessage({ text: "" });
            }
          } catch (err) {
            console.log("err", err);
          }
        } else {
          notification.open({
            message: "Notification!",
            description: "Please enter a message first.",
            duration: 3,
          });
        }
        //end
      } else {
        notification.open({
          message: "Credits!",
          description: "Please update credits.",
          duration: 3,
        });
      }
    }
  };

  const handleStartTimer = () => {
    localStorage.setItem("liveChatAdvisorID", advisor?.id);
    setShowInformationMsg(false);
    // if(llc === false || lc_c === false){
    console.log("timer, check-- startTimer", continueCredits);
    countRef.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
    // }
  };

  const handlePauseTimer = () => {
    // clearInterval(countRef.current);
    const sessionID = localStorage.getItem("livechatsessionID");
    getUpdateChatRoom(sessionID, profile, "live_chat_pause", setShowInfo);
  };
  const handleResumeTimer = () => {
    const sessionID = localStorage.getItem("livechatsessionID");
    getUpdateChatRoom(sessionID, profile, "live_chat_resume", setShowInfo);
    // countRef.current = setInterval(() => {
    //   setTimer((timer) => timer + 1);
    // }, 1000);
  };
  const handleResetTimer = () => {
    localStorage.removeItem("liveChatAdvisorID");
    clearInterval(countRef.current);
    setTimer(0);
  };

  useEffect(() => {
    if (
      clientResume === false &&
      advisorResume === false &&
      startChat === true
    ) {
      countRef.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    }
  }, [clientResume, advisorResume, startChat]);
  console.log(
    "client, Advisor, startChat",
    clientResume,
    advisorResume,
    startChat
  );
  // console.log("continueChat", continueChat);

  useEffect(() => {
    if (
      continueCredits > 0 &&
      continueCredits !== 0 &&
      timer === +localStorage.getItem("timerVal")
    ) {
      handleResetContinueTimer();
      // localStorage.setItem("liveChatAdvisorID", advisor?.id);
      // setShowInformationMsg(false);
      // if((llc === false && lc_c !== false) || (llc !== false && lc_c === false) ){
      console.log("timer, check-- contcredits", continueCredits);
      handleStartTimer();
      // }
    }
  }, [continueCredits]);

  useEffect(() => {
    if (
      llc === false &&
      lc_c === false &&
      localStorage.getItem("continueCredits") !== undefined &&
      continueChat === "continue"
    ) {
      console.log("called here!");
      if (continueCredits === 0) {
        setContinueCredits(+localStorage.getItem("continueCredits"));
      }
      // handleStartTimer();
    }
  }, [llc, lc_c]);

  console.log("continueCredits--", continueCredits);

  useEffect(() => {
    // liveCredits
    var timerr = timer;
    setMinutes(Math.floor(timerr / 60));
    setSeconds(timerr - minutes * 60);
    if (timerr >= liveCredits && timer !== 0 && continueChat === null) {
      clearInterval(countRef.current);
      localStorage.setItem("timerVal", timer);
      // setTimer(0);
      setIsModalOpen4(true);
    }
    var old_credits = localStorage.getItem("timerVal");
    // localStorage.getItem("purchasedLiveCredits")
    if (
      continueCredits !== 0 &&
      old_credits !== undefined &&
      timerr >= +old_credits + continueCredits &&
      timer !== 0 &&
      continueChat === "continue"
    ) {
      // if(localStorage.getItem('continueCredits') !== undefined && timerr >= (+old_credits + +localStorage.getItem('continueCredits'))){
      //   clearInterval(countRef.current);
      //   setIsModalOpen4(true);
      // }
      clearInterval(countRef.current);
      setIsModalOpen4(true);
    }
  }, [timer]);

  console.log(
    "timer, totalCredits",
    +localStorage.getItem("timerVal") + continueCredits
  );
  const EndChat = () => {
    // history.push('/inbox')
    handleResetTimer();
    const sessionID = localStorage.getItem("livechatsessionID");
    getUpdateChatRoom(
      sessionID,
      profile,
      "live_chat_end",
      setShowInfo,
      history,
      dispatch,
      advisor
    );
  };
  useEffect(() => {
    if (
      localStorage.getItem("timerVal") !== undefined &&
      continueCredits !== 0 &&
      continueChat === "continue"
    ) {
      setTotalCredits(+localStorage.getItem("timerVal") + continueCredits);
    }
  }, [continueChat, continueCredits]);

  console.log("timer, credits--- ", timer, continueCredits);

  return (
    <>
      <div className="live-chat-container shadow-sm">
        <div className="row head-boxs mx-0">
          <div className="col-lg-6 first">
            {/* <div className="avatar">
            <Avatar size={42} />
          </div> */}
            <div className="avatar advisor_profile_dot">
              <Avatar
                size={42}
                className="pointing"
                src={advisor?.s3_display_picture}
                onClick={() => history.push(`/advisors/${advisor?.id}`)}
              />
              {advisorOnlineStatus === "1" && (
                <GoPrimitiveDot
                  className="advisor_profile_dot_header"
                  color="#12B76A"
                />
              )}
            </div>
            <div>
              <p className="mb-0 pb-0">{advisor?.first_name}</p>
              {advisorOnlineStatus === "1" && <span>Online</span>}
            </div>
          </div>
          <div className="col-lg-6 second d-flex align-items-center gap-2">
            <h6 style={{ color: "black" }} className="m-0">
              {Math.floor(timer / 60) < 10
                ? "0" + Math.floor(timer / 60)
                : Math.floor(timer / 60)}{" "}
              :{" "}
              {timer - Math.floor(timer / 60) * 60 < 10
                ? "0" + (timer - Math.floor(timer / 60) * 60)
                : timer - Math.floor(timer / 60) * 60}
            </h6>
            <Button onClick={() => EndChat()} className="btn_txt_end">
              End Live Chat
            </Button>
          </div>
        </div>
        {/* <div className="live-chat-header">
          <h6>
            {Math.floor(timer / 60) < 10 ? "0" + Math.floor(timer / 60) : Math.floor(timer / 60)} :{" "}
            {(timer - (Math.floor(timer / 60)) * 60) < 10 ? "0" + (timer - (Math.floor(timer / 60)) * 60) : (timer - (Math.floor(timer / 60)) * 60)}
          </h6>
        </div> */}
        {advisor !== null && (
          <Content
            advisor={advisor}
            setChatArr={setChatArr}
            chatArr={chatArr}
            isSpin={isSpin}
            setIsModalOpen3={setIsModalOpen3}
            userProfilee={userProfilee}
            enableInfoMsg={enableInfoMsg}
            enableRate={enableRate}
            setEnableRate={setEnableRate}
            advisorOnlineStatus={advisorOnlineStatus}
            advisorLink={advisorLink}
            showEmojis={showEmojis}
            setShowEmojis={setShowEmojis}
            addEmoji={addEmoji}
          />
        )}
        <Footer
          msg={msg}
          setMsg={setMsg}
          sendMessage={sendMessage}
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
          setColor={setColor}
          color={color}
          setShowResult={setShowResult}
          showResult={showResult}
          clearInput={clearInput}
          setClearInput={setClearInput}
          setReplyTime={setReplyTime}
          showInput={showInput}
          setShowInput={setShowInput}
          setShowAttach={setShowAttach}
          showAttach={showAttach}
          setShowAudio={setShowAudio}
          showAudio={showAudio}
          isMsgSpin={isMsgSpin}
          setStopTimer={setStopTimer}
          userProfilee={userProfilee}
          handlePauseTimer={handlePauseTimer}
          handleResumeTimer={handleResumeTimer}
          setPause={setPause}
          setResume={setResume}
          messageInput={messageInput}
          setMessage={setMessage}
          typingFun={typingFun}
          advisorTyping={advisorTyping}
          setClientResume={setClientResume}
          setAdvisorResume={setAdvisorResume}
          advisorResume={advisorResume}
          openEmojiBox={openEmojiBox}
          // addEmoji={addEmoji}
        />
      </div>
      <Modal
        title="User Details"
        open={isModalOpen} // 
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <UserDetailForm
          setUserPopDetails={setUserPopDetails}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>

      <Modal
        title="Message Details"
        open={isModalOpen2}
        // onOk={handleOk}
        onCancel={handleCancel2}
        footer={null}
      >
        <MessageDetailForm
          setOrderType={setOrderType}
          setIsModalOpen2={setIsModalOpen2}
          img={img}
          infoo={infoo}
          dur={dur}
          typ={typ}
          replyTime={replyTime}
          sendMessage={sendMessage}
        />
      </Modal>

      <Modal
        title="Info Message"
        open={isModalOpen3}
        // onOk={handleOk}
        onCancel={handleCancel3}
        footer={null}
      >
        <InfoDetailForm
          setInfoMsg={setInfoMsg}
          sendMessage={sendMessage}
          setIsModalOpen3={setIsModalOpen3}
          setEnableInfoMsg={setEnableInfoMsg}
        />
      </Modal>
      {isModalOpen4 === true && (
        <Modal
          title="Purchase Credits"
          open={isModalOpen4}
          // onOk={handleOk}
          onCancel={handleCancel4}
          footer={null}
          maskClosable={false}
        >
          <PurchasecreditsModal
            setIsModalOpen4={setIsModalOpen4}
            profile={profile}
            setShowInfo={setShowInfo}
          />
        </Modal>
      )}
    </>
  );
};

export default ChatContainer;
