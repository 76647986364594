import { usePubNub } from "pubnub-react";
import React, { memo, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ChatContainer from "./ChatContainer";
import ChatSidebar from "./ChatSidebar";
import NavBarInbox from "../../components/Chat/NavBarInbox";
import "./index.css";

const Index = ({
  advisor,
  sendPubnubMsg,
  typingFun,
  endTypingFun,
  advisorTyping,
  continueChat,
  setAdvisorName,
  setAdvisorOnlineStatus,
  setAdvisorLink,
  setAdvisorID,
  advisorOnlineStatus,
  advisorLink,
  advisorRefreshList,
}) => {
  const [stopTimer, setStopTimer] = useState(false);
  const [val, setVal] = useState(false);
  const [clientResume, setClientResume] = useState(false);
  const [advisorResume, setAdvisorResume] = useState(false);
  const [showInformationMsg, setShowInformationMsg] = useState(true);
  const [totalCredits, setTotalCredits] = useState(null);
  return (
    <>
      <Container fluid className="p-0 live_chat_cont">
        <NavBarInbox />
        <Container className="live_chat_main_cont">
          <Row className="live-chat-main-container">
            <Col lg={3} className="sidebar shadow-sm px-3">
              {advisor !== null && (
                <ChatSidebar
                  advisor={advisor}
                  stopTimer={stopTimer}
                  showInformationMsg={showInformationMsg}
                  continueChat={continueChat}
                  setTotalCredits={setTotalCredits}
                  totalCredits={totalCredits}
                  setAdvisorName={setAdvisorName}
                  setAdvisorOnlineStatus={setAdvisorOnlineStatus}
                  setAdvisorLink={setAdvisorLink}
                  setAdvisorID={setAdvisorID}
                   advisorRefreshList={advisorRefreshList}
                />
              )}
            </Col>
            <Col lg={8}>
              {advisor !== null && (
                <ChatContainer
                  advisor={advisor}
                  setStopTimer={setStopTimer}
                  sendPubnubMsg={sendPubnubMsg}
                  typingFun={typingFun}
                  endTypingFun={endTypingFun}
                  advisorTyping={advisorTyping}
                  setClientResume={setClientResume}
                  clientResume={clientResume}
                  advisorResume={advisorResume}
                  setAdvisorResume={setAdvisorResume}
                  setShowInformationMsg={setShowInformationMsg}
                  continueChat={continueChat}
                  stopTimer={stopTimer}
                  setTotalCredits={setTotalCredits}
                  advisorOnlineStatus={advisorOnlineStatus}
                  advisorLink={advisorLink}
                  advisorRefreshList={advisorRefreshList}
                />
              )}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
export default memo(Index);
