import React, { memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Line from "../../../images/line.png";
import "./index.css";
import Cards from "./cards";
import { BsArrowRight } from "react-icons/bs";

const Index = ({ setNavInfo, goTolistingpage }) => {
  return (
    <>
      <Container className=" mt-3">
        <Row>
          <Col lg={12} className="main-heading">
          <div className="line-advisor"></div>
            <h4 className="pri-color">Explore Advisor By Categories</h4>
          </Col>
          <Col lg={12} className="main-content mt-3">
            <Row className="d-flex justify-content-center">
              <Col lg={10} md={10} sm={10} xs={10}>
                <p className="font-text">
                  Get a psychic reading from one of today’s most popular, most
                  trailblazing psychics. As they are sought after for clarity
                  they provide, they are quickly rising to the top. Ask your
                  question now – from your love life to your career, family
                  life, and more.
                </p>
              </Col>
              <Col
                lg={2}
                className="d-flex justify-content-end gap-2"
                style={{ cursor: "pointer" }}
                onClick={()=> goTolistingpage()}
              >
                <p style={{ color: "#3A86F4" }}>View All</p>
                <div className="d-flex pt-1">
                  <BsArrowRight style={{ color: "#3A86F4" }} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="main-crousal pt-4">
        <Row className="main-cont">
          <Cards />
        </Row>
      </Container>
    </>
  );
};

export default memo(Index);
