import React, { memo } from "react";
import { Modal } from "antd";
import "./index.css";

const Index = ({ advisorDetails, handleCancel, showModal }) => {
  return (
    <>
      <Modal title={false} open={showModal} onCancel={handleCancel} footer={false}>
     <video width='100%' height="400" controls loop="true" autoplay="true" muted>
      <source src={advisorDetails?.s3_intro_video} type="video/mp4"/>
     </video>
      </Modal>
    </>
  );
};

export default memo(Index);
