// #F9FAFB
import React, { memo, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import Logo from "../../images/logo.png";

import { BsTwitter, BsFacebook } from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../api/client";

const Index = () => {
  const dispatch = useDispatch();
  const [isUser, setIsUser] = useState(null);
  useEffect(() => {
    getUserDetails(dispatch);
  }, [dispatch]);

  const profile = useSelector((state) => state.profile);
  useEffect(() => {
    setIsUser(profile);
  }, [profile]);
  return (
    <>
      <Container className="footer-main w-100 ">
        <Row className="footer-items">
          <Col lg={4} xs={4} className="main-1">
            <div className="main-1-1">
              <img alt="logo" src={Logo} />
              <p className="main-sub-heading">Psychic Txt</p>
            </div>
            <div className="main-para-footer">
              {" "}
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque maximus volutpat lorem sit amet scelerisque.
              </p>
            </div>
          </Col>
          <Col lg={4} xs={4} className="main-2">
            {/* <div> */}
            <p className="main-sub-heading pt-2">Psychics</p>
            <div className="main-para-footer">
              {" "}
              <p>
                <a href="#">All Advisors</a>
              </p>
              <p>
                <a href="#">Trending Advisors</a>
              </p>
              <p>
                <a href="#">Psychic Readings</a>
              </p>
              <p>
                <a href="#">Tarot Readings</a>
              </p>
              <p>
                <a href="#">Palm Readings</a>
              </p>
              <p>
                <a href="#">Angle insights</a>
              </p>
              <p>
                <a href="#">Astrology and Horoscopes</a>
              </p>
              <p>
                <a href="#">Dream Analysis</a>
              </p>
              <p>
                <a href="#">Oracle guidance</a>
              </p>
            </div>
            {/* </div> */}
          </Col>
          <Col lg={4} xs={4} className="main-3 pt-2">
            <p className="main-sub-heading">Other Menu</p>
            <div className="main-para-footer">
              <p>
                <a href="https://live.psychictxt.net/index.php?/pages/horoscope">
                  Horoscope
                </a>
              </p>
              {isUser !== null && (
                <p>
                  <a
                    href={`https://talkpok.web.app/?user_type=client&user_id=${isUser?.id}&auth_key=f8IY8reK3lpTBxJv1ew3Gl08jfM7ajPWFzslUKiaygvMD2Q`}
                  >
                    Blog
                  </a>
                </p>
              )}
              <p>
                <a href="#">About Us</a>
              </p>
              <p>
                <a href="/contact-us">Contact Us</a>
              </p>
              <p>
                <a href="https://live.psychictxt.net/pages/pp">
                  Privacy Policy
                </a>
              </p>
              <p>
                <a href="https://live.psychictxt.net/pages/tos">
                  Terms and Conditions
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="ending-footer">
        <Container>
          <Row className="ending-footer-sub">
            <Col lg={6} sm={8} xs={8} className="ending-footer-sub-1">
              <p>© 2022 Psychic txt. All rights reserved.</p>
            </Col>
            <Col lg={6} sm={4} xs={4} className="ending-footer-sub-2">
              <div className="ending-footer-sub-2-1">
                <BsTwitter className="style-icon" />
                <FaLinkedin className="style-icon" />
                <BsFacebook className="style-icon" />
                <GrInstagram className="style-icon" />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
export default memo(Index);
