import React, { memo, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Rate,
  Select,
} from "antd";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { getClientInfo, updateUserInfo } from "../../../api/client";
import { useDispatch, useSelector } from "react-redux";
import SigninModal from "../../../modals/signinModal";
import { useHistory, useParams } from "react-router-dom";
import { ImFilePicture } from "react-icons/im";
import LiveChatModal from "../../../modals/liveChatModal";
import { getAdvisorFullProfile } from "../../../api/advisor";
import Text from "../../../images/txt.svg";
import "./index.css";
import DropdownChat from "./dropdownChat";
const { Option } = Select;

const Index = ({
  setIsModalOpen,
  setPurchaseID,
  setSelectedItem,
  selectedItem,
  advisorDetails,
  setIsVisible,
  isVisible,
}) => {
  const dispatch = useDispatch();
  const [isDisable, setIsDisable] = useState(true);
  const [modal2Open, setModal2Open] = useState(false);
  const [isTitle, setIsTitle] = useState(null);
  const [reset, setReset] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [img, setImg] = useState(null);
  const [proff, setProff] = useState(null);
  const [userProff, setUserProff] = useState(null);
  const [isModalLiveOpen, setIsModalLiveOpen] = useState(false);
  const [hidee, setHidee] = useState(false);
  const [payButton, showPayButton] = useState(false);
  var returnTo = `/livereading/${id}`;

  const handleLiveModalCancel = () => {
    setModal2Open(false);
    setIsModalLiveOpen(false);
  };
  useEffect(() => {
    getAdvisorFullProfile(id, dispatch);
  }, [id]);
  const advisorProfile = useSelector((x) => x.advisorProfile);
  console.log("advisorProfile", advisorProfile);

  useEffect(() => {
    setProff(advisorProfile);
  }, [advisorProfile]);

  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      getClientInfo(dispatch);
    }
  }, [reset]);
  const userInfor = useSelector((x) => x.userInfo);
  console.log("userInfor", userInfor);

  useEffect(() => {
    setUserProff(userInfor);
  }, [userInfor]);

  const onFinish = async (values) => {
    console.log("Success:", values);

    //test
    if (localStorage.getItem("livereading")) {
      if (
        localStorage.getItem("userInfo") !== undefined &&
        localStorage.getItem("userInfo") !== null
      ) {
        setIsModalOpen(true);
      } else {
        // history.push('/signup');
        setIsTitle("Create an account!");
        setModal2Open(true);
      }
    } else {
      console.log("Success:", values);
      const obj = { ...values, advisorID: id };
      localStorage.setItem("livereading", JSON.stringify(obj));
      setIsTitle("Create an account!");
      setModal2Open(true);
    }
  };
  const payStart = () => {
    setReset(!reset);
    localStorage.setItem("livereading", id);
    if (
      localStorage.getItem("userInfo") !== undefined &&
      localStorage.getItem("userInfo") !== null
    ) {
      if (userProff?.free_seconds && userProff?.paid_seconds) {
        const credit_val = +userProff?.free_seconds + +userProff?.paid_seconds;
        const calcul = ((credit_val * 2) / 60).toFixed(2);
        // if (calcul >= proff?.live_rate * 2) {
        //   setIsModalLiveOpen(true);
        // } else {
        //   if (selectedItem === null && calcul <= proff?.live_rate * 2) {
        //     message.info("Please choose credits first!");
        //   }
        //   setIsModalOpen(true);
        // }
        if (selectedItem?.price <= calcul) {
          console.log("checkNow---", calcul, selectedItem?.price);
          setIsModalLiveOpen(true);
        } else {
          // if (selectedItem === null && calcul <= proff?.live_rate * 2) {
          //   message.info("Please choose credits first!");
          // }
          setIsModalOpen(true);
        }
      }
    } else {
      // history.push('/signup');
      setIsTitle("Create an account!");
      setModal2Open(true);
    }
  };

  useEffect(()=>{
    if(userProff && selectedItem && ((((+userProff?.free_seconds + +userProff?.paid_seconds)* 2)/60).toFixed(2)) !== NaN){
      if(selectedItem?.price <= ((((+userProff?.free_seconds + +userProff?.paid_seconds)* 2)/60).toFixed(2))){
        showPayButton(true);
      }else{
        showPayButton(false);
      }
    }
  }, [selectedItem, userProff])

  console.log("selectedItem,  MAIN", selectedItem);
  console.log("userProff", userProff);
  console.log("selectedItem, testt---", ((((+userProff?.free_seconds + +userProff?.paid_seconds)* 2)/60).toFixed(2)));
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const userInformation = localStorage.getItem("emailForSignIn") || "";
    console.log("userInformation", userInformation);
  });
  const checkDob = (date, dateString) => {
    setIsDisable(false);
    console.log("Value", date, dateString);

    const date1 = new Date(dateString);
    const date2 = new Date();
    let yearsDiff = date2.getFullYear() - date1.getFullYear();
    console.log("yearsDiff", yearsDiff);
    if (yearsDiff >= 18) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  };

  const checkCreditsToStart = () => {
    setReset(!reset);
    localStorage.setItem("livereading", id);
    if (
      localStorage.getItem("userInfo") !== undefined &&
      localStorage.getItem("userInfo") !== null
    ) {
      if (userProff?.free_seconds && userProff?.paid_seconds) {
        const credit_val = +userProff?.free_seconds + +userProff?.paid_seconds;
        const calcul = ((credit_val * 2) / 60).toFixed(2);
        if (selectedItem?.price <= calcul) {
          console.log("checkNow---", calcul, selectedItem?.price);
          setIsModalLiveOpen(true);
        } else {
          setIsModalOpen(true);
        }
      }
    } else {
      // history.push('/signup');
      // localStorage.setItem("dropdown-credits", JSON.stringify(selectedItem));
      setIsTitle("Create an account!");
      setModal2Open(true);
    }
    // localStorage.setItem("livereading", id);
    // const credit_val = +userProff?.free_seconds + +userProff?.paid_seconds;
    // const calcul = ((credit_val * 2) / 60).toFixed(2);
    // if (calcul >= userProff?.live_rate * 2) {
    //   setIsModalLiveOpen(true);
    // } else {
    //   if (
    //     localStorage.getItem("userInfo") !== undefined &&
    //     localStorage.getItem("userInfo") !== null
    //   ) {
    //     setIsModalOpen(true);
    //   } else {

    //     setIsTitle("Create an account!");
    //     setModal2Open(true);
    //   }

    // }
  };
  console.log("advisorDetails", advisorDetails);
  // const remove = () =>{
  //   setHidee(true);

  //   if(hidee === true){
  //         setIsVisible(false);
  //       }
  // }
  // useEffect(()=>{
  //   document.addEventListener('click', remove);
  // }, [isVisible])
  // console.log("hideee", hidee, isVisible);
  useEffect(() => {
    if (localStorage.getItem("userInfo")) {
      payStart();
    }
  }, []);
  useEffect(()=>{
    if(isModalLiveOpen === true && proff !== null){
      setIsModalLiveOpen(true);
    }
  }, [isModalLiveOpen, proff])
  return (
    <>
      {/* {purchaseID !== null && <PurchaseModal isModalOpen={isModalOpen} handleCancel={handleCancels} purchaseID={purchaseID} setIsModalOpen={setIsModalOpen} />} */}
      <SigninModal
        returnTo={returnTo}
        isTitle={isTitle}
        setModal2Open={setModal2Open}
        modal2Open={modal2Open}
        setIsModalLiveOpen={setIsModalLiveOpen}
        setIsModalOpen={setIsModalOpen}
        proff={proff}
      />
      {isModalLiveOpen === true && (
        <LiveChatModal
          isModalOpen={isModalLiveOpen}
          handleCancel={handleLiveModalCancel}
          livechatAdvisorInfo={proff}
          // liveChatSessionMsg={liveChatSessionMsg}
        />
      )}
      <div
        className="live_chat_box_advisor shadow-sm "
        onClick={() => {
          if (isVisible === true) {
            setIsVisible(false);
          }
        }}
      >
        <div>
          <Avatar size={64} src={advisorDetails?.s3_display_picture} />
        </div>
        <div className="d-flex flex-column align-items-start">
          <p className="part2_name">{advisorDetails?.username}</p>
          <p className="part2_para">{advisorDetails?.short_description}</p>
          <div className="d-flex gap-2">
            <p className="rating_txt mb-0">
              {parseInt(advisorDetails?.ratings).toFixed(1)} Rating
            </p>
            <Rate
              value={parseInt(advisorDetails?.ratings).toFixed(1)}
              disabled={true}
              style={{ fontSize: "15px" }}
            />
          </div>
        </div>
        <div className="d-flex flex-column">
          <img src={Text} style={{ height: "20px", width: "20px" }} />
          <div className="part2_liveChat">
            <span>Live Chat</span>
            <p>{advisorDetails?.live_rate} Cr/min</p>
          </div>
        </div>
      </div>
      <br />
      <p className="main_head_reading_live ">Select Chat Time </p>
      <br />
      <DropdownChat
        setPurchaseID={setPurchaseID}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        setIsModalOpen={setIsModalOpen}
        setIsVisible={setIsVisible}
        isVisible={isVisible}
        setHidee={setHidee}
        hidee={hidee}
        proff={proff}
      />
      <br />
      <Row className="d-flex flex-column gap-4 px-3 mt-2">
       {payButton === false && <Button className="payButton" onClick={() => payStart()}>
          Continue to Pay
        </Button>}
        <Button className="startChatBtn" onClick={() => checkCreditsToStart()}>
          Start Live Chat
        </Button>
      </Row>
      {/* ) : (
        <Row className="d-flex justify-content-center align-items-center">
          <Col lg={12}>
            <Form
              name="basic"
              // labelCol={{
              //   span: 8,
              // }}
              wrapperCol={{
                span: 22,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="d-flex flex-column gap-3 w-100"
            >
              <Form.Item
                name="username"
                // label="User Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your user name!",
                  },
                  {
                    message: "Minimum (3)",
                    min: 3,
                  },
                ]}
              >
                <Input className="w-100 input_reading" placeholder="Username" />
              </Form.Item>
              <div className=" d-flex gap-1" style={{ width: "96%" }}>
                <Form.Item
                  name="date"
                  // label="Date of Birth"
                  rules={[
                    {
                      required: true,
                      message: "Please input your date of birth!",
                    },
                  ]}
                  validateStatus={isDisable === false ? "error" : ""}
                  help={
                    isDisable === false ? "User should be atleast 18 years" : ""
                  }
                  className="w-50"
                >
                  <DatePicker
                    className="w-100 input_reading"
                    onChange={checkDob}
                    placeholder="Date of Birth"
                    // moment(new Date()).format("YYYY-MM-DD")
                  />
                </Form.Item>
                <Form.Item
                  name="gender"
               
                  className="w-50"
                >
                  <Select
                    placeholder="Gender"
                    // onChange={this.onGenderChange}
                    allowClear
                    className="input_reading input_color"
                  >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Form.Item>
              </div>
              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 10,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit_btnn"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )} */}
    </>
  );
};

export default memo(Index);
