import { Alert, Breadcrumb } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { NavBar } from "../../components";
import ReadingForm from "../../components/Reading/ReadingForm";
import BlackTagLine from '../../components/LandingPage/BlackTagLine';

import "./index.css";
import Footer from "../../components/Footer";
import AdvisorDetails from "../../components/Reading/AdvisorDetails";
import { useParams } from "react-router-dom";
import { getAdvisorFullProfile } from "../../api/advisor";
import { useDispatch, useSelector } from "react-redux";
import TopMyBalanceModal from "../../modals/TopMyBalance";

const Index = () => {
  const {id} = useParams();
  const dispatch = useDispatch();
  const [advisorDetails, setAdvisorDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleCancel =() =>{
    setIsModalOpen(false)
  }
  useEffect(() => {
    getAdvisorFullProfile(id, dispatch);
  }, [id]);

  const advisorProfile = useSelector((x) => x.advisorProfile);
  console.log("advisorProfile", advisorProfile);

  useEffect(() => {
    setAdvisorDetails(advisorProfile);
  }, [advisorProfile]);
  return (
    <>
     <NavBar />
     <Container fluid style={{backgroundColor: '#FFFFFF'}} className="shadow-sm">
          <div className="line-draw"></div>
        <Container> 
          <Breadcrumb separator=">" className="breadcrumb-containerr py-2">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href={`/advisors/${id}`}>{advisorDetails?.username}</Breadcrumb.Item>
                  <Breadcrumb.Item href={`/reading/${id}`}>{'Text Reading'}</Breadcrumb.Item>
            </Breadcrumb>
            </Container> 
            <div className="line-draw"></div>
        </Container>
      <Container fluid className="m-0 p-0">
        <Container>
          <TopMyBalanceModal handleCancel={handleCancel} isModalOpen={isModalOpen} />
        <Row className=" py-4">
          <Col lg={6}>
            <ReadingForm setIsModalOpen={setIsModalOpen} />
          </Col>
          <Col lg={6}>
            <AdvisorDetails id={id} />
          </Col>
        </Row>
        </Container>
     
      </Container>
      <BlackTagLine />
      <Footer/>
    </>
  );
};

export default memo(Index);
