import { message, Spin } from "antd";
import axios from "axios";
import { BASE_URL, VERSION } from "../config/constants";
import { setChatContentClient, setCreateLiveChatRoom } from "../redux/actions";

export const getChatContent = async (
  advisor,
  setChatArr,
  dispatch,
  setIsSpin, page, setCount, setHasData
) => {
  console.log("TEST--", advisor.id);
  const userInformation = localStorage.getItem("userInfo") || {};
  const objectuser = JSON.parse(userInformation);
  const obj = {
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    user_a: objectuser.id, //141360, //
    user_b: advisor.id ? advisor.id : advisor,
    page: (page !== undefined && page !==0 && page !== null) ? page : 0,
    type: "",
  };
  console.log("TEST--", obj);

  try {
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Message_API/getChatBody_v3`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    // console.log("res chk----", res);
    // console.log("res ----", res?.data?.message);
    console.log("check_msg_response", res);
    if(page !== undefined && page !==0 && page !== null){
      if(res.data.message.length !== 0){
        // setChatArr(prev => prev.concat(res?.data?.message));
        setChatArr(prev => prev.concat(res?.data?.message));
        setCount(prev => prev + 10);
        setIsSpin(false);
      }else{
        console.log("page, chkkk");
        setHasData(false);
        setCount(prev => prev - 10)
        setIsSpin(false);
        
      }
    }else{
      if (res?.data?.message?.length === 0) {
        setIsSpin(false);
      } else {
        // setChatArr([].concat(res?.data?.message));
        setChatArr([].concat(res?.data?.message));
        // arr = res?.data?.message.sort(function (a, b) {
        //   var dateA = new Date(a.date_prop).getTime();
        //   var dateB = new Date(b.date_prop).getTime();
        //   return dateA < dateB ? 1 : -1; // ? -1 : 1 for ascending/increasing order
        // });
        setIsSpin(false);
      }
    }
    // dispatch(setChatContentClient(res?.data?.message))


    // setChatBody(res?.data?.message);
    //   setChatBody(chatBody.concat(res?.data?.message).reverse());
    //   setChatArr(chatArr.concat(res?.data?.message).reverse())
    //   // , () => scrollToMyRef()
    //   if (res.data.message.length === 0) {
    //     setHasData(false);
    //   }
  } catch (err) {
    console.log("err", err);
  }
};

export const getCreateChatRoom = async (advisorID, userID, setShowInfo, setChannel, handleStartTimer, history,  dispatch) => {
  const obj = {
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    advisor_id: advisorID, //141360, //
    client_id: userID,
    user_id: userID,
    user_type: "client",
    discount: 0,
  };
  console.log("obj--", obj);

  try {
    const res = await axios.post(
      `${BASE_URL}/API/v23/Live_Session_API/createChatRoom`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("response--------------", res);
    console.log("step_1: Check response of create room");
    if (res?.data?.result === "1") {
      // handleStartTimer();
      dispatch(setCreateLiveChatRoom(res?.data));
      setChannel(res?.data?.session_id);
      localStorage.setItem("livechatsessionID", res?.data?.session_id)
      // history.push(`/livechat/${res?.data?.session_id}`);
    }
    if (res?.data?.result === "0") {
      message.error(res?.data?.message);
      setShowInfo(false);
      // history.push(`/livechat/${res?.data?.session_id}`);
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getUpdateChatRoom = async (sessionID, userID, activity, setShowInfo, history,  dispatch, advisor) => {
  const obj = {
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    session_id: sessionID,
    activity: activity,
    type: 'client',
    extended_credits: activity === 'live_chat_continue' ? localStorage.getItem("purchasedLiveCredits") : 0,
    discount: activity === 'live_chat_continue' ? localStorage.getItem('discountLiveCredits') : 0,
    bonus_credits: 0,
    client_id: userID?.id,
    user_id: userID?.id,
    user_type: "client",
  };
  console.log("obj--", obj);

  try {
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Live_Session_API/updateChatRoom`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("responsen_update", res, activity);
    if (res?.data?.result === "1") {
      if(activity !== 'live_chat_discard'){
        setShowInfo(false);
      }
      if(activity === 'live_chat_discard'){
        history.push('/');
      }
      if(activity === 'live_chat_end'){
        history.push(`/chat/${advisor?.id}`)
      }
      if(activity === 'live_chat_accept'){
        history.push(`/livechat/${advisor?.id}`);
      }
     
      // dispatch(setCreateLiveChatRoom(res?.data));
      // history.push(`/livechat/${res?.data?.session_id}`);
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const liveChatLoopPoll = async (sessionID, userID, activity,  history, handleResetTimer, advisorID, setContinueCredits) => {
  const obj = {
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    session_id: sessionID,
    activity: activity,
    user_id: userID,
    user_type: "client",
    type: 'client',
  };
  console.log("Payload_continue", obj);

  try {
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Live_Session_API/liveChatLoopPoll`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("response_pool_api", res);
    console.log("Step_2: checking live_chat_accept pool res", res);
    if (res?.data?.result === "1") {
    console.log("activityy", res?.data?.message);
    if(res?.data?.message?.activity_exist === '1' && activity === 'live_chat_accept'){
      handleResetTimer();
      history.push(`/livechat/${advisorID}`);
    }
    if(res?.data?.message?.activity_exist === '1' && activity === 'live_chat_start'){
      handleResetTimer();
      setContinueCredits(true);
    }
    if(res?.data?.message && activity === 'live_chat_continue'){
      handleResetTimer();
      localStorage.setItem('continueCredits', +res?.data?.message?.paid_seconds);
      setContinueCredits(prev => prev + +res?.data?.message?.paid_seconds);
      // setStartContinueTimer(true);
      // res?.data?.message?.paid_seconds
      // history.push(`/livechat/${advisorID}`)
    }
    // activity_exist
    }
  } catch (err) {
    console.log("err", err);
  }
};
export  const getOneChatHead = async (history) => {
  const userInformation = localStorage.getItem("userInfo") || {};
  const objectuser = JSON.parse(userInformation);
  const obj = {
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    id: objectuser.id, // 141360, //
    type: "client",
    page: 0,
    limit: 1,
  };
  console.log("obj--", obj);
  try {
    const res = await axios.post(
      "https://staging.psychictxt.net/API/v19/Message_API/getChatHead_web",
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("NavBarHeadRes", res?.data?.message);
    if (res?.data?.message[0]?.sender_type === "advisor") {
      history.push(`/chat/${res?.data?.message[0]?.sender_id}`);
    }
    if (res?.data?.message[0]?.receiver_type === "advisor") {
      history.push(`/chat/${res?.data?.message[0]?.receiver_id}`);
    }
  } catch (err) {
    console.log("err", err);
  }
};



