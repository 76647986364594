import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFeed from "react-scrollable-feed";
import { getChatContent } from "../../../api/chat";
import moment from "moment";
import ReviewModal from "../../../modals/reviewModal";
import { Avatar, Divider, Rate, Skeleton, Spin } from "antd";
import { usePubNub } from "pubnub-react";
import "./index.css";
import { GoPrimitiveDot } from "react-icons/go";
import Emoji from "../../Inbox/ChatContent/emoji";

const Content = ({
  advisor,
  setChatArr,
  chatArr,
  isSpin,
  setIsModalOpen3,
  userProfilee,
  enableInfoMsg,
  enableRate,
  setEnableRate,
  advisorOnlineStatus,
  advisorLink,
  showEmojis,
  setShowEmojis,
  addEmoji,
}) => {
  const oneDay = 24 * 60 * 60 * 1000;
  console.log("chatarr--", chatArr);
  const pubnub = usePubNub();
  const [userObj, setUserObj] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [msgId, setMsgId] = useState(null);
  const [channels, setChannels] = useState("");
  const [rateVal, setRateVal] = useState(null);
  const [idd, setmsgID] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(10);
  const [isspin, setIsSpin] = useState();
  console.log("hasData, loading", hasData, loading);
  useEffect(() => {
    if (userProfilee) {
      setUserObj(userProfilee);
    }
  }, [userProfilee]);
  const showModal = (id) => {
    setMsgId(id);
    setIsModalOpen(true);
  };
  console.log("msg id", msgId);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setPage((prev) => prev + 1);
    setLoading(true);
  };
  // useEffect(() => {
  //   loadMoreData();
  // }, []);
  useEffect(() => {
    if (page !== 0) {
      getChatContent(
        advisor,
        setChatArr,
        dispatch,
        setLoading,
        page,
        setCount,
        setHasData
      );
    }
  }, [page]);
  return (
    <div className="live-chat-content shadow-sm">
      {hasData === true ? (
        <>
          {loading === false ? (
            <p className="loadmoreData" onClick={(e) => loadMoreData()}>
              load more
            </p>
          ) : (
            <div className="d-flex justify-content-center">
              {" "}
              <Skeleton active />
            </div>
          )}
        </>
      ) : (
        <Divider plain>no more messages 🤐</Divider>
      )}
      {isSpin === true ? (
        <div className="spinner">
          <Skeleton active />
        </div>
      ) : (
        <>
          {" "}
          {chatArr.length !== 0 ? (
            <>
              <ScrollableFeed>
                {chatArr
                  ?.sort(function (a, b) {
                    var dateA = new Date(a.msg_date).getTime();
                    var dateB = new Date(b.msg_date).getTime();
                    return dateA > dateB ? 1 : -1; // ? -1 : 1 for ascending/increasing order
                  })
                  ?.map((e) => {
                    // console.log("testt", e.video_url.split('~')[0]);
                    return (
                      <>
                        {e?.message_type === "live_chat_start" ? (
                          <>
                            <div className="d-flex justify-content-center my-2">
                              <span className="start_session">
                                Live Chat Started
                              </span>
                            </div>
                          </>
                        ) : e?.message_type === "live_chat_pause" ||
                          e?.message_type === "livechat_pause" ? (
                          <>
                            <div className="d-flex justify-content-center my-2">
                              <span className="start_session">
                                {e?.msg_text
                                  ? e.msg_text
                                  : e?.display_message === ""
                                  ? "chat paused by client"
                                  : ""}
                              </span>
                            </div>
                          </>
                        ) : e?.message_type === "live_chat_resume" ||
                          e?.message_type === "livechat_resume" ? (
                          <>
                            <div className="d-flex justify-content-center my-2">
                              <span className="start_session">
                                {e?.msg_text
                                  ? e.msg_text
                                  : e?.display_message === ""
                                  ? "chat resumed by client"
                                  : ""}
                              </span>
                            </div>
                          </>
                        ) : e?.message_type === "livechat_continue" ||
                          e?.message_type === "live_chat_continue" ? (
                          <div className="d-flex justify-content-center my-2">
                            <span className="start_session">
                              {e?.msg_text ? e.msg_text : "Live chat continue"}
                            </span>
                          </div>
                        ) : e?.message_type === "live_chat_summary" ? (
                          <div id="anchor" className="chatbody my-2">
                            <div
                              className={
                                e?.sender_type === "client"
                                  ? "chat-client"
                                  : "chatAdd"
                                // e?.closing_reason !== null ?
                              }
                              // ref={chatContainer}
                            >
                                                           <p>
                                                            <span>{
                                  e?.msg_text
                                    ?.split("Credits Used:")[0]
                                    ?.split("Chat Duration:")[0]
                                }</span>
                                                              <span
                                id={e?.id}
                                className={`mb-0 summary`}
                              > 
                              <Divider className="divide_layer"/>
                                <span className="d-flex align-items-center gap-3">
                                <div className="chat_duration">
                                <h4> Chat Duration:</h4> 
                                  <h5 className="durationn">
                                    {
                                      e?.msg_text
                                        ?.split("Credits Used:")[0]
                                        ?.split("Chat Duration:")[1]
                                    }
                                  </h5>
                                </div>
                                <Divider type="vertical" className="vertical_divid" />
                                <div className="chat_duration">
                                <h4>  Credits Used:</h4>
                                  <h5 className="creditss">
                                    {e?.msg_text?.split("Credits Used:")[1]}
                                  </h5>
                                </div>
                              </span> 
                              </span>
                                </p> 
                            </div>
                            <hr />
                          </div>
                        ) : e?.message_type === "notice" ? (
                          <div id="anchor" className="chatbody my-2">
                            <div
                              className={
                                e?.sender_type === "client"
                                  ? "chat-client"
                                  : "chatAd"
                                // e?.closing_reason !== null ?
                              }
                              // ref={chatContainer}
                            >
                              <p id={e?.id} className={`mb-0 shadow-sm`}>
                                {e?.msg_text} &nbsp;
                              </p>
                            </div>
                            <hr />
                          </div>
                        ) : e?.msg_text !== "" &&
                          (e?.message_type === "text" ||
                            e?.message_type === "rush_text" ||
                            e?.message_type === "live_text") ? (
                          <div id="anchor" className="chatbody my-2">
                            {e?.sender_type === "advisor" && (
                              <div className="advisor_profile_dot">
                                <Avatar
                                  size={30}
                                  src={advisorLink ? advisorLink : ""}
                                />
                                {advisorOnlineStatus === "1" && (
                                  <GoPrimitiveDot
                                    className="advisor_profile_dot_content"
                                    color="#12B76A"
                                  />
                                )}
                              </div>
                            )}
                            <div
                              className={
                                e?.sender_type === "client"
                                  ? "chat-client"
                                  : "chatAd"
                                // e?.closing_reason !== null ?
                              }
                              // ref={chatContainer}
                            >
                              {e?.sender_type === "client" ? (
                                <span className="time-client">
                                  <span>You</span>
                                  <span>
                                    {moment(e?.msg_date).format("dddd HH:MM A")}
                                  </span>
                                </span>
                              ) : (
                                <span className="time2">
                                  {moment(e?.msg_date).format("dddd HH:MM A")}
                                </span>
                              )}
                              <p
                                id={e?.id}
                                className={`mb-0 shadow-sm  ${
                                  e?.message_type === "notice"
                                    ? "notification-txt"
                                    : ""
                                }`}
                              >
                                {e?.msg_text} &nbsp;
                              </p>
                            </div>
                            <hr />
                          </div>
                        ) : e?.msg_text !== "" && e?.message_type === "info" ? (
                          <>
                            <div className="d-flex justify-content-center">
                              <span className="info infoMsg">Info Message</span>
                            </div>
                            <div id="anchor" className="chatbody">
                              {e?.sender_type === "advisor" && (
                                <div className="advisor_profile_dot">
                                  <Avatar
                                    size={30}
                                    src={advisorLink ? advisorLink : ""}
                                  />
                                  {advisorOnlineStatus === "1" && (
                                    <GoPrimitiveDot
                                      className="advisor_profile_dot_content"
                                      color="#12B76A"
                                    />
                                  )}
                                </div>
                              )}
                              <div
                                className={
                                  e?.sender_type === "client"
                                    ? "chat-client info-msg"
                                    : "chatAd info-msg"
                                  // e?.closing_reason !== null ?
                                }
                                // ref={chatContainer}
                              >
                                {/* <div className="d-flex justify-content-center">
                              <span className="info">Info Message</span>
                            </div> */}
                                {e?.sender_type === "client" ? (
                                  <span className="time-client">
                                    <span>You</span>
                                    <span>
                                      {moment(e?.msg_date).format(
                                        "dddd HH:MM A"
                                      )}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="time2">
                                    {moment(e?.msg_date).format("dddd HH:MM A")}
                                  </span>
                                )}
                                <p
                                  id={e?.id}
                                  className={`mb-0 shadow-sm  ${
                                    e?.message_type === "notice"
                                      ? "notification-txt"
                                      : ""
                                  }`}
                                >
                                  {e?.msg_text} &nbsp;
                                  {/* <span
                                    className={
                                      e?.sender_type === "client"
                                        ? "time-client"
                                        : "time2"
                                    }
                                  >
                                    {moment(e?.msg_date).format("HH:MM")}
                                  </span> */}
                                </p>
                                {e.status === "0" && enableInfoMsg === true && (
                                  <span
                                    className="info infoMsg text-center mb-2"
                                    onClick={(e1) => setIsModalOpen3(true)}
                                  >
                                    Tap to provide Info
                                  </span>
                                )}
                              </div>
                              <hr />
                            </div>
                          </>
                        ) : e?.video_url !== "" &&
                          (e?.message_type === "photo" ||
                            e?.message_type === "rush_photo" ||
                            e?.message_type === "live_photo") ? (
                          <div id="anchor" className="chatbody my-2">
                            <div
                              className={
                                e?.sender_type === "client"
                                  ? "chat-client"
                                  : "chatAd"
                                // e?.closing_reason !== null ?
                              }
                            >
                              {e?.sender_type === "client" ? (
                                <span className="time-client photo_width">
                                  <span>You</span>
                                  <span>
                                    {moment(e?.msg_date).format("dddd HH:MM A")}
                                  </span>
                                </span>
                              ) : (
                                <span className="time2">
                                  {moment(e?.msg_date).format("dddd HH:MM A")}
                                </span>
                              )}
                              <div
                                className={
                                  e?.sender_type === "client"
                                    ? "video-container"
                                    : "video-container-ad"
                                  // e?.closing_reason !== null ?
                                }
                              >
                                <img
                                  src={e?.video_url?.split("~")[0]}
                                  alt="image"
                                />
                              </div>
                              {e?.msg_text !== "" && (
                                <div id="anchor" className="chatbody">
                                  <div
                                    className={
                                      e?.sender_type === "client"
                                        ? "chat-client"
                                        : "chatAd mt-1"
                                      // e?.closing_reason !== null ?
                                    }
                                    // ref={chatContainer}
                                  >
                                    <p
                                      id={e?.id}
                                      className={`mb-0  ${
                                        e?.message_type === "text" ||
                                        e?.message_type === "rush_text"
                                          ? "notification-txt"
                                          : ""
                                      }`}
                                    >
                                      {e?.msg_text} &nbsp;
                                    </p>
                                  </div>
                                  <hr />
                                </div>
                              )}
                            </div>
                          </div>
                        ) : e?.video_url !== "" &&
                          (e?.message_type === "audio" ||
                            e?.message_type === "rush_audio") ? (
                          <div
                            className={`${
                              e?.sender_type === "advisor" ? "d-flex gap-2" : ""
                            }`}
                          >
                            {e?.sender_type === "advisor" && (
                              <div className="advisor_profile_dot">
                                <Avatar
                                  size={30}
                                  src={advisorLink ? advisorLink : ""}
                                />
                                {advisorOnlineStatus === "1" && (
                                  <GoPrimitiveDot
                                    className="advisor_profile_dot_content"
                                    color="#12B76A"
                                  />
                                )}
                              </div>
                            )}
                            <div
                              className={
                                e?.sender_type === "client"
                                  ? "audio-container"
                                  : "audio-container-advisor"
                              }
                            >
                              {e?.sender_type === "client" ? (
                                <span className="time-client audio_width">
                                  <span>You</span>
                                  <span>
                                    {moment(e?.msg_date).format("dddd HH:MM A")}
                                  </span>
                                </span>
                              ) : (
                                <span className="time2">
                                  {moment(e?.msg_date).format("dddd HH:MM A")}
                                </span>
                              )}
                              <audio controls preload="auto">
                                <source src={e?.video_url} id="src" />
                              </audio>
                              {/* 
                              <span className="time-client pe-2">
                                <span className="duration">
                                  {e.video_url?.split("~")[1].split(".")[0]} sec
                                </span>
                                <span>
                                  {" "}
                                  {moment(e?.msg_date).format("HH:MM")}
                                </span>
                              </span> */}
                            </div>
                          </div>
                        ) : e?.message_type === "livechat_ended" ||
                          e?.message_type === "live_chat_ended" ? (
                          <div className="d-flex justify-content-center">
                            <span className="end_session">
                              {e?.msg_text ? e.msg_text : "Live chat ended"}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
              </ScrollableFeed>
              {showEmojis === true && (
          <div className="iconsx">
            <Emoji
              addEmoji={addEmoji}
              setShowEmojis={setShowEmojis}
              showEmojis={showEmojis}
            />
          </div>
        )}
            </>
          ) : (
            // <Spin />
            <div>
              <h6 className="text-center">Start your first chat!</h6>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Content;
