import { useState, useRef, useEffect } from "react";
import { RECORD_STATUS } from "./status";
import useTimer from "./userTimer";

// let record_status_type =
//   RECORD_STATUS.RECORDING || RECORD_STATUS.PAUSED || RECORD_STATUS.IDLE;

let mediaRecorder = MediaRecorder;
let localStream = MediaStream;
let event = BlobEvent;
let track = MediaStreamTrack;
export const useAudioRecorder = () => {
  const dataArray = useRef([]);
  const [status, setStatus] = useState(RECORD_STATUS.IDLE);
  const [audioResult, setAudioResult] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
const [t, setT] = useState('0');
const[ show, setShow] = useState(false)
  const {
    timer,
    handleStartTimer,
    handlePauseTimer,
    handleResumeTimer,
    handleResetTimer,
  } = useTimer();
var abc = '0';
  useEffect(() => {
    if(show === true){
    abc = new Date(timer * 1000).toISOString().substr(11, 8);
    setT(abc);
    }
  }, [show]);

  const startRecording = () => {
    setShow(true)
    if (status === RECORD_STATUS.IDLE) {
      try {
        setErrorMessage("");
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((mediaStreamObj) => {
            localStream = mediaStreamObj;
            mediaRecorder = new MediaRecorder(mediaStreamObj);
            mediaRecorder.start();
            mediaRecorder.onstart = () => {
              handleStartTimer();
              setStatus(RECORD_STATUS.RECORDING);
            };
            mediaRecorder.ondataavailable = (event) => {
              dataArray.current.push(event.data);
            };
          })
          .catch((error) => {
            setErrorMessage(error?.message);
          });
      } catch (error) {
        setErrorMessage(error?.message);
      }
    } else {
      return;
    }
  };

  const resumeRecording = () => {
    if (status === RECORD_STATUS.PAUSED) {
      mediaRecorder.resume();
      mediaRecorder.onresume = () => {
        handleResumeTimer();
        setStatus(RECORD_STATUS.RECORDING);
      };
    } else {
      return;
    }
  };

  const pauseRecording = () => {
    if (status === RECORD_STATUS.RECORDING) {
      mediaRecorder.pause();
      mediaRecorder.onpause = () => {
        handlePauseTimer();
        setStatus(RECORD_STATUS.PAUSED);
      };
    } else {
      return;
    }
  };

  const handler = (obj, e, setSelectedFile) => {
    console.log("file chk", e);
    setSelectedFile({ file: obj, type: "audio" });
  };
  const stopRecording = (setSelectedFile, setRes, time) => {
    setShow(false)
    console.log("t---t", t);
    if (status !== RECORD_STATUS.IDLE) {
      mediaRecorder.stop();
      mediaRecorder.onstop = () => {
        handleResetTimer();
        console.log("dataArray.current", dataArray);
        let audioData = new Blob(dataArray.current, { type: "audio/mpeg"});
        dataArray.current = [];
        console.log("audioData", audioData);
        // setAudioResult(window.URL.createObjectURL(audioData))
        setRes(window.URL.createObjectURL(audioData));
        var d = new Date();
        var name = 'audio'.concat('~').concat(time.split(':0')[3]).concat('mpeg')
        var file = new File([audioData], name, { type: "audio/mpeg"});
        var obj ={
          audioData: audioData,
          type: "audio/mp3",
        }
        console.log("fileeee", file);
        handler(obj, file, setSelectedFile);
        setStatus(RECORD_STATUS.IDLE);
        localStream.getAudioTracks().forEach((track) => {
          track.stop();
        });
      };
    } else {
      return;
    }
  };

  return {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    audioResult,
    errorMessage,
    timer,
  };
};
