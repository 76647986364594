import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ChatContent from "../../components/Inbox/ChatContent";
import ChatSidebar from "../../components/Inbox/ChatSidebar";
import NavBarInbox from "../../components/Chat/NavBarInbox";
import "./index.css";

const Index = () => {
  const [advisorID, setAdvisorID] = useState();
  const [pageNum, setPageNum] = useState(0);
  const [advisorName, setAdvisorName] = useState("");
  const [advisorOnlineStatus, setAdvisorOnlineStatus] = useState("");
  const [advisorLink, setAdvisorLink] = useState(null);
  const [advisorsID, setAdvisorsID] = useState();
  const [chatArr, setChatArr] = useState([]);


  const { id } = useParams();
  console.log("idCHECK", id);

  const getChatID = (e1, ID, receiver_displayname, link, liveStatus) => {
    console.log("e1", e1);
    console.log("receiver_displayname", receiver_displayname);
    setAdvisorLink(link);
    setAdvisorID(ID);
    const p = document.getElementById(e1.target.id);
    p.classList.add("active");
    setPageNum(0);
    setAdvisorName(receiver_displayname);
    setAdvisorOnlineStatus(liveStatus);
    setChatArr([]);
  };
  console.log("pageNum", pageNum);
  useEffect(() => {
    setAdvisorsID(id)
  }, [id]);

  return (
    <>
      <Container fluid className="main-chat-sec">
        <NavBarInbox />
        <Container>
          <Row className="view mx-0">
            <Col lg={3} className="sidebar shadow-sm px-3">
              <ChatSidebar
                getChatID={getChatID}
                setAdvisorID={setAdvisorID}
                setAdvisorLink={setAdvisorLink}
                setAdvisorName={setAdvisorName}
                setAdvisorOnlineStatus={setAdvisorOnlineStatus}
              />
            </Col>
            <Col lg={9} className="content shadow-sm p-0" id="msg_content">
              {advisorID && (
                <ChatContent
                  advisorID={advisorID}
                  pageNum={pageNum}
                  advisorName={advisorName}
                  advisorOnlineStatus={advisorOnlineStatus}
                  setChatArr={setChatArr}
                  chatArr={chatArr}
                  advisorLink={advisorLink}
                  advisorsID={advisorsID}
                />
              )}
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default memo(Index);
