import { Avatar, Button, Divider, Tag } from "antd";
import axios from "axios";
import clevertap from "clevertap-web-sdk";
import React, { memo, useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import moment from "moment";
import "./index.css";
import { useHistory } from "react-router-dom";
import { getAdvisorFullProfile } from "../../../api/advisor";
import { useDispatch, useSelector } from "react-redux";

const Index = ({
  getChatID,
  setAdvisorID,
  setAdvisorLink,
  setAdvisorName,
  setAdvisorOnlineStatus,
  advisorRefreshList,
  setHasData,
  setPage,
  advisorsID,
}) => {
  console.log("testsider", advisorRefreshList);
  const dispatch = useDispatch();
  const [listing, setListing] = useState([]);
  const [chatHead, setChatHead] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const history = useHistory();
  const [proff, setProff] = useState(null);
  useEffect(() => {
    getChatHead();
  }, []);

  useEffect(() => {
    getAdvisorFullProfile(advisorsID, dispatch);
  }, [advisorsID]);

  const advisorProfile = useSelector((x) => x.advisorProfile);
  console.log("advisorProfile", advisorProfile);

  useEffect(() => {
    setProff(advisorProfile);
    const check = chatHead?.find((e) => {
      return e?.receiver_id === proff?.id || e?.sender_id === proff?.id;
    });
    if(check === undefined){
      setAdvisorName(advisorProfile?.first_name)
    } 
  }, [advisorProfile]);

  const getChatHead = async () => {
    const userInformation = localStorage.getItem("userInfo") || {};
    const objectuser = JSON.parse(userInformation);
    const obj = {
      auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
      id: objectuser.id, // 141360, //
      type: "client",
      page: 0,
      limit: 30,
    };
    console.log("obj--", obj);
    try {
      const res = await axios.post(
        "https://staging.psychictxt.net/API/v19/Message_API/getChatHead_web",
        obj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("res", res?.data?.message);
      setChatHead(res?.data?.message);
      setListing(res?.data?.message);
      if (res?.data?.message[0]?.sender_type === "advisor") {
        setAdvisorID(res?.data?.message[0]?.sender_id);
        // setAdvisorLink(res?.data?.message[0]?.s3_profile_picture);
        // setAdvisorName(res?.data?.message[0]?.sender_displayname);
        // setAdvisorOnlineStatus(res?.data?.message[0]?.live_status);
      }
      if (res?.data?.message[0]?.receiver_type === "advisor") {
        setAdvisorID(res?.data?.message[0]?.receiver_id);
        // setAdvisorLink(res?.data?.message[0]?.s3_profile_picture);
        // setAdvisorName(res?.data?.message[0]?.receiver_displayname);
        // setAdvisorOnlineStatus(res?.data?.message[0]?.live_status);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (advisorRefreshList !== undefined) {
      const filtering = listing?.filter((x) => {
        if (
          x?.sender_id === advisorRefreshList?.id ||
          x?.receiver_id === advisorRefreshList?.id
        ) {
          return (x.txt_status = advisorRefreshList.status);
          // return advisorRefreshList;

        }
        return x;
      });
      console.log("filtering", filtering);
      setChatHead(filtering);
      // setAdvisorLink(advisorRefreshList?.live_status);
    }
  }, [advisorRefreshList]);

  console.log("filtering--", chatHead);
  const searchAdvisor = (e) => {
    e.persist();
    setSearchVal((prev) => e.target.value);
    console.log("valll", e.target.value);
  };
  console.log("searchVal", searchVal);
  useEffect(() => {
    var arr_new = listing?.filter((list) =>
      list?.sender_displayname?.toLowerCase()?.includes(searchVal)
    );
    console.log("arr_new", arr_new);
    setChatHead(arr_new);
  }, [searchVal]);
  
  console.log("listing", chatHead);
  

  return (
    <>
      <Row className="head-box_sidebar mb-4">
        <Col lg={12} className="first_msg pt-3 pb-2">
          <h5>My Inbox</h5>
        </Col>
        <Col lg={12} className="search_inboc">
          <BsSearch style={{ fontSize: "20px" }} />
          {/* <InputGroup className="mb-3"> */}
          {/* <InputGroup.Text><BsSearch style={{fontSize: '20px'}} /></InputGroup.Text> */}
          <Form.Control
            placeholder="Search"
            type="text"
            size="sm"
            onChange={(e) => searchAdvisor(e)}
          />
          {/* </InputGroup> */}
          {/* <BiDotsVerticalRounded style={{fontSize: '30px'}}/> */}
        </Col>
      </Row>
      {/* <hr /> */}
      {proff !== null &&
        chatHead?.find((e) => {
          return e?.receiver_id === proff?.id || e?.sender_id === proff?.id;
        }) === undefined && (
          <>
            <div
              className="chathead"
              onClick={(e1) => {
                history.push(`/chat/${proff.id}`);
                setAdvisorLink(proff?.s3_profile_picture);
                setAdvisorName(proff?.first_name);
                setAdvisorOnlineStatus(proff?.status);
              }}
            >
              <div className="w-100 d-flex align-items-center gap-2">
                <div className="w-25 advisor_profile_dot">
                  <Avatar size={40} src={proff?.s3_profile_picture} />
                  { proff?.status === "1" && (
                    <GoPrimitiveDot
                      className="advisor_profile_dot_dot"
                      color="#12B76A"
                    />
                  )}
                </div>
                <div className="w-75 d-flex flex-column px-2">
                  <div className="user_head w-100">
                    <p id={proff?.id}>{proff?.first_name}</p>
                    <span>{moment(new Date()).format("DD MMM")}</span>
                  </div>
            
                </div>
              </div>
              <Divider className="divid" />
            </div>
          </>
        )}
      {chatHead?.map((e, i) => {
        return (
          <>
            {e.sender_type === "advisor" ? (
              <div
                key={i}
                className="chathead"
                onClick={(e1) => {
                  history.push(`/chat/${e.sender_id}`);
                  setAdvisorLink(e?.s3_profile_picture);
                  setAdvisorName(e?.sender_displayname);
                  setAdvisorOnlineStatus(e?.txt_status);
              
                }}
              >
                <div className="w-100 d-flex align-items-center gap-2">
                  <div className="w-25 advisor_profile_dot">
                    <Avatar size={40} src={e?.s3_profile_picture} />
                    {e?.txt_status === "1" && (
                      <GoPrimitiveDot
                        className="advisor_profile_dot_dot"
                        color="#12B76A"
                      />
                    )}
                  </div>
                  <div className="w-75 d-flex flex-column  px-2">
                    <div className="user_head w-100">
                      <p id={e?.sender_id}>{e?.sender_displayname}</p>
                      <span>
                        {moment(new Date(e?.msg_date)).format("DD MMM")}
                      </span>
                      {/* <span>{e?.msg_text?.substring(0, 18)}</span> */}
                    </div>
                    <div className="user_head_date w-100">
                      <p>{e?.msg_text?.substring(0, 18)}</p>
                      {e?.pending_messages_count !== "0" ? (
                        <Tag
                          color="#3A86F4"
                          style={{ color: "white", borderRadius: "15px" }}
                        >
                          {e?.pending_messages_count}
                        </Tag>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <Divider className="divid" />
              </div>
            ) : (
              <>
                <div
                  className="chathead"
                  key={i}
                  onClick={(e1) => {
                    history.push(`/chat/${e.receiver_id}`);
                    setAdvisorLink(e?.s3_profile_picture);
                    setAdvisorName(e?.receiver_displayname);
                    setAdvisorOnlineStatus(e?.txt_status);
                    
                  }}
                >
                  <div className="w-100 d-flex align-items-center gap-2">
                    <div className="w-25 advisor_profile_dot">
                      <Avatar size={40} src={e?.s3_profile_picture} />
                      {e?.txt_status === "1" && (
                        <GoPrimitiveDot
                          className="advisor_profile_dot_dot"
                          color="#12B76A"
                        />
                      )}
                    </div>
                    <div className="w-75 d-flex flex-column px-2">
                      <div className="user_head w-100">
                        <p id={e?.receiver_id}>{e?.receiver_displayname}</p>
                        <span>
                          {moment(new Date(e?.msg_date)).format("DD MMM")}
                        </span>
                      </div>
                      <div className="user_head_date w-100">
                        <p>{e?.msg_text?.substring(0, 18)}</p>
                        {e?.pending_messages_count !== "0" ? (
                          <Tag
                            color="#3A86F4"
                            style={{ color: "white", borderRadius: "15px" }}
                          >
                            {e?.pending_messages_count}
                          </Tag>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <Divider className="divid" />
                </div>
              </>
            )}
          </>
        );
      })}
    </>
  );
};

export default memo(Index);
