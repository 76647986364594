import React, { memo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Line from "../../../images/line.png";
import "./index.css";
import Cards from "./cards";

const Index = ({ setNavInfo, setLoading, loading }) => {
  const [listing, setListing] = useState([]);
  return (
    <>
      <Container className="main-crousal pt-4">
        <Row className="main-cont">
          <Cards
            setNavInfo={setNavInfo}
            listing={listing}
            setListing={setListing}
            setLoading={setLoading}
            loading={loading}
          />
        </Row>
      </Container>
    </>
  );
};

export default memo(Index);
