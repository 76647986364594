import React, { useEffect, useRef, useState } from "react";

// import { useAudioRecorder } from "@sarafhbk/react-audio-recorder";
import { AiTwotoneAudio } from "react-icons/ai";
import {
  BsFillPauseCircleFill,
  BsFillPlayFill,
  BsFillStopFill,
} from "react-icons/bs";
import { useAudioRecorder } from "./useAudioRecorder";

function VoiceRecorder({
  setShowResult,
  setRes,
  setSelectedFile,
  setTime,
  time,
  setShowInput,
  setShowAttach,
  setShowAudio,
  showAudio
}) {
  const [showTime, setShowTime] = useState(false);
  const [showStart, setShowStart] = useState(true);
  const [showStop, setShowStop] = useState(false);
  const [showPause, setShowPause] = useState(false);
  const [showResume, setShowResume] = useState(false);
  var abc = 0;
  const {
    audioResult,
    timer,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    errorMessage,
  } = useAudioRecorder();

  useEffect(() => {
    // if(showStop === true){
    abc = new Date(timer * 1000).toISOString().substr(11, 8);
    setTime(abc);
    // }
  }, [showStop]);
  const startIt = () => {
    setShowInput(false);
    setShowAttach(false);
    startRecording();
    setShowStart(false);
    setShowTime(true);
    setShowStop(true);
    setShowPause(true);
  };
  const stopIt = () => {
    stopRecording(setSelectedFile, setRes, time);
    setShowTime(false);
    setShowResult(true);
    setShowStart(true);
    setShowStop(false);
    setShowPause(false);
    setShowResume(false);
    setShowAudio(false);
    // setRes(audioResult)
  };
  const pauseIt = () => {
    pauseRecording();
    setShowResume(true);
    setShowPause(false);
  };
  const resumeIt = () => {
    resumeRecording();
    setShowPause(true);
    setShowResume(false);
  };
  console.log("result", audioResult);
  console.log("time", time);
  console.log("showAudio", showAudio);
  // localStorage.setItem("time", time)
  return (
    <>
      {/* <audio controls src={audioResult} onPlay={e=> console.log(e.target)}/> */}
      {/* <audio controls src={audioResult} /> */}
      {/* <p>
        Status : <b>{status}</b>
      </p>
      <p>
        Error Message : <b>{errorMessage}</b>
      </p> */}
      {/* <p>{new Date(timer * 1000).toISOString().substr(11, 8)}</p> */}
      <div className="voice-box">
        {/* {showResult === true && <audio controls src={audioResult} />} */}
        {showTime === true && (
          <span> {new Date(timer * 1000).toISOString().substr(11, 8)}</span>
        )}
      {showAudio === true ? <>
        {showStart === true &&  (
          <AiTwotoneAudio
            onClick={startIt}
            className="pointing"
            style={{ fontSize: "25px", color: "green" }}
          />
        )}</> : '' } 
        {showStop === true && (
          <BsFillStopFill
            className="pointing"
            onClick={stopIt}
            style={{ fontSize: "25px", color: "red" }}
          />
        )}
        {showPause === true && (
          <BsFillPauseCircleFill
            className="pointing"
            onClick={pauseIt}
            style={{ fontSize: "25px", color: "lightblue" }}
          />
        )}
        {showResume === true && (
          <BsFillPlayFill
            onClick={resumeIt}
            style={{ fontSize: "25px", color: "lightblue" }}
            className="pointing"
          />
        )}
        {/* <button onClick={resumeRecording}>Resume</button> */}
      </div>
    </>
  );
}

export default VoiceRecorder;
