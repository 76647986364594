import { Divider } from "antd";
import React from "react";
import { Row, Col } from "react-bootstrap";

import "./index.css";
import Parser from 'html-react-parser';

const About = ({advisor}) => {
  return (
    <>
      <Row className="main_about_sub_section" id="about">
     
        <Col lg={12} className="main_about_sub_section_content">
        
          <div className="advisorDetailz">{Parser(advisor?.bio_data)}</div>
        </Col>
      </Row>
    </>
  );
};

export default About;
