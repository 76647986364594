// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID
// };
import "firebase/auth"
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: 'AIzaSyAjW4-QZ7bh5UftiSciJ7fnZ0CTOuGQPn8',
  authDomain: 'talkpok.firebaseapp.com',
  databaseURL: 'https://talkpok.firebaseio.com',
  projectId: 'talkpok',
  storageBucket: 'talkpok.appspot.com',
  messagingSenderId: 742509290708,
  appId: '1:742509290708:web:2659a017422766a26b0741'

  // huma app
  // apiKey: 'AIzaSyByH07Huv6q3ewtxvOiJRYYkffGa6QOVsc',
  // authDomain: 'auth-dev-912c2.firebaseapp.com',
  // projectId: 'auth-dev-912c2',
  // storageBucket: 'auth-dev-912c2.appspot.com',
  // messagingSenderId: 124201037847,
  // appId: '1:124201037847:web:de4725f94c9206d2692c52'
};
// REACT_APP_FIREBASE_API_KEY=AIzaSyByH07Huv6q3ewtxvOiJRYYkffGa6QOVsc
// REACT_APP_FIREBASE_AUTH_DOMAIN=auth-dev-912c2.firebaseapp.com
// REACT_APP_FIREBASE_PROJECT_ID=auth-dev-912c2
// REACT_APP_FIREBASE_STORAGE_BUCKET=auth-dev-912c2.appspot.com
// REACT_APP_FIREBASE_MESSAGING_SENDER_ID=124201037847
// REACT_APP_FIREBASE_APP_ID=1:124201037847:web:de4725f94c9206d2692c52
const apps = initializeApp(firebaseConfig);

const auth = getAuth(apps);
export default auth;

