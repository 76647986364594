import React, { useState } from "react";
import { FiSmile } from "react-icons/fi";
import EmojiPicker from "emoji-picker-react";
import "./index.css";
import { AiOutlineCloseCircle } from "react-icons/ai";

const Emoji = ({ addEmoji, showEmojis, setShowEmojis }) => {

  const closeEmojiBox = () => {
  
    setShowEmojis(false);
  };

  return (
    <>
      <div className="d-flex justify-content-end px-4">
        {showEmojis === true && (
          <AiOutlineCloseCircle className="pointing" style={{fontSize: "20px", color: '#667085'}} onClick={closeEmojiBox} />
        )}
      </div>
      {/* {showEmojis === false && (
        <FiSmile className="pointing" style={{fontSize: "25px", color: '#F7B614'}} onClick={openEmojiBox} />
      )} */}

      <div>
        {showEmojis && (
          <div className="picker">
            <EmojiPicker
              onEmojiClick={addEmoji}
              // width={"663px"}
              // height={"220px"}
              searchDisabled={true}
              size="25px"
              className="setPicker"
            />
             {/* <InputEmoji
            //   value={text}
            //   onChange={setText}
            //   cleanOnEnter
            //   onEnter={handleOnEnter}
            //   placeholder="Type a message"
            // /> */}
           </div>
        )}
      </div>
    </>
  );
};

export default Emoji;
