import React, { memo } from "react";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";
import { Divider, Tabs } from "antd";
import { CiWavePulse1 } from "react-icons/ci";
import { HiOutlineSparkles } from "react-icons/hi";
import { AiOutlineStar } from "react-icons/ai";
import { Row, Col } from "react-bootstrap";

import "./index.css";
import Speciality from "../Specialities";
import AllThree from "./AllThree";
import Reviews from "../Reviews";

const Index = ({ advisorDetails, reviewsList }) => {
  console.log("TESTTTT", reviewsList);
  const items = [
    {
      key: "1",
      Icon: <CiWavePulse1 />,
      label: `About`,
      children: (
        <AllThree advisorDetails={advisorDetails} reviewsList={reviewsList} />
      ),
    },
    {
      key: "2",
      Icon: <HiOutlineSparkles />,
      label: `Specialities`,
      children: <Speciality />,
    },
    {
      key: "3",
      Icon: <AiOutlineStar />,
      label: `Reviews`,
      children: (
        <Reviews advisorDetails={advisorDetails} reviewsList={reviewsList} />
      ),
    },
  ];
  return (
    <>
      <Row className="main_about_section">
        <Col lg={10} className="main_about_section_content">
       
          <div className="tabs_new">
            <a href="#about">
              <CiWavePulse1 />
              <p>About</p>
            </a>
            <a href="#special">
              <HiOutlineSparkles />
              <p> Specialities</p>
            </a>
            <a href="#reviews">
              <AiOutlineStar />
              <p> Reviews</p>
            </a>
          </div>
          <Divider />
          <AllThree advisorDetails={advisorDetails} reviewsList={reviewsList} />
        </Col>
      </Row>
    </>
  );
};

export default memo(Index);
