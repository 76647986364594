import axios from "axios";
import { setAdvisorProfile, setListingAdvisors } from "../redux/actions";
import {BASE_URL, VERSION} from "../config/constants"

export const getAllAdvisorsList = async (setLoading, dispatch) => {
    const obj = { auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj" };
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Advisor_API/getAdvisorWithoutReviews`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch(setListingAdvisors(Object.values(res.data.message)));
    setLoading(false)
  };

  export const getAdvisorFullProfile = async (advisorID, dispatch) => {
    const obj = { user_type: "advisor", id:  advisorID, auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj"};
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Advisor_API/getAdvisorFullProfile`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if(res?.data?.result === 1){
      console.log("RESS", Object.values(res.data.message)[0]);
      dispatch(setAdvisorProfile(Object.values(res.data.message)[0]));
    }
   
    // dispatch(setListingAdvisors(Object.values(res.data.message)));
  };

  export const getFilterAdvisors = async (filterObj, reviewList, pricelist,setIsLoading, dispatch) => {
    let formData = new FormData();
    formData.append("auth_key", "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj");
    if(filterObj?.is_online === 1){
      formData.append("is_online", 1);
    }
    if(filterObj?.text_status === 1){
      formData.append("text_status", 1);
    }
    if(filterObj?.live_status === 1){
      formData.append("live_status", 1);
    }
    if(reviewList?.length !== 0){
      formData.append("reviews", reviewList);
    }
    if(pricelist?.length !== 0){
      // const arr = pricelist?.map((e,i)=>{
        // return 
        const arr =  pricelist[0]?.replace(' cr/min', '')?.replace(' cr/min', '')?.replace(/\s/g, "")
      // });
      console.log("arr", arr);
      formData.append("price_range", arr);
      };  
    console.log("formData", formData);
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Advisor_API/advisorSearch`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    setIsLoading(false)
    console.log("response", res);
    if(res?.data?.result === 1){
      console.log("RESS", Object.values(res.data.message));
      dispatch(setListingAdvisors(Object.values(res.data.message)));
    }
    if(res?.data?.result !== 1){
      console.log("RESS", Object.values(res.data.message));
      dispatch(setListingAdvisors([]));
    }
   
  };

  export const findAdvisorFilter = async (text, setIsLoading, dispatch) => {
    let formData = new FormData();
    formData.append("auth_key", "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj");
    formData.append("search_text", text);
       console.log("formData", formData);
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Advisor_API/advisorSearch`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    setIsLoading(false)
    console.log("response", res);
    if(res?.data?.result === 1){
      console.log("RESS", Object.values(res.data.message));
      dispatch(setListingAdvisors(Object.values(res.data.message)));
    }
    if(res?.data?.result !== 1){
      console.log("RESS", Object.values(res.data.message));
      dispatch(setListingAdvisors([]));
    }
     localStorage.removeItem('search');
  };
