import { Avatar, Divider, Drawer } from "antd";
import React, { memo, useEffect, useState } from "react";
import Logo from "../../../images/logo.png";
import { IoCloseSharp } from "react-icons/io5";
import { RiHome6Line } from "react-icons/ri";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdKeyboardArrowRight } from "react-icons/md";
import { BsCalendar3Week, BsChat, BsInbox, BsPlus } from "react-icons/bs";
import { BiInfoCircle, BiPhoneCall, BiGroup } from "react-icons/bi";
import { FaChartLine } from "react-icons/fa";
import {TbWallet} from 'react-icons/tb';
import {RiShareForwardLine} from 'react-icons/ri';
import {AiOutlineTag} from 'react-icons/ai';
import {MdLogout} from 'react-icons/md';
import "./index.css";
import { useHistory } from "react-router-dom";
import { getOneChatHead } from "../../../api/chat";

const Index = ({ open, onClose, isUser, liveCredits, logoutSession }) => {
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const showMenu = () => {
    setOpenMenu(!openMenu);
  };
  const sendToUrl = (link) => {
    history.push(link);
  };

  return (
    <>
      <Drawer placement="left" open={open} closable={false} className="drawerr">
        <div className="main_drawer_header">
          <div className="main_drawer_sub_header">
            <img className="image-drawer shadow-sm" src={Logo} />
            <h4 className="brand">Psychic Txt</h4>
          </div>
          <IoCloseSharp
            onClick={onClose}
            style={{ fontSize: "25px", cursor: "pointer" }}
          />
        </div>
        <Divider className="dividerr" />
        <div className="drawer_content">
            {isUser !== null && 
            <div className="user_contents my-3">
                <div className="d-flex align-items-center gap-3">
                  <span
                          className="user_Profile_pic new_size"
                          onClick={()=> history.push('/')}
                            // src="#"
                            // size={40}
                           
                          ><span>{isUser?.username?.charAt(0)}</span>
                          </span>
                <div>
                    <h3>{isUser?.username}</h3>
                    <p>User Id: <span>{isUser?.id}</span></p>
                </div>
                </div>
                <MdKeyboardArrowRight style={{ fontSize: "25px", color: '#595F62' }} />
            </div>
            }
            {isUser !== null && isUser?.free_credit && isUser?.paid_credit &&
              <div className="user_credits_contents mb-3 py-2 px-2">
              <div>
                  <h3>TXT Credits</h3>
                  <p>{(typeof isUser?.free_credit === "string" &&
              typeof isUser?.paid_credit) === "string"
              ? +isUser?.free_credit + +isUser?.paid_credit
              : typeof isUser?.free_credit === "string" &&
                typeof isUser?.paid_credit === "number"
              ? +isUser?.free_credit + isUser?.paid_credit
              : typeof isUser?.free_credit === "number" &&
                typeof isUser?.paid_credit === "string"
              ? isUser?.free_credit + +isUser?.paid_credit
              : isUser?.free_credit + isUser?.paid_credit}</p>
              </div>
              <Divider type='vertical' style={{ fontSize: "30px" }} />
              <div>
                  <h3>Live Credits</h3>
                 {liveCredits !== null && <p>{liveCredits}</p>}
              </div>
             <div className="plus-icon">
                <BsPlus style={{ fontSize: "30px", color: '#3A86F4', cursor: 'pointer' }} 
                onClick={()=>{
                  history.push('/credits/purchase')
                }}/>
                </div> 
          </div>
          }
          <div
            className="menu_items"
            onClick={() => {
              sendToUrl("/");
            }}
          >
            <RiHome6Line style={{ fontSize: "20px", color: "#5f5f61" }} />
            <a href="/">Home</a>
          </div>
          <div className="menu_items">
            <div className="menu_dropdown" onClick={() => showMenu()}>
              <div>
                <BiGroup style={{ fontSize: "20px", color: "#5f5f61" }} />
                <p>Psychics</p>
              </div>
              {openMenu === false ? (
                <MdKeyboardArrowDown style={{ fontSize: "25px", color: "#595F62" }} />
              ) : (
                <MdKeyboardArrowUp
                  style={{ fontSize: "25px", color: "#595F62" }}
                />
              )}
            </div>
          </div>
          {openMenu === true && (
           <div className="drawer_content">
           <div className="sub_menu_items">
             <a href="/alladvisors">All Advisor</a>
           </div>
           <div className="sub_menu_items">
             <a href="/category/lovereadings">Love Readings</a>
           </div>
           <div className="sub_menu_items">
             <a href="/category/psychicreadings">Psychic Readings</a>
           </div>
           <div className="sub_menu_items">
             <a href="/category/tarotreadings">Tarot Readings</a>
           </div>
           <div className="sub_menu_items">
             <a href="/category/palmreadings">Palm Readings</a>
           </div>
           <div className="sub_menu_items">
             <a href="/category/angleinsights">Angle insights</a>
           </div>
           <div className="sub_menu_items">
             <a href="/category/astrologyandhoroscopes">{"Astrology & Horoscopes"}</a>
           </div>
           <div className="sub_menu_items">
             <a href="/category/dreamanalysis">Dream Analysis</a>
           </div>
           <div className="sub_menu_items">
             <a href="/category/oracleguidance">Oracle guidance</a>
           </div>
         </div>
          )}
          <div
            className="menu_items"
            // onClick={() => {
            //   history.push("https://live.psychictxt.net/index.php?/pages/horoscope");
            // }}
          >
            <BsCalendar3Week style={{ fontSize: "15px", color: "#5f5f61" }} />
            <a href="https://live.psychictxt.net/index.php?/pages/horoscope">Horoscope</a>
          </div>
        {isUser !== null &&  <div
            className="menu_items"
          >
            <FaChartLine style={{ fontSize: "15px", color: "#5f5f61" }} />
            <a href={`https://talkpok.web.app/?user_type=client&user_id=${isUser?.id}&auth_key=f8IY8reK3lpTBxJv1ew3Gl08jfM7ajPWFzslUKiaygvMD2Q`}>Blog</a>
          </div>}
          {isUser !== null && (
            <div className="drawer_content">
             
              <Divider className="dividerr" />
              <div
                className="menu_items"
                onClick={() => {
                  if(localStorage.getItem("reading")){
                    localStorage.removeItem("reading");
                  }
                  getOneChatHead(history);
                }}
              >
                <BsInbox style={{ fontSize: "20px", color: "#5f5f61" }} />
                <p className="menu_item">My Orders</p>
              </div>
              <div
                className="menu_items"
                onClick={() => {
                  sendToUrl("/credits/purchase");
                }}
              >
                <TbWallet style={{ fontSize: "20px", color: "#5f5f61" }} />
                <a href="/credits/purchase">Top up my balance</a>
              </div>
              <div
                className="menu_items"
                onClick={() => {
                  sendToUrl("/share");
                }}
              >
                <RiShareForwardLine style={{ fontSize: "20px", color: "#5f5f61" }} />
                <a href="/share">Refer a friend</a>
              </div>
              <div
                className="menu_items"
                onClick={() => {
                  sendToUrl("/promocode");
                }}
              >
                <AiOutlineTag style={{ fontSize: "20px", color: "#5f5f61" }} />
                <a href="/promocode">Promocode</a>
              </div>
            </div>
          )}
          <Divider className="dividerr" />
          <div
            className="menu_items"
            onClick={() => {
              sendToUrl("/category/tarotreadings");
            }}
          >
            <BiInfoCircle style={{ fontSize: "20px", color: "#5f5f61" }} />
            <a href="/aboutus">About Us</a>
          </div>
          <div
            className="menu_items"
            // onClick={() => {
            //   sendToUrl("/category/tarotreadings");
            // }}
          >
            <BiPhoneCall style={{ fontSize: "20px", color: "#5f5f61" }} />
            <a href="/contact-us">Contact Us</a>
          </div>
        {isUser !== null && <div
            className="menu_items"
            onClick={() => {
                logoutSession();
            }}
          >
            <MdLogout style={{ fontSize: "20px", color: "#5f5f61" }} />
            <a href="/">Logout</a>
          </div>}
        </div>
      </Drawer>
    </>
  );
};

export default memo(Index);
// RiHome6Line
