import { Modal, notification, Spin } from "antd";
import axios from "axios";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChatContent } from "../../../api/chat";
import { getClientInfo, getUserDetails } from "../../../api/client";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";
import S3FileUpload from "react-s3";
import { usePubNub } from "pubnub-react";
import "./index.css";
import moment from "moment";
import ChannelSubscribeCheck from "../../../channelsubscribecheck";
import UserDetailForm from "../userDetailForm";
import MessageDetailForm from "./messageDetailForm";
import InfoDetailForm from "./InfoDetailForm";
import { setMessages } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
const config = {
  bucketName: "mytarotadvisorvideos",
  region: "us-west-2",
  accessKeyId: "AKIAU7M7MBKJ2DEJLFP4",
  secretAccessKey: "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",
};

const Index = ({ setShow, advisor, setNavInfo }) => {
  console.log("detailz", advisor);
  const pubnub = usePubNub();
  const history = useHistory();
  const [channels, setChannels] = useState("");
  const [chatArr, setChatArr] = useState([]);
  const [msg, setMsg] = useState({ text: "" });
  const [color, setColor] = useState("lightgray");
  const [showResult, setShowResult] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const [isSpin, setIsSpin] = useState(true);
  const [replyTime, setReplyTime] = useState("");
  const [orderType, setOrderType] = useState("");
  const [infoMsg, setInfoMsg] = useState("");
  const [userPopDetails, setUserPopDetails] = useState(null);
  const [userProfilee, setUserProfilee] = useState(null);
  const [showInput, setShowInput] = useState(true);
  const [showAttach, setShowAttach] = useState(true);
  const [showAudio, setShowAudio] = useState(true);
  const [enableInfoMsg, setEnableInfoMsg] = useState(true);
  const [enableRate, setEnableRate] = useState(true);
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    type: "",
  });
  const [messageInput, setMessage] = useState({ text: "" });
  const [isMsgSpin, setIsMsgSpin] = useState(false);
  // test
  const [img, setImg] = useState("");
  const [infoo, setInfooMsg] = useState("");
  const [dur, setDur] = useState("");
  const [typ, setTyp] = useState("");
  // test
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getUserDetails(dispatch);
  }, [userPopDetails]);

  const userInform = useSelector((state) => state.userInfo);
  const profile = useSelector((state) => state.profile);
  console.log("profile", profile);
  useEffect(() => {
    getChatContent(advisor, setChatArr, dispatch, setIsSpin);
  }, [advisor]);
  useEffect(() => {
    setUserProfilee(profile);
    setNavInfo(profile);
  }, [profile, userPopDetails]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };
  const chatMsgs = useSelector((x) => x.chatMsg);
  console.log("chatMsgs", chatMsgs);
  useEffect(() => {
    if (
      msg.text === "" &&
      showAttach === false &&
      showAudio === false &&
      showInput === true
    ) {
      setShowAttach(true);
      setShowAudio(true);
    }
  });
  useEffect(() => {
    setChatArr((prev) => [
      ...prev,
      // chatMsgs
      {
        // client_deleted: chatMsgs?.client_deleted,
        client_dob: chatMsgs.client_dob ? chatMsgs.client_dob : "",
        client_gender: chatMsgs.client_gender ? chatMsgs.client_gender : "",
        // closing_reason: chatMsgs?.closing_reason,
        id: chatMsgs?.message_id,
        // is_closed: chatMsgs?.is_closed,
        is_rateable: chatMsgs?.is_rateable,
        message_review_id: chatMsgs?.message_review_id,
        message_type: chatMsgs?.message_type,
        msg_date: chatMsgs?.msg_date,
        msg_text: chatMsgs?.msg_text,
        receiver_displayname: chatMsgs?.receiver_displayname,
        receiver_id: chatMsgs?.receiver_id,
        receiver_type: chatMsgs?.receiver_type,
        response_time: chatMsgs?.response_time,
        review_status: chatMsgs?.review_status,
        sender_displayname: chatMsgs?.sender_displayname,
        sender_id: chatMsgs?.sender_id,
        sender_type: chatMsgs.sender_type,
        status: chatMsgs.status,
        video_url: chatMsgs.video_url,
      },
    ]);
  }, [chatMsgs]);
  const handleMessage = (event) => {
    const message = event.message;
    console.log("MSG---", message);
    if (message.auth_key !== "") {
      setChatArr((prev) => [
        ...prev,
        {
          msg_text: message.msg_text ? message.msg_text : "",
          msg_date: message.msg_date ? message.msg_date : new Date(),
          sender_type: message.sender_type ? message.sender_type : "advisor",
          video_url: message.video_url ? message.video_url : "",
          message_type: message.message_type ? message.message_type : "",
        },
      ]);
    }
    if (message.message_type === "update_credits") {
      setUserProfilee((prev) => {
        return {
          ...prev,
          free_credit: message?.payload?.free_credit,
          paid_credit: message?.payload?.paid_credit,
        };
      });
      setNavInfo((prev) => {
        return {
          ...prev,
          free_credit: message?.payload?.free_credit,
          paid_credit: message?.payload?.paid_credit,
          free_seconds: message?.payload?.free_seconds,
          paid_seconds: message?.payload?.paid_seconds,
        };
      });
    }
    // updateUserCredits(message, dispatch);
  };
  console.log("chatArr", chatArr);
  const leaveApplication = () => {
    pubnub.removeListener(listnerAdd);
    pubnub.unsubscribeAll();
  };
  const listnerAdd = {
    status: (statusEvent) => {
      var subscribedChannels = statusEvent.subscribedChannels;
      console.log("subscribedChannels", subscribedChannels);
      if (statusEvent.category === "PNConnectedCategory") {
        console.log("Connected !!!");
      }
    },
    message: (event) => {
      const message = event.message;
      console.log("MSG", message);
      // if (message.auth_key !== "") {
      //   setChatArr((prev) => [
      //     ...prev,
      //     {
      //       msg_text: message.msg_text ? message.msg_text : "",
      //       msg_date: message.msg_date ? message.msg_date : new Date(),
      //       sender_type: message.sender_type ? message.sender_type : "advisor",
      //       video_url: message.video_url ? message.video_url : "",
      //       message_type: message.message_type ? message.message_type : "",
      //     },
      //   ]);
      // }
      if (message.message_type === "update_credits") {
        setUserProfilee((prev) => {
          return {
            ...prev,
            free_credit: message?.payload?.free_credit,
            paid_credit: message?.payload?.paid_credit,
          };
        });
        setNavInfo((prev) => {
          return {
            ...prev,
            free_credit: message?.payload?.free_credit,
            paid_credit: message?.payload?.paid_credit,
            free_seconds: message?.payload?.free_seconds,
            paid_seconds: message?.payload?.paid_seconds,
          };
        });
      }
      // updateUserCredits(message, dispatch);
    },
  };
  useEffect(() => {
    const obj = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : {};
    // const obj = JSON.parse(userInformation);
    // if(obj && obj !== null && obj !== undefined){
    //   setUserObj(obj)
    // }
    setChannels("Channel_User_".concat(obj?.id));
    pubnub.addListener(listnerAdd);
    if (channels) {
      pubnub.subscribe({
        channels: [channels, "channel_application"],
        withPresence: true,
      });
    }
    return leaveApplication;
  }, [pubnub, channels, listnerAdd, leaveApplication]);

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  console.log("Sring", 'w_'.concat(makeid(21)));

  const sendMessage = async (image, type, dura, infomsg, orderTyp, repTime) => {
    // const randomString = makeid(27);
    if (orderType !== "") {
      setIsMsgSpin(true);
    }
    console.log("git img and type", image, type);
    console.log("repTime", repTime, replyTime);
    setImg(image);
    setTyp(type);
    setDur(dura);
    setInfooMsg(infomsg);
    if (type === "") {
      type = "text";
    }
    if (infomsg) {
      type = "info";
    }
    console.log("type", type);
    console.log("dura", dura);
    var imgObj = "";
    var secondObject = image;
    const userInformation = localStorage.getItem("userInfo") || {};
    const objectuser = JSON.parse(userInformation);
    console.log("userProfilee", userProfilee);
    if (
      // (
      userProfilee &&
      userProfilee?.username === ""
      // ||
      // userProfilee?.dob === "" || userProfilee?.gender === '')
    ) {
      console.log("--CHKKKK---", userProfilee);
      setIsModalOpen(true);
    } else {
      setIsMsgSpin(true);
      // return;
      var ids = objectuser?.id
        .concat("-to-")
        .concat(advisor.id)
        .concat("/")
        .concat(moment(new Date()).format("YYYY-MM-DD"))
        .concat("-")
        .concat(moment(new Date()).format("HH-MM-SS"));
      var name = "audio"
        .concat("/")
        .concat(ids)
        .concat("~")
        .concat(dura?.split(":0", 3)[2])
        .concat(".mp3");
      var file = new File([image?.audioData], name, { type: image.type });
      console.log("last", file);
      console.log("userInform", userInform);
      const credits = +userProfilee?.free_credit + +userProfilee?.paid_credit;
      const seconds = +userProfilee?.free_seconds + +userProfilee?.paid_seconds;
      if (credits !== 0) {
        console.log("msg chk---", msg.text);

        if ((msg.text !== null && msg.text !== "") || image !== "" || infomsg) {
          //test
          if (advisor?.rush_status === "1" && orderType === "" && !infomsg) {
            setOrderType("text");
            setIsModalOpen2(true);
          } else {
            console.log("OrderType: -> ", orderType);
            if (type === "audio") {
              secondObject = file;
              console.log("secondObject", secondObject);
            }
            if (type === "audio" || type === "photo") {
              await S3FileUpload.uploadFile(secondObject, config)
                .then((data) => {
                  console.log("-------UPLOADED------");
                  console.log("location", data.location);
                  imgObj = data.location;
                })
                .catch((err) => console.error(err));
            }
            console.log("replyTime", replyTime);
            const obj = {
              auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
              // message_type: "text",
              msg_date: "2022-10-07 11:12:27 +0500",
              msg_text: infomsg ? infomsg : msg.text ? msg.text : "",
              receiver_displayname: advisor?.username,
              receiver_id: advisor?.id,
              receiver_type: "advisor",
              sender_displayname: userProfilee?.username
                ? userProfilee.username
                : userPopDetails?.username,
              sender_id: userProfilee?.id,
              sender_type: "client",
              App_Version: "7.2",
              Device_Type: "web",
              client_dob: userPopDetails?.date
                ? moment(userPopDetails.date).format("YYYY-MM-DD")
                : userProfilee?.dob,
              client_gender:
                userProfilee?.gender !== ""
                  ? userProfilee.gender
                  : userPopDetails?.gender,
              client_message_review_id: "",
              email_notification_enabled: 1,
              message_review_id: 'w_'.concat(makeid(21)),//"a_iz5ibkiln3r1mkluoe0jp",
              response_time: infomsg
                ? "0"
                : orderType === "fast" || orderTyp === "fast"
                ? "rush_".concat(
                    replyTime !== "" ||
                      (repTime !== "" &&
                        repTime !== undefined &&
                        repTime !== null)
                      ? replyTime
                      : "text"
                  )
                : replyTime !== "" ||
                  (repTime !== "" && repTime !== undefined && repTime !== null)
                ? replyTime
                : "text",
              review_status: 0,
              status: type === "info" ? 1 : 0,
              video_url: imgObj !== "" ? imgObj : "",
              message_type:
                orderType === "fast" || orderTyp === "fast"
                  ? "rush_".concat(type)
                  : type,
              order_type:
                orderType === "fast" || orderTyp === "fast"
                  ? "rush_".concat(type)
                  : type,
              // re_engagement: 1,
              // live_session_id: 1,
            };
            console.log("new_api_obj", obj);
           
            //test
            try {
              const res = await axios.post(
                "https://staging.psychictxt.net/API/v19/Pubnub/saveChatPayload",
                obj,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              console.log("response Send msg ----", res);
              setIsMsgSpin(false);
              if (res?.data?.result === -1) {
                // notification.open({
                //   message: "Update!",
                //   description: `${res.data.message}`,
                //   duration: 3,
                // });
                history.push("/credits/text");
              } else {
                setChatArr((prev) => [
                  ...prev,
                  {
                    msg_text: infomsg ? infomsg : msg.text ? msg.text : "",
                    msg_date: new Date(),
                    sender_type: "client",
                    video_url: imgObj ? imgObj : "",
                    message_type: type ? type : "text",
                    status: 0,
                  },
                ]);
                setMsg({ text: "" });
                setClearInput(true);
                setShowResult(false);
                setShowInput(true);
                setSelectedFile({
                  file: null,
                  type: "",
                });
                setColor("lightgray");
                // handleEventPushClick();
                setMessage({ text: "" });
              }
            } catch (err) {
              console.log("err", err);
            }
          }
        } else {
          notification.open({
            message: "Notification!",
            description: "Please enter a message first.",
            duration: 3,
          });
          setIsMsgSpin(false);
        }
        //end
      } else {
        // notification.open({
        //   message: "Credits!",
        //   description: "Please update credits.",
        //   duration: 3,
        // });
        history.push("/credits/text");
      }
    }
  };
  console.log("pop details", userPopDetails);
  console.log("advisor", advisor);
  return (
    <>
      <div className="iconn shadow">
        {advisor && <Header setShow={setShow} advisor={advisor} />}
        {advisor && (
          <Content
            advisor={advisor}
            setChatArr={setChatArr}
            chatArr={chatArr}
            isSpin={isSpin}
            setIsModalOpen3={setIsModalOpen3}
            userProfilee={userProfilee}
            enableInfoMsg={enableInfoMsg}
            enableRate={enableRate}
            setEnableRate={setEnableRate}
          />
        )}
        <Footer
          msg={msg}
          setMsg={setMsg}
          sendMessage={sendMessage}
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
          setColor={setColor}
          color={color}
          setShowResult={setShowResult}
          showResult={showResult}
          clearInput={clearInput}
          setClearInput={setClearInput}
          setReplyTime={setReplyTime}
          showInput={showInput}
          setShowInput={setShowInput}
          setShowAttach={setShowAttach}
          showAttach={showAttach}
          setShowAudio={setShowAudio}
          showAudio={showAudio}
          isMsgSpin={isMsgSpin}
          messageInput={messageInput}
          setMessage={setMessage}

          // addEmoji={addEmoji}
        />
      </div>
      <Modal
        title="User Details"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <UserDetailForm
          setUserPopDetails={setUserPopDetails}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>

      <Modal
        title="Message Details"
        open={isModalOpen2}
        // onOk={handleOk}
        onCancel={handleCancel2}
        footer={null}
      >
        <MessageDetailForm
          setOrderType={setOrderType}
          setIsModalOpen2={setIsModalOpen2}
          img={img}
          infoo={infoo}
          dur={dur}
          typ={typ}
          replyTime={replyTime}
          sendMessage={sendMessage}
        />
      </Modal>

      <Modal
        title="Info Message"
        open={isModalOpen3}
        // onOk={handleOk}
        onCancel={handleCancel3}
        footer={null}
      >
        <InfoDetailForm
          setInfoMsg={setInfoMsg}
          sendMessage={sendMessage}
          setIsModalOpen3={setIsModalOpen3}
          setEnableInfoMsg={setEnableInfoMsg}
        />
      </Modal>
    </>
  );
};

export default memo(Index);
