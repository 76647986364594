import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import CardTag from "../../../images/cardheader.png";
import PlayBtn from "../../../images/playbutton.png";
import BellBtn from "../../../images/bellbtn.png";
import { AiOutlineStar } from "react-icons/ai";
import { Divider, Rate, Spin, Tag } from "antd";
import { TbCertificate } from "react-icons/tb";
import { BsChatSquareDots } from "react-icons/bs";
import { MdOutlineMarkChatUnread } from "react-icons/md";
import { GoPrimitiveDot } from "react-icons/go";
import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-alice-carousel/lib/alice-carousel.css";
import { findAdvisorFilter, getAllAdvisorsList } from "../../../api/advisor";
import { useDispatch, useSelector } from "react-redux";
import { usePubNub } from "pubnub-react";
import { updateUserCredits } from "../../../api";
import { useHistory } from "react-router-dom";
import Chatpopup from "../../Chat/chatpopup";
import Parser from "html-react-parser";
import LiveChatModal from "../../../modals/liveChatModal";
import NoData from "../../../images/noDataList.png";
import Line from "../../../images/lineAdvisor.png";
import Chat from "../../../images/chat_icon.png";
import SigninModal from "../../../modals/signinModal";
import VideoModal from "../../../modals/videoModal";
import ReadingModal from "../../../modals/readingModal";
import LiveChatFlowModal from "../../../modals/liveChatFlowModal";

const Cards = ({ setNavInfo, listing, setListing, setLoading, loading }) => {
  const pubnub = usePubNub();
  const [newList, setNewList] = useState(null);
  const [channels, setChannels] = useState("");
  const dispatch = useDispatch();
  // const [listing, setListing] = useState([]);
  const [user, setUser] = useState(null);
  const [show, setShow] = useState(false);
  const [advisor, setAdvisor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [livechatAdvisorInfo, setLivechatAdvisorInfo] = useState(null);
  const [liveChatSessionMsg, setLiveChatSessionMsg] = useState(null);
  const [modal2Open, setModal2Open] = useState(false);
  const [isTitle, setIsTitle] = useState(null);
  const history = useHistory();
  const [userProfilee, setUserProfilee] = useState(null);
  const [modalReadOpen, setModalReadOpen ] = useState(false);
  const [modalLiveOpen, setModalLiveOpen ] = useState(false);
  const [returnToPoint, setReturnToPoint] = useState('/');
  const [isModalLiveOpen, setIsModalLiveOpen] = useState(false);
  var returnTo = "/alladvisors";

  useEffect(() => {
    if (localStorage.getItem("search") !== null) {
      setLoading(true);
      findAdvisorFilter(localStorage.getItem("search"), setLoading, dispatch);
    } else {
      setLoading(true);
      getAllAdvisorsList(setLoading, dispatch);
    }
  }, []);
  console.log("searching", localStorage.getItem("search"));
  const list = useSelector((state) => state.advisorListing);
  const refreshList = useSelector((state) => state.refreshList);
  console.log("refreshList", refreshList);
  console.log("channels", channels);

  const profile = useSelector((state) => state.profile);
  console.log("profile", profile);

  useEffect(() => {
    setUserProfilee(profile);
  }, [profile]);

  const checkUser = (e) => {
    console.log("e", e);
    const obj = JSON.parse(localStorage.getItem("userInfo"));
    console.log("obj", obj);
    if (obj === null) {
      setAdvisor(e);
      history.push(`/reading/${e?.id}`);
   
    } else {
      const credits = +userProfilee?.free_credit + +userProfilee?.paid_credit;
      // const seconds = +userProfilee?.free_seconds + +userProfilee?.paid_seconds;
      if (
        credits >= 150
      ) {
        setAdvisor(e);
        history.push(`/chat/${e?.id}`);
      } else{
       history.push('/purchase-credits'); // credits pop up
      }
    }
  };
  useEffect(() => {
    if (newList !== null) {
      const arr = listing?.map((e) => {
        if (e.id === newList?.id) {
          return newList;
        }
        return e;
      });
      filterListing(arr);
      // setListing((old) => [...old, newList]);
    }
  }, [newList]);

  useEffect(() => {
    if (list?.length !== 0) {
      filterListing(list);
    } else {
      setListing([]);
    }
  }, [list]);

  const filterListing = (res) => {
    var array = res?.filter((e) => {
      return e.status === "1" && e.live_status === "1";
    });
    array = array.sort(function (a, b) {
      return +a.display_order - +b.display_order;
    });
    var array2 = res?.filter((e) => {
      return e.live_status === "1" && e.status !== "1";
    });
    array2 = array2.sort(function (a, b) {
      return +a.display_order - +b.display_order;
    });
    var array3 = res?.filter((e) => {
      return e.status === "1" && e.live_status !== "1";
    });
    array3 = array3.sort(function (a, b) {
      return +a.display_order - +b.display_order;
    });
    var array4 = res?.filter((e) => {
      return e.status === "0" && e.live_status === "0";
    });
    array4 = array4.sort(function (a, b) {
      return +a.display_order - +b.display_order;
    });
    setListing(array.concat(array2).concat(array3).concat(array4));
  };
  console.log("advisor listing--", listing);

  const leaveApplication = () => {
    pubnub.removeListener(listnerAdd);
    pubnub.unsubscribeAll();
  };
  const listnerAdd = {
    status: (statusEvent) => {
      var subscribedChannels = statusEvent.subscribedChannels;
      console.log("subscribedChannels", subscribedChannels);
      if (statusEvent.category === "PNConnectedCategory") {
        console.log("Connected");
      }
    },
    message: (event) => {
      const message = event.message;
      console.log("message", message);
      updateUserCredits(message, dispatch);
      var newObj = Object.values(message?.advisor)[0];
      console.log("new", newObj);
      setNewList(newObj);
      setLiveChatSessionMsg(message);
    },
  };
  console.log("liveChatSessionMsg", liveChatSessionMsg);

  useEffect(() => {
    if (Object.keys(refreshList).length !== 0) {
      var newObj = Object.values(refreshList?.advisor)[0];
      console.log("new", newObj);
      setNewList(newObj);
      setLiveChatSessionMsg(refreshList);
    }
  }, [refreshList]);

  useEffect(() => {
    const obj = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : {};
    // const obj =JSON.parse(userInformation);
    console.log("obj", obj);
    setChannels("Channel_User_".concat(obj?.id));
    pubnub.addListener(listnerAdd);
    if (channels) {
      console.log("channels", channels);
      pubnub.subscribe({
        channels: [channels, "channel_application"],
        withPresence: true,
      });
      // pubnub.publish({
      //   channel: [channels, "channel_application"],
      //   message: "Hello World!",
      // });
    }
    return leaveApplication();
  }, [pubnub, channels]);

  const handleCancel = () => {
    // setIsModalOpen(false);
    setIsModalLiveOpen(false);
  };
  const startLiveChat = (e) => {
 const obj = JSON.parse(localStorage.getItem("userInfo"));
    if (obj === null) {
      history.push(`/livereading/${e?.id}`)
      
    } else {
      if (profile?.free_seconds && profile?.paid_seconds) {
        const credit_val = +profile?.free_seconds + +profile?.paid_seconds;
        const calcul = ((credit_val * 2) / 60).toFixed(2);
        if(calcul >= e?.live_rate * 2){
          // setIsModalOpen(true);
          setLivechatAdvisorInfo(e);
          setIsModalLiveOpen(true);
        }else{
          history.push('/credits/live');
        }
        // setLiveCredits(calcul);
      }
    }
  };
  const handleAdvisor = (e) => {
    console.log("received value", e.target.id);
    const id = e.target.id;
    history.push(`/advisors/${id}`);
  };
  const [showModal, setShowModal] = useState(false);
  const [advisorDetails, setAdvisorDetails] = useState(null);
  const handleCancelModal = () => {
    setShowModal(false);
  };
  const openVideoModal = (e) => {
    setAdvisorDetails(e);
    setShowModal(true);
  };
  return (
    <>
          {modalReadOpen === true && advisor !== null && (
        <ReadingModal
          setModalReadOpen={setModalReadOpen}
          modalReadOpen={modalReadOpen}
          setIsTitle={setIsTitle}
          setModal2Open={setModal2Open}
          setReturnToPoint={setReturnToPoint}
          advisorId={advisor?.id}
        />
      )}
          {modalLiveOpen === true && advisor !== null && (
        <LiveChatFlowModal
          setModalLiveOpen={setModalLiveOpen}
          modalLiveOpen={modalLiveOpen}
          setIsTitle={setIsTitle}
          setModal2Open={setModal2Open}
          setReturnToPoint={setReturnToPoint}
          advisorId={advisor?.id}
        />
      )}
      {isTitle !== null && (
        <SigninModal
          returnTo={returnToPoint}
          isTitle={isTitle}
          setModal2Open={setModal2Open}
          modal2Open={modal2Open}
          setIsModalLiveOpen={setIsModalLiveOpen}
        />
      )}
      {show === true && advisor !== null && (
        <Chatpopup
          setShow={setShow}
          advisor={advisor}
          setNavInfo={setNavInfo}
        />
      )}
      <>
        {" "}
        {isModalLiveOpen === true && (
          <LiveChatModal
            isModalOpen={isModalLiveOpen}
            handleCancel={handleCancel}
            livechatAdvisorInfo={livechatAdvisorInfo}
            liveChatSessionMsg={liveChatSessionMsg}
          />
        )}
      </>
      <>
        {advisorDetails !== null && showModal === true && (
          <VideoModal
            showModal={showModal}
            handleCancel={handleCancelModal}
            advisorDetails={advisorDetails}
          />
        )}
      </>
      {loading === false ? (
        <>
          {" "}
          {listing?.length !== 0 ? (
            listing?.map((e) => {
              return (
                <Col
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  className="card-container mb-3"
                >
                  <Card className="main_card_all_advisors " key={e.id}>
                    <Card.Img
                      variant="top"
                      src={e?.s3_display_picture}
                      className="image-card"
                      id={e.id}
                      onClick={(val) => handleAdvisor(val)}
                      style={{ cursor: "pointer" }}
                    />
                    <Card.Body>
                      <img
                        src={PlayBtn}
                        alt="tag"
                        className="playbtnn pointing"
                        onClick={(e1) => openVideoModal(e)}
                      />
                      <img src={BellBtn} alt="tag" className="bellbtnn" />
                      <img src={CardTag} alt="tag" className="tag" />
                      <Rate
                        value={parseInt(e.ratings).toFixed(1)}
                        className="rate"
                        color="#F29C38"
                        disabled
                      />
                      <p className="rating">
                        {parseInt(e.ratings).toFixed(1)} Reviews
                      </p>
                      <div className="d-flex flex-column">
                        <p className=" username-advisors pt-4">
                          {e?.live_status === "1" && (
                            <Tag
                              className="name-advisors"
                              color="#00A542"
                              style={{ borderRadius: "4px" }}
                            >
                              Online
                            </Tag>
                          )}
                          &nbsp;{e.username}
                        </p>
                        <div
                          className={` ${
                            e?.live_status === "1"
                              ? "line1-username"
                              : "line2-username"
                          }`}
                        >
                          <img src={Line} alt="line" />
                        </div>
                        <div className="featuretxt mt-2">
                          {Parser(e?.short_description)}
                        </div>
                        <div className="tags tags-style mt-1">
                          {e.rush_status === "1" ? (
                            <Tag color="#00A542" className="tags-styling">
                              1 Hr
                            </Tag>
                          ) : (
                            ""
                          )}
                          {e.is_new_advisor === "1" ? (
                            <Tag color="#F29C38" className="tags-styling">
                              New
                            </Tag>
                          ) : (
                            ""
                          )}
                          {e.is_spanish === "1" ? (
                            <Tag color="#3A86F4" className="tags-styling">
                              Espanol
                            </Tag>
                          ) : (
                            ""
                          )}
                        </div>
                        <Row className="my-2">
                          <Col lg={6} md={6} sm={6} xs={6} className="equal">
                            <TbCertificate style={{ fontSize: "20px" }} />{" "}
                            <p className="years-joined">
         
                              {new Date().getFullYear() - e.year_joined} Years
                            </p>
                            {/* </span> */}
                          </Col>
                          <Col lg={6} md={6} sm={6} xs={6} className="equal">
                            <AiOutlineStar style={{ fontSize: "20px" }} />{" "}
                            <p className="years-joined">
             
                              {e?.review_count}
                              {/* {parseInt(e.ratings).toFixed(1)} */}
                              {/* </b> */}
                            </p>
                          </Col>
                        </Row>
                        <div className="last-tag my-1 box_year">
                          <p className="since-year py-1">
                            <span
                              className="since-year since-years"
                              // className="bold-txt"
                              style={{ color: "#00A542" }}
                            >
                              {e.total_orders}
                            </span>{" "}
                            &nbsp; readings since {e.year_joined}
                          </p>
                        </div>
                        <Row className="mt-2 d-flex gap-3 px-2">
                          <Col
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            onClick={(e1) => {
                              if (
                                e.live_status === "1" ||
                                e.live_status === "2"
                              ) {
                                startLiveChat(e);
                              }
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <Row
                              className={`${
                                e.live_status === "1"
                                  ? "chat-section-advisors"
                                  : "chat-disable-section-advisors"
                              } shadow-sm button-style-card pt-2`}
                            >
                              <Col lg={3} md={2} sm={3} xs={3}>
                                <MdOutlineMarkChatUnread
                                  color="#FFFFFF"
                                  style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                />
                              </Col>
                              <Col lg={8} md={6} sm={8} xs={8} className="p-0">
                                <span className=" d-flex flex-column">
                                  <span className="crpermin-advisors">Chat</span>
                                  <span className="chat-advisors">
                                    {e.live_rate} Cr/Min
                                  </span>
                                </span>
                              </Col>
                            </Row>
                          </Col>

                          <Col
                            lg={5}   md={5}
                            sm={5}
                            xs={5}
                            className={`p-0 font-global text pt-1 shadow-sm d-flex 
                                  align-items-center
                                   ${
                                     e?.status === "0"
                                       ? "px-3 reading_disable_btn txt-disable-section"
                                       : "reading_btn txt-section"
                                   }`}
                            onClick={(e1) => {
                              if (e.status === "1") {
                                checkUser(e);
                              }
                            }}
                          >
                            <BsChatSquareDots
                              color={e?.status === "1" ? "#3A86F4" : "#FFFFFF"}
                              style={{
                                fontSize: "20px",
                                paddingTop: "2px",
                                cursor: "pointer",
                              }}
                            />
                             <div className="d-flex flex-column align-items-start">
                                <p className="mb-0 read_clas">Reading</p>
                                <p className="mb-0">150</p>
                                </div>
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })
          ) : (
            <Container className="d-flex flex-column align-items-center">
              {/* <Spin /> */}
              <div style={{ width: "300px", height: "300px" }}>
                <img
                  src={NoData}
                  alt="nodata"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </div>
              <h2>Nothing to show...</h2>
            </Container>
          )}
        </>
      ) : (
        <Container className="d-flex justify-content-center">
          <Spin />
        </Container>
      )}
    </>
  );
};

export default Cards;
