import React, { memo, useEffect, useState } from "react";
import { Button, Divider, message, Spin, Tabs } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Email, NavBar } from "../../components";
import axios from "axios";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getInAppProducts } from "../../api/credits";
import PurchaseCreditsList from "../../components/PurchaseCreditsList";
import { BsChatSquareDots } from "react-icons/bs";
import TextIcon from '../../images/txt.svg'
import {MdOutlineMarkChatUnread} from 'react-icons/md';
import PurchaseModal from "../../modals/purchaseModal";

const Index = () => {
  const history = useHistory();
  const { id } = useParams();
  const [paramId, setParamId] = useState(undefined);
  const [liveId, setLiveId] = useState(undefined);
  const [textId, setTextId] = useState(undefined);
  const [appProd, setAppProd] = useState(null);
  const dispatch = useDispatch();
  const [isSpin, setIsSpin] = useState(true);
  console.log("ExtendSlug", id);
  console.log("paramId", paramId);
  let returnTo = window?.location?.href;
  useEffect(() => {
    if (returnTo?.includes('/livereading')) {
      setLiveId(id);
    }
    if (returnTo?.includes('/reading')) {
      setTextId(id);
    }
    if (id === "Live_Credit") {
      setParamId(id?.replace("_", " "));
    }

    if (localStorage.getItem("paypalToken") !== undefined) {
      localStorage.removeItem("paypalToken");
    }
  }, [id]);

  useEffect(() => {
    getInAppProducts(setIsSpin, dispatch);
  }, []);

  const inappProducts = useSelector((state) => state.inAppProductList);

  useEffect(() => {
    setAppProd(inappProducts);
  }, [inappProducts]);

  console.log("inappProducts", inappProducts);
  console.log("appProd", appProd);
  const [purchaseID, setPurchaseID] = useState(null)
  return (
    <>

   {purchaseID !== null && <PurchaseModal id={purchaseID} />}
      <div className="main_container_topBalance m-0">
   
        <Row className="d-flex justify-content-center div_main" style={{backgroundColor: '#FFFFFF'}}>
          <Col lg={12} className="my-4">
            <h2 className="topup_heading text-center">Top up your balance</h2>

          </Col>
        </Row>


        {isSpin === false ? (
          <>
            {appProd !== null ? (
              <Row className="d-flex justify-content-center" style={{backgroundColor: "#FFFFFF", borderRadius: '16px'}}>
                <Col
                  lg={12}
                  className="sub_container_topup d-flex flex-column  justify-content-around mb-5"
               
                >
                  {paramId ? (
                    <Tabs
                      defaultActiveKey="1"
                      centered
                      items={["1"].map((e, i) => {
                        const id = String(i + 1);
                        return {
                          label: 
                          (
                            <span className="d-flex gap-2">
                             <span className="d-flex align-items-center">
                              <MdOutlineMarkChatUnread  style={{fontSize: '18px'}}/>
                              </span> 
                            <p className="tab_name"> {paramId} </p> 
                            </span>
                          ),
                          key: id,
                          children: (
                            <PurchaseCreditsList data={appProd[paramId]}  setPurchaseID={setPurchaseID} purchaseID={purchaseID} />
                          ),
                        };
                      })}
                    />
                  ) : liveId ? (
                    <Tabs
                      defaultActiveKey="1"
                      centered
                      items={["1"]?.map((e, i) => {
                        const id = String(i + 1);
                        return {
                          label:  (
                            <span className="d-flex gap-2">
                             <span className="d-flex align-items-center">
                              <MdOutlineMarkChatUnread  style={{fontSize: '18px'}}/>
                              </span> 
                            <p className="tab_name"> Live Credit </p> 
                            </span>
                          ),
                          
                          key: id,
                          children: (
                            <PurchaseCreditsList
                              data={appProd["Live Credit"]}
                              setPurchaseID={setPurchaseID}
                              purchaseID={purchaseID}
                            />
                          ),
                        };
                      })}
                    />
                  ) : textId ? (
                    <Tabs
                      defaultActiveKey="1"
                      centered
                      items={["1"]?.map((e, i) => {
                        const id = String(i + 1);
                        return {
                          label:  (
                            <span className="d-flex gap-2">
                             <span className="d-flex align-items-center">
                              <BsChatSquareDots style={{fontSize: '18px'}}/></span> 
                            <p className="tab_name"> Text Credits </p> 
                            </span>
                          ),
                          key: id,
                          children: (
                            <PurchaseCreditsList
                              data={appProd["Text Credits"]}
                              setPurchaseID={setPurchaseID}
                              purchaseID={purchaseID}
                            />
                          ),
                        };
                      })}
                    />
                  ) : (
                    <Tabs
                      defaultActiveKey="1"
                      centered
                      style={{marginleft: '20px'}}
                      items={appProd?.page_list?.map((e, i) => {
                        const id = String(i + 1);
                        return {
                          label: (
                            <span className="d-flex gap-2">
                             <span className="d-flex align-items-center">
                           {e === 'Text Credits' ?  <BsChatSquareDots style={{fontSize: '18px'}}/> :  <MdOutlineMarkChatUnread  style={{fontSize: '18px'}}/>}</span> 
                            <p className="tab_name">  {e} </p> 
                            </span>
                          ),
                          key: id,
                          children: <PurchaseCreditsList
                          data={appProd[e]} 
                          setPurchaseID={setPurchaseID} 
                          purchaseID={purchaseID}
                          />,
                        };
                      })}
                    />
                  )}
                </Col>
              </Row>
            ) : (
              <Row className="d-flex justify-content-center">
                <h5>No data</h5>
              </Row>
            )}
          </>
        ) : (
          <Row className="d-flex justify-content-center align-items-center">
            <Spin />
          </Row>
        )}
      </div>
    </>
  );
};

export default memo(Index);
