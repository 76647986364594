import { Card, Avatar, Rate, Divider } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import Arrow from "../../../images/arrowReading.svg";
import "./index.css";

const AdvisorsReviewList = ({ e1, key, advisorDetails }) => {
  return (
    <>
      <Row className="main_review_section_content" key={key}>
        <Col lg={12} className="review_header">
          <Rate
            defaultValue={e1?.review}
            style={{ color: "#F7b614", fontSize: "20px" }}
          />
          <p>{moment(new Date(e1?.created_at)).format("DD MMM, YY")}</p>
        </Col>
        <Col lg={12} className="review_title">
          <p>{e1?.client_username}</p>
        </Col>
        <Col lg={12} className="review_text">
          <p>{e1?.comment}</p>
        </Col>
        {e1?.reply_text !== "" && (
          <Col lg={12} className="d-flex gap-3">
            <img src={Arrow} alt="arrow" className="reply_div"/>
            <div style={{ width: "50px", height: "50px" }}>
              {" "}
              <Avatar
                src={advisorDetails?.s3_display_picture}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div>
              <p className="reply_username">{advisorDetails?.username}</p>
              <p className="description_reply">
                {advisorDetails?.short_description?.replace(/<[^>]+>/g, "")}
              </p>
              <p className="reply_txt">{e1?.reply_text}</p>
            </div>
          </Col>
        )}
        <Col lg={12}>
          <Divider className="horizontal" />
        </Col>
      </Row>
    </>
  );
};

export default AdvisorsReviewList;
