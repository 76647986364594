import { message } from "antd";
import axios from "axios";
import { BASE_URL, VERSION } from "../config/constants";
import { updateUserInfo } from "./client";
import jwt_decode from "jwt-decode";


export const finalLogin = async (
  val,
  setShowPopupRedirect,
  setModal2Open,
  returnTo,
  history,
  setIsModalLiveOpen,
  setIsModalOpen
) => {
  console.log("val", val);
  const obj = {
    auth_value: val.email
      ? val.email
      : val.phoneNumber
      ? val.phoneNumber
      : val.auth_value
      ? val.auth_value
      : "",
      auth_type:  val.appleID ? 'apple' : '',
    password: "0Niy97D3r1Zydm",
    apple_id: val.appleID ? val.appleID : '',
    tarot_id: "d8f6548b1a5530d2",
    email: val.email ? val.email : "",
    fb_accountkit_id: "",
    device_type: "web",
    app_version: "4.5.5",
  };
  console.log("Success:", obj);
  try {
    const res = await axios.post(`${BASE_URL}/API/v21/Client_API/login`, obj, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("res after final login", res);
    if (res?.data?.result === 1) {
      localStorage.setItem("userInfo", JSON.stringify(res?.data?.user));
      localStorage.setItem("token", res?.data?.jwt);
      if (localStorage.getItem("reading")) {
        const dataa = localStorage.getItem("reading")
          ? JSON.parse(localStorage.getItem("reading"))
          : "";
        if (res?.data?.user?.dob === "" || res?.data?.user?.username === "") {
          updateUserInfo(dataa);
          history.push(`/reading/${dataa?.advisorID}`);
          history.go(0);
          message.success("Successfully logged In");
          // setIsModalOpen(true);
          // setModal2Open(false);
          // if(+res?.data?.user?.free_credit + +res?.data?.user?.paid_credit >=150){
          //   history.push(`/chat/${dataa?.advisorID}`)
          // }else{
          //   history.push('/credits/text');
          // }
        } else {
          history.push(`/reading/${dataa?.advisorID}`);
          history.go(0);
          message.success("Successfully logged In");
          // setIsModalOpen(true);
          // setModal2Open(false);
          // if(+res?.data?.user?.free_credit + +res?.data?.user?.paid_credit >=150){
          //   history.push(`/chat/${dataa?.advisorID}`)
          // }else{
          //   history.push('/credits/text');
          // }
        }
      } else if (localStorage.getItem("livereading")) {
        // setModal2Open(false);
        // message.success("Successfully logged In");
        if (res?.data?.user?.dob === "" || res?.data?.user?.username === "") {
          const dataa = localStorage.getItem("livereading");
            // ? localStorage.getItem("livereading")
            // : "";
          // updateUserInfo(dataa);
          history.push(
            `/livereading/${dataa}`
          );
          history.go(0);
          message.success("Successfully logged In");
          // setIsModalOpen(true);
          // setModal2Open(false);
          // const credit_val = +res?.data?.user?.free_seconds + +res?.data?.user?.paid_seconds;
          // const calcul = ((credit_val * 2) / 60).toFixed(2);
          // if (calcul >= res?.data?.user?.live_rate * 2) {
          //   localStorage.setItem('openLiveModal', true);
          //   history.push(`/`);
          // } else {
          //   history.push("/credits/live");
          // }
        } else {
          const dataa = localStorage.getItem("livereading");
          // ? JSON.parse(localStorage.getItem("livereading"))
          // : "";
          history.push(
            `/livereading/${dataa}`
          );
          history.go(0);
          message.success("Successfully logged In");
          // setIsModalOpen(true);
          // setModal2Open(false);
          // const credit_val = +res?.data?.user?.free_seconds + +res?.data?.user?.paid_seconds;
          // const calcul = ((credit_val * 2) / 60).toFixed(2);
          // if (calcul >= res?.data?.user?.live_rate * 2) {
          //   localStorage.setItem('openLiveModal', true);
          //   history.push(`/`);
          // } else {
          //   history.push("/credits/live");
          // }
        }
      } else {
        history.push("/");
        history.go(0);
        message.success("Successfully logged In");
      }
      // if(returnTo?.includes('/reading')){
      //   setModal2Open(false);
      //   message.success("Successfully logged In");
      //   if(res?.data?.user?.dob === '' || res?.data?.user?.username === ''){
      //     const dataa = localStorage.getItem('reading') ? JSON.parse(localStorage.getItem('reading')) : '';
      //     updateUserInfo(dataa);
      //   }else{
      //     if(+res?.data?.user?.free_credit + +res?.data?.user?.paid_credit >=150){
      //       history.push(`/chat/${returnTo?.split('/reading/')[1]}`)
      //     }else{
      //       history.push('/credits/text');
      //     }
      //   }
      // }else if(returnTo?.includes('/livereading')){
      //   setModal2Open(false);
      //   message.success("Successfully logged In");
      //   if(res?.data?.user?.dob === '' || res?.data?.user?.username === ''){
      //     const dataa = localStorage.getItem('livereading') ? JSON.parse(localStorage.getItem('livereading')) : '';
      //     updateUserInfo(dataa);
      //   }else{
      //     // localStorage.setItem('openLiveChatModal', true);
      //     setIsModalLiveOpen(true);
      //     // if(+res?.data?.user?.free_credit + +res?.data?.user?.paid_credit >=150){
      //     //   history.push(`/live/${returnTo?.split('/livereading/')[1]}`)
      //     // }else{
      //     //   history.push('/credits/live');
      //     // }
      //   }
      // } else{
      //   history.push(`/`);
      //   history.go(0);
      //   message.success("Successfully logged In");
      // }
      // window.location.reload();
      // message.success("Successfully logged In");
    } else {
      registerUser(val, setShowPopupRedirect, setModal2Open, returnTo, history);
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const getClientFromAccountID = async (
  user,
  setShowPopupRedirect,
  setModal2Open,
  returnTo,
  history,
  setIsModalLiveOpen
) => {
  console.log("Success:", user);
  console.log("user idd", user?.userID);
  localStorage.setItem("clientID", user?.userID);
  const obj = {
    fb_accountkit_id: user?.id,
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    password: "0Niy97D3r1Zydm",
    device_type: "android",
    app_version: "4.5.5",
  };
  console.log("obj", obj);
  try {
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Client_API/getClientFromAccountId`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("res after account id----------", res);
    return;
    // if (res?.data?.status === 1) {
    //   console.log("----CHK-----", res.data.user[0])
    //   finalLogin(res?.data?.user[0],setShowPopupRedirect, setModal2Open, returnTo, history, setIsModalLiveOpen)
    // }else{
    //   registerUser(user, setShowPopupRedirect, setModal2Open, returnTo, history, setIsModalLiveOpen)
    // }
  } catch (err) {
    console.log("err", err);
  }
};

export const registerUser = async (
  val,
  setShowPopupRedirect,
  setModal2Open,
  returnTo,
  history,
  setIsModalLiveOpen,
  setIsModalOpen
) => {
  console.log("valTest--", val);
  const obj = {
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    auth_value: val?.email
      ? val.email
      : val.phoneNumber
      ? val.phoneNumber
      : val.auth_value
      ? val.auth_value
      : "", //val?.email,
    password: "0Niy97D3r1Zydm",
    tarot_id: "d8f6548b1a5530d2",
    auth_type: val?.email
      ? "google"
      : val?.phonenumber
      ? "phonenumber"
      : val?.auth_value?.includes("@gmail.com")
      ? "google"
      : "phonenumber",
    username: val?.displayName ? val.displayName : "",
    udid: "d8f6548b1a5530d2",
    paid_credit: 0,
    free_credit: 0,
    dob: "",
    total_orders: 0,
    is_updated: 1,
    firebase_id: val.uid ? val.uid : localStorage.getItem("clientID"),
    app_version: "2.0",
    email: val?.email ? val.email : "",
  };
  console.log("obj register:", obj);
  // return;
  try {
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Client_API/createClient`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("response REGISTER: ", res);
    if (res?.data?.result === 1) {
      finalLogin(
        val,
        setShowPopupRedirect,
        setModal2Open,
        returnTo,
        history,
        setIsModalLiveOpen,
        setIsModalOpen
      ); //again login
    } else {
      message.warning("Unable to login. Please try again later");
      setShowPopupRedirect(false);
      setModal2Open(false);
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const onSigninUser = async (
  user,
  setShowPopupRedirect,
  setModal2Open,
  returnTo,
  history,
  setIsModalLiveOpen,
  setIsModalOpen
) => {
  localStorage.setItem("clientID", user.uid);
  console.log("user---:", user);
  // localStorage.setItem("token", user?.accessToken)
  const obj = {
    auth_value: user.email ? user.email : user.phoneNumber,
    firebase_id: user?.uid,
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    app_version: "5.3.7",
    device_type: "web",
  };
  console.log("objj", obj);
  try {
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Client_API/getClientFromAuthValue`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("RES SIGNIN GMAIL SERVER", res);
    console.log("check---", res.data.client_exist);
    // return;
    if (res?.data?.client_exist === "1") {
      finalLogin(
        obj,
        setShowPopupRedirect,
        setModal2Open,
        returnTo,
        history,
        setIsModalLiveOpen,
        setIsModalOpen
      );
    }
    if (res?.data?.is_deleted === "1") {
      message.error("Your account has been deleted!");
    }
    if (res?.data?.client_exist === "0") {
      registerUser(
        user,
        setShowPopupRedirect,
        setModal2Open,
        returnTo,
        history,
        setIsModalLiveOpen,
        setIsModalOpen
      );
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const addClientLoginAppleData = async (appleID, apple_auth_value, clientID) => {
  // console.log("Success:", user);
  // console.log("user idd", user?.userID)
  // localStorage.setItem("clientID", user?.userID);
  const obj = {
    auth_type: 'apple',
    apple_id: appleID,
    apple_auth_value: apple_auth_value,
    client_id: clientID ? clientID : '',
    tarot_id: 'd8f6548b1a5530d2',
  };
  console.log("obj", obj);
  try {
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Client_API/addClientLoginData`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("res_create_apple_account----------", res);
 
  } catch (err) {
    console.log("err", err);
  }
};

export const createClientApple = async (appleID,  setShowPopupRedirect,
  setModal2Open,
  returnTo,
  history,
  setIsModalLiveOpen, setIsModalOpen) => {
  // console.log("Success:", user);
  // console.log("user idd", user?.userID)
  // localStorage.setItem("clientID", user?.userID);
  const obj = {
    // fb_accountkit_id: user?.id,
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    auth_value: '',
    auth_type: 'apple',
    apple_id: appleID,
    username: '',
    password: "0Niy97D3r1Zydm",
    udid: '',
    free_credit: 0,
    paid_credit: 0,
    dob: '', 
    total_orders: 0,
    is_updated: '',
    email: '',
    firebase_id: '',
    tarot_id: 'd8f6548b1a5530d2',
    // device_type: 'android',
    app_version: '4.5.5',
    lang: 'en',
    live_bonus: 0,
  };
  console.log("obj", obj);
  try {
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Client_API/createClient`,
      obj,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("res_create_apple_account----------", res);
    if(res?.data?.result === 1){
      //res.data.id
      let vall ={auth_value: appleID, appleID: appleID}
      finalLogin(vall,  
        setShowPopupRedirect,
        setModal2Open,
        returnTo,
        history,
        setIsModalLiveOpen, setIsModalOpen)
    }
    // if (res.data.client_exist === "0") {
    //   // client does not exist
    // } else {
    //   //client exists
    // }
  } catch (err) {
    console.log("err", err);
  }
};

export const getClientFromAppleID = async (appleID,  
  setShowPopupRedirect,
  setModal2Open,
  returnTo,
  history,
  setIsModalLiveOpen,
  setIsModalOpen) => {
  const {id_token} = appleID;
  var decoded = jwt_decode(id_token);
  console.log("decoded", decoded);
  const {sub} = decoded;
  // return;
  let obj = {
    auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
    apple_id: sub
  };
  console.log("obj--", obj);
  var formData = new FormData();
  formData.append('auth_key', 'NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj');
  formData.append('apple_id', sub);
  try {
    const res = await axios.post(
      `${BASE_URL}/API/${VERSION}/Client_API/getClientFromAppleID`,
      // 'https://bc49-115-186-169-95.ngrok-free.app/pt/index.php?/API/v19/client_api/getClientFromAppleID',
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      
    );
    console.log("res_apple_account----------", res);
    if (res.data.client_exist === "0") {
      
      createClientApple(sub, setShowPopupRedirect,
        setModal2Open,
        returnTo,
        history,
        setIsModalLiveOpen, setIsModalOpen); // client does not exist
    } else {
      //client exists
      let vall ={auth_value: sub, appleID: sub}
      finalLogin(vall,  setShowPopupRedirect,
        setModal2Open,
        returnTo,
        history,
        setIsModalLiveOpen, setIsModalOpen)
      // addClientLoginAppleData(appleID, res?.data?.apple_auth_value, res.data.client_id, setShowPopupRedirect,
      //   setModal2Open,
      //   returnTo,
      //   history,
      //   setIsModalLiveOpen); 
    }
  } catch (err) {
    console.log("err", err);
  }
};
// "Your login failed. Provide atleast one login key"