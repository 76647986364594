import { Button } from "antd";
import React, { memo } from "react";
import { Container, Row } from "react-bootstrap";
import { NavBar } from "../../components";
import Footer from "../../components/Footer";
import Friends from "../../images/friends.svg";
import "./index.css";

const Index = () => {
  return (
    <>
      <NavBar />
      <Container fluid className="m-0 p-0 bg-light">
        <Row className="d-flex align-items-center justify-content-center vh-100 w-100">
          {/* <h1 className="text-center">Opps! Looks like you are lost!</h1> */}
          <div className="sprinkles-main-div">
            <div className="sprinkles-div">
              <img src={Friends} alt="friends" />
              <div className="sprinkles">
                <p>Refer Your Friends</p>
                <span>
                  Tap the share link to invite a friend. You will get 150 free
                  credits as soon as your friend joins our app!
                </span>
              </div>

              <Button className="btn_share">Share</Button>
            </div>
          </div>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default memo(Index);
