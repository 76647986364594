import PubNub from "pubnub";

import rootreducer from "../redux/signalsReducer";
import { applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";

const pubnub = new PubNub({
  publishKey: "pub-c-3bedba69-11a8-4c67-b225-dcb3f3ddadab",
  subscribeKey: "sub-c-78762070-e783-11e6-8919-0619f8945a4f",
  chatChannel: "chat",
  applicationChannel: "channel_application",
  uuid: "MyUserID",
  // autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
  // restore: true,
});

let thunkArgument = {
  pubnub: {
    api: pubnub,
  },
};

let middleware = applyMiddleware(ReduxThunk.withExtraArgument(thunkArgument));
const myStore = configureStore(
  {
    reducer: rootreducer,
  },
  undefined,
  middleware
);
export default myStore;
