import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./index.css";
import { MdOutlineMarkChatUnread } from "react-icons/md";
import { BsChatSquareDots } from "react-icons/bs";
import Chatpopup from "../../components/Chat/chatpopup";
import SigninModal from "../../modals/signinModal";
import LiveChatModal from "../../modals/liveChatModal";
import { useHistory } from "react-router-dom";

const Index = ({ advisorDetails, setShow }) => {
  console.log("bbbb", advisorDetails);
  const history = useHistory();
  const [modal2Open, setModal2Open] = useState(false);
  const [isTitle, setIsTitle] = useState(null);
  // const [show, setShow] = useState(false);
  // const [navInfo, setNavInfo] = useState(null);
  const [advisor, setAdvisor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [livechatAdvisorInfo, setLivechatAdvisorInfo] = useState(null);
  const [liveChatSessionMsg, setLiveChatSessionMsg] = useState(null);
  var returnTo =
    "/" + document?.URL?.split("/")[3] + "/" + document?.URL?.split("/")[4];
  console.log("returnTo", returnTo);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const checkUser = () => {
    // console.log("e", e);
    const obj = JSON.parse(localStorage.getItem("userInfo"));
    console.log("obj", obj);
    if (obj === null) {
      history.push(`/reading/${advisorDetails?.id}`);
      // history.push("/login");
      // setIsTitle("Login to your account");
      // setModal2Open(true);
    } else {
      setAdvisor(advisorDetails);
      // setShow(true);
      history.push(`/chat/${advisorDetails?.id}`);
    }
  };
  const startLiveChat = () => {
    const obj = JSON.parse(localStorage.getItem("userInfo"));
    if (obj === null) {
      // history.push("/login");
      // setIsTitle("Login to your account");
      // setModal2Open(true);
      history.push(`/livereading/${advisorDetails?.id}`)
    } else {
      setIsModalOpen(true);
      setLivechatAdvisorInfo(advisorDetails);
    }
  };
  return (
    <>
      {isTitle !== null && (
        <SigninModal
          returnTo={returnTo}
          isTitle={isTitle}
          setModal2Open={setModal2Open}
          modal2Open={modal2Open}
        />
      )}
      <>
        {isModalOpen === true && (
          <LiveChatModal
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            livechatAdvisorInfo={livechatAdvisorInfo}
            liveChatSessionMsg={liveChatSessionMsg}
          />
        )}
      </>
      <Row className="d-flex gap-3 px-2">
        <Col
          lg={6}
          style={{
            cursor: "pointer",
            width: "200px",
            height: '67px'
          }}
          onClick={() => {
            if (advisorDetails?.live_status === "1") {
              startLiveChat();
            }
          }}
          // className="chat-section3 shadow-sm"
          className={`${
            advisorDetails?.live_status === "1"
              ? "chat-section3 pointing"
              : "chat-section-disable-3"
          } shadow-sm`}
        >
          <div className=" cover_fixed">
            <MdOutlineMarkChatUnread
              color="#FFFFFF"
              style={{
                fontSize: "20px",
                cursor: "pointer",
              }}
            />
          </div>
          <div className="w-75">
            <span
              className="font-global d-flex flex-column gap-1"
              style={{ fontSize: "16px" }}
            >
              <span className="chat-txt">Chat</span>
              <span>
                <b className="chat-txt_2">2.00 Cr/Min</b>
              </span>
            </span>
          </div>
        </Col>
        <Col
          lg={6}
          // className={` chat-section3_b shadow-sm`}
          className={`${
            advisorDetails?.status === "1"
              ? "chat-section3_b pointing"
              : "chat-section3-disable_b"
          } shadow-sm`}
          style={{
            width: "200px",
            height: '67px',
            // borderLeft: 'solid 1px #000000',
            // backgroundColor: "#ECF3FE",
            borderRadius: "10px",
            cursor: "pointer",
          }}
          // onClick={(e1) => checkUser(e)}
          onClick={() => {
            if (advisorDetails?.status === "1") {
              checkUser();
            }
          }}
        >
          <div className="cover_fixed">
          <BsChatSquareDots
            // color="#3A86F4"
            color={advisorDetails?.status === "1" ? "#3A86F4" : "#FFFFFF"}
            style={{
              fontSize: "20px",
              paddingTop: "2px",
              cursor: "pointer",
            }}
          />
          </div>
          <div>
            <span
              className=" d-flex flex-column gap-1"
              style={{ fontSize: "16px" }}
            >
              <span
              className="text-txt"
                // className={`${
                //   advisorDetails?.status === "1"
                //     ? "enable-txtt"
                //     : "disable-txtt"
                // } text-txt`}
                // style={{ height: '67px'}}
              >
                Reading
              </span>
              <span>
                <b className="text-txt-2"
                // {`${
                //   advisorDetails?.status === "1"
                //     ? "enable-txtt bold-txt"
                //     : "bold-txt disable-txtt"
                // } text-txt-2`}
              //  style={{ fontSize: "12px" }}
              >
                  150 Credits
                </b>
              </span>
            </span>
          </div>
        </Col>
      </Row>
      {/* {show === true && advisor !== null && (
        <Chatpopup
          setShow={setShow}
          advisor={advisorDetails}
          setNavInfo={setNavInfo}
        />
      )} */}
    </>
  );
};

export default memo(Index);
