import { Alert, Avatar, Button, Divider, message, Popconfirm, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { getUserDetails } from "../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { getUpdateChatRoom } from "../../api/chat";
import { useHistory } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import moment from "moment";
import axios from "axios";
import { getAdvisorFullProfile } from "../../api/advisor";

const ChatSidebar = ({
  advisor,
  stopTimer,
  showInformationMsg,
  continueChat,
  setTotalCredits,
  totalCredits,
  setAdvisorID,
  setAdvisorLink,
  setAdvisorName,
  setAdvisorOnlineStatus,
  advisorRefreshList,
}) => {
  const [liveCredits, setLiveCredits] = useState(null);
  // const [totalCredits, setTotalCredits] = useState(null);
  const [info, setShowInfo] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const countRef = useRef(null);
  const countTim = useRef(null);
  const [proff, setProff] = useState(null);
  const [listing, setListing] = useState([]);
  const [chatHead, setChatHead] = useState([]);
  const [openEndChat, setOpenEndChat] = useState(false);
  // const [timer, setTimer] = useState(60);
  console.log("continueChat___Chkkkk, Sidebar", continueChat);
  console.log("advisor---", advisor);
  const [timerUser, setTimerUser] = useState(60);
  useEffect(() => {
    getUserDetails(dispatch);
  }, [dispatch]);
  const chatMsgs = useSelector((x) => x.chatMsg);
  const profile = useSelector((state) => state.profile);
  const [searchVal, setSearchVal] = useState("");
  console.log("profile", profile);
  useEffect(() => {
    // setIsUser(profile);
    if (profile?.free_seconds && profile?.paid_seconds) {
      const credit_val = +profile?.free_seconds + +profile?.paid_seconds;
      const calcul = ((credit_val * 2) / 60).toFixed(2);
      setLiveCredits(calcul);
      if (continueChat === null) {
        setTotalCredits(credit_val);
      }
    }
  }, [profile]);
  useEffect(() => {
    if (continueChat === null && stopTimer === false) {
      console.log("started here");
      handleStartTimer();
    }
    // timerUser === 0
  }, [continueChat]);
  const EndChat = () => {
    handleResetTimer();
    const sessionID = localStorage.getItem("livechatsessionID");
    getUpdateChatRoom(
      sessionID,
      profile,
      "live_chat_end",
      setShowInfo,
      history
    );
    localStorage.removeItem("livechatsessionID");
    localStorage.removeItem("liveChatAdvisorID");
    localStorage.removeItem("purchasedLiveCredits");
    localStorage.removeItem("discountLiveCredits");
    localStorage.removeItem("continueCredits");
    localStorage.removeItem("timerVal");
  };
  const handleStartTimer = () => {
    countRef.current = setInterval(() => {
      setTimerUser((timerUser) => timerUser - 1);
    }, 1000);
  };
  const handleResetTimer = () => {
    clearInterval(countRef.current);
    setTimerUser(0);
  };
  console.log("timerUser", timerUser);
  useEffect(() => {
    if (timerUser <= 0 && continueChat === null) {
      handleResetTimer();
      const sessionID = localStorage.getItem("livechatsessionID");
      getUpdateChatRoom(sessionID, profile, "live_chat_start", setShowInfo);
    }
  }, [timerUser]);

  useEffect(() => {
    if (stopTimer === true && continueChat === null) {
      handleResetTimer();
      const sessionID = localStorage.getItem("livechatsessionID");
      getUpdateChatRoom(sessionID, profile, "live_chat_start", setShowInfo);
    }
  });
  console.log(
    "timer, contCredits type------",
    typeof localStorage.getItem("continueCredits")
  );
  console.log(
    "timer, timerval type--------",
    typeof localStorage.getItem("timerVal")
  );

  useEffect(() => {
    getChatHead();
  }, []);

  const getChatHead = async () => {
    const userInformation = localStorage.getItem("userInfo") || {};
    const objectuser = JSON.parse(userInformation);
    const obj = {
      auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
      id: objectuser.id, // 141360, //
      type: "client",
      page: 0,
      limit: 30,
    };
    console.log("obj--", obj);
    try {
      const res = await axios.post(
        "https://staging.psychictxt.net/API/v19/Message_API/getChatHead_web",
        obj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("res", res?.data?.message);
      setChatHead(res?.data?.message);
      setListing(res?.data?.message);
      if (res?.data?.message[0]?.sender_type === "advisor") {
        setAdvisorID(res?.data?.message[0]?.sender_id);
        setAdvisorLink(res?.data?.message[0]?.s3_profile_picture);
        setAdvisorName(res?.data?.message[0]?.sender_displayname);
        setAdvisorOnlineStatus(res?.data?.message[0]?.live_status);
      }
      if (res?.data?.message[0]?.receiver_type === "advisor") {
        setAdvisorID(res?.data?.message[0]?.receiver_id);
        setAdvisorLink(res?.data?.message[0]?.s3_profile_picture);
        setAdvisorName(res?.data?.message[0]?.receiver_displayname);
        setAdvisorOnlineStatus(res?.data?.message[0]?.live_status);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    var arr_new = listing?.filter((list) =>
      list?.sender_displayname?.toLowerCase()?.includes(searchVal)
    );
    console.log("arr_new", arr_new);
    setChatHead(arr_new);
  }, [searchVal]);
  // useEffect(() => {
  //   if(chatMsgs?.message_type === 'livechat_start'){
  //     clearInterval(countRef.current);
  //     setTimer(0);
  //     // setTimerUser(+profile?.free_seconds);
  //     handleStartTimer1();
  //   }

  // }, [chatMsgs]);
  // useEffect(() => {
  //   if (timerUser <= 0) {
  //     handleResetTimer1();
  //     const sessionID = localStorage.getItem("livechatsessionID");
  //     getUpdateChatRoom(sessionID, profile?.id, "live_chat_end", setShowInfo);
  //   }
  // }, [timerUser]);
  useEffect(() => {
    getAdvisorFullProfile(advisor, dispatch);
  }, [advisor]);

  const advisorProfile = useSelector((x) => x.advisorProfile);
  console.log("advisorProfile", advisorProfile);

  useEffect(() => {
    setProff(advisorProfile);
    const check = chatHead?.find((e) => {
      return e?.receiver_id === proff?.id || e?.sender_id === proff?.id;
    });
    if(check === undefined){
      setAdvisorName(advisorProfile?.first_name)
    } 
  }, [advisorProfile]);

  const searchAdvisor = (e) => {
    e.persist();
    setSearchVal((prev) => e.target.value);
    console.log("valll", e.target.value);
  };
  useEffect(() => {
    if (advisorRefreshList !== undefined) {
      const filtering = chatHead?.filter((x) => {
        if (
          x?.sender_id === advisorRefreshList?.id ||
          x?.receiver_id === advisorRefreshList?.id
        ) {
          return (x.live_status = advisorRefreshList.live_status);
        }
        return x;
      });
      console.log("filtering", filtering);
      setChatHead(filtering);
      // setAdvisorLink(advisorRefreshList?.live_status);
    }
  }, [advisorRefreshList]);
  const confirm = (e) => {
    console.log("eee", e);
    if (e.sender_type === "advisor") {
      history.push(`/chat/${e?.sender_id}`);
      setAdvisorLink(e?.s3_profile_picture);
      setAdvisorName(e?.sender_displayname);
      setAdvisorOnlineStatus(e?.live_status);
    } else {
      history.push(`/chat/${e.receiver_id}`);
      setAdvisorLink(e?.s3_profile_picture);
      setAdvisorName(e?.receiver_displayname);
      setAdvisorOnlineStatus(e?.live_status);
    }
  };
  const cancel = (e) => {
    console.log(e);
  };
  const arr = chatHead?.find((e) => {
    return e?.receiver_id === proff?.id || e?.sender_id === proff?.id;
  });
  console.log("array_", arr);
  return (
    <>
      {/* <Row className="live-chat-sidebar">
        <Col lg={12} className="sidebar-header">
          <Button onClick={EndChat} type="danger">
            End
          </Button>
        </Col>
        <Col lg={12} className="sidebar-details">
          <div>
            <Avatar src={advisor?.s3_display_picture} size={48} />{" "}
          </div>
          <h5>{advisor?.username}</h5>
          {totalCredits !== null && continueChat === null && (
            <h6>
              Time Available: {Math.floor(totalCredits / 60) < 10 ? "0" + Math.floor(totalCredits / 60) : Math.floor(totalCredits / 60) }:
              {(totalCredits - Math.floor(totalCredits / 60) * 60) < 10 ? "0" + (totalCredits - Math.floor(totalCredits / 60) * 60): (totalCredits - Math.floor(totalCredits / 60) * 60)}
            </h6>
          )}
          {totalCredits !== null && continueChat === 'continue' && (
            <h6>
              Time Available: {Math.floor((+localStorage.getItem('timerVal') + +localStorage.getItem('continueCredits')) / 60) < 10 ? "0" + Math.floor((+localStorage.getItem('timerVal') + +localStorage.getItem('continueCredits')) / 60) : Math.floor((+localStorage.getItem('timerVal') + +localStorage.getItem('continueCredits')) / 60) }:
              {((+localStorage.getItem('timerVal') + +localStorage.getItem('continueCredits')) - Math.floor((+localStorage.getItem('timerVal') + +localStorage.getItem('continueCredits')) / 60) * 60) < 10 ? "0" + ((+localStorage.getItem('timerVal') + +localStorage.getItem('continueCredits')) - Math.floor((+localStorage.getItem('timerVal') + +localStorage.getItem('continueCredits')) / 60) * 60): ((+localStorage.getItem('timerVal') + +localStorage.getItem('continueCredits')) - Math.floor((+localStorage.getItem('timerVal') + +localStorage.getItem('continueCredits')) / 60) * 60)}
            </h6>
          )}
        </Col>
      </Row> */}
      <Row className="head-box_sidebar mb-4">
        <Col lg={12} className="first_msg pt-3 pb-2">
          <h5>My Inbox</h5>
        </Col>
        <Col lg={12} className="search_inboc">
          <BsSearch style={{ fontSize: "20px" }} />
          {/* <InputGroup className="mb-3"> */}
          {/* <InputGroup.Text><BsSearch style={{fontSize: '20px'}} /></InputGroup.Text> */}
          <Form.Control
            placeholder="Search"
            type="text"
            size="sm"
            onChange={(e) => searchAdvisor(e)}
          />
          {/* </InputGroup> */}
          {/* <BiDotsVerticalRounded style={{fontSize: '30px'}}/> */}
        </Col>
      </Row>
      {proff !== null &&
        chatHead?.find((e) => {
          return e?.receiver_id === proff?.id || e?.sender_id === proff?.id;
        }) === undefined && (
          <>
            <div
              className="chathead"
              onClick={(e1) => {
                history.push(`/chat/${proff.id}`);
                setAdvisorLink(proff?.s3_profile_picture);
                setAdvisorName(proff?.first_name);
                setAdvisorOnlineStatus(proff?.live_status);
                // setPage(0);
                // setHasData(true);
                // getChatID(
                //   e1,
                //   e.receiver_id,
                //   e?.receiver_displayname,
                //   e?.s3_profile_picture,
                //   e?.live_status
                // )
              }}
            >
              <div className="w-100 d-flex align-items-center gap-2">
                <div className="w-25 advisor_profile_dot">
                  <Avatar size={40} src={proff?.s3_profile_picture} />
                  {proff?.live_status === "1" && (
                    <GoPrimitiveDot
                      className="advisor_profile_dot_dot"
                      color="#12B76A"
                    />
                  )}
                </div>
                <div className="w-75 d-flex flex-column px-2">
                  <div className="user_head w-100">
                    <p id={proff?.id}>{proff?.first_name}</p>
                    <span>{moment(new Date()).format("DD MMM")}</span>
                  </div>
                  
                </div>
              </div>
              <Divider className="divid" />
            </div>
          </>
        )}
      {chatHead?.map((e) => {
        return (
          <>
            {e.sender_type === "advisor" ? (
              <Popconfirm
                title="Are you sure to end this live chat?"
                description="Are you sure to end this live chat?"
                onConfirm={() => confirm(e)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <div
                  key={e.id}
                  className="chathead"
                
                >
                  <div className="w-100 d-flex align-items-center gap-2">
                    <div className="w-25 advisor_profile_dot">
                      <Avatar size={40} src={e?.s3_profile_picture} />
                      {e?.live_status === "1" && (
                        <GoPrimitiveDot
                          className="advisor_profile_dot_dot"
                          color="#12B76A"
                        />
                      )}
                    </div>
                    <div className="w-75 d-flex flex-column  px-2">
                      <div className="user_head w-100">
                        <p id={e?.sender_id}>{e?.sender_displayname}</p>
                        <span>
                          {moment(new Date(e?.msg_date)).format("DD MMM")}
                        </span>
                        {/* <span>{e?.msg_text?.substring(0, 18)}</span> */}
                      </div>
                      <div className="user_head_date w-100">
                        <p>{e?.msg_text?.substring(0, 18)}</p>
                        {e?.pending_messages_count !== "0" ? (
                          <Tag
                            color="#3A86F4"
                            style={{ color: "white", borderRadius: "15px" }}
                          >
                            {e?.pending_messages_count}
                          </Tag>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <Divider className="divid" />
                </div>
              </Popconfirm>
            ) : (
              <>
                <Popconfirm
                  title="Are you sure to end this live chat?"
                  description="Are you sure to end this live chat?"
                  onConfirm={() => confirm(e)}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <div
                    className="chathead"
                    key={e.id}
                    onClick={(e1) => {
                      setOpenEndChat(true);
                      // history.push(`/chat/${e.receiver_id}`);
                      // setAdvisorLink(e?.s3_profile_picture);
                      // setAdvisorName(e?.receiver_displayname);
                      // setAdvisorOnlineStatus(e?.live_status);
                    }}
                  >
                    <div className="w-100 d-flex align-items-center gap-2">
                      <div className="w-25 advisor_profile_dot">
                        <Avatar size={40} src={e?.s3_profile_picture} />
                        {e?.live_status === "1" && (
                          <GoPrimitiveDot
                            className="advisor_profile_dot_dot"
                            color="#12B76A"
                          />
                        )}
                      </div>
                      <div className="w-75 d-flex flex-column px-2">
                        <div className="user_head w-100">
                          <p id={e?.receiver_id}>{e?.receiver_displayname}</p>
                          <span>
                            {moment(new Date(e?.msg_date)).format("DD MMM")}
                          </span>
                        </div>
                        <div className="user_head_date w-100">
                          <p>{e?.msg_text?.substring(0, 18)}</p>
                          {e?.pending_messages_count !== "0" ? (
                            <Tag
                              color="#3A86F4"
                              style={{ color: "white", borderRadius: "15px" }}
                            >
                              {e?.pending_messages_count}
                            </Tag>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <Divider className="divid" />
                  </div>
                </Popconfirm>
              </>
            )}
          </>
        );
      })}
    </>
  );
};
export default ChatSidebar;
