import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import "antd/dist/antd.css";
import { AdvisorProfile, Chat, Home, LandingPage, Login } from "./views";
import Phone from "./components/Login/Phone";
import Email from "./components/Login/Email";
import clevertap from "clevertap-web-sdk";
import Register from "./views/Register";
import LoginCheck from "./loginCheck";
import ChannelSubscribeCheck from "./channelsubscribecheck";
import LiveChat from "./views/LiveChat";
import PurchaseCredits from "./views/PurchaseCredits";
import Page404 from "./views/Page404";
import Payment from "./views/Payment";
import ExtendedCredits from "./views/ExtendedCredits";
import { updateUserCredits } from "./api";
import { usePubNub } from "pubnub-react";
import { useDispatch } from "react-redux";
import { refreshListAdvisor, setMessages } from "./redux/actions";
import AllAdvisors from "./views/AllAdvisors";
import Inbox from "./views/Inbox";
import LoginCheckInbox from "./loginCheckInbox";
import LiveChatInbox from "./views/LiveChatInbox";
import ReadingEngagementPage from "./views/ReadingEngagementPage";
import LiveChatEngagementPage from "./views/LiveChatEngagementPage";
import SigninSignup from "./views/SigninSignup";
import Contactus from "./views/Contactus";
import { useLocation } from "react-router-dom";
import ReferAFriend from "./views/ReferAFriend"
clevertap.init("KZ9-4K7-K75Z", "global");
clevertap.spa = true;
const App = () => {
  const pubnub = usePubNub();
  const dispatch = useDispatch();
  const [channels, setChannels] = useState("");
  const leaveApplication = () => {
    pubnub.removeListener(listnerAdd);
    pubnub.unsubscribeAll();
  };
  const listnerAdd = {
    status: (statusEvent) => {
      var subscribedChannels = statusEvent.subscribedChannels;
      console.log("subscribedChannels", subscribedChannels);
      if (statusEvent.category === "PNConnectedCategory") {
        console.log("Connected");
      }
    },
    message: (event) => {
      const message = event.message;
      console.log("APP_MSGS", message);
      dispatch(setMessages(message));
      dispatch(refreshListAdvisor(message));
    },
  };
  

  useEffect(() => {
    const obj = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : {};
    console.log("obj", obj);
    setChannels("Channel_User_".concat(obj?.id));
    pubnub.addListener(listnerAdd);
    console.log("channels", channels);
    pubnub.subscribe({
      channels: [channels, "channel_application"],
      withPresence: true,
    });
  }, [pubnub, channels]);


const location = useLocation();
useEffect(() => {
  window.scrollTo(0,0);
}, [location]);
  return (
    <>
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        {/* <Route exact path="/signin">
          <Login />
        </Route> */}
        <Route exact path="/login">
          <SigninSignup />
        </Route>
        <Route exact path="/signup">
          <SigninSignup />
        </Route>
        {/* <Route path="/phone">
          <Phone />
        </Route> */}
        <Route path="/advisors/:id">
          <AdvisorProfile />
        </Route>
        {/* <Route path="/email">
          <Email />
        </Route> */}
        <Route path="/advisors">
          <Home />
        </Route>
        <Route path="/chat/:id">
          <LoginCheckInbox>
            <ChannelSubscribeCheck>
              <Inbox />
            </ChannelSubscribeCheck>
          </LoginCheckInbox>
        </Route>
        <Route path="/live/:id">
          <LoginCheckInbox>
            <ChannelSubscribeCheck>
              <LiveChat />
            </ChannelSubscribeCheck>
          </LoginCheckInbox>
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/livechat/:id">
          <LiveChat />
        </Route>
        <Route path="/purchase-credits">
          <PurchaseCredits />
        </Route>
        <Route path="/billing/:id">
          <Payment />
        </Route>
        <Route path="/extended-credits/:id">
          <PurchaseCredits />
        </Route>
        <Route path="/credits/:id">
          <PurchaseCredits />
        </Route>
        <Route path="/alladvisors">
          <AllAdvisors />
        </Route>
        <Route path="/search">
          <AllAdvisors />
        </Route>
        <Route path="/category/:id">
          <AllAdvisors />
        </Route>
        <Route path="/reading/:id">
          <ReadingEngagementPage />
        </Route>
        <Route path="/livereading/:id">
          <LiveChatEngagementPage />
        </Route>
        <Route path="/contact-us">
          <Contactus />
        </Route>
        <Route path="/refer-a-friend">
          <ReferAFriend />
        </Route>
        <Route path="/*">
          <Page404 />
        </Route>
      </Switch>
    </>
  );
};

export default App;
