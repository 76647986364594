import React, { memo, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Select,
  Spin,
} from "antd";
import { useAcceptJs } from "react-acceptjs";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import paypal from "../../../images/paypal.png";
import { addPaymentProfile, chargeCustomer, getPaypalClientToken } from "../../../api/payment";
import { FiShoppingCart } from "react-icons/fi";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import PaymentCartDetails from "../PaymentCartDetails";
import Visa from "../../../images/visa_logo.png";
import Paypal from "./paypal";
import {
  getPaymentProfiles,
} from "../../../api/payment";
import { TiTick } from "react-icons/ti";
import LiveChatFlowModal from "../../../modals/liveChatFlowModal";
import LiveChatModal from "../../../modals/liveChatModal";
import { getAdvisorFullProfile } from "../../../api/advisor";
import {
  LoadingOutlined,
} from '@ant-design/icons';
const { Option } = Select;

const authData = {
  apiLoginID: "6b869TjJ2",
  clientKey: "59x45hDkHx893sUa4pd5WNkVsenP9u232djS4Q54mX9gExrQqMfd8VubDMsS34Xz",
};

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select
      style={{
        width: 70,
      }}
      defaultValue="92"
    >
      <Option value="92">+92</Option>
      <Option value="91">+91</Option>
    </Select>
  </Form.Item>
);

const Index = ({ oneProdValue, prodID, isUser, id , setIsModalOpen}) => {
  // const { id } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [enablePaymentTypeVal, setEnablePaymentTypeVal] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const [productID, setProductID] = useState(null);
  const [getpostdata, setgetpostdata] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isSpin, setIsSpin] = useState(false);
  const [modalLiveOpen, setModalLiveOpen] = useState(false)
  // const [isUser, setIsUser] = useState(null);

  // const environment =
  //   isUser?.is_test_client === "0"
  //     ? "PRODUCTION"
  //     : "SANDBOX";

  const environment = "SANDBOX";
  console.log("environment", environment);
  useEffect(() => {
    if (
      localStorage.getItem("paypalToken") === undefined ||
      localStorage.getItem("paypalToken") === null
    ) {
      setIsSpin(true);
      getPaypalClientToken(dispatch, setIsSpin);
    }
  }, []);

  const { dispatchData, loading, error } = useAcceptJs({
    environment,
    authData,
  });

  console.log("prodID", prodID);
  // Test below
  // useEffect(()=>{
  //   setIsModalOpen(false);
  // }, [prodID])

  useEffect(() => {
    getPaymentProfiles(dispatch);
  }, []);

  const [advisorDetails, setAdvisorDetails] = useState(null);
  useEffect(()=>{
    if(localStorage.getItem('livereading')){
    const advisorID =  JSON.parse(localStorage.getItem('livereading'))?.advisorID;
    console.log('advisorID', advisorID);
    getAdvisorFullProfile(advisorID, dispatch);
    }
  }, [])

  const advisorProfile = useSelector((x) => x.advisorProfile);
  console.log("advisorProfile", advisorProfile);

  useEffect(() => {
    setAdvisorDetails(advisorProfile);
  }, [advisorProfile]);

  const paymentProfiles = useSelector((state) => state.paymentProfiles);
  console.log("paymentProfiles", paymentProfiles);

  function paymentFormUpdate(opaqueData) {
    // var dataDescriptor = {}
    // var dataValue = {}
    var dataDescriptor = opaqueData.dataDescriptor;
    var dataValue = opaqueData.dataValue;
  }
  function responseHandler(response) {
    if (response.messages.resultCode === "Error") {
      var i = 0;
      while (i < response.messages.message.length) {
        console.log(
          response.messages.message[i].code +
            ": " +
            response.messages.message[i].text
        );
        i = i + 1;
      }
    } else {
      paymentFormUpdate(response.opaqueData);
    }
  }
  const callPaymentConfirmModal = () => {
    if(id !== undefined && id.split('-', 2)[1] === 'extended'){
      localStorage.setItem("purchasedLiveCredits", oneProdValue?.credits)
      localStorage.setItem("discountLiveCredits", oneProdValue?.discount)
    }
    if(id.split('-', 2)[1] === 'extended'){
      // localStorage.setItem('liveChatAdvisorID', 75);
     const advisorID = localStorage.getItem('liveChatAdvisorID');
     localStorage.removeItem("paypalToken");
      history.push(`/livechat/${advisorID.concat('-continue')}`);
    }else{
      if(localStorage.getItem('reading') !== null && localStorage.getItem('reading') !== undefined){
        localStorage.removeItem("paypalToken");
        const advisorIDD = JSON.parse(localStorage.getItem('reading'))
         history.push(`/chat/${advisorIDD?.advisorID}`);
       }else if(localStorage.getItem('livereading') !== null && localStorage.getItem('livereading') !== undefined){
        localStorage.removeItem("paypalToken");
        setModalLiveOpen(true);
       }else{
        localStorage.removeItem("paypalToken");
        history.push("/");
        message.success(`Your payment was received, and $ ${oneProdValue?.price} has been added to your credit balance. You earned $ ${oneProdValue?.discount} bonus.`);
       }
    }
  
  };
  const success = () => {
    // let secondsToGo = 2;
    setBtnLoading(true);
    // message.success({
    //   content: `Card has been added Successfully. \n Proceed with payment of ${oneProdValue?.price}`,
    // });
    console.log("test---");
    // Make purchase
    if (id !== undefined && id !== null) {
      console.log("test---2");
      const obj = {
        auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
        client_id: JSON.parse(localStorage.getItem("userInfo"))?.id, //200828, //
        purchase_item_id: id !== null && id !== undefined && id.split('-', 1)[0]?.split('/')[2],
        receipt_email: JSON.parse(localStorage.getItem("userInfo")).email,
        device_type: "web",
        extended: id !== undefined && id.split('-', 2)[1] === 'extended' ? 1 : 0, // id.split('/', 1)[0]
      };
      console.log("test---3");
      chargeCustomer(obj,setBtnLoading, callPaymentConfirmModal);
    }
    //
    setgetpostdata(false);
    form.resetFields();
  };
  const onFinish = async (values) => {
    setBtnLoading(true);
    console.log("Success:", values);

    // var authData = {};
    // authData.clientKey = "9x45hDkHx893sUa4pd5WNkVsenP9u232djS4Q54mX9gExrQqMfd8VubDMsS34Xz";
    // authData.apiLoginID = "6b869TjJ2";

    var cardData = {};
    cardData.cardNumber = values?.cardNumber;
    cardData.month = moment(values?.expYear).format("MM");
    cardData.year = moment(values?.expYear).format("YY");
    cardData.cardCode = values?.cardCode;

    console.log("cardData", cardData);
    // var secureData = {};
    // secureData.authData = authData;
    // secureData.cardData = cardData;

    // console.log("secureData", secureData);
    // Dispatch CC data to Authorize.net and receive payment nonce for use on your server
    try {
      const response = await dispatchData({ cardData });
      console.log("Received response:", response);
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (response?.messages?.resultCode === "Ok") {
        const obj = {
          auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
          client_id: JSON.parse(localStorage.getItem("userInfo"))?.id,
          data_descriptor: response?.opaqueData?.dataDescriptor,
          data_value: response?.opaqueData?.dataValue,
          receipt_email: JSON.parse(localStorage.getItem("userInfo"))?.email,
          zip_code: values?.zipPin,
          // cardholder_address: values?.address1,
          // cardholder_address_2: values.address2 ? values.address2 : "",
          // cardholder_name: values?.firstName,
          // cardholder_last_name: values?.lastName,
          // cardholder_phone: values.phone ? values.phone : "",
          device_type: "web",
          app_version: "3.7.4",
          // cardholder_country: values?.country,
        };
        console.log("obj", obj);
        addPaymentProfile(obj, setBtnLoading, success);
      }
    } catch (err) {
      console.log("error", err);
      setBtnLoading(false);
      const error = err?.messages?.message?.map((e, i) => {
        return e.text;
      });
      console.log("error", error);
      message.error(error[0]);
    }

    // Accept.dispatchData(secureData, responseHandler);
    // try {
    //   const res = axios.post("/");
    // } catch (err) {
    //   console.log("err", err);
    // }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    if (e.target.value === "paypal") {
      setEnablePaymentTypeVal(false);
      // onReset();
    } else {
      setEnablePaymentTypeVal(true);
    }
  };
  const cartVisibleFunction = () => {
    setCartVisible(true);
  };
  const cartInVisibleFunction = () => {
    setCartVisible(false);
  };
  const handleCancel = () => {
    // setIsModalOpen(false);
    setModalLiveOpen(false);
  };
  return (
    <>
          {/* {modalLiveOpen === true && (
        <LiveChatFlowModal
          setModalLiveOpen={setModalLiveOpen}
          modalLiveOpen={modalLiveOpen}
          // setIsTitle={setIsTitle}
          // setModal2Open={setModal2Open}
          setReturnToPoint={'/'}
          advisorId={JSON.parse(localStorage.getItem('livereading'))?.advisorID}
        />
      )} */}
         {modalLiveOpen === true &&  advisorDetails !== null && (
          <LiveChatModal
            isModalOpen={modalLiveOpen}
            handleCancel={handleCancel}
            livechatAdvisorInfo={advisorDetails}
            // liveChatSessionMsg={liveChatSessionMsg}
          />
        )}
      <Row className="d-flex justify-content-center align-items-center">
        <Col
          lg={12}
          className="d-flex flex-column justify-content-around align-items-center"
        >
          <Row className="w-100 d-flex justify-content-center">
            <Col lg={12} 
            style={{ padding: '20px'}}
            // style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)', padding: '20px', borderRadius: '8px', backgroundColor: '#FFFFFF'}}
            >
              <div className="d-flex justify-content-between pe-4">
                <img
                  src={paypal}
                  alt="logoPaypal"
                  style={{ width: "100px", height: "30px" }}
                />
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <FiShoppingCart />
                  <p>${oneProdValue?.price}</p>
                  {cartVisible === false ? (
                    <IoMdArrowDropdown
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={cartVisibleFunction}
                    />
                  ) : (
                    <IoMdArrowDropup
                      style={{ fontSize: "25px", cursor: "pointer" }}
                      onClick={cartInVisibleFunction}
                    />
                  )}
                </div>
              </div>
              <Divider />
              {cartVisible === true && (
                <Row>
                  <Col lg={12} className="d-flex justify-content-around">
                    <p>{oneProdValue?.product_type}:</p>
                    <p>{oneProdValue?.credits}</p>
                  </Col>
                  <Divider />
                  <Col lg={12} className="d-flex justify-content-around">
                    <p>Total:</p>
                    <p>${oneProdValue?.price}</p>
                  </Col>
                </Row>
              )}
              <Row>
                <Col
                  lg={12}
                  className="d-flex justify-content-between align-items-center pt-sm-3"
                >
                  <p>Have a PayPal account?</p>
                  <Paypal
                    oneProdValue={oneProdValue}
                    prodID={prodID}
                    id={id}
                    isSpin={isSpin}
                  />
                </Col>
              </Row>
              <Divider>or</Divider>
              <h5 className="mb-sm-4">Pay with debit or credit card</h5>
              <Form
                name="basic"
                // labelCol={{
                //   span: 6,
                // }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={paymentProfiles?.length === 0 ? onFinish : success}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
                className="d-flex flex-column gap-3"
              >
                {paymentProfiles?.length === 0 && <Form.Item
                  // label="Card Number"
                  name="cardNumber"
                  id="cardNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input your card number!",
                    },
                    {
                      min: 13,
                      message: "card number should be between 13 to 16 digits!",
                    },
                    {
                      max: 16,
                      message: "card number should be more than 16 digits!",
                    },
                  ]}
                >
                  <Input placeholder="Card Number" />
                </Form.Item>}
                {paymentProfiles?.length === 0 && <Form.Item
                  // label="Expiry Date"
                  name="expYear"
                  id="expYear"
                  rules={[
                    {
                      required: true,
                      message: "Please select the expiry date!",
                    },
                  ]}
                >
                  {/* <DatePicker format={"MM/YY"} /> */}
                  <DatePicker
                    className="w-100"
                    picker="month"
                    format={"MM/YY"}
                    placeholder="Expiry Date"
                  />
                </Form.Item>}
               {paymentProfiles?.length === 0 && <Form.Item
                  // label="CVV"
                  name="cardCode"
                  id="cardCode"
                  rules={[
                    {
                      required: true,
                      message: "Please input your card code!",
                    },
                    {
                      min: 3,
                    },
                    {
                      max: 4,
                    },
                  ]}
                >
                  <Input placeholder="CVV" />
                </Form.Item>}
               {paymentProfiles?.length === 0 && <Form.Item
                  name="zipPin"
                  rules={[
                    {
                      required: true,
                      message: "Please input your ZIP / PIN!",
                    },
                  ]}
                >
                  <Input placeholder="ZIP/ PIN" />
                </Form.Item>}
                <Row>
                  {paymentProfiles?.length !== 0 && paymentProfiles?.map((e, i) => {
                    return (
                      <>
                        {" "}
                        {e?.defaultPaymentProfile === true && <Col
                          lg={12}
                          className="d-flex justify-content-around  align-items-baseline"
                          key={e?.customerPaymentProfileId}
                        >
                          <div>
                            <Avatar shape="square" size="large" src={Visa} />
                          </div>
                          <p className="p-0 mb-0">
                            {e?.payment?.creditCard?.cardNumber}
                          </p>
                          {e?.defaultPaymentProfile === true && (
                            <p>
                              <TiTick
                                style={{ fontSize: "20px", color: "green" }}
                              />
                              Default
                            </p>)}
                          {/* {e?.defaultPaymentProfile === true ? (
                            <p>
                              <TiTick
                                style={{ fontSize: "20px", color: "green" }}
                              />
                              Default
                            </p>
                          ) : (
                            <div>
                              {" "}
                              <Radio
                                onChange={onChange}
                                value={e?.customerPaymentProfileId}
                              >
                                Set Default
                              </Radio>{" "}
                              <AiTwotoneDelete
                                style={{ fontSize: "25px", cursor: "pointer" }}
                                color="red"
                                onClick={(val) =>
                                  deleteCustomerCard(
                                    e?.customerPaymentProfileId
                                  )
                                }
                              />
                            </div>
                          )} */}
                          {/* <Radio>Proceed</Radio> */}
                        </Col>}
                        <Divider />
                      </>
                    );
                  })}
                </Row>
                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit" style={{width: '150px'}} className={`${btnLoading === true && 'd-flex align-items-center gap-1'}`}>
                {btnLoading === true && <LoadingOutlined />} <span>Pay (${oneProdValue?.price}) </span>
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default memo(Index);
