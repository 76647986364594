import { Alert, Breadcrumb } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAdvisorFullProfile } from "../../api/advisor";
import { NavBar } from "../../components";
import Footer from "../../components/Footer";
import BlackTagLine from "../../components/LandingPage/BlackTagLine";
import LiveFlowForm from "../../components/LiveFlow/LiveFlowForm";
import AdvisorDetails from "../../components/Reading/AdvisorDetails";
import ReadingForm from "../../components/Reading/ReadingForm";
import PurchaseModal from "../../modals/purchaseModal";
import PurchaseModalLive from "../../modals/purchaseModalLive";
import TopMyBalanceModal from "../../modals/TopMyBalance";
import "./index.css";

const Index = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [advisorDetails, setAdvisorDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purchaseID, setPurchaseID] = useState(null);
  const [isModalPurchaseOpen, setIsModalPurchaseOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancels = () => {
    setIsModalPurchaseOpen(false);
    // setSelectedItem(null);
    setPurchaseID(null);
  };
  useEffect(() => {
    getAdvisorFullProfile(id, dispatch);
  }, [id]);

  const advisorProfile = useSelector((x) => x.advisorProfile);
  console.log("advisorProfile", advisorProfile);

  useEffect(() => {
    setAdvisorDetails(advisorProfile);
  }, [advisorProfile]);
  console.log("purchase_ID", purchaseID);

  return (
    <>
      <NavBar />
      <Container
        fluid
        style={{ backgroundColor: "#FFFFFF" }}
        className="shadow-sm"
        
      >
        <div className="line-draw"></div>
        <Container  >
          <Breadcrumb separator=">" className="breadcrumb-containerr py-2">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item href={`/advisors/${id}`}>
              {advisorDetails?.username}
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/livereading/${id}`}>
              {"Live Chat"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Container>
        <div className="line-draw"></div>
      </Container>
      <Container fluid className="m-0 p-0" onClick={()=>{
        if(isVisible === true){
          setIsVisible(false);
        }
      }}>
        <Container >
          {purchaseID !== null && (
            <PurchaseModalLive
              isModalOpen={isModalPurchaseOpen}
              handleCancel={handleCancels}
              purchaseID={purchaseID}
              setIsModalOpen={setIsModalPurchaseOpen}
              selectedItem={selectedItem}
            />
          )}
          {/* <TopMyBalanceModal handleCancel={handleCancel} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/> */}
          <Row className=" py-4 m-0"  >
            <Col lg={6} sm={10} xs={10} className="p-0">
              <LiveFlowForm
                setIsModalOpen={setIsModalPurchaseOpen}
                setPurchaseID={setPurchaseID}
                handleCancels={handleCancels}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                advisorDetails={advisorDetails}
                isVisible={isVisible} 
                setIsVisible={setIsVisible}
              />
            </Col>
            <Col lg={6}>
              <AdvisorDetails />
            </Col>
          </Row>
        </Container>
      </Container>
      <BlackTagLine />
      <Footer />
    </>
  );
};

export default memo(Index);
