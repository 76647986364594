import React, { memo, useEffect, useState } from "react";
import { Button, Divider, message, Spin, Tabs } from "antd";
import { Link, useHistory } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Email } from "../../components";
import axios from "axios";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getInAppProducts } from "../../api/credits";
import PurchaseCreditsList from "../../components/PurchaseCreditsList";

const Index = () => {
  const history = useHistory();
  const [status, setStatus] = useState(false);
  const [appProd, setAppProd] = useState(null);
  const dispatch = useDispatch();
  const [isSpin, setIsSpin] = useState(true);

  useEffect(() => {
    getInAppProducts(setIsSpin, dispatch);
  }, []);

  const inappProducts = useSelector((state) => state.inAppProductList);

  useEffect(() => {
    setAppProd(inappProducts);
  }, [inappProducts]);

  console.log("inappProducts", inappProducts);
  console.log("appProd", appProd);
  return (
    <>
      <Container>
        <h2 className="text-center">Top up your balance</h2>
        {isSpin === false ? (
          <>
            {appProd !== null ? (
              <Row className="d-flex justify-content-center align-items-center vh-100">
                <Col
                  lg={8}
                  className="d-flex flex-column  justify-content-around "
                  style={{ border: "2px solid gray" }}
                >
                  <Tabs
                    defaultActiveKey="1"
                    centered
                    items={appProd?.page_list?.map((e, i) => {
                      const id = String(i + 1);
                      return {
                        label: `${e}`,
                        key: id,
                        children: <PurchaseCreditsList data={appProd[e]} />,
                      };
                    })}
                  />
                </Col>
              </Row>
            ) : (
              <Row className="d-flex justify-content-center">
                <h5>No data</h5>
              </Row>
            )}
          </>
        ) : (
          <Row className="d-flex justify-content-center align-items-center">
            <Spin />
          </Row>
        )}
      </Container>
    </>
  );
};

export default memo(Index);
