import React, { memo, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getInAppProducts } from "../../../api/credits";
import { TiTick } from "react-icons/ti";
import Visa from "../../../images/visa_logo.png";
import {
  chargeCustomer,
  deletePaymentProfile,
  getPaymentProfiles,
  updatePaymentProfile,
} from "../../../api/payment";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { AiTwotoneDelete } from "react-icons/ai";
import { setUpdatePaymentProfiles } from "../../../redux/actions";
// import "./index.css";

const Index = ({ oneProdValue, getpostdata , id}) => {
  console.log("idss", id.split('-', 2)[1]);
  const [messageApi, contextHolder] = message.useMessage();
  const history = useHistory();
  // const { id } = useParams();
  const dispatch = useDispatch()
  const [paymentCards, setPaymenTCards] = useState([]);
  const [isSpin, setIsSpin] = useState(true);
  const [chargeLoadBtn, setChargeLoadBtn] = useState(false)
  const onFinish = async (values) => {
    console.log("Success:", values);
    try {
      const res = axios.post("/");
    } catch (err) {
      console.log("err", err);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [appProd, setAppProd] = useState(null);
  // const dispatch = useDispatch();

  useEffect(() => {
    getInAppProducts(setIsSpin, dispatch);
  }, []);

  const inappProducts = useSelector((state) => state.inAppProductList);

  useEffect(() => {
    getPaymentProfiles(dispatch);
  }, []);

  useEffect(() => {
    getPaymentProfiles(dispatch);
  }, [getpostdata]);
  const paymentProfiles = useSelector((state) => state.paymentProfiles);
  console.log("paymentProfiles", paymentProfiles);

  useEffect(()=>{
    setPaymenTCards(paymentProfiles);
  }, [paymentProfiles])
  useEffect(() => {
    setAppProd(inappProducts);
  }, [inappProducts]);
  const callPaymentConfirmModal = () => {
    if(id !== undefined && id.split('-', 2)[1] === 'extended'){
      localStorage.setItem("purchasedLiveCredits", oneProdValue?.credits)
      localStorage.setItem("discountLiveCredits", oneProdValue?.discount)
    }
    if(id.split('-', 2)[1] === 'extended'){
      // localStorage.setItem('liveChatAdvisorID', 75);
     const advisorID = localStorage.getItem('liveChatAdvisorID');
     localStorage.removeItem("paypalToken");
      history.push(`/livechat/${advisorID.concat('-continue')}`);
    }else{
      if(localStorage.getItem('reading') !== null && localStorage.getItem('reading') !== undefined){
        const advisorIDD = JSON.parse(localStorage.getItem('reading'))
         history.push(`/chat/${advisorIDD?.advisorID}`);
       }else{
        localStorage.removeItem("paypalToken");
        history.push("/");
        message.success(`Your payment was received, and $ ${oneProdValue?.price} has been added to your credit balance. You earned $ ${oneProdValue?.discount} bonus.`);
       }
    }
    // Modal.success({
    //   content: (
    //     // <div className="d-flex flex-column justify-content-center align-items-center gap-3">
    //    <>  <p>
    //         Your payment was received, and $ {oneProdValue?.price} has been added to your credit
    //         balance.
    //       </p>
    //       <p>You earned $ {oneProdValue?.discount} bonus.</p></>

    //   ),
    //   okText: `${id.split('-', 2)[1] === 'extended' ? 'Continue Live Chat' : 'Ok'}`,
    //   maskClosable: false,
    //   onOk() {
    //     if(id.split('-', 2)[1] === 'extended'){
    //       // localStorage.setItem('liveChatAdvisorID', 75);
    //      const advisorID = localStorage.getItem('liveChatAdvisorID');
    //      localStorage.removeItem("paypalToken");
    //       history.push(`/livechat/${advisorID.concat('-continue')}`);
    //     }else{
    //       localStorage.removeItem("paypalToken");
    //       history.push("/");
    //     }
    //   },
    // });
  };
  const success = () => {
    message.success("Default card updated successfully!");
  };
  const deleteSuccess = () => {
    message.success("Default card deleted successfully!");
  };
  const makePayment = () => {
    setChargeLoadBtn(true)
    if (id !== undefined && id !== null) {
      const obj = {
        auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
        client_id: JSON.parse(localStorage.getItem("userInfo")).id, //200828, //
        purchase_item_id: id !== null && id !== undefined && id.split('-', 1)[0],
        receipt_email: JSON.parse(localStorage.getItem("userInfo")).email,
        device_type: "web",
        extended: id !== undefined && id.split('-', 2)[1] === 'extended' ? 1 : 0, // id.split('/', 1)[0]
      };
      chargeCustomer(obj,setChargeLoadBtn, callPaymentConfirmModal);
    }
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    const customerPaymentProfileId = e.target.value;
    updatePaymentProfile(customerPaymentProfileId, success, dispatch);
    const foundID = paymentCards?.filter(
      (paymentCards) =>
        paymentCards.customerPaymentProfileId === customerPaymentProfileId
    ).map((e1,i)=>{
      return {...e1,defaultPaymentProfile : true }
    });
    const otherIDs = paymentCards?.filter(
      (paymentCards) =>
        paymentCards.customerPaymentProfileId !== customerPaymentProfileId
    ).map((e,i)=>{
      return {...e, defaultPaymentProfile : false}
    })
    // console.log("idss", idss);
    console.log("foundID", foundID);
    console.log("otherIDs", otherIDs);
   const finalArr =  [...foundID, ...otherIDs]; 
    console.log("finalArr", finalArr);
    // setPaymenTCards(prev=> [...prev, finalArr])
    dispatch(setUpdatePaymentProfiles(finalArr))
    // getPaymentProfiles(dispatch);
  };
  const deleteCustomerCard = (customerPaymentProfileId) => {
    // jjj
    deletePaymentProfile(customerPaymentProfileId, paymentProfiles, deleteSuccess, dispatch);
  };
  return (
    <>
      <Row className="d-flex justify-content-center align-items-center">
        <Col
          lg={8}
          // className="d-flex flex-column justify-content-center"
        >
          {paymentProfiles?.length !== 0 ? (
            <Row
              className="d-flex flex-column justify-content-center gap-4"
              style={{ backgroundColor: "white" }}
            >
              <Col lg={10}>
                <h6>Card Details</h6>
                <Divider />

                <Row>
                  {paymentProfiles?.map((e, i) => {
                    return (
                      <>
                        {" "}
                        <Col
                          lg={12}
                          className="d-flex justify-content-around bg-light align-items-baseline"
                          key={e?.customerPaymentProfileId}
                        >
                          <div>
                            <Avatar shape="square" size="large" src={Visa} />
                          </div>
                          <p className="p-0 mb-0">
                            {e?.payment?.creditCard?.cardNumber}
                          </p>
                          {e?.defaultPaymentProfile === true ? (
                            <p>
                              <TiTick
                                style={{ fontSize: "20px", color: "green" }}
                              />
                              Default
                            </p>
                          ) : (
                            <div>
                              {" "}
                              <Radio
                                onChange={onChange}
                                value={e?.customerPaymentProfileId}
                              >
                                Set Default
                              </Radio>{" "}
                              <AiTwotoneDelete
                                style={{ fontSize: "25px", cursor: "pointer" }}
                                color="red"
                                onClick={(val) =>
                                  deleteCustomerCard(
                                    e?.customerPaymentProfileId
                                  )
                                }
                              />
                            </div>
                          )}
                          {/* <Radio>Proceed</Radio> */}
                        </Col>
                        <Divider />
                      </>
                    );
                  })}
                </Row>
              </Col>
              <Col
                lg={12}
                className="d-flex justify-content-center align-items-center"
              >
                <Button type="primary" onClick={makePayment} loading={chargeLoadBtn}>
                  Pay (${oneProdValue?.price})
                </Button>
              </Col>
            </Row>
          ) : (
            <Player
              autoplay
              loop
              src="https://assets8.lottiefiles.com/private_files/lf30_4b8xfsqj.json"
              style={{ height: "250px", width: "250px" }}
            >
              <Controls
                visible={false}
                buttons={["play", "repeat", "frame", "debug"]}
              />
            </Player>
          )}
        </Col>
      </Row>
    </>
  );
};

export default memo(Index);
