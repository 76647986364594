import clevertap from 'clevertap-web-sdk';

export const pushEvent = (event, metaData) => {
    try {
        clevertap.event.push(event, metaData)
    } catch (error) {
        console.log("err", error)
    }
  };

  