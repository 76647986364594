import { Avatar, Breadcrumb, Input } from "antd";
import React, { memo } from "react";
import { Col, Row, Container, Form, Button } from "react-bootstrap";
import NavBar from "../../../components/LandingPage/NavBar";
import "./index.css";
import { FiSearch } from "react-icons/fi";
import Filters from "./filters";
import LoveReadings from "../../../images/loveReadings.png"


const Index = ({ categoryObj, setLoading }) => {
  console.log("categoryObj", categoryObj);
  const onSearch = (value) => console.log(value);
  return (
    <>
      <div className={`main-bann ${(categoryObj !== null && categoryObj !== undefined) && 'main-bann-height'} `}>
        <div
          className={`banner-containers ${categoryObj?.bg_url}`}
        >
          <Container fluid className="nav-bg">
            <NavBar />
          </Container>
          <Container className="my-2">
            <Breadcrumb separator=">" className="breadcrumb-containerr">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              {categoryObj?.id ? (
                <>
                  <Breadcrumb.Item>Category</Breadcrumb.Item>
                  <Breadcrumb.Item>{categoryObj?.title}</Breadcrumb.Item>
                </>
        
              ): (
                <Breadcrumb.Item>All Advisors</Breadcrumb.Item>
              )}
            </Breadcrumb>
          </Container>
          <Container>
            <Row className="my-md-4">
              <Col lg={8} className="d-flex flex-column gap-3">
                {(categoryObj !== null && categoryObj !== undefined) && 
                <Avatar shape={categoryObj?.id ? 'square' : ''} size={64} src={categoryObj?.img}/>}
                <p className="heading-alladvisor">
                  {categoryObj !== null && categoryObj !== undefined
                    ? categoryObj?.title
                    : "All Advisors"}
                </p>
                <p className="description-alladvisor">
                  — We take pride in offering you the best advisors anywhere in
                  the world. Psychics, Tarot, Astrology and more. Your journey
                  to a brighter future starts with Psychic TXT.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className={`filter-container ${categoryObj !== null && categoryObj !== undefined ? 'top-1': ''}`}>
          <Filters setLoading={setLoading}/>
        </Container>
      </div>
    </>
  );
};

export default memo(Index);
