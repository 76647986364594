import React, { memo, useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Spin,
} from "antd";
import {
  createPaypalTransaction,
  getPaypalClientToken,
} from "../../../api/payment";
import { useDispatch } from "react-redux";
import {
  PayPalScriptProvider,
  BraintreePayPalButtons,
} from "@paypal/react-paypal-js";
import { useHistory } from "react-router-dom";

const Paypal = ({ oneProdValue, prodID, id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  //   const beforePaypalLogin = () => {
  //     getPaypalClientToken(dispatch);
  //   };
  const [isSpin, setIsSpin] = useState(false);

  console.log("iddItem", prodID.split("-")[0]);
  const callPaymentConfirmModal = (msg) => {
    setIsSpin(false);
    if (id !== undefined && id.split("-", 2)[1] === "extended") {
      localStorage.setItem("purchasedLiveCredits", oneProdValue?.credits);
      localStorage.setItem("discountLiveCredits", oneProdValue?.discount);
    }
    if (id.split("-", 2)[1] === "extended") {
      // localStorage.setItem('liveChatAdvisorID', 75);
      const advisorID = localStorage.getItem("liveChatAdvisorID");
      localStorage.removeItem("paypalToken");
      history.push(`/livechat/${advisorID.concat("-continue")}`);
    } else {
      localStorage.removeItem("paypalToken");
      message.success("Your ".concat(msg).concat("."));
      history.push("/");
    }
    // Modal.success({
    //   content: (
    //     // <div className="d-flex flex-column justify-content-center align-items-center gap-3">
    //     <>
    //       {" "}
    //       <p>{"Your ".concat(message).concat(".")}</p>
    //     </>
    //   ),
    //   okText: `${
    //     id.split("-", 2)[1] === "extended" ? "Continue Live Chat" : "Ok"
    //   }`,
    //   maskClosable: false,
    //   onOk() {
    //     if (id.split("-", 2)[1] === "extended") {
    //       // localStorage.setItem('liveChatAdvisorID', 75);
    //       const advisorID = localStorage.getItem("liveChatAdvisorID");
    //       localStorage.removeItem("paypalToken");
    //       history.push(`/livechat/${advisorID.concat("-continue")}`);
    //     } else {
    //       localStorage.removeItem("paypalToken");
    //       history.push("/");
    //     }
    //   },
    // });
  };
  return (
    <>
      {/* <Button type="primary" onClick={beforePaypalLogin}>
        Log In
      </Button> */}
      {localStorage.getItem("paypalToken") === undefined ||
      localStorage.getItem("paypalToken") === null ? (
        <Spin />
      ) : (
        <PayPalScriptProvider
          options={{
            "data-client-token": localStorage.getItem("paypalToken"),
            "client-id": "test", // 'production', //"test",
            "data-page-type": "checkout",
            // sdkBaseURL: "http://localhost.paypal.com:8000/sdk/js",
          }}
        >
          {/* <ButtonWrapper currency={"USD"} /> */}
          <BraintreePayPalButtons
            fundingSource="paypal"
            createOrder={(data, actions) => {
              return actions.braintree.createPayment({
                flow: "checkout",
                amount: oneProdValue?.price,
                currency: "USD",
                intent: "capture",
                // env: 'production'
              });
            }}
            onApprove={(data, actions) => {
              return actions.braintree.tokenizePayment(data).then((payload) => {
                setIsSpin(true);
                console.log("paypal_payload", payload);
                const userInfo = JSON.parse(localStorage.getItem("userInfo"));
                const obj = {
                  auth_key: "NWtBFkO8zOLHpSpmbCOOZBVobq7j2mAj",
                  payment_method_nonce: payload?.nonce,
                  amount: oneProdValue?.price,
                  description: "",
                  purchase_item_id: prodID.split("-")[0],
                  client_id: userInfo?.id,
                  extended:
                    id !== undefined && id.split("-", 2)[1] === "extended"
                      ? 1
                      : 0,
                  device_type: "web",
                };
                createPaypalTransaction(
                  obj,
                  callPaymentConfirmModal,
                  setIsSpin
                );
                // call server-side endpoint to finish the sale 33757137491, 33644626940
              });
            }}
          />
        </PayPalScriptProvider>
      )}
      {isSpin === true && (
        <div className="d-flex gap-1 align-items-center ms-2">
          <Spin />
          <p className="m-0 p-0" style={{fontSize: '0.7rem'}}>Processing...</p>
        </div>
      )}
    </>
  );
};

export default Paypal;
