import React from "react";
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { ImCross } from 'react-icons/im';

function Header({setShow, advisor}) {
  console.log("hiiiii--", advisor);
  return <div className="header">
     <div><Avatar src={advisor?.s3_display_picture} /> &nbsp;
     {advisor?.username}</div>
     <ImCross onClick={e => setShow(false)} className="cross"/>
  </div>;
}

export default Header;
