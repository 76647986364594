import React, { memo } from "react";
import { Container, Row } from "react-bootstrap";
import { NavBar } from "../../components";
import Footer from "../../components/Footer";
import PageError from '../../images/404.svg'

const Index = () => {

  return (
    <>
    <NavBar/>
      <Container fluid className="m-0 p-0 bg-light">
        <Row className="d-flex align-items-center justify-content-center vh-100">
          {/* <h1 className="text-center">Opps! Looks like you are lost!</h1> */}
          <div style={{width: '400px', height: '400px'}}>
          <img src={PageError} alt="404error" style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
          </div>
          
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default memo(Index);
