import {
  Button,
  Modal,
  Avatar,
  Spin,
  message,
  Alert,
  notification,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getCreateChatRoom,
  getUpdateChatRoom,
  liveChatLoopPoll,
} from "../api/chat";
import { getUserDetails } from "../api/client";
import { usePubNub } from "pubnub-react";
import "./index.css";

const LiveChatModal = ({
  isModalOpen,
  handleCancel,
  livechatAdvisorInfo,
  liveChatSessionMsg,
}) => {
  const pubnub = usePubNub();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showInfo, setShowInfo] = useState(false);
  const [liveCredits, setLiveCredits] = useState(null);
  const [channel, setChannel] = useState("");
  const [clearTime, setClearTime] = useState(false);
  const countRef = useRef(null);
  const countpoolRef = useRef(null);
  const [timer, setTimer] = useState(40);
  const [continueCredits, setContinueCredits] = useState(0);
  let countdownTimer;
  useEffect(() => {
    getUserDetails(dispatch);
  }, []);
  const profile = useSelector((state) => state.profile);
  console.log("profile", profile);
  // console.log("liveChatSessionMsg", liveChatSessionMsg);
  useEffect(() => {
    // setIsUser(profile);
    if (profile?.free_seconds && profile?.paid_seconds) {
      const credit_val = +profile?.free_seconds + +profile?.paid_seconds;
      const calcul = ((credit_val * 2) / 60).toFixed(2);
      setLiveCredits(calcul);
    }
  }, [profile]);
  var i = 40;
  const handleStartTimer = () => {
    // setTimerVisible(true);
    countRef.current = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    countpoolRef.current = setInterval(() => {
      const sessionID = localStorage.getItem("livechatsessionID");
      console.log("Step_2: Calling live_chat_accept pool");
      liveChatLoopPoll(
        sessionID,
        profile?.id,
        "live_chat_accept",
        history,
        handleResetTimer,
        livechatAdvisorInfo?.id,
        setContinueCredits
      );
    }, 4000);
  };

  const handleResetTimer = () => {
    clearInterval(countRef.current);
    setTimer(0);
    clearInterval(countpoolRef.current);
  };
  const chatMsg = useSelector((x) => x.chatMsg);
  console.log("chatMsg", chatMsg);
  useEffect(() => {
    if (chatMsg?.message_type === "livechat_accepted") {
      console.log("okk");
      handleResetTimer();
      history.push(`/live/${livechatAdvisorInfo?.id}`);
    }
    if (chatMsg?.message_type === "livechat_rejected") {
      handleResetTimer();
      const sessionID = localStorage.getItem("livechatsessionID");
      getUpdateChatRoom(sessionID, profile, "livechat_rejected", setShowInfo);
    }
    if (
      chatMsg?.message_type === "livechat_discard" &&
      chatMsg?.sender_type === "advisor"
    ) {
      message.info("Advisor has cancelled the call.");
      history.push('/');
    }
  }, [chatMsg]);
  // function startTimer() {
  //   countdownTimer = setInterval(function () {
  //     console.log(i);
  //     i = i - 1;
  //     if (i <= 0) {
  //       // clearInterval(countdownTimer);
  //       handleResetTimer();
  //       const sessionID = localStorage.getItem("livechatsessionID");
  //       getUpdateChatRoom(sessionID, profile, "live_chat_miss", setShowInfo);
  //       // setShowInfo(false);
  //     }
  //   }, 1000);
  // }
  useEffect(() => {
    if (timer <= 0) {
      handleResetTimer();
      const sessionID = localStorage.getItem("livechatsessionID");
      getUpdateChatRoom(sessionID, profile, "live_chat_miss", setShowInfo);
    }
  }, [timer]);
  console.log("timer", timer);
  const leaveApplication = () => {
    pubnub.removeListener(listnerAdd);
    pubnub.unsubscribeAll();
  };
  const listnerAdd = {
    status: (statusEvent) => {
      var subscribedChannels = statusEvent.subscribedChannels;
      console.log("subscribedChannels", subscribedChannels);
      if (statusEvent.category === "PNConnectedCategory") {
        console.log("Connected to Channel:", channel);
      }
    },
    message: (event) => {
      const message = event.message;
      console.log("message", message);
    },
  };
  useEffect(() => {
    //  const channell= localStorage.getItem('livechatsessionID');
    //   setChannel(channell);
    pubnub.addListener(listnerAdd);
    if (channel !== "") {
      console.log("channel", channel);
      pubnub.subscribe({
        channels: ["live_session_".concat(channel)],
        withPresence: true,
      });
      // pubnub.publish({
      //   channel: [channels, "channel_application"],
      //   message: "Hello World!",
      // });
    }
    return leaveApplication;
  }, [pubnub, channel]);
  console.log("channel", channel);
  const onFinish = async () => {
    if (liveCredits >= livechatAdvisorInfo?.live_rate * 2) {
      const liveStatus = livechatAdvisorInfo?.live_status;
      if (liveStatus === "0") {
        notification.open({
          message: "Notification!",
          description: "Advisor is not live at the moment.",
          duration: 15,
        });
        handleCancel();
      }
      if (liveStatus === "2") {
        notification.open({
          message: "Notification!",
          description:
            "Advisor is busy on another call. Please try again later.",
          duration: 15,
        });
        handleCancel();
      }
      if (liveStatus === "1") {
        setShowInfo(true);
        console.log("step_1: Advisor live sttaus is on so create chat room");
        getCreateChatRoom(
          livechatAdvisorInfo?.id,
          profile?.id,
          setShowInfo,
          setChannel,
          handleStartTimer,
          history,
          dispatch
        );
        // history.push(`/livechat/${livechatAdvisorInfo.id}`);
      }
    } else {
      history.push("/credits/live");
    }
  };
  const updateSessionDetails = () => {
    // clearInterval(countdownTimer);
    message.info("Live Chat cancelled!");
    handleResetTimer();
    const sessionID = localStorage.getItem("livechatsessionID");
    getUpdateChatRoom(sessionID, profile, "live_chat_cancel", setShowInfo);
    // i=0;
    setShowInfo(false);
  };
  console.log("livechatAdvisorInfo", livechatAdvisorInfo);
  console.log("liveCredits", liveCredits);
  return (
    <>
      <Modal
        title="Live Chat"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        style={{ width: "850px", padding: '20px !important' }}
      >
        {showInfo === false ? (
          <>
            {liveCredits !== null ? (
              <>
                {/* {liveCredits >= livechatAdvisorInfo?.live_rate * 2 ? ( */}
                {/* <> */}
                <div className="livechat-elments pt-3">
                  <Avatar src={livechatAdvisorInfo?.s3_display_picture} />
                  <h6>{livechatAdvisorInfo?.live_rate} Cr/Min</h6>
                  <h5>Live Chat Session Request</h5>
                  <h6>Do you want to start the live chat with this psychic?</h6>
                  <h6>Your Blance:</h6>
                  <h6>
                    <b>{liveCredits}</b> Live chat credits
                  </h6>
                </div>
                <div className="btn-options pb-3">
                  <Button type="danger" onClick={onFinish}>
                    Connect
                  </Button>
                  <Button type="primary" onClick={(e) => handleCancel()}>
                    Cancel
                  </Button>
                </div>
              </>
            ) : (
              // ) : (
              //   <Alert
              //     message="Credits"
              //     description="Please update your live credits!"
              //     type="info"
              //     showIcon
              //   />
              // )}
              // </>
              <Spin />
            )}
          </>
        ) : (
          <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
            <Spin />
            <h6>We are trying to connect to your psychic. Please Wait.</h6>
            <Button type="danger" onClick={updateSessionDetails}>
              Cancel
            </Button>
          </Container>
        )}
      </Modal>
    </>
  );
};
export default LiveChatModal;
