import React, { useState } from "react";
import { Col, Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Avatar, Spin } from "antd";
import "./index.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-alice-carousel/lib/alice-carousel.css";

import { cardData } from "./data";
import { useHistory } from "react-router-dom";
import Arrow from "../../../images/arrowIcon.png"
const CardOne = ({e}) => {
  const history = useHistory();
  const [isEnable, setIsEnable] = useState(false);
  
  return (
    <>

                  <Card
                    className={`category ${isEnable === true && 'border-categ'} `}
                    key={e.id}
                  >
                    <Card.Body>
                      <div className="d-flex flex-column gap-3">
                      <div className={`d-flex ${isEnable === true && 'justify-content-between'} `}>
                          <Avatar shape="square" size={60} src={e.img} />
                        {isEnable === true && <div> <img src={Arrow} alt="arrowIcon" /></div>}
                          </div>
                        <p className="font-category">
                          {e.title}
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                
    </>
  );
};

export default CardOne;
