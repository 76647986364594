import {
  setAdvisorsListing,
  setMessaging,
  setClientInfo,
  setReviewMetaData,
  setClientChat,
  setUserProfile,
  setAdvisorProfileDetails,
  setChatMsg,
  setCreateChatRoom,
  setInAppProductList,
  getPaymentProfiles,
  setOneWebProductList,
  updatePaymentProfiles,
  deletePaymentProfiles,
  setUpdateCreditsMsg,
  refreshListingAdvisor,
  setReviewsForAdvisor
} from "./signalsReducer";

export const setListingAdvisors = (advisorListing) => (dispatch) => {
  try {
    dispatch(setAdvisorsListing(advisorListing));
  } catch (error) {}
};

export const setMessages = (messages) => (dispatch) => {
  console.log("message in actions----", messages);
  try {
    if(messages?.message_type === "update_credits"){
      dispatch(setUpdateCreditsMsg(messages.payload));
    }
    if(messages?.auth_key !== '' && messages?.message_type !== "refreshAdvisorList" && messages?.message_type !== 'update_credits'){
      dispatch(setChatMsg(messages));
    }
    if(messages?.message_type === 'livechat_accepted' || messages?.message_type === 'livechat_start'){
      dispatch(setChatMsg(messages));
    }
    // dispatch(setMessaging(messages));
  } catch (error) {}
};
export const refreshListAdvisor = (messages) => (dispatch) => {
  console.log("message in actions----", messages);
  try {
    if(messages?.message_type === "refreshAdvisorList"){
      dispatch(refreshListingAdvisor(messages));
    }
    // dispatch(setMessaging(messages));
  } catch (error) {}
};
export const setUserInfo = (userInfo) => (dispatch) => {
  try {
    dispatch(setClientInfo(userInfo));
  } catch (error) {}
};

export const setMetaDataOfReview = (reviewMetadata) => (dispatch) => {
  try {
    dispatch(setReviewMetaData(reviewMetadata));
  } catch (error) {}
};
export const setAdvisorReviews = (advisorReviews) => (dispatch) => {
  try {
    dispatch(setReviewsForAdvisor(advisorReviews));
  } catch (error) {}
};

export const setChatContentClient = (chatContent) => (dispatch) => {
  try {
    dispatch(setClientChat(chatContent));
  } catch (error) {}
};

export const setUserProfileInfo = (profile) => (dispatch) => {
  try {
    dispatch(setUserProfile(profile));
  } catch (error) {}
};
export const setAdvisorProfile = (advisorProfile) => (dispatch) => {
  try {
    dispatch(setAdvisorProfileDetails(advisorProfile));
  } catch (error) {}
};

export const setCreateLiveChatRoom = (liveChatRoomDetails) => (dispatch) => {
  try {
    dispatch(setCreateChatRoom(liveChatRoomDetails));
  } catch (error) {}
};

export const setInAppProductListing = (inAppProductList) => (dispatch) => {
  try {
    dispatch(setInAppProductList(inAppProductList));
  } catch (error) {}
};
export const setOneWebProductListing = (oneProductList) => (dispatch) => {
  try {
    dispatch(setOneWebProductList(oneProductList));
  } catch (error) {}
};
export const setGetPaymentProfiles = (paymentProfiles) => (dispatch) => {
  try {
    dispatch(getPaymentProfiles(paymentProfiles));
  } catch (error) {}
};
export const setUpdatePaymentProfiles = (paymentProfiles) => (dispatch) => {
  try {
    dispatch(updatePaymentProfiles(paymentProfiles));
  } catch (error) {}
};
export const setDeletePaymentProfiles = (paymentProfiles) => (dispatch) => {
  try {
    dispatch(deletePaymentProfiles(paymentProfiles));
  } catch (error) {}
};

