import React, { memo, useEffect, useState } from "react";
import { Alert, Button, Divider, Select } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { getUpdateChatRoom } from "../api/chat";


const PurchasecreditsModal = ({ setIsModalOpen4, profile, setShowInfo }) => {
  const history = useHistory();
  const extendMySession = () =>{
    history.push("/extended-credits/Live_Credit");
  }

  const endChat = () => {
    const sessionID = localStorage.getItem("livechatsessionID");
    getUpdateChatRoom(
      sessionID,
      profile,
      "live_chat_end",
      setShowInfo,
      history
    );
    localStorage.removeItem("livechatsessionID");
    localStorage.removeItem('liveChatAdvisorID');
  };
  return (
    <>
      <Container>
        <Row className="d-flex justify-content-center align-items-center">
          <Col lg={12}>
            <Alert
            title="Out of Live Chat Credits"
              description="Extend your session for a Special Discount now"
              type="info"
              showIcon
            />
            <div className="d-flex flex-column align-items-center justify-content-center w-100 mt-sm-2">
            <Button type="primary" onClick={extendMySession}>Extend My Session</Button>
            <Divider />
            <Button onClick={endChat}>End Session</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PurchasecreditsModal;
