import React, { memo, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ChatContent from "../../components/Inbox/ChatContent";
import ChatSidebar from "../../components/Inbox/ChatSidebar";
import NavBarInbox from "../../components/Chat/NavBarInbox";
import "./index.css";
import { useSelector } from "react-redux";

const Index = () => {
  const [advisorID, setAdvisorID] = useState();
  const [pageNum, setPageNum] = useState(0);
  const [advisorName, setAdvisorName] = useState("");
  const [advisorOnlineStatus, setAdvisorOnlineStatus] = useState("");
  const [chatArr, setChatArr] = useState([]);
  const [advisorLink, setAdvisorLink] = useState(null);
  const [advisorsID, setAdvisorsID] = useState();
  const [advisorRefreshList, setAdvisorRefreshList] = useState();
  const { id, slug } = useParams();
  const [hasData, setHasData] = useState(true);
  const [page, setPage] = useState(0);
  console.log("idCHECK", id, slug);

  console.log("pageNum", pageNum);
  useEffect(() => {
    setAdvisorsID(id);
  }, [id]);

  const advisorPubnubList = useSelector((x)=> x.refreshList);
  console.log("advisorPubnubList", advisorPubnubList);

  useEffect(()=>{
    if (Object.keys(advisorPubnubList).length !== 0) {
      var newObj = Object.values(advisorPubnubList?.advisor)[0];
      console.log("new", newObj);
      setAdvisorRefreshList(newObj);
  
    }
  }, [advisorPubnubList])

 
console.log("advisorRefreshList", advisorRefreshList);
  return (
    <>
      <Container fluid className="main-chat-sec">
        <NavBarInbox />
        <Container>
          <Row className="view mx-0">
            <Col lg={3} className="sidebar shadow-sm px-3">
              <ChatSidebar
                // getChatID={getChatID}
                advisorRefreshList={advisorRefreshList}
                setAdvisorID={setAdvisorID}
                setAdvisorLink={setAdvisorLink}
                setAdvisorName={setAdvisorName}
                setAdvisorOnlineStatus={setAdvisorOnlineStatus}
                setHasData={setHasData}
                setPage={setPage}
                advisorsID={advisorsID}
              />
            </Col>
            <Col lg={9} className="content shadow-sm p-0" id="msg_content">
              {advisorsID && (
                <ChatContent
                  advisorID={advisorID}
                  pageNum={pageNum}
                  advisorName={advisorName}
                  advisorOnlineStatus={advisorOnlineStatus}
                  setChatArr={setChatArr}
                  chatArr={chatArr}
                  advisorLink={advisorLink}
                  advisorsID={advisorsID}
                  setAdvisorLink={setAdvisorLink}
                  setAdvisorName={setAdvisorName}
                  setAdvisorOnlineStatus={setAdvisorOnlineStatus}
                  advisorRefreshList={advisorRefreshList}
                  setHasData={setHasData}
                  hasData={hasData}
                  setPage={setPage}
                  page={page}
                />
              )} 
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default memo(Index);
