import React, { memo, useEffect, useState } from "react";
import { updateUserCredits } from "../../api";
import { MeetOurAdvisors } from "../../components";
import { usePubNub } from "pubnub-react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../api/client";
import BlackTagLine from "../../components/LandingPage/BlackTagLine";
import Footer from "../../components/Footer";
import AllAdvisorBanner from "../../components/Advisors/AllAdvisorBanner";
import {advisorCategory} from '../../components/Advisors/AllAdvisorBanner/category';
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
const Index = () => {
  const pubnub = usePubNub();
  const dispatch = useDispatch();
  const [navInfo, setNavInfo] = useState(null);
  const [isUser, setIsUser] = useState(null);
  const [channels, setChannels] = useState("");
  const [categoryObj, setCategoryObj] = useState(null);
  const {id} = useParams();
  
  // const params = useParams();
  const [loading, setLoading] = useState(false)
  console.log("idddd--", id);
  // console.log("slug--", idd);
  useEffect(()=>{
  const finded = advisorCategory?.find((e) => e?.id === id);
  console.log("finded", finded);
  setCategoryObj(finded)
  }, [id])
  useEffect(() => {
    getUserDetails(dispatch);
  }, [dispatch]);

  const profile = useSelector((state) => state.profile);
  console.log("profile", profile);
  useEffect(() => {
    setIsUser(profile);
  }, [profile]);

  const listnerAdd = {
    status: (statusEvent) => {
      var subscribedChannels = statusEvent.subscribedChannels;
      console.log("subscribedChannels", subscribedChannels);
      if (statusEvent.category === "PNConnectedCategory") {
        console.log("Connected nav");
      }
    },
    message: (event) => {
      const message = event.message;
      console.log("main_msgs", message);
      updateUserCredits(message, dispatch);
    },
  };
  useEffect(() => {
    const idd = JSON.parse(localStorage.getItem("userInfo"));
    setChannels(
      "Channel_User_".concat(idd !== undefined ? idd?.id : isUser?.id)
    );
  }, [isUser]);

  const leaveApplication = () => {
    pubnub.removeListener(listnerAdd);
    pubnub.unsubscribeAll();
  };
  useEffect(() => {
    pubnub.addListener(listnerAdd);
    if (channels) {
      console.log("channels", channels);
      pubnub.subscribe({
        channels: [channels, "channel_application"],
        withPresence: true,
      });
    }
    return leaveApplication;
  }, []);

  console.log("advisorCategory", advisorCategory);
  return (
    <>
    <Container fluid className="bg-light p-0 m-0">
      <AllAdvisorBanner categoryObj={categoryObj} loading={loading} setLoading={setLoading}/>
      <MeetOurAdvisors setNavInfo={setNavInfo} loading={loading} setLoading={setLoading}/>
      <BlackTagLine />
      <Footer />
      </Container>
    </>
  );
};

export default memo(Index);
