import { Alert, Button, Card, Divider, Modal } from "antd";
import React, { memo, useEffect, useState } from "react";
import { DatePicker, Form, Input, Select } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { updateUserInfo } from "../api/client";
import { useDispatch } from "react-redux";
import SigninModal from "./signinModal";
import { useHistory, useParams } from "react-router-dom";
import { ImFilePicture } from "react-icons/im";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "./index.css";
const { Option } = Select;
dayjs.extend(customParseFormat);
const dateFormat = "YYYY/MM/DD";
const LiveChatFlowModal = ({
  setModalLiveOpen,
  modalLiveOpen,
  setIsTitle,
  setModal2Open,
  setReturnToPoint,
  advisorId,
}) => {

  console.log("hiiiiiiiiiiii");
  // const history = useHistory();
  const [isDisable, setIsDisable] = useState(true);
  // const [modal2Open, setModal2Open] = useState(false);
  // const [isTitle, setIsTitle] = useState(null);
  // const history = useHistory();
  // const { id } = useParams();
  // const [img, setImg] = useState(null);
  // var returnTo = "/";
  // useEffect(()=>{
  //   getAdvisorFullProfile(id, dispatch);
  // }, [id])
  // const advisorProfile = useSelector((x) => x.advisorProfile);
  // console.log("advisorProfile", advisorProfile);

  // useEffect(() => {
  //   setProff(advisorProfile);
  // }, [advisorProfile]);

  // useEffect(()=>{
  //   if(localStorage.getItem('openLiveModal')){
  //     localStorage.removeItem('openLiveModal')
  //   }
  // }, [])
  const onFinish = async (values) => {
    console.log("Success:", values);
      const obj = {...values, advisorID: advisorId }
      localStorage.setItem("livereading", JSON.stringify(obj));
      setReturnToPoint(`/livereading/${advisorId}`)
      // setModal2Open(true);
      setIsTitle("Create an account!");
      setModal2Open(true);
      setModalLiveOpen(false);
    
    // localStorage.setItem("question", values?.question);
    // updateUserInfo(values, dispatch);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const userInformation = localStorage.getItem("emailForSignIn") || "";
    console.log("userInformation", userInformation);
  });
  const checkDob = (date, dateString) => {
    setIsDisable(false);
    console.log("Value", date, dateString);

    const date1 = new Date(dateString);
    const date2 = new Date();
    let yearsDiff = date2.getFullYear() - date1.getFullYear();
    console.log("yearsDiff", yearsDiff);
    if (yearsDiff >= 18) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  };

  // console.log("type", '+'.concat((phone?.phone)?.slice(0,1))).concat('**** ***').concat((phone?.phone)?.slice(-3));
  return (
    <>
      <Modal
        // title="Sign up/Signin"
        // className={`"shadow main_modal_view"`}
        centered
        open={modalLiveOpen}
        onCancel={() => setModalLiveOpen(false)}
        onOk={() => setModalLiveOpen(false)}
        // closable={false}
        width={700}
        // height={500}
        footer={null}
      >
        <div className="d-flex flex-column justify-content-center align-items-center" style={{height: '400px'}}>
        <h5>Introduce yourself to your psychic. </h5>
        <Form
          name="basic"
          // labelCol={{
          //   span: 8,
          // }}
          // wrapperCol={{
          //   span: 16,
          // }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="d-flex flex-column align-items-center justify-content-around gap-3 py-5"
        >
          <Form.Item
            name="username"
            // label="User Name"
            rules={[
              {
                required: true,
                message: "Please enter your user name!",
              },
              {
                message: "Minimum (3)",
                min: 3,
              },
            ]}
          >
            <Input style={{width: '300px'}} placeholder="username" />
          </Form.Item>
          <Form.Item
            name="date"
            // label="Date of Birth"
            rules={[
              {
                required: true,
                message: "Please input your date of birth!",
              },
            ]}
            validateStatus={isDisable === false ? "error" : ""}
            help={isDisable === false ? "User should be atleast 18 years" : ""}
          >
            <DatePicker
              format={dateFormat}
              onChange={checkDob}
              placeholder={moment(new Date()).format("YYYY-MM-DD")}
              style={{width: '300px'}}
            />
            {/* <DatePicker
                  className="w-100"
                  onChange={checkDob}
                  placeholder={moment(new Date()).format("YYYY-MM-DD")}
                /> */}
          </Form.Item>
          <Form.Item
            name="gender"
            // label="Gender"
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
          >
            <Select
              placeholder="Select gender"
              // onChange={this.onGenderChange}
              allowClear
              style={{width: '300px'}}
            >
              <Option value="Male">Male</Option>
              <Option value="Female">Female</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item
            // wrapperCol={{
            //   offset: 4,
            //   span: 10,
            // }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
        </div>
      </Modal>
    </>
  );
};
export default LiveChatFlowModal;
