import React, { memo, useEffect, useState } from "react";
import { Alert, Button, DatePicker, Form, Input, Select } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { updateUserInfo } from "../../../api/client";
import { useDispatch } from "react-redux";
import SigninModal from "../../../modals/signinModal";
import { useHistory, useParams } from "react-router-dom";
import { ImFilePicture } from "react-icons/im";
import './index.css';

const { Option } = Select;

const Part1 = ({ setUserPopDetails, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const [isDisable, setIsDisable] = useState(true);
  const [modal2Open, setModal2Open] = useState(false);
  const [isTitle, setIsTitle] = useState(null);
  const history = useHistory();
  const {id} = useParams()
  const [img, setImg] = useState(null);
  const [displayImg, setDisplayImg] = useState(null);
  var returnTo = `/reading/${id}`;

  const onFinish = async (values) => {
    console.log("Success:", values);
    let msgRead = 'SITUATION: ' + values?.situation + ' ' + 'QUESTION: ' + values?.question;
    if(img !== null){
      const obj = {...values, advisorID: id, selectedFile: img, reading: msgRead}
      localStorage.setItem("reading", JSON.stringify(obj));
      history.push('/signup')
      // setIsTitle("Create an account!");
      // setModal2Open(true);
    }else{
      const obj = {...values, advisorID: id, reading: msgRead}
      localStorage.setItem("reading", JSON.stringify(obj));
      history.push('/signup')
      // setIsTitle("Create an account!");
      // setModal2Open(true);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const userInformation = localStorage.getItem("emailForSignIn") || "";
    console.log("userInformation", userInformation);
  });
  const checkDob = (date, dateString) => {
    setIsDisable(false);
    console.log("Value", date, dateString);

    const date1 = new Date(dateString);
    const date2 = new Date();
    let yearsDiff = date2.getFullYear() - date1.getFullYear();
    console.log("yearsDiff", yearsDiff);
    if (yearsDiff >= 18) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  };
  const handler = async (e) => {
    console.log("fileee", e);
    var filename = e?.target?.files[0];
    const {name} = e?.target?.files[0];
    console.log("filename", name?.split('.')[0]);
    setDisplayImg(e?.target?.files[0])
  
   setImg({ file: URL.createObjectURL(e?.target?.files[0]), type: "photo" , fileName: name?.split('.')[0]});
  };
  useEffect(()=>{
    console.log('ch', img?.file);
  }, [])
  console.log("img", img);
  console.log("displayImg", displayImg);
  return (
    <>
<div className="part1_main_container">
<p className="part1_promotext mb-2">Promo/ Student Code</p>
<Form
              name="basic"
            //   labelCol={{
            //     span: 8,
            //   }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className="d-flex gap-3 w-100"
            //   style={{width: '90%'}}
            >
               
              <Form.Item
                name="username"
                // label="User Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your user name!",
                  },
                  {
                    message: "Minimum (3)",
                    min: 3,
                  },
                ]}
                style={{width: '75%'}}
              >
                <Input placeholder="Enter Promo Code Here ..." className="promo_input"/>
              </Form.Item>
              <Form.Item
                // wrapperCol={{
                //   offset: 6,
                //   span: 10,
                // }}
              >
                <Button type="primary" htmlType="submit" className="button_apply">
                  Apply
                </Button>
              </Form.Item>
            </Form>
</div>
          
    </>
  );
};

export default Part1;
