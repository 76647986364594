import axios from "axios";
import React, { memo, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import { useParams } from "react-router-dom";

import "./index.css";
import Banner from "../../../images/profile_background.png";
import { Avatar, Tag } from "antd";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";

const Index = ({advisorDetails}) => {
  console.log("advisorDetails", advisorDetails);
  return (
    <>
      <div className="main_profile_banner">
        <div className="main_profile_banner_img">
          <img src={Banner} alt="profile_banner" />
        </div>
        <Container className="main_profile_banner_content" id="main_profile_banner_content">
          <Row className="p-4 position-row">
            {/* <Col lg={1}></Col> */}
            <Col lg={3}>
              <Section1 advisorDetails={advisorDetails} />
            </Col>
            <Col
              lg={6}
              className="d-flex justify-content-start align-items-start"
              style={{position: 'relative'}}
            >
              <Section2 advisorDetails={advisorDetails}/>
            </Col>
            <Col lg={3}>
                <Section3 advisorDetails={advisorDetails}/>
            </Col>
          </Row>
        </Container>
        
      </div>
    </>
  );
};

export default memo(Index);
